import React, {useMemo} from "react";
import clsx from "clsx";
import {useTable, useFlexLayout, useResizeColumns, useSortBy, useGlobalFilter} from "react-table";
import Cell from "./Cell";
import Header from "./HeaderSort";

const defaultColumn = {
  minWidth: 50,
  width: 150,
  maxWidth: 400,
  Cell: Cell,
  Header: Header,
  sortType: "alphanumericFalsyLast"
};


export default function Table({columns, data, dispatch: dataDispatch, skipReset, filtervalue, Byoutou}) {
  const sortTypes = useMemo(
    () => ({
      alphanumericFalsyLast(rowA, rowB, columnId, desc) {
        if (!rowA.values[columnId] && !rowB.values[columnId]) {
          return 0;
        }

        if (!rowA.values[columnId]) {
          return desc ? -1 : 1;
        }

        if (!rowB.values[columnId]) {
          return desc ? 1 : -1;
        }

        return isNaN(rowA.values[columnId])
          ? rowA.values[columnId].localeCompare(rowB.values[columnId])
          : rowA.values[columnId] - rowB.values[columnId];
      }
    }),
    []
  );

  const {
    getTableProps, 
    getTableBodyProps, 
    headerGroups, 
    rows, 
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      dataDispatch,
      autoResetSortBy: !skipReset,
      autoResetFilters: !skipReset,
      autoResetRowState: !skipReset,
      sortTypes,
    },
    useFlexLayout,
    useResizeColumns,
    useGlobalFilter, 
    useSortBy,
  );

  function isTableResizing() {
    for (let headerGroup of headerGroups) {
      for (let column of headerGroup.headers) {
        if (column.isResizing) {
          return true;
        }
      }
    }

    return false;
  }

  function getFilteredRows() {
    if (filtervalue[0].length === 0 && filtervalue[1].length === 0){
      return rows
    }

    if (filtervalue[0].length!==0 && filtervalue[1].length===0){
      return rows.filter((row) => {
        const staff = JSON.parse(localStorage.getItem('Staff-' + Byoutou))[row.id];
        return (
          (filtervalue[0].length !== 0 && filtervalue[0].includes(staff.TEAM))
        );
      });
    }
    
    if (filtervalue[0].length===0 && filtervalue[1].length!==0){
      return rows.filter((row) => {
        const staff = JSON.parse(localStorage.getItem('Staff-' + Byoutou))[row.id];
        return (
          (filtervalue[1].length !== 0 && filtervalue[1].includes(staff.skill))
        );
      });
    }

    if (filtervalue[0].length!==0 && filtervalue[1].length!==0){
      return rows.filter((row) => {
        const staff = JSON.parse(localStorage.getItem('Staff-' + Byoutou))[row.id];
        return (
          (filtervalue[0].length !== 0 && filtervalue[0].includes(staff.TEAM)) &&
          (filtervalue[1].length !== 0 && filtervalue[1].includes(staff.skill))
        );
      });
    }
  }

  const filteredRows = getFilteredRows();

  return (
    <>
      <div {...getTableProps()} className={clsx("table", isTableResizing() && "noselect")}>
        <div className="thead">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className='tr-header'>
              {headerGroup.headers.map((column) => column.render("Header", {
                globalFilter: state.globalFilter,
                setGlobalFilter,
                columnState: state,
              }))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()} >
          {filteredRows.map((row, i) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className='tr'>
                {row.cells.map((cell) => (
                  <div {...cell.getCellProps()} className='td2'>
                    {cell.render("Cell")}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
