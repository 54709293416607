import { setcol } from "../../../Table/colors";
import React, { useRef, useEffect, useReducer, useState, useCallback, ref } from "react";
import "../../../styles.css";
import Table from "../../../Table/TableS";
import { randomColor, shortId } from "../../../Table/utils";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Select from 'react-select'
import {usePopper} from "react-popper";
import Relationship from "../../../Table/Relationship";
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';

function RandomColor() {
  return `hsl(${Math.floor(Math.random() * 360)}, 95%, 90%)`;
}

class ExcelRequestsImport extends React.Component {
  state = {
    cols: [],
    rows: []
  };

  constructor(props) {
    super(props);
    this.state = {
      keyvalue: { ward: '', year: '', month: '' },
    };
  }

  componentDidMount() {
    const localvalue = JSON.parse(localStorage.getItem('TeamSkill-key'));
    this.setState({ keyvalue: localvalue });
  }

  render() {
    if (sessionStorage.getItem("Login")==="1"){
      sessionStorage.setItem("Login", "1")
    }    
    let Byoutou = localStorage.getItem("Byoutou")
    let byoutou = Byoutou.toLocaleLowerCase()

    const JapaneseHolidays = require('japanese-holidays');
 
    var date = new Date();
    var lastDate = new Date(date.getFullYear(), date.getMonth()+2, 0);
    var days = lastDate.getDate()
    const isholiday = JapaneseHolidays.getHolidaysOf(lastDate.getFullYear(), true);

    const holiday = []
    for (let i = 0; i < isholiday.length; i++){
      if (isholiday[i].month===lastDate.getMonth()+1){
        holiday.push(isholiday[i].date)
      }
    }

    const weekend = []
    for (let i = 1; i <= days; i++){
      var day = new Date(date.getFullYear(), date.getMonth()+2, i)
      if ([0, 6].includes(day.getDay())){
        weekend.push(i)
      }
      if (holiday.includes(i)){
        if (!weekend.includes(i)){
          weekend.push(i)
        }
      }
    }

    if (localStorage.getItem('ts1'+byoutou)!=2){
      localStorage.setItem('ts1'+byoutou, 1)
    }

    let skilloptions = [
      {label: "HEAD", value: "HEAD", level: "師長", backgroundColor: setcol(20)},
      {label: "TL", value: "TL", level: "リーダー", backgroundColor: setcol(7)},
      {label: "L2", value: "L2" , level: "リーダー", backgroundColor: setcol(7)},
      {label: "L1", value: "L1", level: "リーダー" , backgroundColor: setcol(7)},
      {label: "L", value: "L", level: "リーダー", backgroundColor: setcol(7)},
      {label: "M", value: "M", level: "メンバー", backgroundColor: setcol(19)},
      {label: "M2", value: "M2", level: "メンバー", backgroundColor: setcol(19)},
      {label: "M1", value: "M1", level: "メンバー", backgroundColor: setcol(19)},
    ];

    let leveloptions = [
      {label: "師長", value: "HEAD", backgroundColor: setcol(20)},
      {label: "リーダー", value: "Leader" , backgroundColor: setcol(7)},
      {label: "メンバー", value: "Member", backgroundColor: setcol(19)},
    ]

    let teamoptions 
    = (Byoutou=="ICU")
    ? [
        {label: "A", value: "A", backgroundColor: setcol(3)},
    ]
    : (Byoutou=="C3")
    ? [
      {label: "A", value:"A", backgroundColor: setcol(3)},
      {label: "B", value:"B",backgroundColor: setcol(16)},
      {label: "C", value:"C",backgroundColor: setcol(6)},
    ]
    : (Byoutou=="C5")
    ? [
      {label: "A", value:"A",backgroundColor: setcol(3)},
      {label: "B", value:"B",backgroundColor: setcol(16)},
      {label: "HCU", value:"HCU",backgroundColor: setcol(6)},
    ]
    : (Byoutou=="W2")
    ? [
      {label: "A", value:"A",backgroundColor: setcol(3)},
      {label: "B", value:"B",backgroundColor: setcol(16)},
      {label: "C", value:"C",backgroundColor: setcol(6)},
    ]
    : []

    let shiftoptions 
    = (Byoutou=="ICU")
    ? [
      {label: "管", backgroundColor: setcol(20)},
      {label: "◎", backgroundColor: setcol(20)},  
      {label: "ｶ/", backgroundColor: setcol(20)},
      {label: "ｶ*", backgroundColor: setcol(20)},
      {label: "〇", backgroundColor: setcol(7)},
      {label: "L", backgroundColor: setcol(7)},
      {label: "5/", backgroundColor: setcol(16)},
      {label: "ER準", backgroundColor: setcol(16)},
      {label: "専/", backgroundColor: setcol(16)},
      {label: "*", backgroundColor: setcol(19)},
      {label: "専*", backgroundColor: setcol(19)},
      {label: "禁〇", backgroundColor: setcol(15)},
      {label: "禁5/", backgroundColor: setcol(15)},
      {label: "禁*", backgroundColor: setcol(15)},
      {label: "禁5/・禁*", backgroundColor: setcol(15)},
      {label: "*・公", backgroundColor: "#8FBC8B"},
      {label: "ｹ1-3.5", backgroundColor: setcol(10)},
      {label: "ｹ4-8", backgroundColor: setcol(10)},
      {label: "ｺ1-3.5", backgroundColor: setcol(10)},
      {label: "ｺ4-8", backgroundColor: setcol(10)},
      {label: "会", backgroundColor: setcol(10)},
      {label: "応", backgroundColor: setcol(10)},
      {label: "公", backgroundColor: setcol(18)},
      {label: "○/", backgroundColor: setcol(9)},
      {label: "/○", backgroundColor: setcol(9)},
      {label: "年", backgroundColor: setcol(9)},
      {label: "リ", backgroundColor: setcol(9)},
      {label: "外", backgroundColor: setcol(9)},
      {label: "婚", backgroundColor: setcol(9)},
      {label: "産前", backgroundColor: setcol(9)},
      {label: "産後", backgroundColor: setcol(9)},
      {label: "育", backgroundColor: setcol(9)},
      {label: "病", backgroundColor: setcol(9)},
      {label: "積", backgroundColor: setcol(9)},
      {label: "忌", backgroundColor: setcol(9)},
      {label: "欠", backgroundColor: setcol(9)},
      {label: "退", backgroundColor: setcol(9)},
      {label: "5/→*→公", backgroundColor: "#DEB887"},
      {label: "", backgroundColor: setcol(1)},
    ]
    : (Byoutou=="C3")
    ? [
      {label: "管", backgroundColor: setcol(20)},
      {label: "◎", backgroundColor: setcol(20)},  
      {label: "ｶ/", backgroundColor: setcol(20)},
      {label: "ｶ*", backgroundColor: setcol(20)},
      {label: "〇", backgroundColor: setcol(7)},
      {label: "長1", backgroundColor: setcol(13)},
      {label: "1/", backgroundColor: setcol(16)},
      {label: "ER準", backgroundColor: setcol(16)},
      {label: "専/", backgroundColor: setcol(16)},
      {label: "*", backgroundColor: setcol(19)},
      {label: "専*", backgroundColor: setcol(19)},
      {label: "遅J", backgroundColor: setcol(14)},
      {label: "遅F", backgroundColor: setcol(14)},
      {label: "禁〇", backgroundColor: setcol(15)},
      {label: "禁長1", backgroundColor: setcol(15)},
      {label: "禁1/", backgroundColor: setcol(15)},
      {label: "禁*", backgroundColor: setcol(15)},
      {label: "禁遅", backgroundColor: setcol(15)},
      {label: "禁1/・禁*", backgroundColor: setcol(15)},
      {label: "〇・長1", backgroundColor: "#8FBC8B"},
      {label: "*・公", backgroundColor: "#8FBC8B"},
      {label: "遅番・公", backgroundColor: "#8FBC8B"},
      {label: "ｹ1-3.5", backgroundColor: setcol(10)},
      {label: "ｹ4-8", backgroundColor: setcol(10)},
      {label: "ｺ1-3.5", backgroundColor: setcol(10)},
      {label: "ｺ4-8", backgroundColor: setcol(10)},
      {label: "会", backgroundColor: setcol(10)},
      {label: "応", backgroundColor: setcol(10)},
      {label: "公", backgroundColor: setcol(18)},
      {label: "○/", backgroundColor: setcol(9)},
      {label: "/○", backgroundColor: setcol(9)},
      {label: "年", backgroundColor: setcol(9)},
      {label: "リ", backgroundColor: setcol(9)},
      {label: "外", backgroundColor: setcol(9)},
      {label: "婚", backgroundColor: setcol(9)},
      {label: "産前", backgroundColor: setcol(9)},
      {label: "産後", backgroundColor: setcol(9)},
      {label: "育", backgroundColor: setcol(9)},
      {label: "病", backgroundColor: setcol(9)},
      {label: "積", backgroundColor: setcol(9)},
      {label: "忌", backgroundColor: setcol(9)},
      {label: "欠", backgroundColor: setcol(9)},
      {label: "退", backgroundColor: setcol(9)},
      {label: "1/→*→公", backgroundColor: "#DEB887"},
      {label: "長1→1/→*→公", backgroundColor: "#DEB887"},
      {label: "", backgroundColor: setcol(1)},
    ]
    : (Byoutou=="C5")
    ? [
      {label: "管", backgroundColor: setcol(20)},
      {label: "◎", backgroundColor: setcol(20)},  
      {label: "ｶ/", backgroundColor: setcol(20)},
      {label: "ｶ*", backgroundColor: setcol(20)},
      {label: "〇", backgroundColor: setcol(7)},
      {label: "長1", backgroundColor: setcol(13)},
      {label: "1/", backgroundColor: setcol(16)},
      {label: "ER準", backgroundColor: setcol(16)},
      {label: "専/", backgroundColor: setcol(16)},
      {label: "*", backgroundColor: setcol(19)},
      {label: "専*", backgroundColor: setcol(19)},
      {label: "禁〇", backgroundColor: setcol(15)},
      {label: "禁長1", backgroundColor: setcol(15)},
      {label: "禁1/", backgroundColor: setcol(15)},
      {label: "禁*", backgroundColor: setcol(15)},
      {label: "禁1/・禁*", backgroundColor: setcol(15)},
      {label: "〇・長1", backgroundColor: "#8FBC8B"},
      {label: "*・公", backgroundColor: "#8FBC8B"},
      {label: "ｹ1-3.5", backgroundColor: setcol(10)},
      {label: "ｹ4-8", backgroundColor: setcol(10)},
      {label: "ｺ1-3.5", backgroundColor: setcol(10)},
      {label: "ｺ4-8", backgroundColor: setcol(10)},
      {label: "会", backgroundColor: setcol(10)},
      {label: "応", backgroundColor: setcol(10)},
      {label: "公", backgroundColor: setcol(18)},
      {label: "○/", backgroundColor: setcol(9)},
      {label: "/○", backgroundColor: setcol(9)},
      {label: "年", backgroundColor: setcol(9)},
      {label: "リ", backgroundColor: setcol(9)},
      {label: "外", backgroundColor: setcol(9)},
      {label: "婚", backgroundColor: setcol(9)},
      {label: "産前", backgroundColor: setcol(9)},
      {label: "産後", backgroundColor: setcol(9)},
      {label: "育", backgroundColor: setcol(9)},
      {label: "病", backgroundColor: setcol(9)},
      {label: "積", backgroundColor: setcol(9)},
      {label: "忌", backgroundColor: setcol(9)},
      {label: "欠", backgroundColor: setcol(9)},
      {label: "退", backgroundColor: setcol(9)},
      {label: "1/→*→公", backgroundColor: "#DEB887"},
      {label: "長1→1/→*→公", backgroundColor: "#DEB887"},
      {label: "", backgroundColor: setcol(1)},
    ]
    : (Byoutou=="W2")
    ? [
      {label: "管", backgroundColor: setcol(20)},
      {label: "◎", backgroundColor: setcol(20)},  
      {label: "ｶ/", backgroundColor: setcol(20)},
      {label: "ｶ*", backgroundColor: setcol(20)},
      {label: "〇", backgroundColor: setcol(7)},
      {label: "長2", backgroundColor: setcol(13)},
      {label: "2/", backgroundColor: setcol(16)},
      {label: "ER準", backgroundColor: setcol(16)},
      {label: "専/", backgroundColor: setcol(16)},
      {label: "*", backgroundColor: setcol(19)},
      {label: "専*", backgroundColor: setcol(19)},
      {label: "禁〇", backgroundColor: setcol(15)},
      {label: "禁長2", backgroundColor: setcol(15)},
      {label: "禁2/", backgroundColor: setcol(15)},
      {label: "禁*", backgroundColor: setcol(15)},
      {label: "禁2/・禁*", backgroundColor: setcol(15)},
      {label: "〇・長2", backgroundColor: "#8FBC8B"},
      {label: "*・公", backgroundColor: "#8FBC8B"},
      {label: "ｹ1-3.5", backgroundColor: setcol(10)},
      {label: "ｹ4-8", backgroundColor: setcol(10)},
      {label: "ｺ1-3.5", backgroundColor: setcol(10)},
      {label: "ｺ4-8", backgroundColor: setcol(10)},
      {label: "会", backgroundColor: setcol(10)},
      {label: "応", backgroundColor: setcol(10)},
      {label: "公", backgroundColor: setcol(18)},
      {label: "○/", backgroundColor: setcol(9)},
      {label: "/○", backgroundColor: setcol(9)},
      {label: "年", backgroundColor: setcol(9)},
      {label: "リ", backgroundColor: setcol(9)},
      {label: "外", backgroundColor: setcol(9)},
      {label: "婚", backgroundColor: setcol(9)},
      {label: "産前", backgroundColor: setcol(9)},
      {label: "産後", backgroundColor: setcol(9)},
      {label: "育", backgroundColor: setcol(9)},
      {label: "病", backgroundColor: setcol(9)},
      {label: "積", backgroundColor: setcol(9)},
      {label: "忌", backgroundColor: setcol(9)},
      {label: "欠", backgroundColor: setcol(9)},
      {label: "退", backgroundColor: setcol(9)},
      {label: "2/→*→公", backgroundColor: "#DEB887"},
      {label: "長2→2/→*→公", backgroundColor: "#DEB887"},
      {label: "", backgroundColor: setcol(1)},
    ]
    : (Byoutou=="ER")
    ? [
      {label: "管", backgroundColor: setcol(20)},
      {label: "◎", backgroundColor: setcol(20)},  
      {label: "ｶ/", backgroundColor: setcol(20)},
      {label: "ｶ*", backgroundColor: setcol(20)},
      {label: "〇", backgroundColor: setcol(7)},
      {label: "L", backgroundColor: setcol(7)},
      {label: "ｶﾃ", backgroundColor: setcol(7)},
      {label: "車", backgroundColor: setcol(7)},
      {label: "ﾊﾘｰ", backgroundColor: setcol(7)},
      {label: "大", backgroundColor: setcol(7)},
      {label: "RRT", backgroundColor: setcol(7)},
      {label: "ﾄﾘ", backgroundColor: setcol(7)},
      {label: "IVR", backgroundColor: setcol(7)},
      {label: "WI", backgroundColor: setcol(7)},
      {label: "5/", backgroundColor: setcol(16)},
      {label: "3準", backgroundColor: setcol(16)},
      {label: "ER準", backgroundColor: setcol(16)},
      {label: "専/", backgroundColor: setcol(16)},
      {label: "*", backgroundColor: setcol(19)},
      {label: "3*", backgroundColor: setcol(19)},
      {label: "専*", backgroundColor: setcol(19)},
      {label: "遅J", backgroundColor: setcol(14)},
      {label: "遅F", backgroundColor: setcol(14)},
      {label: "禁〇", backgroundColor: setcol(15)},
      {label: "禁5/", backgroundColor: setcol(15)},
      {label: "禁*", backgroundColor: setcol(15)},
      {label: "禁遅", backgroundColor: setcol(15)},
      {label: "禁5/・禁*", backgroundColor: setcol(15)},
      {label: "*・公", backgroundColor: "#8FBC8B"},
      {label: "遅番・公", backgroundColor: "#8FBC8B"},
      {label: "ｹ1-3.5", backgroundColor: setcol(10)},
      {label: "ｹ4-8", backgroundColor: setcol(10)},
      {label: "ｺ1-3.5", backgroundColor: setcol(10)},
      {label: "ｺ4-8", backgroundColor: setcol(10)},
      {label: "会", backgroundColor: setcol(10)},
      {label: "応", backgroundColor: setcol(0)},
      {label: "公", backgroundColor: setcol(18)},
      {label: "○/", backgroundColor: setcol(9)},
      {label: "/○", backgroundColor: setcol(9)},
      {label: "年", backgroundColor: setcol(9)},
      {label: "リ", backgroundColor: setcol(9)},
      {label: "外", backgroundColor: setcol(9)},
      {label: "婚", backgroundColor: setcol(9)},
      {label: "産前", backgroundColor: setcol(9)},
      {label: "産後", backgroundColor: setcol(9)},
      {label: "育", backgroundColor: setcol(9)},
      {label: "病", backgroundColor: setcol(9)},
      {label: "積", backgroundColor: setcol(9)},
      {label: "忌", backgroundColor: setcol(9)},
      {label: "欠", backgroundColor: setcol(9)},
      {label: "退", backgroundColor: setcol(9)},
      {label: "5/→*→公", backgroundColor: "#DEB887"},
      {label: "", backgroundColor: setcol(1)},
    ]
    : [
      {label: "管", backgroundColor: setcol(20)},
      {label: "◎", backgroundColor: setcol(20)},  
      {label: "ｶ/", backgroundColor: setcol(20)},
      {label: "ｶ*", backgroundColor: setcol(20)},
      {label: "〇", backgroundColor: setcol(7)},
      {label: "長1", backgroundColor: setcol(13)},
      {label: "1/", backgroundColor: setcol(16)},
      {label: "ER準", backgroundColor: setcol(16)},
      {label: "専/", backgroundColor: setcol(16)},
      {label: "*", backgroundColor: setcol(19)},
      {label: "専*", backgroundColor: setcol(19)},
      {label: "遅J", backgroundColor: setcol(14)},
      {label: "遅F", backgroundColor: setcol(14)},
      {label: "禁〇", backgroundColor: setcol(15)},
      {label: "禁長1", backgroundColor: setcol(15)},
      {label: "禁1/", backgroundColor: setcol(15)},
      {label: "禁*", backgroundColor: setcol(15)},
      {label: "禁遅", backgroundColor: setcol(15)},
      {label: "禁1/・禁*", backgroundColor: setcol(15)},
      {label: "〇・長1", backgroundColor: "#8FBC8B"},
      {label: "*・公", backgroundColor: "#8FBC8B"},
      {label: "遅番・公", backgroundColor: "#8FBC8B"},
      {label: "ｹ1-3.5", backgroundColor: setcol(10)},
      {label: "ｹ4-8", backgroundColor: setcol(10)},
      {label: "ｺ1-3.5", backgroundColor: setcol(10)},
      {label: "ｺ4-8", backgroundColor: setcol(10)},
      {label: "会", backgroundColor: setcol(10)},
      {label: "応", backgroundColor: setcol(10)},
      {label: "公", backgroundColor: setcol(18)},
      {label: "○/", backgroundColor: setcol(9)},
      {label: "/○", backgroundColor: setcol(9)},
      {label: "年", backgroundColor: setcol(9)},
      {label: "リ", backgroundColor: setcol(9)},
      {label: "外", backgroundColor: setcol(9)},
      {label: "婚", backgroundColor: setcol(9)},
      {label: "産前", backgroundColor: setcol(9)},
      {label: "産後", backgroundColor: setcol(9)},
      {label: "育", backgroundColor: setcol(9)},
      {label: "病", backgroundColor: setcol(9)},
      {label: "積", backgroundColor: setcol(9)},
      {label: "忌", backgroundColor: setcol(9)},
      {label: "欠", backgroundColor: setcol(9)},
      {label: "退", backgroundColor: setcol(9)},
      {label: "", backgroundColor: setcol(1)},
    ]

    let typeoptions 
    = [
      {label: "連続系", value: "連続系", backgroundColor: "#DEB887"},      
      {label: "管理", value: "管理", backgroundColor: setcol(20)},
      {label: "日勤系", value: "日勤系", backgroundColor: setcol(7)},
      {label: "ロング系",value: "ロング系", backgroundColor: setcol(13)},
      {label: "準夜勤系", value: "準夜勤系", backgroundColor: setcol(16)},
      {label: "深夜勤系", value: "深夜勤系", backgroundColor: setcol(19)},
      {label: "遅番系", value: "遅番系", backgroundColor: setcol(14)},
      {label: "禁止系", value: "禁止系", backgroundColor: setcol(15)},
      {label: "選択系", value: "選択系", backgroundColor: "#8FBC8B"},
      {label: "研修系", value: "研修系", backgroundColor: setcol(10)},
      {label: "その他", value: "その他", backgroundColor: setcol(0)},
      {label: "公休", value: "公休", backgroundColor: setcol(18)},
      {label: "休み系", value: "休み系", backgroundColor: setcol(9)},
    ]

    let useoptions = [
      {label: "使用", backgroundColor: "#FFC0CB"},
      {label: "", backgroundColor: "#FFC0CB"},
    ]

    let allowshift = [
      "管",
      "〇",
      "L",
      "ｶﾃ",
      "車",
      "ﾊﾘｰ",
      "大",
      "RRT",
      "ﾄﾘ",
      "IVR",
      "WI",
      "長1",
      "長2",
      "1/",
      "2/",
      "5/",
      "*",
      "遅J",
    ]

    function useLatest(value) {
      const ref = useRef(value);
      ref.current = value;
      return ref;
    }

    function useKeybind({
      altKey,
      ctrlKey,
      metaKey,
      shiftKey,
      key,
      onKeyDown,
      targetRef,
    }) {
      const onKeyDownLatest = useLatest(onKeyDown);

      useEffect(() => {
        const eventListener = (event) => {
          if (altKey && !event.altKey) return;
          if (ctrlKey && !event.ctrlKey) return;
          if (metaKey && !event.metaKey) return;
          if (shiftKey && !event.shiftKey) return;
          if (event.key !== key) return;

          event.preventDefault();
          onKeyDownLatest.current?.(event);
        };

        if (targetRef?.current) {
          const target = targetRef.current;

          target.addEventListener("keydown", eventListener);
          return () => target.removeEventListener("keydown", eventListener);
        } else {
          window.addEventListener("keydown", eventListener);
          return () => window.removeEventListener("keydown", eventListener);
        }
      }, [altKey, ctrlKey, key, metaKey, onKeyDownLatest, shiftKey, targetRef]);
    }

    function arraysAreEqual(array1, array2) {
      return JSON.stringify(array1) === JSON.stringify(array2);
    }

    let mdata = [];
    let tmdata = [];
    let scolumns = [
      {
        id: "team",
        label: "種類",
        accessor: "team",
        width: 1,
        dataType: "text",
        options: []
      },    
    ]
    const teamdata = (JSON.parse(localStorage.getItem('Team-'+Byoutou))!==null && JSON.parse(localStorage.getItem('Team-'+Byoutou)).length!==0) ? JSON.parse(localStorage.getItem('Team-'+Byoutou)) : []
    teamoptions.forEach(data=>{
      if (data.label!=="" && data.label!==undefined && data.label!==null){
        mdata.push(
          {
            team: data.label,
            color: data.backgroundColor
          }
        )
      }
    })
    teamdata.forEach(data=>{
      if (data.team!=="" && data.team!==undefined && data.team!==null){
        tmdata.push(
          {
            team: data.team,
            color: data.color!=null ? data.color : RandomColor()
          }
        )
      }
    })
    if (localStorage.getItem('ts1'+byoutou)==1){
      localStorage.setItem('Team-'+Byoutou, JSON.stringify(mdata))
    } else {
      localStorage.setItem('Team-'+Byoutou, JSON.stringify(tmdata))
    }

    function getBackgroundColorByLabel(label) {
      const foundOption = leveloptions.find(option => option.label === label);
      
      if (foundOption) {
        return foundOption.backgroundColor;
      } else {
        return null; // もしくは、デフォルトの背景色など、適切な値を返すこともできます。
      }
    }

    let skdata = [];
    let skndata = [];
    let skcolumns = [
      {
        id: "skill",
        label: "種類",
        accessor: "skill",
        width: 11,
        dataType: "text",
        options: []
      },  
      {
        id: "level",
        label: "レベル",
        accessor: "level",
        width: 15,
        dataType: "select",
        options: leveloptions
      },    
    ]
    const skilldata = JSON.parse(localStorage.getItem('Skill-'+Byoutou))!==null ? JSON.parse(localStorage.getItem('Skill-'+Byoutou)) : []
    skilloptions.forEach((data,i)=>{
        skdata.push(
          {
            skill: data.label,
            level: data.level,
            color: data.backgroundColor
          }
        )  
    })

    skilldata.forEach((data,i)=>{
      if (data.skill!=="" && data.skill!==undefined && data.skill!==null){
        skndata.push(
          {
            skill: data.skill,
            level: data.level,
            color: getBackgroundColorByLabel(data.level)
          }
        )  
      }
    })

    const skillgroupedData = {};

    skndata.forEach(item => {
      const label = item.level;
      if (!skillgroupedData[label]) {
        skillgroupedData[label] = [];
      }
      skillgroupedData[label].push(item);
    });

    // typeoptionsのlabelの順番に従ってデータを並べ替える新しい配列を作成
    const skillsortedData = leveloptions.reduce((result, option) => {
      const label = option.label;
      if (skillgroupedData[label]) {
        result = result.concat(skillgroupedData[label]);
      }
      return result;
    }, []);
    if (localStorage.getItem('ts1'+byoutou)==1 || JSON.parse(localStorage.getItem('Skill-'+Byoutou))==null){
      localStorage.setItem('Skill-'+Byoutou, JSON.stringify(skdata))
    } else {
      localStorage.setItem('Skill-'+Byoutou, JSON.stringify(skillsortedData))
    }

    function getTypeByLabel(color) {
      const foundOption = typeoptions.find(option => option.backgroundColor === color);
      
      if (foundOption) {
        return foundOption.label;
      } else {
        return null; // もしくは、デフォルトの背景色など、適切な値を返すこともできます。
      }
    }

    let shiftdata = [];
    let shiftcolumns = [
      {
        id: "ID",
        label: "No.",
        accessor: "ID",
        width: 13,
        dataType: "text",
        options: []
      }, 
      {
        id: "shift",
        label: "記号",
        accessor: "shift",
        width: 50,
        dataType: "text",
        options: []
      },       
      {
        id: "type",
        label: "種類",
        accessor: "type",
        width: 30,
        dataType: "select",
        options: typeoptions
      }, 
      {
        id: "count",
        label: "枠数使用",
        accessor: "count",
        width: 25,
        dataType: "select",
        options: useoptions
      },    
      {
        id: "det",
        label: "詳細",
        accessor: "det",
        width: 60,
        dataType: "text",
        options: []
      },    
    ]
    const storedData = (JSON.parse(localStorage.getItem('Shift-'+Byoutou))!==null) ? JSON.parse(localStorage.getItem('Shift-'+Byoutou)) : []
   
    for (let i = 0; i < shiftoptions.length-1; i++) {
      let row = {
        ID: String(i+1),
        shift: shiftoptions[i].label,
        label: shiftoptions[i].label,
        type: getTypeByLabel(shiftoptions[i].backgroundColor),
        det: "",
        color: shiftoptions[i].backgroundColor,
        count: (allowshift.includes(shiftoptions[i].label)) ? "使用" : ""
      }
      shiftdata.push(row);
    }

    // データをlabelでグループ化するためのオブジェクトを作成
    const groupedData = {};

    storedData.forEach(item => {
      const label = item.type;
      if (!groupedData[label]) {
        groupedData[label] = [];
      }
      groupedData[label].push(item);
    });

    // typeoptionsのlabelの順番に従ってデータを並べ替える新しい配列を作成
    const sortedData = typeoptions.reduce((result, option) => {
      const label = option.label;
      if (groupedData[label]) {
        result = result.concat(groupedData[label]);
      }
      return result;
    }, []);


    let sortedshiftdata = []
    sortedData.forEach((data,i)=>{
      let row = {ID: String(i+1)}
      Object.keys(data).forEach((key,j)=>{
        if (key!=="ID"){
          row[key] = data[key]
        }
      })
      if (data.type=="連続系"){
        row.count = ""
      }

      sortedshiftdata.push(row)
    })

    localStorage.setItem("Shift-"+Byoutou, JSON.stringify(sortedshiftdata))


    let showshiftoptions = []
    sortedData.forEach((data,i) => {
      if (data.type!=="禁止系" && data.type!=="選択系" && data.type!=="連続系"){
        showshiftoptions.push(data);
      }
    })


    let mmcdata = (localStorage.getItem('ts1'+byoutou)==1 || localStorage.getItem('Team-'+Byoutou)==null) ? {columns: scolumns, data: mdata, skipReset: false} : {columns: scolumns, data: tmdata, skipReset: false}
    let smcdata = (localStorage.getItem('ts1'+byoutou)==1 || localStorage.getItem('Skill-'+Byoutou)==null) ? {columns: skcolumns, data: skdata, skipReset: false} : {columns: skcolumns, data: skillsortedData, skipReset: false}
    let shiftmcdata = (localStorage.getItem('ts1'+byoutou)==1 || localStorage.getItem('Shift-'+Byoutou)==null) ? {columns: shiftcolumns, data: shiftdata, skipReset: false} : {columns: shiftcolumns, data: JSON.parse(localStorage.getItem('Shift-'+Byoutou)), skipReset: false}
    
    function reducer(state, action) {
      switch (action.type) {
        case "add_option_to_column":
          const optionIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, optionIndex),
              {
                ...state.columns[optionIndex],
                options: [
                  ...state.columns[optionIndex].options,
                  { label: action.option, backgroundColor: action.backgroundColor }
                ]
              },
              ...state.columns.slice(optionIndex + 1, state.columns.length)
            ]
          };
        case "add_row":
          return {
            ...state,
            skipReset: true,
            data: [...state.data, {}]
          };
        case "delete_row":
          return {
            ...state,
            skipReset: true,
            data: state.data.filter((_, index) => index !== action.rowIndex)
          };
        case "update_column_type":
          const typeIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          switch (action.dataType) {
            case "number":
              if (state.columns[typeIndex].dataType === "number") {
                return state;
              } else {
                return {
                  ...state,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  data: state.data.map((row) => ({
                    ...row,
                    [action.columnId]: isNaN(row[action.columnId])
                      ? ""
                      : Number.parseInt(row[action.columnId])
                  }))
                };
              }
            case "select":
              if (state.columns[typeIndex].dataType === "select") {
                return {
                  ...state,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  skipReset: true
                };
              } else {
                let options = [];
                state.data.forEach((row) => {
                  if (row[action.columnId]) {
                    options.push({
                      label: row[action.columnId],
                      backgroundColor: randomColor()
                    });
                  }
                });
                return {
                  ...state,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    {
                      ...state.columns[typeIndex],
                      dataType: action.dataType,
                      options: [...state.columns[typeIndex].options, ...options]
                    },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  skipReset: true
                };
              }
            case "text":
              if (state.columns[typeIndex].dataType === "text") {
                return state;
              } else if (state.columns[typeIndex].dataType === "select") {
                return {
                  ...state,
                  skipReset: true,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ]
                };
              } else {
                return {
                  ...state,
                  skipReset: true,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  data: state.data.map((row) => ({
                    ...row,
                    [action.columnId]: row[action.columnId] + ""
                  }))
                };
              }
            default:
              return state;
          }
        case "update_column_header":
          const index = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, index),
              { ...state.columns[index], label: action.label },
              ...state.columns.slice(index + 1, state.columns.length)
            ]
          };
        case "update_cell":
          return {
            ...state,
            skipReset: true,
            data: state.data.map((row, index) => {
              if (index === action.rowIndex) {
                return {
                  ...state.data[action.rowIndex],
                  [action.columnId]: action.value
                };
              }
              return row;
            })
          };
        case "add_column_to_left":
          const leftIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          let leftId = shortId();
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, leftIndex),
              {
                id: leftId,
                label: "Column",
                accessor: leftId,
                dataType: "text",
                created: action.focus && true,
                options: []
              },
              ...state.columns.slice(leftIndex, state.columns.length)
            ]
          };
        case "add_column_to_right":
          const rightIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          const rightId = shortId();
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, rightIndex + 1),
              {
                id: rightId,
                label: "Column",
                accessor: rightId,
                dataType: "text",
                created: action.focus && true,
                options: []
              },
              ...state.columns.slice(rightIndex + 1, state.columns.length)
            ]
          };
        case "delete_column":
          const deleteIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, deleteIndex),
              ...state.columns.slice(deleteIndex + 1, state.columns.length)
            ]
          };
        case "enable_reset":
          return {
            ...state,
            skipReset: false
          };
        default:
          return state;
      }
    }

    function TableShow() {
      const [tstate, tdispatch] = useReducer(reducer, mmcdata);
      useEffect(() => {tdispatch({ type: "enable_reset" });
      }, [tstate.data, tstate.columns]);
      const [state, dispatch] = useReducer(reducer, smcdata);
      useEffect(() => {dispatch({ type: "enable_reset" });
      }, [state.data, state.columns]);
      const [hstate, hdispatch] = useReducer(reducer, shiftmcdata);
      useEffect(() => {hdispatch({ type: "enable_reset" });
      }, [hstate.data, hstate.columns]);
      
      if (localStorage.getItem('ts1'+byoutou)==1){
        localStorage.setItem('Team-'+Byoutou, JSON.stringify(tstate.data))
        localStorage.setItem('Skill-'+Byoutou, JSON.stringify(state.data))
        localStorage.setItem('Shift-'+Byoutou, JSON.stringify(hstate.data))
        localStorage.setItem('ts1'+byoutou, 2)
      }
      
      function handleSave(){
        localStorage.setItem('Team-'+Byoutou, JSON.stringify(tstate.data))
        localStorage.setItem('Skill-'+Byoutou, JSON.stringify(state.data))
        localStorage.setItem('Shift-'+Byoutou, JSON.stringify(hstate.data))
        alert('勤務情報を保存しました')
      }

      function handleSaveAuto(){
        localStorage.setItem('Team-'+Byoutou, JSON.stringify(tstate.data))
        localStorage.setItem('Skill-'+Byoutou, JSON.stringify(state.data))
        localStorage.setItem('Shift-'+Byoutou, JSON.stringify(hstate.data))
      }

      const [open, setOpen] = useState(false)

      const [stype, setStype] = useState(null)
      function DropdownShift() {
        const handleClick = (event) => {
          setStype(event);
        };
    
        return (
          <Select
            placeholder="グループ"
            value={stype}
            onChange={handleClick} 
            options={typeoptions}
          />
        );
      }

      const [inputValue, setInputValue] = useState("");

      const handleInputChange = (e) => {
        setInputValue(e.target.value);
      };

      const [isChecked, setIsChecked] = useState(false);

      const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
      };

      const handleOpen = () => {
        setOpen(prevState=>!prevState); 
        setStype(null);
        setSelectedValue1(null)
        setSelectedValue2(null)
      }

      const AddShift = () => {
        if (!(stype==null || (inputValue==="" && (((stype.value=="連続系" || stype.value=="選択系") && selectedValue1==null && selectedValue2==null) || (!pat && selectedValue1==null && stype.value=="禁止系") || (pat && (selectedValue1==null || selectedValue2==null) && stype.value=="禁止系"))))){
          const matchedOption = typeoptions.find(option => option.label === stype.label);
          let shift = {
            ID: String(hstate.data.length+1),
            label: 
              (inputValue!=="") ? inputValue 
              : (!pat && stype.value=="禁止系") ? "禁" + selectedValue1 
              : (pat && stype.value=="禁止系") ? "禁" + selectedValue1 + "・禁" + selectedValue2 
              : (stype.value=="連続系") ? selectedValue1 + "→" + selectedValue2 + (selectedValue3!==null ? "→" + selectedValue3 : "") + (selectedValue4!==null ? "→" + selectedValue4 : "") + (selectedValue5!==null ? "→" + selectedValue5 : "")
              : (stype.value=="選択系") ?  selectedValue1 + "・" + selectedValue2 : "",
            shift: 
              (inputValue!=="") ? inputValue 
              : (!pat && stype.value=="禁止系") ? "禁" + selectedValue1 
              : (pat && stype.value=="禁止系") ? "禁" + selectedValue1 + "・禁" + selectedValue2 
              : (stype.value=="連続系") ? labelname
              : (stype.value=="選択系") ?  selectedValue1 + "・" + selectedValue2 : "",
            type: stype.value,
            det: "",
            color: matchedOption.backgroundColor,
            count: (isChecked) ? "使用" : ""
          }

          const confirm = window.confirm("シフトを追加しますか？")
          if (confirm){
            let localdata = JSON.parse(localStorage.getItem('Shift-'+Byoutou))
            localdata.push(shift)
            localStorage.setItem('Shift-'+Byoutou, JSON.stringify(localdata))
            setOpen(false)
            window.location.reload()  
          }
        } else {
          alert("シフト情報を入力してください")
        }
      }

      const [pat, setPat] = useState(false)

      useKeybind({
        key: "s",
        ctrlKey: true,
        onKeyDown: () => handleSave(),
      });

      function ShowOptions({name, onOptionSelected, selectedValue, color, setColor}){
        const [showSelect, setShowSelect] = useState(false);
        const [selectRef, setSelectRef] = useState(null);
        const [selectPop, setSelectPop] = useState(null);

        const {styles, attributes} = usePopper(selectRef, selectPop, {
          placement: "bottom-start",
          strategy: "fixed"
        });
      
        const handleOptionClick = (option) => {
          setColor(option.color)
          onOptionSelected(option.shift); 
          setShowSelect(false); 
        };
        
          return (
            <div>
                <div style={{ border: '1px solid gray', width: '100px', cursor: 'pointer', borderRadius: 5, backgroundColor: selectedValue===null ? "white" : color}} onClick={() => setShowSelect(true)}>
                  <div style={{color: 'black', fontSize: '20px', display: 'flex', justifyContent: 'center'}}>
                    {selectedValue==null ? name : selectedValue}
                  </div>
                </div>

                {showSelect && <div className='overlay' onClick={() => setShowSelect(false)} />}
                {showSelect && (
                  <div
                    className='shadow-5 bg-white border-radius-md'
                    ref={setSelectPop}
                    {...attributes.popper}
                    style={{
                      ...styles.popper,
                      zIndex: 10,
                      minWidth: 350,
                      maxWidth: 400,
                      padding: "0.75rem",
                      position: "absolute",
                      top: "45%",
                      left: "50%",
                      transform: "translate(-50%, -50%)"
                    }}>
                    <div className='d-flex flex-wrap-wrap' style={{marginTop: "-0.5rem"}}>
                      {showshiftoptions.map((option) => (
                        <div
                          className='cursor-pointer'
                          style={{marginRight: "0.5rem", marginTop: "0.5rem"}}
                          onClick={() => {
                            setShowSelect(false);
                            handleOptionClick(option)
                          }}>
                          <Relationship value={option.shift} backgroundColor={option.color} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
            </div>
          )
      }
      
      const [selectedValue1, setSelectedValue1] = useState(null);
      const [selectedValue2, setSelectedValue2] = useState(null);
      const [selectedValue3, setSelectedValue3] = useState(null);
      const [selectedValue4, setSelectedValue4] = useState(null);
      const [selectedValue5, setSelectedValue5] = useState(null);
      const [color1, setColor1] = useState(null)
      const [color2, setColor2] = useState(null)
      const [color3, setColor3] = useState(null)
      const [color4, setColor4] = useState(null)
      const [color5, setColor5] = useState(null)

      const handleOptionSelected1 = (selectedOption) => {
        setSelectedValue1(selectedOption); // selectedValue1を更新
      }
      const handleOptionSelected2 = (selectedOption) => {
        setSelectedValue2(selectedOption); // selectedValue2を更新
      }
      const handleOptionSelected3 = (selectedOption) => {
        setSelectedValue3(selectedOption); // selectedValue2を更新
      }
      const handleOptionSelected4 = (selectedOption) => {
        setSelectedValue4(selectedOption); // selectedValue2を更新
      }
      const handleOptionSelected5 = (selectedOption) => {
        setSelectedValue5(selectedOption); // selectedValue2を更新
      }

      const [pat1, setPat1] = useState(0)

      const togglePat = () => {
        setPat1((prevState) => (prevState === 3 ? 0 : prevState + 1));
        if (pat1<=2){
          setSelectedValue5(null)
        } 
        if (pat1<=1){
          setSelectedValue4(null)
        } 
        if (pat1<=0){
          setSelectedValue3(null)
        }
      };

      const [labelname, setLabelname] = useState("")

      const handleInputChangeLabel = (e) => {
        setLabelname(e.target.value);
      };
      

      return (
        <div>
          <div>
            <Button size="small" variant="contained" color="success" onClick={handleSave}>
              保存
            </Button>
          </div>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} style={{paddingBottom: "1rem"}}>
          <Grid container spacing={3}>
            <Grid item xs={4} md={4} lg={2}>
            <strong>チーム</strong>
              <div onClick={handleSaveAuto} style={{maxHeight: "400px", width: "150px",zIndex: 5, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}>
                    <Table
                      columns={tstate.columns}
                      data={tstate.data}
                      skipReset={tstate.skipReset}
                      dispatch={tdispatch}
                      />
              </div>
              </Grid>
              <Grid item xs={4} md={4} lg={2.7}>
              <strong>スキル</strong>
              <div onClick={handleSaveAuto} style={{maxHeight: "450px", overflowY: "scroll", width: "220px",zIndex: 5, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}>
                    <Table
                      columns={state.columns}
                      data={state.data}
                      skipReset={state.skipReset}
                      dispatch={dispatch}
                      />
              </div>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <strong>シフト</strong>
              {<div style={{paddingBottom: '0.2rem'}}>
              <Button size="small" variant="contained" color="info" onClick={handleOpen}>
                シフトを追加
              </Button>
              </div>}
              </div>
              <div onClick={handleSaveAuto} style={{maxHeight: "450px", overflowY: "scroll", width: "570px",zIndex: 5, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}>
                    <Table
                      columns={hstate.columns}
                      data={hstate.data}
                      skipReset={hstate.skipReset}
                      dispatch={hdispatch}
                      noadd={"nd"}
                      edit={"n"}
                      />
              </div>
              </Grid>

              {open && <div className='overlay' onClick={() => setOpen(false)} />}
              {open && (
                <div
                  className='shadow-5 bg-white border-radius-md'
                  style={{
                    zIndex: 10,
                    minWidth: 450,
                    minWidth: 500,
                    padding: "0.75rem",
                    position: "fixed",
                    top: "45%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                  }}>
                    <div style={{paddingBottom: '0.5rem'}}>
                    追加するシフトの情報を入力してください
                    </div>
                    <div style={{width: '200px',paddingTop: '1rem'}}>
                      <DropdownShift/>
                    </div>

                    <div style={{paddingTop: '1rem'}}>
                      {stype!==null && !(stype.value=="選択系" || stype.value=="禁止系" || stype.value=="連続系") &&
                      <input
                        type="text"
                        placeholder="シフト名を入力してください"
                        value={inputValue}
                        onChange={handleInputChange}
                        style={{height: '40px'}}
                      />}

                      {stype!==null && (stype.value=="選択系") &&
                      <div>
                        2種類のシフトどちらかに入るという勤務希望の記号
                      <div style={{display: 'flex', paddingTop: '1rem', justifyContent: 'center'}}>
                        <ShowOptions 
                          name={"シフトA"} 
                          onOptionSelected={handleOptionSelected1} 
                          selectedValue={selectedValue1}
                          color={color1}
                          setColor={setColor1}/>
                        <div style={{fontSize: '30px', display: 'flex', alignItems: 'center', marginTop: '-0.5rem'}}>
                        ・
                        </div>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                      </div>
                      </div>
                      }

                      {stype!==null && (stype.value=="連続系") &&
                      <div>
                        <div style={{display: 'flex'}}>
                        <div>
                        <Button size="small" variant="contained" color="inherit" onClick={togglePat}>
                        パターン変更
                      </Button>
                      </div>
                      <div style={{paddingLeft: '0.5rem', fontSize: '13px', display: 'flex', alignItems: 'center'}}>
                        複数連続日で続けてシフトを入力するための記号
                      </div>
                      </div>
                      <div style={{display: 'flex', paddingTop: '-1rem', justifyContent: 'center'}}>
                      {pat1==0 &&
                    <div> 
                    <div style={{display: 'flex', justifyContent: 'center'}}> 
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', paddingTop: '2rem'}}>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトA"} 
                          onOptionSelected={handleOptionSelected1} 
                          selectedValue={selectedValue1}
                          color={color1}
                          setColor={setColor1}/>
                      </div>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトB"} 
                          onOptionSelected={handleOptionSelected2} 
                          selectedValue={selectedValue2}
                          color={color2}
                          setColor={setColor2}/>
                      </div>                     
                    </div>
                    </div>
                    </div>}

                    {pat1==1 &&
                    <div> 
                    <div style={{display: 'flex', justifyContent: 'center'}}> 
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', paddingTop: '2rem'}}>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトA"} 
                          onOptionSelected={handleOptionSelected1} 
                          selectedValue={selectedValue1}
                          color={color1}
                          setColor={setColor1}/>
                      </div>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトB"} 
                          onOptionSelected={handleOptionSelected2} 
                          selectedValue={selectedValue2}
                          color={color2}
                          setColor={setColor2}/>
                      </div>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトC"} 
                          onOptionSelected={handleOptionSelected3} 
                          selectedValue={selectedValue3}
                          color={color3}
                          setColor={setColor3}/>
                      </div>                     
                    </div>
                    </div>
                    </div>}

                    {pat1==2 &&
                    <div> 
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', paddingTop: '2rem'}}>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトA"} 
                          onOptionSelected={handleOptionSelected1} 
                          selectedValue={selectedValue1}
                          color={color1}
                          setColor={setColor1}/>
                      </div>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトB"} 
                          onOptionSelected={handleOptionSelected2} 
                          selectedValue={selectedValue2}
                          color={color2}
                          setColor={setColor2}/>
                      </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', paddingTop: '0.25rem'}}>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトC"} 
                          onOptionSelected={handleOptionSelected3} 
                          selectedValue={selectedValue3}
                          color={color3}
                          setColor={setColor3}/>
                      </div>  
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトD"} 
                          onOptionSelected={handleOptionSelected4} 
                          selectedValue={selectedValue4}
                          color={color4}
                          setColor={setColor4}/>
                      </div>                     
                    </div>
                    </div>}

                    {pat1==3 &&
                    <div> 
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', paddingTop: '2rem'}}>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトA"} 
                          onOptionSelected={handleOptionSelected1} 
                          selectedValue={selectedValue1}
                          color={color1}
                          setColor={setColor1}/>
                      </div>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトB"} 
                          onOptionSelected={handleOptionSelected2} 
                          selectedValue={selectedValue2}
                          color={color2}
                          setColor={setColor2}/>
                      </div>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトC"} 
                          onOptionSelected={handleOptionSelected3} 
                          selectedValue={selectedValue3}
                          color={color3}
                          setColor={setColor3}/>
                      </div>                    
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', paddingTop: '0.25rem'}}>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトD"} 
                          onOptionSelected={handleOptionSelected4} 
                          selectedValue={selectedValue4}
                          color={color4}
                          setColor={setColor4}/>
                      </div>  
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトE"} 
                          onOptionSelected={handleOptionSelected5} 
                          selectedValue={selectedValue5}
                          color={color5}
                          setColor={setColor5}/>
                      </div>                     
                    </div>
                    </div>}
                      </div>
                      <div style={{display: 'flex', justifyContent: 'center', paddingTop: '1rem'}}>
                      <input
                        type="text"
                        placeholder="ラベル名を入力してください"
                        value={labelname}
                        onChange={handleInputChangeLabel}
                        style={{height: '40px'}}
                      />
                      </div>
                      </div>
                      }

                      {stype!==null && (stype.value=="禁止系") &&
                      <div>
                        以下のシフトに入ることを禁止する勤務希望の記号<br/>
                        <Button size="small" variant="contained" color="inherit" onClick={()=>setPat(prevState=>!prevState)}>
                          パターン変更
                        </Button>
                      <div style={{display: 'flex', paddingTop: '1rem', justifyContent: 'center'}}>

                        {!pat && 
                        <div style={{display: 'flex'}}>
                        <div style={{fontSize: '20px', display: 'flex', alignItems: 'center', paddingRight: '0.25rem'}}>
                        禁
                        </div>
                        <div>
                        <ShowOptions 
                          name={"シフト"} 
                          onOptionSelected={handleOptionSelected1} 
                          selectedValue={selectedValue1}
                          color={color1}
                          setColor={setColor1}/>
                        </div>
                        </div>}

                        {pat && 
                        <div style={{display: 'flex'}}>
                        <div style={{fontSize: '20px', display: 'flex', alignItems: 'center', paddingRight: '0.25rem'}}>
                        禁
                        </div>
                        <div style={{paddingTop: '0.5rem'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                        </div>
                        <div style={{fontSize: '30px', display: 'flex', alignItems: 'center'}}>
                        ・
                        </div>
                        <div style={{fontSize: '20px', display: 'flex', alignItems: 'center', paddingRight: '0.25rem'}}>
                        禁
                        </div>
                        <div style={{paddingTop: '0.5rem'}}>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                        </div>
                        </div>}

                      </div>
                      </div>
                      }

                    </div>

                    {stype!==null && stype.value!=="連続系" &&
                    <div style={{paddingTop: '3rem'}}>
                    <label>
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        step={{height: '30px', width: '30px'}}
                      />
                      勤務表自動作成の際に枠数としてカウントしますか？
                    </label>
                    </div>}

                    <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: '1rem'}}>
                    <Button size="small" variant="contained" color="info" onClick={AddShift}>
                      追加
                    </Button>
                    </div>
                </div>
              )}

          </Grid>
          </Container>
        </div>
      );
    }
    
    return (
      <div className="excel-table-container">
        <div className="foo">
        </div>
        <div>
          <TableShow/>
        </div>
      </div>
    );
  }
}

export default ExcelRequestsImport;