import React, {useMemo} from "react";
import clsx from "clsx";
import {useTable, useFlexLayout, useResizeColumns, useSortBy} from "react-table";
import Cell from "./CellStaff";
import Header from "./Header";
import PlusIcon from "./img/Plus";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { setcol } from "./colors";

const defaultColumn = {
  minWidth: 5,
  width: 30,
  maxWidth: 110,
  Cell: Cell,
  Header: Header,
  sortType: "alphanumericFalsyLast"
};

export default function Table({columns, data, dispatch: dataDispatch, skipReset, noadd, cellstaff, edit}) {
  const sortTypes = useMemo(
    () => ({
      alphanumericFalsyLast(rowA, rowB, columnId, desc) {
        if (!rowA.values[columnId] && !rowB.values[columnId]) {
          return 0;
        }

        if (!rowA.values[columnId]) {
          return desc ? -1 : 1;
        }

        if (!rowB.values[columnId]) {
          return desc ? 1 : -1;
        }

        return isNaN(rowA.values[columnId])
          ? rowA.values[columnId].localeCompare(rowB.values[columnId])
          : rowA.values[columnId] - rowB.values[columnId];
      }
    }),
    []
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable(
    {
      columns,
      data,
      defaultColumn,
      dataDispatch,
      autoResetSortBy: !skipReset,
      autoResetFilters: !skipReset,
      autoResetRowState: !skipReset,
      sortTypes
    },
    useFlexLayout,
    useResizeColumns,
    useSortBy
  );

  function isTableResizing() {
    for (let headerGroup of headerGroups) {
      for (let column of headerGroup.headers) {
        if (column.isResizing) {
          return true;
        }
      }
    }

    return false;
  }

  return (
    <>
      <div {...getTableProps()} className={clsx("table", isTableResizing() && "noselect")}>
        <div className="thead">
          {headerGroups.map((headerGroup) => (
            <>
            <div {...headerGroup.getHeaderGroupProps()} className='tr-header'>
              {headerGroup.headers.map((column) => column.render("Header"))}
          {(noadd!=="n") &&
          <div style={{ backgroundColor: setcol(0), borderBottom: '1px solid #e0e0e0'}}>
            <div style={{visibility: 'hidden'}}>　<DeleteForeverOutlinedIcon/>　</div>
          </div>}
            </div>
          </>
          ))}
        </div>

        <div {...getTableBodyProps()} >
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className='tr'>
                {row.cells.map((cell, j) => (
                  <div {...cell.getCellProps()} className='td custom-class'>
                    {cell.render("Cell", {
                      edit: (cell.column.label==="グループ" || cell.column.label==="条件" || cell.column.label==="シフト" || (cell.column.label==="記号" && row.values.type!=="連続系") || (cell.column.label==="種類" && edit=="n")) ? "n" : "",
                      colname: cell.column.id,
                      idname: cell.row.original.ID,
                      cellstaff: cellstaff,
                      scroll: cell.column.label==="条件" ? true : false,
                    })}
                  </div>
                ))}
                {(noadd!=="n")&&
                <div style={{cursor: 'pointer', borderBottom: '1px solid #e0e0e0' ,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'}} className='delete-row' onClick={() => {
                  const userConfirmed = window.confirm("このデータを削除しますか？");
                  if (userConfirmed) {
                    dataDispatch({type: "delete_row", rowIndex: i});
                  }
                }}>
                  　<DeleteForeverOutlinedIcon/>　
                </div>}
              </div>
            );
          })}
          {(noadd!=="n" && noadd!=="nd") &&
          <div className='tr add-row' onClick={() => dataDispatch({type: "add_row"})}>
            <span className='svg-icon svg-gray' style={{marginRight: 4}}>
              <PlusIcon />
            </span>
            データを追加
          </div>}
        </div>
      </div>
    </>
  );

}
