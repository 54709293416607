import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TopPage from "./components/pages/TopPage";
import TablePage from "./components/pages/TablePage";
import Staff from "./components/pages/Staff/Staff";
import RequestPage from "./components/pages/Request/Request";
import Shift from "./components/pages/Setting/SetTeamSkill";
import Const from "./components/pages/Setting/SetConst";
import Schedule from "./components/pages/Scheduling/MakeOptions";
import MessagePage from "./components/pages/MessagePage";
import CalendarPage from "./components/pages/CalendarPage";
import HelpPage from "./components/pages/HelpPage";
import LoginPage from "./components/pages/LoginPage";
import StorageTable from "./components/pages/Storage/StorageShow";
import StorageTablePDF from "./components/pages/Storage/StorageShowPDF";
import StatsPage from "./components/pages/StatsPage";
import ManagePage from "./components/pages/Manage";

const root = ReactDOM.createRoot(
  document.getElementById('root')
);

const userData = JSON.parse(localStorage.getItem("UserID"));

// ルートの初期配列
const initialRoutes = [
  <Route path="/Login" element={<LoginPage />} />,
  <Route path="/" element={<TopPage />} />,
  <Route path="/Table" element={<TablePage />} />,
  <Route path="/Message" element={<MessagePage />} />,
  <Route path="/Calendar" element={<CalendarPage />} />,
  <Route path="/Manage" element={<ManagePage />} />,
  <Route path="/Help" element={<HelpPage />} />,
  <Route path="/Account" element={<ManagePage />} />,
  <Route path="/Stats" element={<StatsPage />} />,
];

// ローカルストレージからデータを読み込んで、動的なルートを追加
if (Array.isArray(userData)) {
  userData.forEach(item => {
    initialRoutes.push(
      <Route
        key={item.code}
        path={`/Table/Request/${item.code}`}
        element={<RequestPage code={item.code} />} />
      );
    initialRoutes.push(
      <Route
        key={item.code}
        path={`/Table/Storage/${item.code}`}
        element={<StorageTable />} />
      );
    initialRoutes.push(
      <Route
        key={item.code}
        path={`/Table/Storage/${item.code}/PDF`}
        element={<StorageTablePDF />} />
      );
    initialRoutes.push(
      <Route
        key={item.code}
        path={`/Table/Schedule/${item.code}`}
        element={<Schedule />} />
      );
    initialRoutes.push(
      <Route
        key={item.code}
        path={`/Setting/Shift/${item.code}`}
        element={<Shift />} />
      );
    initialRoutes.push(
      <Route
        key={item.code}
        path={`/Setting/Const/${item.code}`}
        element={<Const />} />
      );    
    initialRoutes.push(
      <Route
        key={item.code}
        path={`/Staff/${item.code}`}
        element={<Staff />} />
      );
  });
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        {initialRoutes}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

