import { Link, useNavigate} from 'react-router-dom'
import AccountCircle from '@mui/icons-material/AccountCircle';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import "../../styles.css";
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import ChecklistRtlOutlinedIcon from '@mui/icons-material/ChecklistRtlOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import { useState } from 'react';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';

const pages = ['トップ', '勤務表', '勤務情報', '集計', '連絡事項', 'カレンダー', 'ヘルプ'];
const settings = ['アカウント', 'ログアウト'];

var today = new Date()
var date = new Date();
var lastDate = new Date(date.getFullYear(), date.getMonth()+2, 0);
var days = lastDate.getDate()
const year = today.getFullYear()
const month = today.getMonth() + 2
const thismonth = date.getMonth()+1
const nextmonth = (thismonth==12) ? 1 : date.getMonth()+2
const thisyear = (thismonth==12) ? date.getFullYear() + 1 : date.getFullYear()
const thisday = date.getDate()

function SetAppbarFunction({pagename}) {
  const [showname, setShowname] = useState(pagename)
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElNav2, setAnchorElNav2] = React.useState(null);
  const [anchorElNav3, setAnchorElNav3] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElUser2, setAnchorElUser2] = React.useState(null);
  const [anchorElUser3, setAnchorElUser3] = React.useState(null);
  const [anchorElUser4, setAnchorElUser4] = React.useState(null);
  const [anchorElUser5, setAnchorElUser5] = React.useState(null);

  const Byoutou = localStorage.getItem("Byoutou")

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenNavMenu2 = (event) => {
    setAnchorElNav2(event.currentTarget);
  };
  const handleOpenNavMenu3 = (event) => {
    setAnchorElNav3(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenUserMenu2= (event) => {
    setAnchorElUser2(event.currentTarget);
  };
  const handleOpenUserMenu3= (event) => {
    setAnchorElUser3(event.currentTarget);
  };
  const handleOpenUserMenu4= (event) => {
    setAnchorElUser4(event.currentTarget);
  };
  const handleOpenUserMenu5= (event) => {
    setAnchorElUser5(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  }; 
  const handleCloseNavMenu2 = () => {
    setAnchorElNav2(null);
  };
  const handleCloseNavMenu3 = () => {
    setAnchorElNav3(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseUserMenu2 = () => {
    setAnchorElUser2(null);
  };
  const handleCloseUserMenu3 = () => {
    setAnchorElUser3(null);
  };

  const navigate = useNavigate();

  function handleLogout (){
    const confirm = window.confirm('ログアウトしますか？')
    if (confirm) {
      window.history.pushState({}, document.title, "/Login");
      navigate('/Login')
    }
  }

  function handlekeyStaff(ward){
    let keyvalue = {ward:ward, year:thisyear, month:nextmonth}
    localStorage.setItem('Staff-key', JSON.stringify(keyvalue))
  }

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters style={{minHeight: '10px'}}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 0.5,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            飯塚病院
          </Typography>
          <Typography
            variant="h8"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.1rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            スケジューリングシステム
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >

              <Link to="/" className='text-deco'>
                <MenuItem key={pages[0]} onClick={handleCloseNavMenu}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                  <Typography textAlign="center">{pages[0]}</Typography>
                </MenuItem>
              </Link>
              <Link to="/Table" className='text-deco'>
                <MenuItem key={pages[1]} onClick={handleCloseNavMenu}>
                <ListItemIcon>
                  <TableChartOutlinedIcon />
                </ListItemIcon>
                  <Typography textAlign="center">{pages[1]}</Typography>
                </MenuItem>
              </Link>
                {/*<MenuItem key={pages[1]} onClick={handleOpenNavMenu2}>
                <ListItemIcon>
                  <TableChartOutlinedIcon />
                </ListItemIcon>
                  <Typography textAlign="center">{pages[1]}</Typography>
                </MenuItem>
              <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElNav2}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav2)}
              onClose={handleCloseNavMenu2}
            >
              <Link to="/Table/Storage" className='text-deco'>
                <MenuItem onClick={handleCloseNavMenu2}>
                  <ListItemIcon>
                    <EditOutlinedIcon />
                  </ListItemIcon>
                  <Typography textAlign="center">勤務表確認・修正</Typography>
                </MenuItem>
              </Link>
              <Link to="/Table/Request" className='text-deco'>
                <MenuItem onClick={handleCloseNavMenu2}>
                  <ListItemIcon>
                    <AddBoxOutlinedIcon />
                  </ListItemIcon>
                  <Typography textAlign="center">勤務希望登録</Typography>
                </MenuItem>
              </Link>
              <Link to="/Table/Schedule" className='text-deco'>
                <MenuItem onClick={handleCloseNavMenu2}>
                  <ListItemIcon>
                    <HandymanOutlinedIcon />
                  </ListItemIcon>
                  <Typography textAlign="center">勤務表作成</Typography>
                </MenuItem>
              </Link>
            </Menu>*/}

              {/* <Link to={`/Staff/${Byoutou}`} className='text-deco'>
                <MenuItem key={pages[2]} onClick={()=>{handleCloseNavMenu(); handlekeyStaff(Byoutou)}}>
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <Typography textAlign="center">{pages[2]}</Typography>
                </MenuItem>
              </Link> */}

              <MenuItem key={pages[2]} onClick={handleOpenNavMenu3}>
                <ListItemIcon>
                  <PostAddOutlinedIcon />
                </ListItemIcon>
                  <Typography textAlign="center">{pages[2]}</Typography>
                </MenuItem>
              <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElNav3}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav3)}
              onClose={handleCloseNavMenu3}
            >
              <Link to={`/Staff/${Byoutou}`} className='text-deco'>
                <MenuItem onClick={handleCloseNavMenu3}>
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <Typography textAlign="center">スタッフ</Typography>
                </MenuItem>
              </Link>
              <Link to={`/Setting/Shift/${Byoutou}`} className='text-deco'>
                <MenuItem onClick={handleCloseNavMenu3}>
                  <ListItemIcon>
                    <CategoryOutlinedIcon />
                  </ListItemIcon>
                  <Typography textAlign="center">シフト・チーム・スキル</Typography>
                </MenuItem>
              </Link>
              <Link to={`/Setting/Const/${Byoutou}`} className='text-deco'>
                <MenuItem onClick={handleCloseNavMenu3}>
                  <ListItemIcon>
                    <RuleOutlinedIcon />
                  </ListItemIcon>
                  <Typography textAlign="center">勤務条件・枠数</Typography>
                </MenuItem>
              </Link>
            </Menu>

              <Link to="/Stats" className='text-deco'>
                <MenuItem key={pages[3]} onClick={handleCloseNavMenu}>
                <ListItemIcon>
                  <InsertChartOutlinedIcon />
                </ListItemIcon>
                  <Typography textAlign="center">{pages[3]}</Typography>
                </MenuItem>
              </Link>
              <Link to="/Message" className='text-deco'>
                <MenuItem key={pages[4]} onClick={handleCloseNavMenu}>
                <ListItemIcon>
                  <EmailOutlinedIcon />
                </ListItemIcon>
                  <Typography textAlign="center">{pages[4]}</Typography>
                </MenuItem>
              </Link>
              <Link to="/Calendar" className='text-deco'>
                <MenuItem key={pages[5]} onClick={handleCloseNavMenu}>
                  <ListItemIcon>
                    <CalendarMonthOutlinedIcon />
                  </ListItemIcon>
                  <Typography textAlign="center">{pages[5]}</Typography>
                </MenuItem>
              </Link>
              <Link to="/Help" className='text-deco'>
                <MenuItem key={pages[6]} onClick={handleCloseNavMenu}>
                  <ListItemIcon>
                    <HelpOutlineOutlinedIcon />
                  </ListItemIcon>
                  <Typography textAlign="center">{pages[6]}</Typography>
                </MenuItem>
              </Link>

            </Menu>
          </Box>
          
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 1,
              display: { xs: 'flex', md: 'none' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            飯塚病院
          </Typography>
          <Typography
            variant="h8"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 0,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.1rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            スケジューリングシステム
          </Typography>
          

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} style={{height: '50px', alignItems: "center"}}>
            <Link to="/" className='text-deco'>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                  <Typography textAlign="center">{pages[0]}</Typography>
              </Button>
            </Link>

            <Link to="/Table" className='text-deco'>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                  <Typography textAlign="center">{pages[1]}</Typography>
              </Button>
            </Link>

              {/*<Button
                onClick={handleOpenUserMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                  <Typography textAlign="center">{pages[1]}</Typography>
              </Button>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Link to="/Table/Storage" className='text-deco'>
                <MenuItem onClick={handleCloseUserMenu}>
                  <ListItemIcon>
                    <EditOutlinedIcon />
                  </ListItemIcon>
                  <Typography textAlign="center">勤務表確認・修正</Typography>
                </MenuItem>
              </Link>
              <Link to="/Table/Request" className='text-deco'>
                <MenuItem onClick={handleCloseUserMenu}>
                  <ListItemIcon>
                    <AddBoxOutlinedIcon />
                  </ListItemIcon>
                  <Typography textAlign="center">勤務希望登録</Typography>
                </MenuItem>
              </Link>
              <Link to="/Table/Schedule" className='text-deco'>
                <MenuItem onClick={handleCloseUserMenu}>
                  <ListItemIcon>
                    <HandymanOutlinedIcon />
                  </ListItemIcon>
                  <Typography textAlign="center">勤務表作成</Typography>
                </MenuItem>
              </Link>
            </Menu>*/}

            {/* <Link to={`/Staff/${Byoutou}`} className='text-deco'>
              <Button
                onClick={()=>{handleCloseNavMenu(); handlekeyStaff(Byoutou)}}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                  <Typography textAlign="center">{pages[2]}</Typography>
              </Button>
            </Link> */}
            
            <Button
                onClick={handleOpenUserMenu3}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                  <Typography textAlign="center">{pages[2]}</Typography>
              </Button>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser3}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser3)}
              onClose={handleCloseUserMenu3}
            >
              <Link to={`/Staff/${Byoutou}`} className='text-deco'>
                <MenuItem onClick={handleCloseUserMenu3}>
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <Typography textAlign="center">スタッフ</Typography>
                </MenuItem>
              </Link>
              <Link to={`/Setting/Shift/${Byoutou}`} className='text-deco'>
                <MenuItem onClick={handleCloseUserMenu3}>
                  <ListItemIcon>
                    <CategoryOutlinedIcon />
                  </ListItemIcon>
                  <Typography textAlign="center">シフト・チーム・スキル</Typography>
                </MenuItem>
              </Link>
              <Link to={`/Setting/Const/${Byoutou}`} className='text-deco'>
                <MenuItem onClick={handleCloseUserMenu3}>
                  <ListItemIcon>
                    <RuleOutlinedIcon />
                  </ListItemIcon>
                  <Typography textAlign="center">勤務条件・枠数</Typography>
                </MenuItem>
              </Link>
            </Menu>

            <Link to="/Stats" className='text-deco'>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                  <Typography textAlign="center">{pages[3]}</Typography>
              </Button>
            </Link>
            <Link to="/Message" className='text-deco'>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                  <Typography textAlign="center">{pages[4]}</Typography>
              </Button>
            </Link>
            <Link to="/Calendar" className='text-deco'>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                  <Typography textAlign="center">{pages[5]}</Typography>
              </Button>
            </Link>
            <Link to="/Help" className='text-deco'>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                  <Typography textAlign="center">{pages[6]}</Typography>
              </Button>
            </Link>
          </Box>

          {showname!==undefined && 
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <div style={{overflow: 'hidden', height: '49.5px', fontSize: '16px', padding: '0.75rem 1rem 0 1rem', color: 'black', fontWeight: 'bold', backgroundColor: '#DCDCDC'}}>
              {showname}
            </div>
            {"　"}
          </div>}

          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleOpenUserMenu2} sx={{ p: 0 }} color="inherit" size="large">
              <AccountCircle />
            </IconButton>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser2}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser2)}
              onClose={handleCloseUserMenu2}
            >
              <Link to="/Account" className='text-deco'>
                <MenuItem onClick={handleCloseUserMenu2}>
                  <ListItemIcon>
                    <BadgeOutlinedIcon />
                  </ListItemIcon>
                  <Typography textAlign="center">{settings[0]}</Typography>
                </MenuItem>
              </Link>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <ExitToAppOutlinedIcon />
                  </ListItemIcon>
                  <Typography textAlign="center">{settings[1]}</Typography>
                </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default SetAppbarFunction;