import React from "react";
import {setcol} from "./colors";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

export default function Relationship({value, backgroundColor, word}) {
  return (
    <span
      style={{
        boxSizing: "border-box",
        backgroundColor: backgroundColor,
        color: setcol(100),
        fontWeight: 400,
        padding: "2px 6px",
        borderRadius: 4,
        textTransform: "capitalize",
        display: "inline-block"
      }}>
      {value}
      {value=="" && word}
    </span>
  );
}
