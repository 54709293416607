import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import SetAppbar from "./TopPage2";
import { Button } from "@mui/material";

const mdTheme = createTheme();

function DashboardContent() {
  if (sessionStorage.getItem("Login")==="1"){
    sessionStorage.setItem("Login", "1")
  }
  const [todoText, setTodoText] = useState("");
  const [incompleteTodo, setIncompleteTodo] = useState(JSON.parse(localStorage.getItem("message")));

  const onChangeTodoText = (event) => setTodoText(event.target.value);

  const addTodo = () => {
    if (todoText === "") return;
    const today = new Date();
    const month = today.getMonth() + 1 + "月";
    const minutes = today.getMinutes() > 9 ? today.getMinutes() : "0" + today.getMinutes();
    const time = today.getFullYear() + "年" + month + today.getDate() + "日 " + today.getHours() + ":" + minutes;
    const newTodo = [...incompleteTodo, time + "\n" + todoText];
    setIncompleteTodo(newTodo);
    setTodoText("");
    localStorage.setItem("message", JSON.stringify(newTodo));
  };

  const deleteTodo = (index) => {
    const confirm = window.confirm("削除しますか？");
    if (confirm) {
      const newTodo = [...incompleteTodo];
      newTodo.splice(index, 1);
      setIncompleteTodo(newTodo);
      localStorage.setItem("message", JSON.stringify(newTodo));
    }
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <SetAppbar pagename={"連絡事項"} />
      <Box sx={{ display: "flex" }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 2,
            height: "100vh",
            overflow: "auto",
            marginTop: "40px",
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid container spacing={1}>
                <div style={{width: '100%'}}>
                <div>
                <input
                  className="text-box"
                  placeholder="入力"
                  value={todoText}
                  onChange={onChangeTodoText}
                />
                </div>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                  size="small"
                  variant="contained"
                  color="success"
                  onClick={addTodo}
                >
                  追加
                </Button>
                </div>
                </div>

                <Grid item xs={12} md={12} lg={12}>
                  <strong>連絡事項一覧</strong>
                  {incompleteTodo!==null && incompleteTodo.map((todo, index) => (
                    <div key={index} className="list-row">
                      <div>
                      <Grid item xs={12} md={12} lg={12}>
                        <Paper
                          sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            height: 100,
                          }}
                        >
                          {todo.split('\n').map((line, index) => (
                            <span key={index}>
                              {line}
                              {index < todo.split('\n').length - 1 && <br />}
                            </span>
                          ))}
                        </Paper>
                      </Grid>
                      </div>
                      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <Button size="small"
                          variant="contained"
                          color="error" onClick={() => deleteTodo(index)}>削除</Button>
                      </div>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

const MessagePage = () => {
  return <DashboardContent />;
};

export default MessagePage;
