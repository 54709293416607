import React, { useState } from "react";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Paper from '@mui/material/Paper';
import EditShift from './TableShow';
import SetAppbar from "../TopPage2";

window.onload = function () {
    var today = new Date();
 }
var today = new Date()
const year = today.getFullYear() + '年'
const month = today.getMonth()+2 + '月'

const mdTheme = createTheme();

function DashboardContent() {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [data, setData] = useState([]);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 2,
            height: '100%',
            overflow: 'auto',
            marginTop: '50px',
          }}
        >
         <SetAppbar pagename={"勤務表確認"}/> 
          <div style={{margin: '10px'}}>
            <Paper>
              <EditShift  />
            </Paper>
          </div>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

const Storage = () => {
    return <DashboardContent />;
  };
  
  export default Storage;