// 全病棟対応の勤務表修正画面
import { setcol } from "../../../Table/colors";
import React, { useRef, useEffect, useReducer, useState } from "react";
import "../../../styles.css";
import TableEdit from "../../../Table/TableEdit";
import TableEditPre from "../../../Table/TablePre";
import { randomColor, shortId } from "../../../Table/utils";
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import Button from '@mui/material/Button';
import Select from 'react-select'
import Paper from '@mui/material/Paper';
import {usePopper} from "react-popper";
import PlusIcon from "../../../Table/img/Plus";
import Relationship from "../../../Table/Relationship";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { CSVLink } from "react-csv";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

function Slider({ value, onValueChange }) {
  const handleChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    onValueChange(newValue);
  };

  return (
      <input
        type="range"
        min={50}
        max={150}
        value={value}
        onChange={handleChange}
        style={{ 
          width: '130px',
          background: 'gray',
          appearance: 'none', 
          outline: 'none', 
          height: '3px', 
          borderRadius: '5px',
        }}
      />
  );
}

class ExcelRequestsImport extends React.Component {
  state = {
    cols: [],
    rows: []
  };

  constructor(props) {
    super(props);
    this.state = {
      keyvalue: { ward: '', year: '', month: '' },
    };
  }

  componentDidMount() {
    const localvalue = JSON.parse(localStorage.getItem('Request-key'));
    this.setState({ keyvalue: localvalue });
  }

  render() {

    if (sessionStorage.getItem("Login")==="1"){
      sessionStorage.setItem("Login", "1")
    }    
    let Byoutou = this.state.keyvalue.ward
    let byoutou = Byoutou.toLocaleLowerCase()

    const JapaneseHolidays = require('japanese-holidays');
    const today = new Date()
    const thisyear = this.state.keyvalue.year
    const nextmonth = this.state.keyvalue.month
    var date = new Date();
    var lastDate = new Date(thisyear, nextmonth, 0);
    var days = lastDate.getDate()
    const isholiday = JapaneseHolidays.getHolidaysOf(lastDate.getFullYear(), true);
    const thismonth = (nextmonth=="1") ? "12" : String(Number(nextmonth) - 1)
    var prelastDate = new Date(thisyear, thismonth, 0);
    const predays = prelastDate.getDate()
    const preyear = (nextmonth==1) ? String(Number(thisyear)-1) : thisyear
    const thismonth0 = thismonth.length==1 ? "0" + thismonth : thismonth
    const thisday = String(date.getDate()).length==1 ? "0" + String(date.getDate()) : date.getDate()
    const nextnextmonth = (nextmonth=="12") ? "1" : String(Number(nextmonth) + 1)
    const nextyear = (nextmonth=="12") ? String(Number(thisyear) + 1) : thisyear

    const holiday = []
    for (let i = 0; i < isholiday.length; i++){
      if (isholiday[i].month===lastDate.getMonth()+1){
        holiday.push(isholiday[i].date)
      }
    }

    const weekend = []
    const whatday = []
    for (let i = 1; i <= days; i++){
      var day = new Date(date.getFullYear(), date.getMonth()+1, i)
      if ([0, 6].includes(day.getDay())){
        weekend.push(String(i))
      }
      if (holiday.includes(i)){
        if (!weekend.includes(i)){
          weekend.push(String(i))
        }
      }
      if ([0].includes(day.getDay())){
        whatday.push("日")
      }
      if ([1].includes(day.getDay())){
        whatday.push("月")
      }
      if ([2].includes(day.getDay())){
        whatday.push("火")
      }
      if ([3].includes(day.getDay())){
        whatday.push("水")
      }
      if ([4].includes(day.getDay())){
        whatday.push("木")
      }
      if ([5].includes(day.getDay())){
        whatday.push("金")
      }
      if ([6].includes(day.getDay())){
        whatday.push("土")
      }
    }

    if (localStorage.getItem('r1' + byoutou + '-' + thisyear + '-' + nextmonth)!=2 || localStorage.getItem('Request-' + Byoutou  + thisyear + '-' + nextmonth)==null){
      localStorage.setItem('r1' + byoutou + '-' + thisyear + '-' + nextmonth, 1)
    }

    const shiftdata = (JSON.parse(localStorage.getItem("Shift-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Shift-"+Byoutou)) : []
    const teamdata = (JSON.parse(localStorage.getItem("Team-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Team-"+Byoutou)) : []
    const skillleveldata = (JSON.parse(localStorage.getItem("Skill-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Skill-"+Byoutou)) : []

    let shiftoptions = [];
    let preshiftoptions = [];
    let allowshift = [];

    shiftdata.forEach((data,i) => {
      if (data.shift=="管"){
        allowshift.push({label: data.shift, backgroundColor: data.color, type: data.type})
      }
      if ((data.shift=="〇" || data.shift=="L" || data.shift.includes("/") || data.shift.includes("長")) && (data.type=="日勤系" || data.type=="準夜勤系" || data.type=="ロング系")){
        if (!data.shift.includes("専")){
          allowshift.push({label: data.shift, backgroundColor: data.color, type: data.type})
          preshiftoptions.push({label: data.shift, backgroundColor: data.color, type: data.type})
        }
        for (const t in teamdata){
          preshiftoptions.push({label: data.shift + teamdata[t].team, backgroundColor: data.color, type: data.type})
        }
      } else {
        if (data.shift=="*" || data.shift=="公"|| data.shift=="遅J" ){
          allowshift.push({label: data.shift, backgroundColor: data.color, type: data.type})
        }   
        preshiftoptions.push({label: data.shift, backgroundColor: data.color, type: data.type})
      }
      shiftoptions.push({ label: data.label, value: data.shift, backgroundColor: data.color, type: data.type});
    })
    const deleteoption = { label: "", backgroundColor: "#FFFFFF", type: "削除"}
    shiftoptions.push(deleteoption);
    
    const shifttypes = [...new Set(shiftoptions.map(option => option.type))];
    const shifttypescolor = [...new Set(shiftoptions.map(option => option.backgroundColor))];

    let teamoptions = teamdata.map((data,i) => {
      return { label: data.team, value: data.team, backgroundColor: data.color };
    })

    let skilloptions = skillleveldata.map((data,i) => {
      return { label: data.skill, value: data.skill, backgroundColor: data.color };
    })

    let checkoptions = [
      {label: "可", backgroundColor: setcol(9)},
      {label: "", backgroundColor: setcol(1)},
    ]

    let sexoptions = [
      {label: "男性", backgroundColor: setcol(9)},
      {label: "女性", backgroundColor: setcol(9)},
      {label: "", backgroundColor: setcol(1)},
    ]

    let dayoptions = []
    for (let i = 1; i <= days; i++) {
      let ds = {
          label: String(i),
          value: i,
          };
      dayoptions.push(ds);
    }

    let daysoptions = [
      {label: "月", value: "mon"},
      {label: "火", value: "tue"},
      {label: "水", value: "wed"},
      {label: "木", value: "thu"},
      {label: "金", value: "fri"},
      {label: "土", value: "sat"},
      {label: "日", value: "sun"},
      {label: "土日", value: "satsun"},
      {label: "土日+祝日", value: "weekend"},
    ]

    function useLatest(value) {
        const ref = useRef(value);
        ref.current = value;
        return ref;
    }

    function useKeybind({
        altKey,
        ctrlKey,
        metaKey,
        shiftKey,
        key,
        onKeyDown,
        targetRef,
        }) {
        const onKeyDownLatest = useLatest(onKeyDown);

        useEffect(() => {
            const eventListener = (event) => {
            if (altKey && !event.altKey) return;
            if (ctrlKey && !event.ctrlKey) return;
            if (metaKey && !event.metaKey) return;
            if (shiftKey && !event.shiftKey) return;
            if (event.key !== key) return;

            event.preventDefault();
            onKeyDownLatest.current?.(event);
            };

            if (targetRef?.current) {
            const target = targetRef.current;

            target.addEventListener("keydown", eventListener);
            return () => target.removeEventListener("keydown", eventListener);
            } else {
            window.addEventListener("keydown", eventListener);
            return () => window.removeEventListener("keydown", eventListener);
            }
        }, [altKey, ctrlKey, key, metaKey, onKeyDownLatest, shiftKey, targetRef]);
    }

    // データをアップロード時に戻す関数
    function handleRemove(){
      const confirm = window.confirm('勤務希望データを削除しますか？')
        if (confirm) {
            localStorage.removeItem('Request-' + Byoutou  + thisyear + '-' + nextmonth)
            alert('勤務希望データを削除しました')
            let mdata = [];

            var num = 0
            const staffdata = JSON.parse(localStorage.getItem('Staff-' + Byoutou))
            for (let i = 1; i<=staffdata.length; i++) {
                if (staffdata[i-1].ID !== "" && staffdata[i-1].ID !== undefined){
                    num = num + 1
                }
            }

            for (let i = 1; i <= num; i++) {
                let mrow = {
                  ID: staffdata[i-1].ID + " " + staffdata[i-1].name,
                }
                for (let j = 1; j <= days; j++){
                  mrow["d" + String(j)] = ""
                }
                mdata.push(mrow);
              }
            
            let mcolumns = [{
              id: "ID",
              label: "ID",
              accessor: "ID",
              width: 110,
              dataType: "text",
              options: []
            }];
            
            for (let i = 1; i <= days; i++) {
                let mcol = {
                    id: "d" + String(i),
                    label: String(i),
                    accessor: "d" + String(i),
                    dataType: "select",
                    width: 40,
                    options: shiftoptions
                    };
                mcolumns.push(mcol);
            }
            localStorage.setItem('Request-'  + Byoutou  + thisyear + '-' + nextmonth, JSON.stringify(mdata))
        
            window.location.reload()  
        }
      }

    // シフトのデータ作成
    let sdata = [];
    let mdata = [];
    let pdata = [];

    var num = 0
    const staffdata = (JSON.parse(localStorage.getItem('Staff-' + Byoutou ))!==null) ? JSON.parse(localStorage.getItem('Staff-' + Byoutou )) : []
    for (let i = 1; i<=staffdata.length; i++) {
        if (staffdata[i-1].ID !== "" && staffdata[i-1].ID !== undefined){
            num = num + 1
        }
    }

    const staffcol = (JSON.parse(localStorage.getItem('Staff-Columns-' + Byoutou ))!==null) ? JSON.parse(localStorage.getItem('Staff-Columns-' + Byoutou )) : []

    const checkedstaff = (JSON.parse(localStorage.getItem("checkedstaff" + Byoutou))!==null) ? JSON.parse(localStorage.getItem("checkedstaff" + Byoutou)) : []
      let scolumns = []
      staffcol.forEach(item=>{
        if (checkedstaff.includes(item.label)){
          scolumns.push({
            id: item.id,
            label: item.label,
            accessor: item.accessor,
            dataType: item.dataType,
            width: 40,
            height: 40,
            options: item.options
          })
        }
      })

    for (let i = 1; i <= num; i++) {
      const istaffdata = staffdata[i-1]
      let srow = {}
      scolumns.forEach(col=>{
        srow[col.id] = istaffdata.hasOwnProperty(col.id) ? istaffdata[col.id] : ""
      })
        let mrow = {
          ID: istaffdata.ID + " " + istaffdata.name,
        }
        for (let j = 1; j <= days; j++){
          mrow["d" + String(j)] = ""
      }
      let prow = {}
      for (let j = 1; j <= predays; j++){
        prow["d" + String(predays+1-j)] = ""
        if (localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + thismonth)!==null){
          const prelocaldata = JSON.parse(localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + thismonth))
          for (let k = 1; k <= prelocaldata.length; k++){
            const preid = prelocaldata[k-1]
            if (istaffdata.ID + " " + istaffdata.name == preid.ID){
              prow["d" + String(predays+1-j)] = preid["d" + String(predays-(predays-j))]
            }
          }  
        }
      }
        sdata.push(srow);
        mdata.push(mrow);
        pdata.push(prow);
      }

    let reqdata = JSON.parse(localStorage.getItem('Request-' + Byoutou  + thisyear + '-' + nextmonth))!==null ? JSON.parse(localStorage.getItem('Request-' + Byoutou  + thisyear + '-' + nextmonth)) : mdata
    let mdata1 = []
    let sdata1 = []
    let pdata1 = []
    staffdata.forEach((data,i)=>{
      const istaffdata = staffdata[i]
      if (istaffdata["ID"]!==null && istaffdata["ID"]!==undefined){
      if (reqdata[i]!=undefined){
        mdata1.push(reqdata[i])
        pdata1.push(pdata[i])
      } else{
        let mrow = {}
        for (let j = 1; j <= days; j++){
          mrow["d" + String(j)] = ""
        }
        mrow["ID"] = istaffdata["ID"] + ' ' + istaffdata["name"]
        mdata1.push(mrow)
        let prow = {}
        for (let j = 1; j <= predays; j++){
          prow["d" + String(predays+1-j)] = ""
        }
        pdata1.push(prow)
      }
      let srow = {}
      scolumns.forEach(col=>{
        srow[col.id] = istaffdata.hasOwnProperty(col.id) ? istaffdata[col.id] : ""
      })
      sdata1.push(srow)}
    })


    let mcolumns = [{
      id: "ID",
      label: "ID",
      accessor: "ID",
      width: 110,
      dataType: "text",
      options: []
    }];
    let mcolumns2 = [{
      id: "d" + String(5),
      label: "前5",
      accessor: "d" + String(5),
      dataType: "select",
      width: 40,
      options: preshiftoptions
      },
      {
        id: "d" + String(4),
        label: "前4",
        accessor: "d" + String(4),
        dataType: "select",
        width: 40,
        options: preshiftoptions
        },
        {
          id: "d" + String(3),
          label: "前3",
          accessor: "d" + String(3),
          dataType: "select",
          width: 40,
          options: preshiftoptions
          },
          {
            id: "d" + String(2),
            label: "前2",
            accessor: "d" + String(2),
            dataType: "select",
            width: 40,
            options: preshiftoptions
            },
            {
              id: "d" + String(1),
              label: "前1",
              accessor: "d" + String(1),
              dataType: "select",
              width: 40,
              options: preshiftoptions
    }];
    
    for (let i = 1; i <= days; i++) {
      let mcol = {
        id: "d" + String(i),
        label: String(i),
        accessor: "d" + String(i),
        dataType: "select",
        width: 40,
        options: shiftoptions
        };
      mcolumns.push(mcol);
    }
    
    // 表に適した形のデータ
    let smcdata = {columns: scolumns, data: sdata1, skipReset: false}
    let mmcdata = {columns: mcolumns, data: mdata1, skipReset: false} 
    let predata = {columns: mcolumns2, data: pdata1, skipReset: false}

    // 初回実行時にローカルストレージを設定
    localStorage.setItem('Request-'  + Byoutou  + thisyear + '-' + nextmonth, JSON.stringify(mdata1))
    if (localStorage.getItem('r1' + byoutou + '-' + thisyear + '-' + nextmonth)==1 && num!==0){
      localStorage.setItem('r1' + byoutou + '-' + thisyear + '-' + nextmonth, 2)
    }

    // 表のデータや行、列の変更
    function reducer(state, action) {
      switch (action.type) {
        case "update_cell":
            const valuelist = action.value.split("→")
            const historydata = [...state.history]
            const updatedData = state.data.map((row, index) => {
              if (index === action.rowIndex) {
                // 対象の行のセルを更新
                const updatedRow = {
                  ...row,
                };
                valuelist.forEach((value, valueIndex) => {
                  if (valueIndex==0){
                    updatedRow[action.columnId] = value
                    historydata.push(
                      {
                        type: "update_cell",
                        rowIndex: action.rowIndex,
                        columnId: action.columnId,
                        value: state.data[action.rowIndex][action.columnId],
                      }
                    )
                  }
                  else if (String(Number(action.columnId.slice(1)) + valueIndex)<=days) {
                    updatedRow["d" + String(Number(action.columnId.slice(1)) + valueIndex)] = value;
                    historydata.push(
                      {
                        type: "update_cell",
                        rowIndex: action.rowIndex,
                        columnId: "d" + String(Number(action.columnId.slice(1)) + valueIndex),
                        value: state.data[action.rowIndex]["d" + String(Number(action.columnId.slice(1)) + valueIndex)],
                      }
                    )
                  }
                });
                return updatedRow;
              }
              return row;
            });

            return {
              ...state,
              skipReset: true,
              data: updatedData,
              history: historydata,
              future: [], 
            };
        case "enable_reset":
          return {
            ...state,
            skipReset: false
          };
        case "UNDO_ACTION":
          if (state.history.length > 0) {
            const lastAction = state.history[state.history.length - 1];
            const updatedData = state.data.map((row, index) => {
              if (index === lastAction.rowIndex) {
                // 対象の行のセルを更新
                return {
                  ...row,
                  [lastAction.columnId]: lastAction.value
                };
              }
              return row;
            });
            return {
              ...state,
              skipReset: true,
              data: updatedData,
              history: [...state.history.slice(0, -1)],
              future: [state, ...state.future],
              ...lastAction,
            };
          }
          return state;
        case "REDO_ACTION":
          if (state.future.length > 0) {
            const nextAction = state.future[0];
            const updatedData = state.data.map((row, index) => {
              if (index === nextAction.rowIndex) {
                // 対象の行のセルを更新
                return {
                  ...row,
                  [nextAction.columnId]: nextAction.value
                };
              }
              return row;
            });
            return {
              ...state,
              skipReset: true,
              data: updatedData,
              history: [...state.history],
              future: [state.future.slice(1)],
              ...nextAction,
            };
          }
          return state;
        case "DELETE_ROWVALUE":
            const historydatad = [...state.history]
            const updatedDatad = state.data.map((row, index) => {

              if (index === action.payload.index) {
                // 対象の行のセルを更新
                const updatedRow = {
                  ...row,
                };

                Object.keys(updatedRow).forEach((key, i) => {
                  if (i !== 0) {
                    updatedRow[key] = "";
                    if (row[key]!==""){
                      historydatad.push({
                        type: "update_cell",
                        rowIndex: action.payload.index,
                        columnId: key,
                        value: row[key],
                      });
                    }
                  }
                });
                return updatedRow;
              }
              return row;
            });

            return {
              ...state,
              skipReset: true,
              data: updatedDatad,
              history: historydatad,
              future: [], 
            };
        case "PASTE_ROWVALUE":
                const historydatap = [...state.history]
                const updatedDatap = state.data.map((row, index) => {

                  if (index === action.payload.index) {
                    // 対象の行のセルを更新
                    const updatedRow = {
                      ...row,
                    };
    
                    Object.keys(updatedRow).forEach((key, i) => {
                      if (i !== 0) {
                        updatedRow[key] = action.payload.value;
                        if (row[key]!==action.payload.value){
                          historydatap.push({
                            type: "update_cell",
                            rowIndex: action.payload.index,
                            columnId: key,
                            value: row[key],
                          });
                        }
                      }
                    });
                    return updatedRow;
                  }
                  return row;
                });
    
                return {
                  ...state,
                  skipReset: true,
                  data: updatedDatap,
                  history: historydatap,
                  future: [], 
                };
    
        default:
          return state;
      }
    }

    const {keyvalue} = this.state;

    // 表、ボタン、セレクトボックスなどを表示する関数
    function TableShow() {
      const [state, dispatch] = useReducer(reducer, mmcdata);
      useEffect(() => {dispatch({ type: "enable_reset" });
      }, [state.data, state.columns]);

      const [sstate, sdispatch] = useReducer(reducer, smcdata);
      useEffect(() => {sdispatch({ type: "enable_reset" });
      }, [sstate.data, sstate.columns]);

      const [pstate, pdispatch] = useReducer(reducer, predata);
      useEffect(() => {pdispatch({ type: "enable_reset" });
      }, [pstate.data, pstate.columns]);

      // 行データ削除、貼り付け
      const [cellState, setCellState] = useState({index: null, bl: false});
      const [cellStatepaste, setCellStatepaste] = useState({index: null, value: null, bl: false});

      const handleCellDataUpdate = (newValue) => {
        setCellState(newValue);
      };
      const handleCellDataUpdatePaste = (newValue) => {
        setCellStatepaste(newValue);
      };

      const fmcdata = {columns: mcolumns, data: state.data, skipReset: false, history: [], future: []}

      const [fstate, fdispatch] = useReducer(reducer, fmcdata);
      useEffect(() => {
        fdispatch({ type: "enable_reset" });
      }, [fstate.data, fstate.columns]);
      useEffect(() => {
        fdispatch({ type: "DELETE_ROWVALUE", payload: cellState});
      }, [cellState, fdispatch]);
      useEffect(() => {
        fdispatch({ type: "PASTE_ROWVALUE", payload: cellStatepaste});
      }, [cellStatepaste, fdispatch]);

      const [width, setWidth] = useState(null)
      useEffect(() => {
        setWidth(window.innerWidth)
      }, ([]))
      const updateWidth = (event) => {
        setWidth(window.innerWidth)
      }
      useEffect(() => {
        window.addEventListener(`resize`, updateWidth, {
          capture: false,
          passive: true,
        })
  
        return () => window.removeEventListener(`resize`, updateWidth)
      })

      // ローカルストレージに保存するための関数
      function handleSave(){
        localStorage.setItem('Request-'  + Byoutou  + thisyear + '-' + nextmonth, JSON.stringify(fstate.data))
        localStorage.setItem('progress-' + byoutou + '-' + thisyear + '-' + nextmonth, 2)
        alert('勤務希望を保存しました')
      }

      function handleSaveAuto(){
        localStorage.setItem('Request-'  + Byoutou  + thisyear + '-' + nextmonth, JSON.stringify(fstate.data))
        localStorage.setItem('progress-' + byoutou + '-' + thisyear + '-' + nextmonth, 2)
      }

      useKeybind({
        key: "s",
        ctrlKey: true,
        onKeyDown: () => handleSave(),
      });

      useKeybind({
        key: "z",
        ctrlKey: true,
        onKeyDown: () => fdispatch({ type: "UNDO_ACTION" }),
      });
  
      useKeybind({
        key: "y",
        ctrlKey: true,
        onKeyDown: () => fdispatch({ type: "REDO_ACTION" }),
      });

      const sname = "Request"
      const checkedshift = (JSON.parse(localStorage.getItem("allowshift-" + sname + Byoutou))!==null) ? JSON.parse(localStorage.getItem("allowshift-" + sname + Byoutou)) : []
      const [checkedItemsshift, setCheckedItemsshift] = useState(checkedshift);
      
      useEffect(()=>{
        setCheckedItemsshift(checkedshift)
      },[])
      const handleCheckboxChangeShift = (event) => {
        const { value } = event.target;
        let updatedLabels = [...checkedItemsshift];

        if (checkedItemsshift.includes(value)) {
          updatedLabels = updatedLabels.filter((label) => label !== value);
        } else {
          updatedLabels.push(value);
        }
        setCheckedItemsshift(updatedLabels);
      }
      useEffect(()=>{
        localStorage.setItem("allowshift-" + sname + Byoutou, JSON.stringify(checkedItemsshift))
      }, [checkedItemsshift])
      
      let allowshift = [];

      shiftoptions.forEach((data,i) => {
        if (checkedshift.includes(data.label)){
          allowshift.push({label: data.label, backgroundColor: data.backgroundColor, type: data.type})
        }
      })

      const [showallowshift, setshowallowshift] = useState(false)

      
      // セレクトボックスハンドラ
      const [team, setTeam] = useState([])
      const [skill, setSkill] = useState([])
      const [day, setDay] = useState([])
      const [ddays, setDdays] = useState([])
      const handleTeamChange = (selectedOptions) => {
        setTeam(selectedOptions.map(option => option.value)); 
      };
      function DropdownTeam() {
        const handleClick = (
          event
        ) => {
          console.log(event);
        };
        handleSaveAuto()
        return (
          <Select 
          isMulti
          placeholder="チーム" 
          value={teamoptions.filter(option => team.includes(option.value))} 
          onChange={handleTeamChange} 
          options={teamoptions} 
          isClearable={true} 
          isSearchable={true}/>
        )
      }
      const handleSkillChange = (selectedOptions) => {
        setSkill(selectedOptions.map(option => option.value));
      };
      function DropdownSkill() {
        const handleClick = (
          event
        ) => {
          console.log(event);
        };
        handleSaveAuto()
        return (
          <Select
          isMulti 
          placeholder="スキル" 
          value={skilloptions.filter(option => skill.includes(option.value))} 
          onChange={handleSkillChange} 
          options={skilloptions} 
          isClearable={true} 
          isSearchable={true}/>
        )
      }
      const handleDayChange = (selectedOptions) => {
        setDay(selectedOptions.map(option => option.value));
      };
      function DropdownDay() {
        const handleClick = (
          event
        ) => {
          console.log(event);
        };
        handleSaveAuto()
        return (
          <Select
           isMulti
           placeholder="日にち" 
           value={dayoptions.filter(option => day.includes(option.value))} 
           onChange={handleDayChange} 
           options={dayoptions} 
           isClearable={true} 
           isSearchable={true}/>
        )
      }
      function DropdownDays() {
        const handleClick = (
          event
        ) => {
          console.log(event);
        };
        handleSaveAuto()
        return (
          <Select placeholder="曜日" onClick={handleClick} value={ddays} onChange={setDdays} options={daysoptions} isClearable={true} isSearchable={true}/>
        )
      }

      const [modalOpenstaff, setModalOpenstaff] = useState(false);

      const checkedstaff = (JSON.parse(localStorage.getItem("checkedstaff" + Byoutou))!==null) ? JSON.parse(localStorage.getItem("checkedstaff" + Byoutou)) : []

      const [checkedItemsstaff, setCheckedItemsstaff] = useState(checkedstaff);

      const handleCheckboxChangeStaff = (event) => {
        const { value } = event.target;
        let updatedLabels = [...checkedItemsstaff];

        if (checkedItemsstaff.includes(value)) {
          updatedLabels = updatedLabels.filter((label) => label !== value);
        } else {
          updatedLabels.push(value);
        }

        setCheckedItemsstaff(updatedLabels);
      }
      localStorage.setItem("checkedstaff" + Byoutou, JSON.stringify(checkedItemsstaff))


      // ボタンハンドラ
      const [power, setPower] = useState(false);
      const [powers, setPowers] = useState(false);
      const [powerp, setPowerp] = useState(false);
      const [powercon, setPowercon] = useState(false);
      function handlestaff() {
        setPower(prevState => !prevState)
      }

      function handlepre() {
        setPowerp(prevState => !prevState)
      }


      //フィルタリング実装部分
      function FilteredColumns(col){
        let fildata = 
          ((day=="" || day==undefined) && (ddays=="" || ddays==undefined))
          ? col
          : (day!="" && day!=undefined)
          ? col.filter(item => {
            if (item.id=="ID"){
              return true
            }
            for (let j = 0; j < day.length; j++){
              if (item.id=="d"+day[j]){
                return true
              }
            }
            })
          : (ddays!="" && ddays!=undefined)
          ? col.filter((item,i) => {
            if (item.id=="ID"){
              return true
            }
            if (ddays.label==whatday[i-1]){
              return true
            }
            if (ddays.label=="土日" && ("土"==whatday[i-1] || "日"==whatday[i-1])){
              return true
            }
            if (ddays.label=="土日+祝日" && (weekend.includes(String(i)))){
              return true
            }
            else return false
            })
          : col
        return fildata
      }

      function FilterCancel() {
        if (team!=undefined && team!=""){
          setTeam([])
        }
        if (skill!=undefined && skill!=""){
          setSkill([])
        }
        if (day!=undefined && day!=""){
          setDay([])
        }
        if (ddays!=undefined && ddays!=""){
          setDdays([])
        }
      }

      const [showSelect, setShowSelect] = useState(false);
      const [selectRef, setSelectRef] = useState(null);
      const [selectPop, setSelectPop] = useState(null);
      const [selectedValue, setSelectedValue] = useState(null);
      const [selectedValues, setSelectedValues] = useState(null);

      const {styles, attributes} = usePopper(selectRef, selectPop, {
        placement: "bottom-start",
        strategy: "fixed"
      });

      const handleSelectClick = () => {
        setShowSelect(true); // モーダルを表示
      };
    
      const handleOptionClick = (option) => {
        setSelectedValues(option.value);
        setSelectedValue(option.label); // 選択した値を保持
        setShowSelect(false); // モーダルを閉じる
      };

      function getBackgroundColor(selectedValue) {
        const item = shiftoptions.find(item => item.label === selectedValue);
        if (item) {
          return item.backgroundColor;
        } else {
          return "#FF4500";
        }
      }

      // エスケープキーを押した場合
      document.addEventListener("keydown", function(event) {
        if (event.key === "Escape") { 
            setShowSelect(false)
        }
      });

      // カーソルが重なったときのアクション
      const [hoveredStates, setHoveredStates] = useState({});
      const handleMouseEnter = (shifttype) => {
        // カーソルが重なったときに対応するisHoveredステートをtrueに設定
        setHoveredStates((prevStates) => ({
          ...prevStates,
          [shifttype]: true,
        }));
      };
      const handleMouseLeave = (shifttype) => {
        // カーソルが離れたときに対応するisHoveredステートをfalseに設定
        setHoveredStates((prevStates) => ({
          ...prevStates,
          [shifttype]: false,
        }));
      };

      const optypes = shiftoptions.map(option => option.type);
      const typeCount = {};
      optypes.forEach(type => {
        typeCount[type] = (typeCount[type] || 0) + 1;
      });
      const typeCountList = Object.entries(typeCount);
      const typeCounts = typeCountList.map(([type, count]) => count);

      const handlereload = () => {
        window.location.reload()  
      }

      const [filterbutton, setfilterbutton] = useState((preyear==today.getFullYear() && thismonth==today.getMonth()+1) ? true : false)
      const [databutton, setdatabutton] = useState((preyear==today.getFullYear() && thismonth==today.getMonth()+1) ? true : false)
      const [filebutton, setfilebutton] = useState((preyear==today.getFullYear() && thismonth==today.getMonth()+1) ? true : false)
      const [editbutton, seteditbutton] = useState((preyear==today.getFullYear() && thismonth==today.getMonth()+1) ? true : false)
      const [expandbutton, setexpandbutton] = useState((preyear==today.getFullYear() && thismonth==today.getMonth()+1) ? true : false)

      const handleexpand = () => {
        if (filterbutton || databutton || filebutton || editbutton){
          setfilterbutton(false)
          setdatabutton(false)
          seteditbutton(false)
          setfilebutton(false)
          setexpandbutton(prevstate=>!prevstate)
        } else {
          setfilterbutton(true)
          setdatabutton(true)
          seteditbutton(true)
          setfilebutton(true)
          setexpandbutton(prevstate=>!prevstate)
        }
      }

      const zoomstate = localStorage.getItem("zoom-Request")!==null ? Number(localStorage.getItem("zoom-Request")) : 80
      const [zoomsize, setZoom] = useState(zoomstate)
      const handleZoomChange = (newValue) => {
        setZoom(newValue);
      };
      localStorage.setItem("zoom-Request", zoomsize)

      let dldata = "Request-" + Byoutou + thisyear + '-' + nextmonth
      let rdata = []

      var prelastDate = (nextmonth=="1") ? new Date(thisyear-1, "12", 0) : new Date(thisyear, nextmonth-1, 0)
      const predays = prelastDate.getDate()

      let kdata = JSON.parse(localStorage.getItem(dldata))
      const staffdata = JSON.parse(localStorage.getItem('Staff-' + Byoutou ))!==null ? JSON.parse(localStorage.getItem('Staff-' + Byoutou )) : ""
      for (let i = 0; i<staffdata.length; i++){
        if (JSON.parse(localStorage.getItem('Staff-' + Byoutou))[i].ID!=null){
          let ikdata = kdata[i]
          rdata[i] = JSON.parse(localStorage.getItem('Staff-' + Byoutou))[i]
          rdata[i] = Object.assign(rdata[i], ikdata)
        }
      }

      const saveddata = rdata
      const filename = "勤務希望データ_" + Byoutou  + thisyear + '年' + nextmonth + '月' + "_" + preyear + thismonth0 + thisday

      const [helpopen, setHelpopen] = useState(false)

      function handlemonthchange(Byoutou, year, month){
        let keyvalue = {ward:Byoutou, year:year, month:month}
        localStorage.setItem('Request-key', JSON.stringify(keyvalue))
        handlereload()
      }


      handleSaveAuto()

      return (
        <div onContextMenu={(e) => {
          e.preventDefault(); 
        }}>
        <div>
        <div  style={{position: 'sticky', top: '50', width: '100%', zIndex: '10', border: '1.5px solid gray'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#DCDCDC', height: "25px"}}>
              <div style={{display: 'flex'}}>
                  <div style={{display: 'flex', paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
                    <div onClick={()=>setHelpopen(true)} style={{cursor: 'pointer', paddingRight: '0.3rem', display: 'flex', alignItems: 'center'}}>
                      <HelpOutlineOutlinedIcon style={{fontSize: '20px'}}/>
                    </div>
                    <div onClick={handleSave} style={{display: 'flex', alignItems: 'center', paddingRight: '0.2rem'}}>
                      <SaveOutlinedIcon style={{fontSize: '20px', cursor: 'pointer'}}/>
                    </div>
                    <div  style={{display: 'flex', alignItems: 'center', paddingRight: '0.3rem', marginTop: '0.3rem'}}>
                      <CSVLink data={saveddata} filename={filename} className="text-deco">
                      <FileDownloadOutlinedIcon style={{fontSize: '20px', cursor: 'pointer'}}/>
                      </CSVLink>
                    </div>
                    <div onClick={() => fdispatch({ type: "UNDO_ACTION" })} style={{display: 'flex', alignItems: 'center'}}>
                      <UndoOutlinedIcon style={{fontSize: '20px', cursor: 'pointer', color: fstate.history.length>0 ? 'black' : 'gray'}}/>
                    </div>
                    <div onClick={() => fdispatch({ type: "REDO_ACTION" })} style={{display: 'flex', alignItems: 'center', paddingLeft: '0.3rem'}}>
                      <RedoOutlinedIcon style={{fontSize: '20px', cursor: 'pointer', color: fstate.future.length>0 ? 'black' : 'gray'}}/>
                    </div>
                  </div>
                <div onClick={()=>{setfilebutton(prevstate=>!prevstate);}} style={{height: '22px', color: "#2F4F4F", borderBottom: filebutton && '2px solid #008000', cursor: 'pointer', marginLeft: '0.5rem', marginRight: '0.5rem', fontSize: '15px'}}>
                  ファイル
                </div>
                <div onClick={()=>{seteditbutton(prevstate=>!prevstate);}} style={{height: '22px', color: "#2F4F4F", borderBottom: editbutton && '2px solid #FF4500', cursor: 'pointer', marginLeft: '0.5rem', marginRight: '0.5rem', fontSize: '15px'}}>
                  編集
                </div>
                <div onClick={()=>{setdatabutton(prevstate=>!prevstate);}} style={{height: '22px', color: "#2F4F4F", borderBottom: databutton && '2px solid #2196F3', cursor: 'pointer', marginLeft: '0.5rem', marginRight: '0.5rem', fontSize: '15px'}}>
                  データ
                </div>
                <div onClick={()=>{setfilterbutton(prevstate=>!prevstate);}} style={{height: '22px', color: "#2F4F4F", borderBottom: filterbutton && '2px solid gray', cursor: 'pointer', marginLeft: '0.5rem', marginRight: '0.5rem', fontSize: '15px'}}>
                  フィルター
                </div>
              </div>
              <div>
                <div onClick={handleexpand} style={{height: '22px', color: "#2F4F4F", cursor: 'pointer', marginLeft: '0.5rem', marginRight: '0.5rem', fontSize: '15px'}}>
                  {(filterbutton || databutton || filebutton || editbutton) ? <ExpandLessOutlinedIcon/> : <ExpandMoreOutlinedIcon/>}
                </div>
              </div>
            </div>
            <div style={{minHeight: (filterbutton || databutton || filebutton || editbutton) && '30px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', zIndex: 2}}>
              
                {filebutton && <Button sx={{ border: '1px solid gray' }} size="small" variant="contained" style={{backgroundColor: "#008000", zoom: '80%'}} onClick={handleSave}>
                  保存
                </Button>}
                {filebutton && <CSVLink data={saveddata} filename={filename} className="text-deco">
                  <Button sx={{ border: '1px solid gray', height: '26px'}} size="small" variant="contained" color="success">
                    <div style={{fontSize: '10px', display: 'flex', alignItems: 'center'}}>ダウンロード</div><div style={{display: 'flex', alignItems: 'center'}}><FileDownloadOutlinedIcon style={{fontSize: '17px'}}/></div>
                  </Button>
                </CSVLink>}
                {filebutton && <Button sx={{ border: '1px solid gray' }} size="small" variant="contained" color="error" style={{zoom: '80%'}} onClick={handleRemove}>
                  全削除
                </Button>}
                {editbutton && <Button sx={{ border: '1px solid gray' }} size="small" variant="contained" style={{backgroundColor: powercon ? "#FFA07A" : "#FF4500" , color: !powercon ? '#ffffff' : '#000000', zoom: '80%'}} onClick={()=>setPowercon(prevState => !prevState)}>
                  条件違反
                </Button>}
                {editbutton && <Button sx={{ border: '1px solid gray' }} onClick={handleSelectClick} size="small" variant="contained" style={{color: selectedValue===null ? "#FFFFFF" : "#000000", backgroundColor: getBackgroundColor(selectedValue), zoom: '80%'}}>
                  {selectedValue===null ? "シフト記号" : (selectedValues!=="") ? selectedValues : "☒削除"}
              </Button>}
                {databutton&&  <div>
                  <Button onMouseEnter={()=>setModalOpenstaff(true)} onMouseLeave={()=>setModalOpenstaff(false)} sx={{ border: '1px solid gray' }} style={{ backgroundColor: !power ? '#2196F3' : '#B0C4DE', color: !power ? '#ffffff' : '#000000', zoom: '80%'}} size="small" variant="contained" color="info" onClick={handlestaff}>
                    スタッフ情報
                  </Button>
                  {modalOpenstaff && (
                    <div
                    className='shadow-5 bg-white border-radius-md'
                    style={{
                      zIndex: 15,
                      minWidth: 200,
                      maxWidth: 400,
                      position: "absolute",
                    }}
                    onMouseEnter={()=>setModalOpenstaff(true)}
                    onMouseLeave={()=>setModalOpenstaff(false)}
                    >
                      <div style={{display: 'flex', flexWrap: 'wrap', padding: '0.5rem'}}>
                      {staffcol.map((data) => {
                        if (!data.label.includes("No.") && !data.label.includes("仮番号") && !data.label.includes("限") && !data.label.includes("禁止") && !data.label.includes("姓")){
                          return(
                          <form>
                            <label style={{ paddingRight: '1rem', cursor: 'pointer' }}>
                              <input
                                type="checkbox"
                                name="options"
                                value={data.label}
                                checked={checkedItemsstaff.includes(data.label)}
                                onChange={handleCheckboxChangeStaff}
                                style={{ cursor: 'pointer' }}
                              />
                              {data.label}
                            </label>
                          </form>)}
                      })}
                      </div>
                      <div style={{padding: '0 0.5rem 0.5rem 0.5rem', display: 'flex', justifyContent: 'flex-end'}}>
                        <Button sx={{ border: '1px solid gray', height: '25px'}} variant="contained" style={{backgroundColor: "#008000"}} onClick={handlereload}>
                          更新
                        </Button>
                      </div>
                    </div>
                  )}
                  </div>}
                  {databutton&& <div>
                  <Button sx={{ border: '1px solid gray' }} style={{ backgroundColor: !powerp ? '#2196F3' : '#B0C4DE', color: !powerp ? '#ffffff' : '#000000' , zoom: '80%'}} size="small" variant="contained" color="info" onClick={handlepre}>
                  前月
                  </Button>
                  </div>}
                 {filterbutton&&  <div style={{zIndex:5, zoom: '80%'}}>
                <Paper>
                <DropdownTeam/>
                </Paper>
                </div>}
                  {filterbutton&&  <div style={{zIndex:5, zoom: '80%'}}>
                <Paper>
                <DropdownSkill/>
                </Paper>
                </div>}
                  {filterbutton&&  <div style={{zIndex:5, zoom: '80%'}}>
                {(ddays==undefined || ddays=="") &&
                <Paper>
                <DropdownDay/>
                </Paper>
                }
                </div>}
                  {filterbutton&&  <div style={{zIndex:5, zoom: '80%'}}>
                {(day==undefined || day=="") &&
                <Paper>
                <DropdownDays/> 
                </Paper>
                }       
                </div>}
                {filterbutton&&  <div>
                <Button sx={{ border: '1px solid gray' }} onClick={FilterCancel} size="small" variant="contained" color="inherit" style={{zoom: '80%'}}>
                  解除
              </Button>
              </div>}
              {showSelect && <div className='overlay' onClick={() => setShowSelect(false)} />}
              {showSelect && (
                <div
                  className='shadow-5 bg-white border-radius-md'
                  ref={setSelectPop}
                  {...attributes.popper}
                  style={{
                    ...styles.popper,
                    zIndex: 10,
                    minWidth: 200,
                    maxWidth: 400,
                    padding: "0.75rem",
                    position: "absolute",
                    left: "50%",
                    transform: "translate(-50%, 30%)",
                    zoom: '0.9',
                  }}>
                  <div
                    className='cursor-pointer'
                    style={{marginBottom: "0.8rem", marginTop: "0.3rem", marginRight: "10rem"}}
                    >
                    <Button onClick={()=>{setSelectedValue(null); setShowSelect(false)}} sx={{ border: '1px solid gray' }} size="small" variant="contained" color="inherit">
                        連続入力解除
                    </Button>
                  </div>
                  <div className='d-flex flex-wrap-wrap' style={{padding: '0 0.2rem 0.5rem 0', justifyContent: 'center', flexWrap: 'wrap'}}>
                  {allowshift.map((option) => (
                              <div
                                key={option.label}
                                className='cursor-pointer'
                                style={{ marginRight: "0.5rem", marginTop: "0.5rem", maxWidth: '150px'}}
                                onClick={() => {
                                  setShowSelect(false);
                                  handleOptionClick(option);
                                }}
                              >
                                <Relationship value={option.label} backgroundColor={option.backgroundColor} />
                              </div>
                    ))}
                  </div>
                  <div onClick={()=>setshowallowshift(true)} style={{display: 'flex', justifyContent: 'flex-end', marginTop: '-1.5rem', paddingBottom: '0.5rem', cursor: 'pointer'}}>
                  <EditOutlinedIcon style={{fontSize: '18px'}}/>
                </div>
                  <hr/>
                  <div className='d-flex flex-wrap-wrap' style={{paddingTop: "0.75rem", justifyContent: 'center'}}>
                    
                    {shifttypes.map((shifttype, i) => (
                      <div style={{paddingBottom: '0.5rem'}}>
                      {shifttype!=="削除" &&
                      <div style={{border: "1px solid #C0C0C0", boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)', cursor: 'pointer', backgroundColor: shifttypescolor[i], borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80px', height: '40px', marginRight: "0.75rem"}}
                        onMouseEnter={() => handleMouseEnter(shifttype)}
                        onMouseLeave={() => handleMouseLeave(shifttype)}
                      >
                        {shifttype}
                      </div>}
                      {shifttype=="削除" &&
                        <div
                        key={deleteoption.label}
                        className='cursor-pointer'
                        style={{border: "1px solid #C0C0C0", boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)', cursor: 'pointer', backgroundColor: shifttypescolor[i], borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80px', height: '40px', marginRight: "0.75rem"}}                        onClick={() => {
                          setShowSelect(false);
                          handleOptionClick(deleteoption);
                        }}
                      >
                        <Relationship value={deleteoption.label} backgroundColor={deleteoption.backgroundColor} word={"☒削除"}/>
                      </div>
                      }
                      {hoveredStates[shifttype] && shifttype!=="削除" &&
                      <div
                      className='shadow-5 bg-white border-radius-md'
                      onMouseEnter={() => handleMouseEnter(shifttype)}
                      onMouseLeave={() => handleMouseLeave(shifttype)}
                      style={{
                        zIndex: 10,
                        padding: "0.25rem 0.75rem 0.75rem 0.75rem",
                        position: "absolute",
                        marginLeft: (typeCounts[i]>2) ? '-15%' : '-2%',
                        marginTop: (typeCounts[i]>8) ? '-25%' : (9>typeCounts[i]>4) ? '-20%' : '-15%',
                        maxWidth: '225px',
                      }}>
                      <div key={i} style={{display: 'inline-flex', flexWrap: 'wrap'}}>
                        {shiftoptions.map((option) => {
                          if (option.type === shifttype) {
                            return (
                              <div
                                key={option.label}
                                className='cursor-pointer'
                                style={{ marginRight: "0.5rem", marginTop: "0.5rem", maxWidth: '150px'}}
                                onClick={() => {
                                  setShowSelect(false);
                                  handleMouseLeave(shifttype);
                                  handleOptionClick(option);
                                }}
                              >
                                <Relationship value={option.value} backgroundColor={option.backgroundColor} />
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                      </div>
                      }
                      </div>

                    ))}
                {showallowshift && <div className='overlay' onClick={() => {setshowallowshift(false)}} />}
                {showallowshift && (
                  <div
                    className='shadow-5 bg-white border-radius-md'
                    style={{
                      zIndex: 101,
                      width: 410,
                      padding: "0.75rem",
                      position: "absolute",
                      left: "50%",
                      top: "40%",
                      transform: "translate(-50%, -50%)",
                    }}>
                      <div style={{display: 'flex', flexWrap: 'wrap', padding: '0.5rem'}}>
                        {shiftoptions.map((data) => {
                          if (data.label!==""){return(
                          <form>
                            <label style={{ paddingRight: '1rem', cursor: 'pointer' }}>
                              <input
                                type="checkbox"
                                name="options"
                                value={data.label}
                                checked={checkedItemsshift.includes(data.label)}
                                onChange={handleCheckboxChangeShift}
                                style={{ cursor: 'pointer' }}
                              />
                              {data.label}
                            </label>
                          </form>)
                        }})}
                        </div>
                  </div>)}

                  </div>
                </div>
              )}
            </div>
          </div> 

          <ScrollSync>
            <div style={{zoom: zoomsize*0.01, borderBottom: '1px solid gray'}}>

              <div className="body-container-r">

                {power && ( // スタッフ情報
                <div style={{borderLeft: '2px solid gray', borderBottom: '2px solid gray'}}>
                  <ScrollSyncPane group="h">
                    <div 
                    style={{
                      height: 
                      (!expandbutton) ? `${String(106/((zoomsize+zoomsize/4)/100))}vh` 
                      : ((expandbutton) && `${String(100/((zoomsize+zoomsize/4)/100))}vh`) 
                    }}  className="table-container-sub">
                      <TableEdit
                        columns={sstate.columns}
                        data={sstate.data}
                        skipReset={sstate.skipReset}
                        dispatch={sdispatch}
                        filtervalue={[team,skill]}
                        Byoutou={Byoutou}
                        thisyear={thisyear}
                        nextmonth={nextmonth}
                        noncolor={"n"}
                        noselect={"n"}

                      />
                    </div>
                  </ScrollSyncPane>
                </div>
                )}

                {powerp && ( // 前月
                <div style={{borderLeft: '2px solid gray', borderBottom: '2px solid gray'}}>
                  <ScrollSyncPane group="h">
                    <div 
                    style={{
                      height: 
                      (!expandbutton) ? `${String(106/((zoomsize+zoomsize/4)/100))}vh` 
                      : ((expandbutton) && `${String(100/((zoomsize+zoomsize/4)/100))}vh`) 
                    }}  className="table-container-sub">
                      <TableEditPre
                        columns={pstate.columns}
                        data={pstate.data}
                        skipReset={pstate.skipReset}
                        dispatch={pdispatch}
                        filtervalue={[team,skill]}
                        Byoutou={Byoutou}
                        noncolor={"n"}
                        noselect={"n"}
                      />
                    </div>
                  </ScrollSyncPane>
                </div>
                )}

                {/*勤務表*/}
                <div onClick={handleSaveAuto} className="table-container-m" style={{borderLeft: '2px solid gray', borderRight: '2px solid gray', borderBottom: '2px solid gray'}}>
                  <ScrollSyncPane group="h">
                  <div 
                    style={{
                      height: 
                      (!expandbutton) ? `${String(106/((zoomsize+zoomsize/4)/100))}vh` 
                      : ((expandbutton) && `${String(100/((zoomsize+zoomsize/4)/100))}vh`) 
                    }} 
                    className="table-container-shift-f">
                    <TableEdit
                      columns={FilteredColumns(fstate.columns)}
                      data={fstate.data}
                      skipReset={fstate.skipReset}
                      dispatch={fdispatch}
                      filtervalue={[team,skill]}
                      Byoutou={Byoutou}
                      thisyear={thisyear}
                      nextmonth={nextmonth}
                      noncolor={!powercon ? "rr" : "req"}
                      staffdata={powercon && sstate.data}
                      predata={powercon && pstate.data}
                      cellvalue={selectedValue}
                      days={days}
                      localname={'Request-' + Byoutou  + thisyear + '-' + nextmonth}
                      deleterowvalue={cellState.bl}
                      pasterowvalue={cellState.bl}
                      handledeleterow={handleCellDataUpdate}
                      handlepasterow={handleCellDataUpdatePaste}
                    />
                  </div>
                  </ScrollSyncPane>
                </div>         

                
                
              </div>
            </div>
            </ScrollSync>

        </div>

        {helpopen && <div className='overlay' onClick={() => setHelpopen(false)} />}
        {helpopen && (
          <div
            className='shadow-5 bg-white border-radius-md'
            style={{
              zIndex: 10,
              width: "400px",
              maxHeight: "500px",
              padding: "0.75rem",
              position: "absolute",
              top: "55%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              paddingBottom: '0.5rem',
            }}>
              ショートカット一覧
              <div style={{fontSize: '14px', paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
                <div>
                  <strong>「Ctrl + s」</strong>：データを保存
                </div>
                <div>
                  <strong>「Ctrl + z」</strong>：アンドゥ
                </div>
                <div>
                  <strong>「Ctrl + y」</strong>：リドゥ
                </div>
                <div>
                  <strong>「セル選択 + Ctrl + c」</strong>：セルの値をコピー
                </div>
                <div>
                  <strong>「セル選択 + Ctrl + v」</strong>：コピーした値を貼り付け
                </div>
                <div>
                  <strong>「セル選択 + Ctrl + x」</strong>：セルの値を切り取り
                  </div>
                <div>
                  <strong>「セル選択 + Ctrl + d」</strong>：セルの値を削除
                  </div>
                <div>
                  <strong>「セル選択 + Ctrl + q」</strong>：選択されたシフト記号を入力
                </div>
                <div>
                  <strong>「セル選択 + Ctrl + Alt + v」</strong>：行全ての要素に複製
                </div>
                <div>
                  <strong>「セル選択 + Ctrl + Alt + d」</strong>：行全ての要素を削除
                </div>
                <div>
                  <strong>「Ctrl + "+"」</strong>：表画面拡大
                </div>
                <div>
                  <strong>「Ctrl + "-"」</strong>：表画面縮小
                </div>
              </div>
            </div>)}

        <div style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#DCDCDC', position: 'fixed', bottom: '0', width: '100vw', marginLeft: '-0.5rem', height: '20px'}}>
          <div style={{display: 'flex', paddingLeft: '0.5rem', fontWeight: 'bold', fontSize: '13px'}}>
            <div onClick={()=>handlemonthchange(Byoutou, preyear, thismonth)} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
              <ArrowLeftOutlinedIcon/>
            </div>
            <div style={{display: 'flex', alignItems: 'center', width: '80px', justifyContent: 'center'}}>
              {thisyear}年{nextmonth}月
            </div>
            <div onClick={()=>handlemonthchange(Byoutou, nextyear, nextnextmonth)} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
              <ArrowRightOutlinedIcon/>
            </div>
          </div>
          <div style={{paddingRight: '0.25rem', display: 'flex'}}>
            <div style={{alignItems: 'center', display: 'flex', cursor: 'pointer'}} onClick={()=>setZoom(zoomsize==50 ? zoomsize : pre=>pre-5)}>
              <RemoveOutlinedIcon style={{fontSize: '20px'}}/>
            </div>
            <div style={{alignItems: 'center', display: 'flex'}}>
              <Slider value={zoomsize} onValueChange={handleZoomChange}/>
            </div>
            <div style={{alignItems: 'center', display: 'flex', cursor: 'pointer'}} onClick={()=>setZoom(zoomsize==150 ? zoomsize : pre=>pre+5)}>
              <AddOutlinedIcon style={{fontSize: '20px'}}/>
            </div>
            <div style={{alignItems: 'center', display: 'flex',paddingLeft: '0.3rem', fontSize: '14px', width: '20px', paddingRight: '4rem'}}>
              {zoomsize}%
            </div>
          </div>
        </div>
        
      </div>
        );
      }

    return (
      <div className="excel-table-container">
        <div className="foo">
        </div>
        <div>
          <TableShow/>
        </div>
      </div>
    );
  }
}

export default ExcelRequestsImport;