import React, {useEffect, useState, useRef} from "react";
import ContentEditable from "react-contenteditable";
import Relationship from "./Relationship";
import {usePopper} from "react-popper";
import {setcol} from "./colors";
import PlusIcon from "./img/Plus";
import {randomColor} from "./utils";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import ContentPasteGoOutlinedIcon from '@mui/icons-material/ContentPasteGoOutlined';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

localStorage.removeItem('copydata')

export default function Cell(
  {
    value: initialValue, 
    cellvalue,
    row: {index}, 
    column: {id, dataType, options}, 
    dataDispatch, 
    bgcolor,
    error,
    noncolor,
    deleterow,
    updateDelete,
    pasterow,
    updatePaste,
    noselect,
    Byoutou,
    localname, 
    onCellHover, 
    onCellLeave
  }) {
  const [value, setValue] = useState({value: initialValue, update: false});
  const [selectRef, setSelectRef] = useState(null);
  const [selectPop, setSelectPop] = useState(null);
  const [showSelect, setShowSelect] = useState(false);
  const [showTrash, setShowTrash] = useState(false);
  const [draggedData, setDraggedData] = useState(null);
  const onChange = (e) => {
    setValue({value: e.target.value, update: false});
  };
  const [showAdd, setShowAdd] = useState(false);
  const [addSelectRef, setAddSelectRef] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [showmenu, setShowmenu] = useState(false)

  const [color, setColor] = useState(bgcolor)
  const [erm, setErm] = useState(error)

  const copydata = (localStorage.getItem('copydata')!==null) ? localStorage.getItem('copydata') : null

  useEffect(() => {
    setValue({value: initialValue, update: false});
  }, [initialValue]);

  useEffect(() => {
    if (value.update) {
      dataDispatch({type: "update_cell", columnId: id, rowIndex: index, value: value.value});
    }
  }, [value, dataDispatch, id, index]);

  function handleOptionKeyDown(e) {
    if (e.key === "Enter") {
      if (e.target.value !== "") {
        dataDispatch({
          type: "add_option_to_column",
          option: e.target.value,
          backgroundColor: randomColor(),
          columnId: id
        });
      }
      setShowAdd(false);
    }
  }

  function handleAddOption(e) {
    setShowAdd(true);
  }

  function handleOptionBlur(e) {
    if (e.target.value !== "") {
      dataDispatch({
        type: "add_option_to_column",
        option: e.target.value,
        backgroundColor: randomColor(),
        columnId: id
      });
    }
    setShowAdd(false);
  }

  const {styles, attributes} = usePopper(selectRef, selectPop, {
    placement: "bottom-start",
    strategy: "fixed"
  });

  // セルの色を取得
  function getColor() {
    let match = options.find((option) => option.label === value.value);
    return (match && match.backgroundColor) || setcol(0);
  }

  // セルがクリックされたときに、選択した値をセルに反映
  const handleCellClick = () => {
    if (cellvalue !== null) {
      setValue({ value: cellvalue, update: true });
      setDraggedData(null)
    }
  };

  // ゴミ箱エリアのコンポーネント
  function TrashArea() {
    if (!showTrash) {
      return null; // ゴミ箱領域を非表示にする
    }
    const handleDrop = (e) => {
      e.preventDefault();
      const draggedData = e.dataTransfer.getData("drag"); // ドラッグデータを取得
      setValue({ value: "", update: true }); // セルの値を空に設定する例
      setDraggedData(null)
      setShowTrash(false)
    };
    const allowDrop = (e) => {
      e.preventDefault();
    };
    return (
      <div
        className="trash-area"
        onDrop={handleDrop}
        onDragOver={allowDrop}
      >
        <DeleteOutlineOutlinedIcon />
      </div>
    );
  }
  
  // 条件違反の種類を表示する関数
  function ErrorMessage({ cellData }) {
    const lines = (cellData!==undefined) ? cellData.split('\n') : []
    return (
      <div className="modal" style={{ fontWeight: "bold"}}>
        <p>{lines.map((text, index) => (
          <React.Fragment key={index}>
            {text}
            {index < lines.length - 1 && <br />} {/* 最後の行以外に <br> タグを追加 */}
          </React.Fragment>
        ))}</p>
      </div>
    );
  }

  const hoverTimeoutRef = useRef(null);
  const [howlong, setHowlong] = useState(false)

  // セルにカーソルが入ったときにモーダルを表示する処理
  const handleCellMouseEnter = (message) => {
    setShowModal(true);
    hoverTimeoutRef.current = setTimeout(() => {
      setHowlong(true)
    }, 500); // 1秒後に処理を実行
    setModalData(message); 
  };

  // セルからカーソルが離れたときにモーダルを非表示にする処理
  const handleCellMouseLeave = () => {
    clearTimeout(hoverTimeoutRef.current); 
    setHowlong(false)
    setShowModal(false);
    setModalData(null);
  };  

  useEffect(() => {
    // `howlong`ステートが変更されたときに実行されるコード
    if (howlong) {
      // howlongがtrueに変更された場合
      onCellHover({ id, index });
    } else {
      // howlongがfalseに変更された場合
      onCellLeave();
    }
  }, [howlong]); // howlongが変更されたときにuseEffectが実行されるように指定


  // コンテキストメニューのコピー
  const handleCopy = (cell) => {
    localStorage.setItem('copydata', cell)
    setShowmenu(false)
    console.log(cell)
  }

  // コンテキストメニューの貼り付け
  const handlePaste = () => {
    if (copydata!==null){
      setValue({value: copydata, update: true})
    }
    setShowmenu(false)
  }

  // コンテキストメニューの行の全ての要素の削除
  const handlePasteRow = () => {
    const confirm = window.confirm("行の全ての要素に複製しますか？")
    if (confirm){
      const newValue = !pasterow
      updatePaste({index: index, value: value.value, bl: newValue})
      setShowmenu(false)
    }
  }

  // コンテキストメニューの切り取り
  const handleCut = (cell) => {
    localStorage.setItem('copydata', cell)
    setValue({value: "", update: true})
    setShowmenu(false)
  }

  // コンテキストメニューの削除
  const handleDelete = () => {
    setValue({value: "", update: true})
    setShowmenu(false)
  }

  // コンテキストメニューの行の全ての要素の削除
  const handleDeleteRow = () => {
    const confirm = window.confirm("行の全ての要素を削除しますか？")
    if (confirm){
      const newValue = !deleterow
      updateDelete({index: index, bl: newValue})
      setShowmenu(false)
    }
  }

  // コンテキストメニューの選択
  const handleSelect = () => {
    setValue({value: "", update: true})
    setShowmenu(false)
  }

  // コンテキストメニューの入れ替え
  const handleSwap = () => {
    setValue({value: "", update: true})
    setShowmenu(false)
  }

  const [mark, setMark] = useState(false)

  // コンテキストメニューのマーク
  const handleMark = () => {
    if (!mark){
      //setColor("#FFFF00")
      setMark(true)
    } else {
      //setColor(bgcolor)
      setMark(false)
    }
    setShowmenu(false)
  }
  const markmessage = () => {
    if (!mark){
      return "マーク"
    } else {
      return "マークを外す"
    }
  }
  
  // セルの色を設定
  const cellBackgroundColor = (showModal || showSelect || showmenu) ? 'lightblue' : (mark) ? "#FFFF00" : (noncolor!=="n") ? bgcolor : "#FFFFFF"

  useEffect(() => {
    if (addSelectRef && showAdd) {
      addSelectRef.focus();
    }
  }, [addSelectRef, showAdd]);

  const types = [...new Set(options.map(option => option.type))];
  const typescolor = [...new Set(options.map(option => option.backgroundColor))];
  // カーソルが重なったときのアクション
  const [hoveredStates, setHoveredStates] = useState({});
  const handleMouseEnter = (type) => {
    // カーソルが重なったときに対応するisHoveredステートをtrueに設定
    setHoveredStates((prevStates) => ({
      ...prevStates,
      [type]: true,
    }));
  };
  const handleMouseLeave = (type) => {
    // カーソルが離れたときに対応するisHoveredステートをfalseに設定
    setHoveredStates((prevStates) => ({
      ...prevStates,
      [type]: false,
    }));
  };
  const deleteoption = { label: "", backgroundColor: "#FFFFFF", type: "削除"}
  
  const optypes = options.map(option => option.type);
  const typeCount = {};
  optypes.forEach(type => {
    typeCount[type] = (typeCount[type] || 0) + 1;
  });
  const typeCountList = Object.entries(typeCount);
  const typeCounts = typeCountList.map(([type, count]) => count);

  const sname = (localname!==null && localname!==undefined) ? localname.split("-")[0] : ""
  const checkedshift = (JSON.parse(localStorage.getItem("allowshift-" + sname + Byoutou))!==null) ? JSON.parse(localStorage.getItem("allowshift-" + sname + Byoutou)) : []
  const [checkedItemsshift, setCheckedItemsshift] = useState(checkedshift);
  useEffect(()=>{
    setCheckedItemsshift(checkedshift)
  },[])
  const handleCheckboxChangeShift = (event) => {
    const { value } = event.target;
    let updatedLabels = [...checkedItemsshift];

    if (checkedItemsshift.includes(value)) {
      updatedLabels = updatedLabels.filter((label) => label !== value);
    } else {
      updatedLabels.push(value);
    }
    setCheckedItemsshift(updatedLabels);
  }
  useEffect(()=>{
    localStorage.setItem("allowshift-" + sname + Byoutou, JSON.stringify(checkedItemsshift))
  }, [checkedItemsshift])
  
  let allowshift = [];

  options.forEach((data,i) => {
    if (checkedshift.includes(data.label)){
      allowshift.push({label: data.label, value: data.value, backgroundColor: data.backgroundColor, type: data.type})
    }
  })

  const [showallowshift, setshowallowshift] = useState(false)

  function useLatest(value) {
    const ref = useRef(value);
    ref.current = value;
    return ref;
  }
  function useKeybind({
    altKey,
    ctrlKey,
    metaKey,
    shiftKey,
    key,
    onKeyDown,
    targetRef,
    }) {
    const onKeyDownLatest = useLatest(onKeyDown);

    useEffect(() => {
        const eventListener = (event) => {
        if (altKey && !event.altKey) return;
        if (ctrlKey && !event.ctrlKey) return;
        if (metaKey && !event.metaKey) return;
        if (shiftKey && !event.shiftKey) return;
        if (event.key !== key) return;

        event.preventDefault();
        onKeyDownLatest.current?.(event);
        };

        if (targetRef?.current) {
        const target = targetRef.current;

        target.addEventListener("keydown", eventListener);
        return () => target.removeEventListener("keydown", eventListener);
        } else {
        window.addEventListener("keydown", eventListener);
        return () => window.removeEventListener("keydown", eventListener);
        }
    }, [altKey, ctrlKey, key, metaKey, onKeyDownLatest, shiftKey, targetRef]);
  }
  useKeybind({
    key: "c",
    ctrlKey: true,
    onKeyDown: (event) => {
      event.preventDefault();
  
      if (cellBackgroundColor === "lightblue") {
        handleCopy(value.value);
      }
    },
  });

  useKeybind({
    key: "x",
    ctrlKey: true,
    onKeyDown: (event) => {
      event.preventDefault();
  
      if (cellBackgroundColor === "lightblue") {
        handleCut(value.value);
      }
    },
  });

  useKeybind({
    key: "v",
    ctrlKey: true,
    onKeyDown: (event) => {
      event.preventDefault();
  
      if (cellBackgroundColor === "lightblue") {
        handlePaste();
      }
    },
  });

  useKeybind({
    key: "d",
    ctrlKey: true,
    onKeyDown: (event) => {
      event.preventDefault();
  
      if (cellBackgroundColor === "lightblue") {
        handleDelete();
      }
    },
  });

  useKeybind({
    key: "d",
    ctrlKey: true,
    altKey: true,
    onKeyDown: (event) => {
      event.preventDefault();
  
      if (cellBackgroundColor === "lightblue") {
        handleDeleteRow();
      }
    },
  });

  useKeybind({
    key: "v",
    ctrlKey: true,
    altKey: true,
    onKeyDown: (event) => {
      event.preventDefault();
  
      if (cellBackgroundColor === "lightblue") {
        handlePasteRow();
      }
    },
  });

  useKeybind({
    key: "q",
    ctrlKey: true,
    onKeyDown: (event) => {
      event.preventDefault();
  
      if (cellBackgroundColor === "lightblue") {
        setValue({value: cellvalue, update: true});
      }
    },
  });
  

  let element;
  switch (dataType) {
    case "text":
      element = (
        <div
          className='data-input'
          style={{backgroundColor: `
            ${((value.value)<0) ? "#FF4500" : bgcolor}
            `}} 
            >
              {value.value}
        </div>
      );
      break;
    case "number":
      element = (
        <div
          className='data-input text-align-right'
          style={{backgroundColor: `
            ${((value.value)<0) ? "#FF4500" : cellBackgroundColor}
            `}} 
            >
              {value.value}
        </div>
      );
      break;
    case "select":
      if (noselect=="n"){
        element = (
          <>
          <div
              ref={setSelectRef}
              className='cell-padding d-flex cursor-default align-items-center flex-1'
              style={{backgroundColor: cellBackgroundColor}}
                  
              // カーソルがセルに入ったときのイベント
              onMouseEnter={() => handleCellMouseEnter(error)} 
              onMouseLeave={handleCellMouseLeave} 
              onClick={()=>{noncolor!=="n" && alert("シフトを変更する場合は編集モードをオンにしてください")}}
            >
              <div>
                {value.value && <Relationship value={value.value} backgroundColor={getColor()} />}
              </div>
              </div>
          </>
        )
      } else {
        element = (
          <>
            <div
              ref={setSelectRef}
              className='cell-padding d-flex cursor-default align-items-center flex-1'
              style={{backgroundColor: cellBackgroundColor}}
              // クリックイベント
              onClick={(e) => {
              if (cellvalue !== null) {
                    handleCellClick(); // 連続入力
                  } else {
                    setShowSelect(true); // セル内のクリックでシフト記号のモーダルを開く
                  }
                }
              }
              // 右クリックでメニューを開く
              onContextMenu={(e) => {
                e.preventDefault(); 
                setShowmenu(true)
              }}
                  
              // ドラッグアンドドロップのドロップ部分
              onDragOver={(e) => e.preventDefault()} 
              onDrop={(e) => {
                e.preventDefault();
                const draggedData = e.dataTransfer.getData("drag"); 
                setValue({ value: draggedData, update: true })
                setDraggedData(null)
              }}
              // カーソルがセルに入ったときのイベント
              onMouseEnter={() => handleCellMouseEnter(error)} 
              onMouseLeave={handleCellMouseLeave} 
            >
              <div
                // ドラッグアンドドロップのドラッグ部分
                draggable
                onDragStart={(e) => {
                  e.dataTransfer.setData("drag", value.value); 
                  const draggedData = e.dataTransfer.getData("drag");
                  setDraggedData(draggedData)
                  setTimeout(() => {
                    setValue({ value: "", update: true });
                  }, 0);
                }}
                style={{cursor: 'pointer'}}
              >
                {value.value && <Relationship value={value.value} backgroundColor={getColor()} word={"☒削除"}/>}
              </div>
            </div>
            {/* ゴミ箱エリア */}
            {/*showTrash && draggedData!==null && (
              <div
                className='shadow-5 bg-white border-radius-md'
                ref={setSelectPop}
                {...attributes.popper}
                style={{
                  ...styles.popper,
                  zIndex: 100,
                  padding: "0.75rem",
                }}>
                  <TrashArea/>
              </div>
              )*/}

            {/* 条件違反メッセージ、勤務希望 */}
            {(showModal && (bgcolor=="#FF4D4D" || bgcolor=="#FFA500")) && (
              <div
                className='shadow-5 bg-white border-radius-md'
                ref={setSelectPop}
                {...attributes.popper}
                style={{
                  ...styles.popper,
                  zIndex: 100,
                  padding: "0.75rem",
                  backgroundColor: "#FFE4E1",
                  position: "absolute", 
                }}>
                <ErrorMessage cellData={modalData} />
              </div>
            )}
            
            {/* コンテキストメニュー */}
            {showmenu && <div className='overlay' onClick={() => {setShowmenu(false)}} />}
            {showmenu && (
              <div
                className='shadow-5 bg-white border-radius-md'
                ref={setSelectPop}
                {...attributes.popper}
                style={{
                  ...styles.popper,
                  zIndex: 100,
                  position: 'absolute',
                  display: "flex",
                  flexDirection: "column",
                }}>
                  <div onClick={() => handleCopy(value.value)} className="context-menu" style={{padding: "0.2rem", paddingTop: "0.5rem", paddingRight: "0.75rem", paddingLeft: "0.75rem", cursor: "pointer", fontSize: "15px"}}><ContentCopyOutlinedIcon style={{fontSize: "15px"}}/>&nbsp;&nbsp;コピー</div>
                  <div onClick={() => handleCut(value.value)} className="context-menu" style={{padding: "0.2rem", paddingBottom: "0.3rem", paddingRight: "0.75rem", paddingLeft: "0.75rem", cursor: "pointer", fontSize: "15px"}}><ContentCutOutlinedIcon style={{fontSize: "15px"}}/>&nbsp;&nbsp;切り取り</div>
                  <hr style={{marginLeft: "34px"}}/>
                  <div onClick={(copydata!==null) && handlePaste} className={(copydata!==null) && "context-menu"} style={{padding: "0.2rem", paddingTop: "0.3rem", paddingRight: "0.75rem", paddingLeft: "0.75rem", cursor: "pointer", fontSize: "15px", color: (copydata==null) && "#D3D3D3"}}><ContentPasteOutlinedIcon style={{fontSize: "15px"}}/>&nbsp;&nbsp;貼り付け</div>
                  <div onClick={(value.value!=="") && handlePasteRow} className={(value.value!=="") && "context-menu"} style={{padding: "0.2rem", paddingBottom: "0.3rem", paddingRight: "0.75rem", paddingLeft: "0.75rem", cursor: "pointer", fontSize: "15px", color: (value.value=="") && "#D3D3D3"}}><ContentPasteGoOutlinedIcon style={{fontSize: "15px", visibility: "hidden"}}/>&nbsp;&nbsp;行の全ての要素に複製</div>
                  <hr style={{marginLeft: "34px"}}/>
                  <div onClick={handleDelete} className="context-menu" style={{padding: "0.2rem", paddingTop: "0.3rem", paddingRight: "0.75rem", paddingLeft: "0.75rem", cursor: "pointer", fontSize: "15px"}}><DeleteOutlineOutlinedIcon style={{fontSize: "15px"}}/>&nbsp;&nbsp;削除</div>
                  <div onClick={handleDeleteRow} className="context-menu" style={{padding: "0.2rem", paddingBottom: "0.3rem", paddingRight: "0.75rem", paddingLeft: "0.75rem", cursor: "pointer", fontSize: "15px"}}><ContentPasteGoOutlinedIcon style={{fontSize: "15px", visibility: "hidden"}}/>&nbsp;&nbsp;行の全ての要素を削除</div>
                  <hr style={{marginLeft: "34px"}}/>
                  {/*<div onClick={handleSwap} className={"context-menu"} style={{padding: "0.2rem", paddingTop: "0.3rem", paddingRight: "0.75rem", paddingLeft: "0.75rem", cursor: "pointer", fontSize: "15px"}}><HighlightAltOutlinedIcon style={{fontSize: "15px"}}/>&nbsp;&nbsp;選択</div>
                  <div onClick={(copydata!==null) && handleSwap} className={(copydata!==null) && "context-menu"} style={{padding: "0.2rem", paddingRight: "0.75rem", paddingLeft: "0.75rem", cursor: "pointer", fontSize: "15px", color: (copydata==null) && "#D3D3D3"}}><SwapHorizOutlinedIcon style={{fontSize: "15px"}}/>&nbsp;&nbsp;入れ替え</div>*/}
                  <div onClick={handleMark} className="context-menu" style={{padding: "0.2rem", paddingTop: "0.3rem", paddingBottom: "0.5rem", paddingRight: "0.75rem", paddingLeft: "0.75rem", cursor: "pointer", fontSize: "15px"}}><DriveFileRenameOutlineOutlinedIcon style={{fontSize: "15px"}}/>&nbsp;&nbsp;{markmessage()}</div>
              </div>
            )}

                {showallowshift && <div className='overlay' onClick={() => {setshowallowshift(false)}} />}
                {showallowshift && (
                  <div
                    className='shadow-5 bg-white border-radius-md'
                    style={{
                      zIndex: 101,
                      width: 410,
                      padding: "0.75rem",
                      position: "absolute",
                      left: "50%",
                      top: "40%",
                      transform: "translate(-50%, -50%)",
                      zoom: '1.2'
                    }}>
                      <div style={{display: 'flex', flexWrap: 'wrap', padding: '0.5rem'}}>
                        {options.map((data) => {
                          if (data.label!==""){return(
                          <form>
                            <label style={{ paddingRight: '1rem', cursor: 'pointer' }}>
                              <input
                                type="checkbox"
                                name="options"
                                value={data.label}
                                checked={checkedItemsshift.includes(data.label)}
                                onChange={handleCheckboxChangeShift}
                                style={{ cursor: 'pointer' }}
                              />
                              {data.label}
                            </label>
                          </form>)
                        }})}
                        </div>
                  </div>)}


            {cellvalue === null && showSelect && <div className='overlay' onClick={() => {setShowSelect(false); setshowallowshift(false)}} />}
            {cellvalue === null && showSelect && (
              <div
                className='shadow-5 bg-white border-radius-md'
                ref={setSelectPop}
                {...attributes.popper}
                style={{
                  ...styles.popper,
                  zIndex: 100,
                  minWidth: 400,
                  maxWidth: 320,
                  padding: "0.5rem 0.75rem 0.5rem 0.75rem",
                  position: 'absolute',
                }}>
                <div className='d-flex flex-wrap-wrap' style={{padding: '0 0.2rem 0.5rem 0', justifyContent: 'center', flexWrap: 'wrap', minHeight: '30px'}}>
                {allowshift.map((option) => (
                            <div
                              key={option.label}
                              className='cursor-pointer'
                              style={{ marginRight: "0.5rem", marginTop: "0.5rem", maxWidth: '150px'}}
                              onClick={() => {
                                setValue({value: option.label, update: true});
                                setShowSelect(false);
                              }}
                            >
                              <Relationship value={option.value} backgroundColor={option.backgroundColor} word={"☒削除"}/>
                            </div>
                  ))}
                </div>
                <div onClick={()=>setshowallowshift(true)} style={{display: 'flex', justifyContent: 'flex-end', marginTop: '-1.5rem', paddingBottom: '0.5rem', cursor: 'pointer'}}>
                  <EditOutlinedIcon style={{fontSize: '18px'}}/>
                </div>

                <hr/>
                <div className='d-flex flex-wrap-wrap' style={{paddingTop: "0.75rem", justifyContent: 'center'}}>                    
                    {types.map((type, i) => (
                      <div style={{paddingBottom: '0.5rem'}}>
                      {type!=="削除" &&
                      <div style={{border: "1px solid #C0C0C0", boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)', cursor: 'pointer', backgroundColor: typescolor[i], borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80px', height: '40px', marginRight: "0.75rem"}}
                        onMouseEnter={() => handleMouseEnter(type)}
                        onMouseLeave={() => handleMouseLeave(type)}
                      >
                        {type}
                      </div>}
                      {type=="削除" &&
                        <div
                        key={deleteoption.label}
                        className='cursor-pointer'
                        style={{border: "1px solid #C0C0C0", boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)', cursor: 'pointer', backgroundColor: typescolor[i], borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80px', height: '40px', marginRight: "0.75rem"}}                        
                        onClick={() => {
                          setValue({value: deleteoption.label, update: true});
                          setShowSelect(false);
                          handleMouseLeave(type)
                        }}
                      >
                        <Relationship value={deleteoption.label} backgroundColor={deleteoption.backgroundColor} word={"☒削除"}/>
                      </div>
                      }
                      {hoveredStates[type] && type!=="削除" &&
                      <div
                      className='shadow-5 bg-white border-radius-md'
                      onMouseEnter={() => handleMouseEnter(type)}
                      onMouseLeave={() => handleMouseLeave(type)}
                      style={{
                        zIndex: 10000,
                        padding: "0.25rem 0.75rem 0.75rem 0.75rem",
                        position: "absolute",
                        marginLeft: (typeCounts[i]>2) ? '-15%' : '0%',
                        marginTop: (typeCounts[i]>8) ? '-25%' : (9>typeCounts[i]>4) ? '-20%' : '-15%',
                        maxWidth: '225px',
                      }}>
                      <div key={i} style={{display: 'inline-flex', flexWrap: 'wrap'}}>
                        {options.map((option) => {
                          if (option.type === type) {
                            return (
                              <div
                                key={option.label}
                                className='cursor-pointer'
                                style={{ marginRight: "0.5rem", marginTop: "0.5rem", maxWidth: '150px'}}
                                onClick={() => {
                                  setValue({value: option.label, update: true});
                                  setShowSelect(false);
                                  handleMouseLeave(type)
                                }}
                              >
                                <Relationship value={option.value} backgroundColor={option.backgroundColor} word={"☒削除"}/>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                      </div>
                      }
                      </div>

                    ))}


                  </div>


                {/*<div className='d-flex flex-wrap-wrap' style={{marginTop: "-0.5rem"}}>
                  {options.map((option) => (
                    <div
                      className='cursor-pointer'
                      style={{marginRight: "0.5rem", marginTop: "0.5rem"}}
                      onClick={() => {
                        setValue({value: option.label, update: true});
                        setShowSelect(false);
                      }}>
                      <Relationship value={option.label} backgroundColor={option.backgroundColor} />
                    </div>
                  ))}
                  {showAdd && (
                    <div
                      style={{
                        marginRight: "0.5rem",
                        marginTop: "0.5rem",
                        width: 120,
                        padding: "2px 4px",
                        backgroundColor: setcol(0),
                        borderRadius: 4
                      }}>
                      <input
                        type='text'
                        className='option-input'
                        onBlur={handleOptionBlur}
                        ref={setAddSelectRef}
                        onKeyDown={handleOptionKeyDown}
                      />
                    </div>
                  )}
                  <div
                    className='cursor-pointer'
                    style={{marginRight: "0.5rem", marginTop: "0.5rem"}}
                    onClick={handleAddOption}>
                    <Relationship
                      value={
                        <span className='svg-icon-sm svg-text'>
                          <PlusIcon />
                        </span>
                      }
                      backgroundColor={setcol(1)}
                    />
                  </div>
                    </div>*/}
              </div>
            )}
          </>
        );
      }
      break;
    default:
      element = <span></span>;
      break;
  }

  return element;
}
