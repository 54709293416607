import React, { useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom"; 


if (sessionStorage.getItem("Login")=="0" || sessionStorage.getItem("Login")==null){
  window.history.pushState({}, document.title, "/Login");
} 

// シーザー暗号のシフト値
const shift = 5; 

function DashboardContent() {
  sessionStorage.setItem("Login", "0")

  const [userid, setUserid] = useState("");
  const [pwd, setPwd] = useState("");
  const [rpwd, setrPwd] = useState("");
  const [wardname, setWardname] = useState("");
  const [wardcode, setWardcode] = useState("");
  const navigate = useNavigate();

  const [enroll, setEnroll] = useState(false);
  const [setting, setSetting] = useState(false);

  const initialid = [
    {code: "ICU", name: "ICU", userid: "ICU", password: encrypt("123456")},
    {code: "C5", name: "中央5階", userid: "C5", password: encrypt("123456")},
    {code: "C3", name: "中央3階", userid: "C3", password: encrypt("123456")},
    {code: "W2", name: "西2階", userid: "W2", password: encrypt("123456")},
    {code: "ER", name: "救命救急センター", userid: "ER", password: encrypt("123456")},
  ]

  if (localStorage.getItem("UserID")==null){
    localStorage.setItem("UserID", JSON.stringify(initialid))
  }

  const idlist = (localStorage.getItem("UserID")==null) ? initialid : JSON.parse(localStorage.getItem("UserID"))
  

  const handleLogin = () => {
    const foundUser = idlist.find(user => user.userid === userid && decrypt(user.password) === pwd);
    // ユーザー名とパスワードをチェック
    if (foundUser) {
      // ログイン成功時の処理
      localStorage.setItem("Byoutou", foundUser.code)
      sessionStorage.setItem("Login", "1")
      navigate("/"); // リダイレクト
    } else {
      alert("ユーザー名またはパスワードが正しくありません");
      setPwd("")
    }
  };

  const handleEnroll = () => {
    if (userid==="" || pwd==="" || rpwd===""){
      alert("ユーザー名またはパスワードを入力してください")
    } else {
      if (pwd!==rpwd){
        alert("パスワードが正しくありません")
        setPwd("")
        setrPwd("")
      }
      else if (pwd.length<6){
        alert("パスワードが短すぎます")
      } else {
        setSetting(true)
      }
    } 
  }

  const handleSet = () => {
    if (wardname!=="" && wardcode!==""){
      localStorage.setItem("Byoutou", wardcode)
      let localID = JSON.parse(localStorage.getItem("UserID"))
      localID.push({code: wardcode, name: wardname, userid: userid, password: encrypt(pwd)})
      localStorage.setItem("UserID", JSON.stringify(localID))
      sessionStorage.setItem("Login", "1")
      localStorage.setItem("first-login", "0")
      navigate("/");
    } else {
      alert("病棟名または病棟識別コードを入力してください")
    }
  }

  // 文字列を暗号化する関数
  function encrypt(text) {
    let encryptedText = '';
    for (let i = 0; i < text.length; i++) {
      let char = text[i];
      let code = text.charCodeAt(i);

      if (char.match(/[a-z]/i)) {
        const isUpperCase = char === char.toUpperCase();
        code = code - (isUpperCase ? 65 : 97);
        code = (code + shift) % 26;
        if (code < 0) {
          code += 26;
        }
        code = code + (isUpperCase ? 65 : 97);
        char = String.fromCharCode(code);
      } else if (char.match(/[0-9]/)) {
        code = code - 48;
        code = (code + shift) % 10;
        if (code < 0) {
          code += 10;
        }
        code = code + 48;
        char = String.fromCharCode(code);
      }

      encryptedText += char;
    }
    return encryptedText;
  }

  // 暗号文を復号化する関数
  function decrypt(encryptedText) {
    let decryptedText = '';
    for (let i = 0; i < encryptedText.length; i++) {
      let char = encryptedText[i];
      let code = encryptedText.charCodeAt(i);

      if (char.match(/[a-z]/i)) {
        const isUpperCase = char === char.toUpperCase();
        code = code - (isUpperCase ? 65 : 97);
        code = (code - shift) % 26;
        if (code < 0) {
          code += 26;
        }
        code = code + (isUpperCase ? 65 : 97);
        char = String.fromCharCode(code);
      } else if (char.match(/[0-9]/)) {
        code = code - 48;
        code = (code - shift) % 10;
        if (code < 0) {
          code += 10;
        }
        code = code + 48;
        char = String.fromCharCode(code);
      }

      decryptedText += char;
    }
    return decryptedText;
  }

  const mngpass = "123456"

  const managermessage = () => {
    const userInput = prompt("管理者パスワードを入力してください")?.trim();
    if (userInput === mngpass) {
        setEnroll(prevState => !prevState);
    } else {
        alert("パスワードが違います");
    }
};




  return (
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 2,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Grid container justifyContent="center" alignItems="center"  style={{ paddingTop: 100 }}>
            <Grid item>
            <Grid container justifyContent="center" alignItems="center">
            <Typography
              variant="h5"
              color="inherit"
              noWrap
              sx={{
                  mr: 0,
                  flexGrow: 2,
                  fontFamily: 'monospace',
                  fontWeight: 550,
                  color: '#0F52BA',
                  textDecoration: 'none',
              }}
              >
                  飯塚病院 スケジューリングシステム
              </Typography>
            </Grid>
              {!enroll &&
              <Grid item style={{paddingTop: '40px'}}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 250,
                    width: 380,
                  }}
                >
                    <Grid item xs={12} md={12} lg={12} style={{paddingTop: '2rem'}}>
                      <input
                        placeholder=" ユーザー名を入力してください"
                        value={userid}
                        onChange={(event) => setUserid(event.target.value)}
                        style={{height: "30px", width: "100%", marginBottom: '1rem'}} 
                      ></input>
                      <input
                        placeholder=" パスワードを入力してください"
                        value={pwd}
                        type="password"
                        onChange={(event) => setPwd(event.target.value)}
                        style={{height: "30px", width: "100%"}} 
                      ></input>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <Button style={{width: "100%", marginTop: '2rem'}} size="medium" variant="contained" color="info" onClick={handleLogin}>
                            ログイン
                        </Button>
                    </Grid>
                    <div>
                      <Link>
                      <button style={{
                        backgroundColor: "transparent",
                        cursor: "pointer",
                        fontSize: "15px",
                        border: "0px",
                        width: "100%",
                        color: '#0F52BA',
                      }}
                      onClick={managermessage}>
                        アカウント新規作成
                      </button>
                      </Link>
                    </div>
                </Paper>
              </Grid>
              }
              {enroll && !setting &&
              <Grid item style={{paddingTop: '40px'}}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 290,
                  width: 380,
                }}
              >
                  <Grid item xs={12} md={12} lg={12} style={{paddingTop: '0.75rem'}}>
                  <div style={{marginBottom: '0.75rem', color: "#0F52BA", fontWeight: "bold"}}>
                    アカウント新規作成
                  </div>
                    <input
                      placeholder=" ユーザー名"
                      value={userid}
                      onChange={(event) => setUserid(event.target.value)}
                      style={{height: "30px", width: "100%", marginBottom: '1rem'}} 
                    ></input>
                    <input
                      placeholder=" パスワード"
                      value={pwd}
                      type="password"
                      onChange={(event) => setPwd(event.target.value)}
                      style={{height: "30px", width: "100%", marginBottom: '1rem'}}></input>
                    <input
                      placeholder=" パスワード再入力"
                      value={rpwd}
                      type="password"
                      onChange={(event) => setrPwd(event.target.value)}
                      style={{height: "30px", width: "100%"}} 
                    ></input>
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                      <Button style={{width: "100%", marginTop: '2rem'}} size="medium" variant="contained" color="info" onClick={handleEnroll}>
                        新規作成
                      </Button>
                  </Grid>
                </Paper>
                </Grid>
              }
              {setting &&
              <Grid item style={{paddingTop: '40px'}}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 250,
                  width: 380,
                }}
              >
                  <Grid item xs={12} md={12} lg={12} style={{paddingTop: '0.75rem'}}>
                  <div style={{marginBottom: '0.75rem', color: "#0F52BA", fontWeight: "bold"}}>
                    初期設定
                  </div>
                    <input
                      placeholder=" 病棟名（例）中央5階"
                      value={wardname}
                      onChange={(event) => setWardname(event.target.value)}
                      style={{height: "30px", width: "100%", marginBottom: '1rem'}} 
                    ></input>
                    <input
                      placeholder=" 病棟識別コード（例）C5"
                      value={wardcode}
                      onChange={(event) => setWardcode(event.target.value)}
                      style={{height: "30px", width: "100%", marginBottom: '1rem'}}></input>
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                      <Button style={{width: "100%", marginTop: '2rem'}} size="medium" variant="contained" color="info" onClick={handleSet}>
                          登録
                      </Button>
                  </Grid>
                </Paper>
                </Grid>
              }

            </Grid>
          </Grid>
        </Box>
      </Box>
  );
}

const AccountPage = () => {
    return <DashboardContent />;
  };
  
  export default AccountPage;