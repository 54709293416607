import React, {useState} from "react";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SetAppbar from "./TopPage2";
import Select from 'react-select'
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { ExcelRenderer } from "react-excel-renderer";

function DashboardContent() {
  const Byoutou = localStorage.getItem("Byoutou")==null ? "" : localStorage.getItem("Byoutou")
  const byoutou = Byoutou.toLocaleLowerCase()

  var today = new Date()
  const year = today.getFullYear()
  const mdTheme = createTheme();

  let shiftoptions = [];
  const shiftdata = (JSON.parse(localStorage.getItem("Shift-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Shift-"+Byoutou)) : []
  const teamdata = (JSON.parse(localStorage.getItem("Team-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Team-"+Byoutou)) : []
  shiftdata.forEach((data,i) => {
    if (data.shift=="〇" || data.shift=="1/" ||  data.shift=="2/" ||  data.shift=="5/" ||  data.shift=="長1" ||   data.shift=="長2"){
      for (const t in teamdata){
        shiftoptions.push({label: data.shift + teamdata[t].team, backgroundColor: data.color})
      }
    } else {
      shiftoptions.push({ label: data.shift, backgroundColor: data.color });
    }
  })

  const optionsyear = [
    { value: '2023', label: '2023' },
  ]

  const optionstype = [
    { value: 'staff', label: 'スタッフ情報' },
    { value: 'request', label: '勤務希望' },
    { value: 'table', label: '勤務表' },
  ]

  var date = new Date();
  var lastDate = new Date(date.getFullYear(), date.getMonth()+2, 0);
  var days = lastDate.getDate()
  const thismonth = Number(date.getMonth())+1
  const nextmonth = (thismonth==12) ? 1 : date.getMonth()+2
  const nextnextmonth = (thismonth==11) ? 1 : (thismonth==12) ? 2 : date.getMonth()+3
  const thisyear = (thismonth==12) ? date.getFullYear() + 1 : date.getFullYear()
  const nextthisyear = (thismonth>=11) ? date.getFullYear() + 1 : date.getFullYear()
  const thisday = date.getDate()

  const ydev = year - 2023
  let n = 0
  for (let i = 1; i <= ydev; i++){
    optionsyear.push({value: String(2023+i), label: String(2023+i)})
    n = n + 1
  }
  if (nextmonth==1 || nextmonth==12){
    optionsyear.push({value: String(nextthisyear), label: String(nextthisyear)})
  }

  const optionsmonth = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
  ]


  if (sessionStorage.getItem("Login")==="1"){
    sessionStorage.setItem("Login", "1")
  }
  const [state, setState] = useState({cols: [], rows: []});
  const [upload, setUpload] = useState(false);
  const [uploadb, setUploadb] = useState(false);
  const [uploadbpre, setUploadbpre] = useState(false);
  const [updata, setData] = useState([])
  
  const [fn, setFn] = useState([""])
  
  const [dtype, dsetType] = useState([])
  const [ddyear, dsetYear] = useState([])
  const [ddmonth, dsetMonth] = useState([])

  const ward = Byoutou
  const [dyear, setYear] = useState([])
  const [dmonth, setMonth] = useState([])

  const [ryear, rsetYear] = useState([])
  const [rmonth, rsetMonth] = useState([])

  const [ryear2, rsetYear2] = useState([])
  const [rmonth2, rsetMonth2] = useState([])

  function DropdownTyped() {
    const handleClick = (
      event
    ) => {
      console.log(event);
    };
    return (
      <div onClick={handleClick}>
        <Select placeholder="データ" value={dtype} onChange={dsetType} options={optionstype} />
      </div>
    )
  }
  
  function DropdownYeard() {
    const handleChange = (e) => {
      alert(e)
    }
  
    const handleClick = (
      event
    ) => {
      console.log(event);
    };
    return (
      <div onClick={handleClick}>
        <Select placeholder="年" value={ddyear} onChange={dsetYear} options={optionsyear} />
      </div>
    )
  }
  
  function DropdownMonthd() {
    const handleClick = (
      event
    ) => {
      console.log(event);
    };
    return (
      <div onClick={handleClick}>
        <Select placeholder="月" value={ddmonth} onChange={dsetMonth} options={optionsmonth} />
      </div>
    )
  }

  function handleAlertd() {
    alert("ダウンロードするデータを選択してください。")
  }
  
  function DropdownYear() {
    const handleChange = (e) => {
      alert(e)
    }
  
    const handleClick = (
      event
    ) => {
      console.log(event);
    };
    return (
      <div onClick={handleClick}>
        <Select placeholder="年" value={dyear} onChange={setYear} options={optionsyear} />
      </div>
    )
  }
  
  function DropdownMonth() {
    const handleClick = (
      event
    ) => {
      console.log(event);
    };
    return (
      <div onClick={handleClick}>
        <Select placeholder="月" value={dmonth} onChange={setMonth} options={optionsmonth} />
      </div>
    )
  }

  function handleAlert() {
    alert("年、月を選択してください。")
  }
  
  function DropdownYearr() {
    const handleChange = (e) => {
      alert(e)
    }
  
    const handleClick = (
      event
    ) => {
      console.log(event);
    };
    return (
      <div onClick={handleClick}>
        <Select placeholder="年" value={ryear} onChange={rsetYear} options={optionsyear} />
      </div>
    )
  }
  
  function DropdownMonthr() {
    const handleClick = (
      event
    ) => {
      console.log(event);
    };
    return (
      <div onClick={handleClick}>
        <Select placeholder="月" value={rmonth} onChange={rsetMonth} options={optionsmonth} />
      </div>
    )
  }

  function DropdownYearr2() {
    const handleChange = (e) => {
      alert(e)
    }
  
    const handleClick = (
      event
    ) => {
      console.log(event);
    };
    return (
      <div onClick={handleClick}>
        <Select placeholder="年" value={ryear2} onChange={rsetYear2} options={optionsyear} />
      </div>
    )
  }
  
  function DropdownMonthr2() {
    const handleClick = (
      event
    ) => {
      console.log(event);
    };
    return (
      <div onClick={handleClick}>
        <Select placeholder="月" value={rmonth2} onChange={rsetMonth2} options={optionsmonth} />
      </div>
    )
  }

  const keytype = dtype.value 
  const keyward = ward
  const keyyear = ddyear.value
  const keymonth = ddmonth.value

  var dldata = ""
  
  if (keytype=="staff"){
    var dldata = dldata + "Staff-"
  } else if (keytype=="request"){
    var dldata = dldata + "Request-"
  } else if (keytype=="table"){
    var dldata = dldata + "Edit-"
  }
  var dldata = dldata + keyward
  if (keytype=="table" || keytype=="request"){
    var dldata = dldata + keyyear + '-' + keymonth
  }

  const preyear = (nextmonth==1) ? thisyear-1 : thisyear
  const premonth = (keymonth==1) ? 12 : keymonth-1 
  var prelastDate = (keyyear!=undefined && keymonth!=undefined && keymonth=="1") ? new Date(keyyear-1, "12", 0) : new Date(keyyear, keymonth-1, 0)
  const predays = prelastDate.getDate()
  
  const saveddata = (localStorage.getItem(dldata)!=null) ? JSON.parse(localStorage.getItem(dldata)) : []
  
  const filename = (dtype.value=="staff") ? dtype.value + '_' + keyward : dtype.value + '_' + keyward + '_' + ddyear.value + '-' + ddmonth.value
  
  function handleDownload(){
    if (keytype=="request"){
      localStorage.setItem('progress-' + keyward.toLowerCase() + '-' + thisyear + '-' + nextmonth, 2)
    }
    window.location.reload()  
  };

  function handleAlertup() {
    alert("送信するファイルを選択してください。")
  }

  function uploadFile(event) {
    let fileObj = event.target.files[0];
    setFn(fileObj.name);
  
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileContent = event.target.result;
  
      if (fileObj.name.endsWith('.csv')) {
        // CSVファイルの場合の処理
        const rows = fileContent.replace(/\r/g, '').split('\n').map(row => row.split(','));
        const cols = rows[0];
  
        setState(
          {
            cols: cols,
            rows: rows
          },
          () => {
            const data = [...rows];
            this.props.uploadHandler(data);
          }
        );
  
        setUpload(true);
        setUploadb(true);
      } else if (fileObj.name.endsWith('.xlsx')) {
        // Excelファイルの場合の処理
        ExcelRenderer(fileObj, (err, resp) => {
          if (err) {
            console.log(err);
          } else {
            const { cols, rows } = resp;
            setState(
              {
                cols: cols,
                rows: rows
              },
              () => {
                const data = [...rows];
                data.shift();
                this.props.uploadHandler(data);
              }
            );
  
            setUpload(true);
            setUploadb(true);
          }
        });
      } else {
        console.log('Unsupported file format');
      }
    };
  
    reader.readAsText(fileObj, 'UTF-8');
  };
  
  function uploadFilePre(event) {
    let fileObj = event.target.files[0];
    setFn(fileObj.name);
  
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileContent = event.target.result;
  
      if (fileObj.name.endsWith('.csv')) {
        // CSVファイルの場合の処理
        const rows = fileContent.replace(/\r/g, '').split('\n').map(row => row.split(','));
        const cols = rows[0];
  
        setState(
          {
            cols: cols,
            rows: rows
          },
          () => {
            const data = [...rows];
            this.props.uploadHandler(data);
          }
        );
  
        setUpload(true);
        setUploadbpre(true);
      } else if (fileObj.name.endsWith('.xlsx')) {
        // Excelファイルの場合の処理
        ExcelRenderer(fileObj, (err, resp) => {
          if (err) {
            console.log(err);
          } else {
            const { cols, rows } = resp;
            setState(
              {
                cols: cols,
                rows: rows
              },
              () => {
                const data = [...rows];
                data.shift();
                this.props.uploadHandler(data);
              }
            );
  
            setUpload(true);
            setUploadbpre(true);
          }
        });
      } else {
        console.log('Unsupported file format');
      }
    };
  
    reader.readAsText(fileObj, 'UTF-8');
  };


  //データの保存
  if (upload){
    if (uploadbpre){
      var lastDate = new Date(date.getFullYear(), date.getMonth()+1, 0);
      var days = lastDate.getDate()
    }
    let mdata = [];
    let shifts = [];
    var num = 0
    for (let i = 1; i<=state.rows.slice(3).length; i++) {
        if (state.rows.slice(3)[i-1][0] !== undefined){
            num = num + 1
        }
    }
    for (let i = 1; i <= days+1; i++) {
      const shift = [state.rows.map(function(item, index){return item[10+i]})]
      shifts.push(shift);
    }

    for (let i = 1; i < num; i++) {
      let row = {
        ID: state.rows.slice(3)[i-1][1] + " " + state.rows.slice(3)[i-1][2],
      };
      for (let j = 0; j < days; j++) {
        if (shifts[j][0][i+2]==undefined){
          row["d" + String(j+1)] = ""  
        } else {
          row["d" + String(j+1)] = shifts[j][0][i+2]
        }
      }
      mdata.push(row);
      updata.push(row);
    }

    let mcolumns = [
      {
        id: "ID",
        label: "ID",
        accessor: "ID",
        width: 110,
        dataType: "text",
        options: []
      },           
    ];

    for (let i = 1; i <= days+1; i++) {
      let col = {
          id: "d" + String(i),
          label: String(i),
          accessor: "d" + String(i),
          dataType: "select",
          width: 40,
          options: shiftoptions
        };
      mcolumns.push(col);
    }
    setUpload(false)
    setData(updata.slice(0,num-1))
  }

  function handleSave(){
    const confirm = window.confirm(nextmonth + '月' + Byoutou + 'の勤務表を修正画面に送信しますか？')
    if (confirm) {
      localStorage.setItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth, JSON.stringify(updata.slice(0,num)))
      localStorage.setItem('progress-' + byoutou + '-' + thisyear + '-' + nextmonth, 3)
      alert("勤務表を登録しました。")
      window.location.reload()  
  }}

  function handleSavePre(){
    const confirm = window.confirm(thismonth + '月' + Byoutou + 'の勤務表を確認画面に送信しますか？')
    if (confirm) {
      localStorage.setItem('Edit-' + Byoutou  + thisyear + '-' + thismonth, JSON.stringify(updata.slice(0,num)))
      alert("勤務表を登録しました。")
      window.location.reload()  
  }}

  function handlekey(){
    let keyvalue = {ward:ward, year:String(dyear.value), month:String(dmonth.value)}
    localStorage.setItem('Storage-show', JSON.stringify(keyvalue))
  }

  function handlekeych(Byoutou, year, month){
    let keyvalue = {ward:Byoutou, year:year, month:month}
    localStorage.setItem('Storage-show', JSON.stringify(keyvalue))
  }

  function handlekeyRequest(Byoutou, year, month){
    let keyvalue = {ward:Byoutou, year:year, month:month}
    localStorage.setItem('Request-key', JSON.stringify(keyvalue))
  }

  function handlekeysch(Byoutou, year, month){
    let keyvalue = {ward:Byoutou, year:year, month:month}
    localStorage.setItem('Schedule-key', JSON.stringify(keyvalue))
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <SetAppbar pagename={"勤務表"}/>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 2,
            height: '100vh',
            overflow: 'auto',
            marginTop: '40px',
            zIndex: 1
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>

              <Grid item xs={12} md={12} lg={4}>
                <Paper sx={{p: 2,display: 'flex',height: '100%', padding:'30px', zIndex:15}}>
                  <Grid container rowSpacing={0.5} columnSpacing={0.5}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{
                          mr: 0,
                          flexGrow: 2,
                          fontFamily: 'monospace',
                          fontWeight: 550,
                          color: 'inherit',
                          textDecoration: 'none',
                        }}
                      >
                        勤務希望確認・登録
                      </Typography>
                    </Grid>

                    <Grid sx={{paddingTop: '1rem'}}>
                    <Button sx={{ border: '1px solid gray' }} onClick={()=>handlekeyRequest(Byoutou,preyear,thismonth)} size="large" variant="contained" color="info" component={Link} to={`/Table/Request/${Byoutou}`}>
                            {preyear}年{thismonth}月
                      </Button>
                    </Grid>

                    <Grid  sx={{paddingTop: '1rem', paddingLeft: '1rem'}}>
                    <Button sx={{ border: '1px solid gray' }} onClick={()=>handlekeyRequest(Byoutou,thisyear,nextmonth)} size="large" variant="contained" color="info" component={Link} to={`/Table/Request/${Byoutou}`}>
                            {thisyear}年{nextmonth}月
                      </Button>
                    </Grid>

                    <Grid container spacing={1}>
                    
                    <Grid sx={{paddingTop: '2rem', paddingLeft: '0.5rem', zIndex:15}}>
                    <DropdownYearr/>
                    </Grid>
                    
                    <Grid sx={{paddingTop: '2rem', paddingLeft: '0.5rem', zIndex:15}}>
                    <DropdownMonthr/>
                    </Grid>

                    { rmonth.value!=undefined &&
                    <Grid sx={{paddingTop: '2rem', paddingLeft: '0.5rem'}}>
                      <Button sx={{ border: '1px solid gray' }} onClick={()=>handlekeyRequest(ward, ryear.value, rmonth.value)} size="small" variant="contained" color="success" style={{height: '38px', width: '10px'}} component={Link} to={`/Table/Request/${Byoutou}`}>
                      決定
                      </Button>
                    </Grid>
                      }

                    {(rmonth.value==undefined) &&
                    <Grid sx={{paddingTop: '2rem', paddingLeft: '0.5rem'}}>
                      <Button sx={{ border: '1px solid gray' }} onClick={handleAlert} size="small" variant="contained" color="success" style={{height: '38px', width: '10px'}}>
                        決定
                      </Button>
                    </Grid>
                      }
                    </Grid>
                  
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12} lg={4}>
                <Paper sx={{p: 2,display: 'flex',height: '100%', padding:'30px'}}>
                  <Grid container rowSpacing={0.5} columnSpacing={0.5}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{
                          mr: 0,
                          flexGrow: 2,
                          fontFamily: 'monospace',
                          fontWeight: 550,
                          color: 'inherit',
                          textDecoration: 'none',
                        }}
                      >
                        勤務表作成
                      </Typography>
                    </Grid>

                    <Grid  sx={{paddingTop: '1rem'}}>
                    <Button sx={{ border: '1px solid gray' }} onClick={()=>handlekeysch(Byoutou,thisyear,nextmonth)} size="large" variant="contained" color="info" component={Link} to={`/Table/Schedule/${Byoutou}`}>
                            {thisyear}年{nextmonth}月
                      </Button>
                    </Grid>

                    <Grid  sx={{paddingTop: '1rem', paddingLeft: '1rem'}}>
                    <Button sx={{ border: '1px solid gray' }} onClick={()=>handlekeysch(Byoutou,nextthisyear,nextnextmonth)} size="large" variant="contained" color="info" component={Link} to={`/Table/Schedule/${Byoutou}`}>
                            {nextthisyear}年{nextnextmonth}月
                      </Button>
                    </Grid>

                    <Grid container spacing={1}>
                    
                    <Grid sx={{paddingTop: '2rem', paddingLeft: '0.5rem', zIndex:14}}>
                    <DropdownYearr2/>
                    </Grid>
                    
                    <Grid sx={{paddingTop: '2rem', paddingLeft: '0.5rem', zIndex:14}}>
                    <DropdownMonthr2/>
                    </Grid>

                    { rmonth2.value!=undefined &&
                    <Grid sx={{paddingTop: '2rem', paddingLeft: '0.5rem'}}>
                      <Button sx={{ border: '1px solid gray' }} onClick={()=>handlekeysch(ward, ryear2.value, rmonth2.value)} size="small" variant="contained" color="success" style={{height: '38px', width: '10px'}} component={Link} to={`/Table/Schedule/${Byoutou}`}>
                      決定
                      </Button>
                    </Grid>
                      }

                    {(rmonth2.value==undefined) &&
                    <Grid sx={{paddingTop: '2rem', paddingLeft: '0.5rem'}}>
                      <Button sx={{ border: '1px solid gray' }} onClick={handleAlert} size="small" variant="contained" color="success" style={{height: '38px', width: '10px'}}>
                        決定
                      </Button>
                    </Grid>
                      }
                    </Grid>
                  
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12} lg={4}>
                <Paper sx={{p: 2,display: 'flex',height: '100%', padding:'30px', zIndex:5}}>
                  <Grid container rowSpacing={0.5} columnSpacing={0.5}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{
                          mr: 0,
                          flexGrow: 2,
                          fontFamily: 'monospace',
                          fontWeight: 550,
                          color: 'inherit',
                          textDecoration: 'none',
                        }}
                      >
                        勤務表確認・修正
                      </Typography>
                    </Grid>

                    <Grid sx={{paddingTop: '1rem'}}>
                      <Button sx={{ border: '1px solid gray' }} onClick={() => handlekeych(Byoutou,String(preyear),String(thismonth))} size="large" variant="contained" color="info" component={Link} to={`/Table/Storage/${Byoutou}`}>
                            {preyear}年{thismonth}月
                      </Button>
                    </Grid>

                    <Grid  sx={{paddingTop: '1rem', paddingLeft: '1rem'}}>
                      <Button sx={{ border: '1px solid gray' }} onClick={() => handlekeych(Byoutou,String(thisyear),String(nextmonth))} size="large" variant="contained" color="info" component={Link} to={`/Table/Storage/${Byoutou}`}>
                            {thisyear}年{nextmonth}月
                      </Button>
                    </Grid>

                    <Grid container spacing={1}>
                    
                    <Grid sx={{paddingTop: '2rem', paddingLeft: '0.5rem', zIndex:11}}>
                    <DropdownYear/>
                    </Grid>
                    
                    <Grid sx={{paddingTop: '2rem', paddingLeft: '0.5rem', zIndex:11}}>
                    <DropdownMonth/>
                    </Grid>

                    {dmonth.value!=undefined &&
                    <Grid sx={{paddingTop: '2rem', paddingLeft: '0.5rem'}}>
                      <Button sx={{ border: '1px solid gray' }} onClick={handlekey} size="small" variant="contained" color="success" style={{height: '38px', width: '10px'}} component={Link} to={`/Table/Storage/${Byoutou}`}>
                      決定
                      </Button>
                    </Grid>
                      }

                    {(dmonth.value==undefined) &&
                    <Grid sx={{paddingTop: '2rem', paddingLeft: '0.5rem'}}>
                      <Button sx={{ border: '1px solid gray' }} onClick={handleAlert} size="small" variant="contained" color="success" style={{height: '38px', width: '10px'}}>
                        決定
                      </Button>
                    </Grid>
                      }
                    </Grid>
                  
                  </Grid>
                </Paper>
              </Grid>
            
            <Grid item xs={12} md={12} lg={6} style={{zIndex:5}}>
                <Paper sx={{p: 2,display: 'flex',height: '100%', padding:'30px'}}>
                  <Grid container rowSpacing={0.5} columnSpacing={0.5}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{
                          mr: 0,
                          flexGrow: 2,
                          fontFamily: 'monospace',
                          fontWeight: 550,
                          color: 'inherit',
                          textDecoration: 'none',
                        }}
                      >
                        各種データ CSVダウンロード
                      </Typography>
                    </Grid>

                    <Grid item sx={12}>
                    <br/>
                    </Grid>

                    <Grid container spacing={1}>

                    <Grid item sx={12}>
                    <DropdownTyped/>
                    </Grid>

                    
                    {(dtype.value=="table" || dtype.value=="request" || dtype.value==undefined) && 
                    <Grid item sx={12}>
                    <DropdownYeard/>
                    </Grid>}
                    
                    {(dtype.value=="table" || dtype.value=="request" || dtype.value==undefined) && 
                    <Grid item sx={12}>
                    <DropdownMonthd/>
                    </Grid>}

                    {dtype.value=="staff" && dtype.value!="table" && dtype.value!="request" &&
                    <Grid item xs={1}>
                    <CSVLink onClick={handleDownload} data={dtype.value!=undefined && keyward!=undefined ? saveddata: ""} filename={filename} className="text-deco">
                      <Button sx={{ border: '1px solid gray' }} size="small" variant="contained" color="success" style={{height: '38px', width: '10px'}}>
                        <FileDownloadOutlinedIcon/>
                      </Button>
                    </CSVLink>
                    </Grid>
                      }

                    {dtype.value!=undefined && keyward!=undefined && ddyear.value!=undefined && ddmonth.value!=undefined &&
                    <Grid item xs={1}>
                    <CSVLink onClick={handleDownload} data={dtype.value!=undefined && keyward!=undefined ? saveddata: ""} filename={filename} className="text-deco">
                      <Button sx={{ border: '1px solid gray' }} size="small" variant="contained" color="success" style={{height: '38px', width: '10px'}}>
                        <FileDownloadOutlinedIcon/>
                      </Button>
                    </CSVLink>
                    </Grid>
                      }

                    {(dtype.value=="table" || dtype.value=="request") && (dtype.value==undefined || ddyear.value==undefined || ddmonth.value==undefined) &&
                    <Grid item xs={1}>
                      <Button sx={{ border: '1px solid gray' }} onClick={handleAlertd} size="small" variant="contained" color="success" style={{height: '38px', width: '10px'}}>
                        <FileDownloadOutlinedIcon/>
                      </Button>
                    </Grid>
                      }

                    {(dtype.value==undefined) &&
                    <Grid item xs={1}>
                      <Button sx={{ border: '1px solid gray' }} onClick={handleAlertd} size="small" variant="contained" color="success" style={{height: '38px', width: '10px'}}>
                        <FileDownloadOutlinedIcon/>
                      </Button>
                    </Grid>
                      }

                    </Grid>
                  </Grid>
                </Paper>
            </Grid>

            {/*<Grid item xs={12} md={12} lg={6}>
                <Paper sx={{p: 2,display: 'flex',height: '100%', padding:'30px'}}>
                  <Grid container rowSpacing={0.5} columnSpacing={0.5}>
                    
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{
                          mr: 0,
                          flexGrow: 2,
                          fontFamily: 'monospace',
                          fontWeight: 550,
                          color: 'inherit',
                          textDecoration: 'none',
                        }}
                      >
                        {thismonth}月 実績勤務表 アップロード
                      </Typography>
                    </Grid>

                    <Grid item sx={12}>
                    <br/>
                    </Grid>
                    
                    <Grid item sx={12}>
                    <div className="excel-table-container" style={{width: "366px"}}>
                      <div className="file-upload">
                        <input type="file" onChange={uploadFilePre} accept=".xlsx,.csv,.xls"/>
                        <button>+</button>
                      </div>
                    </div>
                    </Grid>

                    <Grid item sx={12}>
                    <div style={{paddingTop: "6px"}}>
                    {uploadbpre &&
                    <Button sx={{ border: '1px solid gray' }} onClick={handleSavePre} size="small" variant="contained" color="success" style={{height: '38.3px', width: '10px'}}>
                        保存
                    </Button>
                    }
                    {!uploadbpre &&
                    <Button sx={{ border: '1px solid gray' }} onClick={handleAlertup} size="small" variant="contained" color="success" style={{height: '38.3px', width: '10px'}}>
                        保存
                    </Button>
                    }
                    </div>
                    </Grid>
                    
                  </Grid>
                </Paper>
          </Grid>*/}

          {/*<Grid item xs={12} md={12} lg={6}>
                <Paper sx={{p: 2,display: 'flex',height: '100%', padding:'30px'}}>
                  <Grid container rowSpacing={0.5} columnSpacing={0.5}>
                    
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{
                          mr: 0,
                          flexGrow: 2,
                          fontFamily: 'monospace',
                          fontWeight: 550,
                          color: 'inherit',
                          textDecoration: 'none',
                        }}
                      >
                        {nextmonth}月 作成済み勤務表 アップロード
                      </Typography>
                    </Grid>

                    <Grid item sx={12}>
                    <br/>
                    </Grid>
                    
                    <Grid item sx={12}>
                    <div className="excel-table-container" style={{width: "366px"}}>
                      <div className="file-upload">
                        <input type="file" onChange={uploadFile} accept=".xlsx,.csv,.xls"/>
                        <button>+</button>
                      </div>
                    </div>
                    </Grid>

                    <Grid item sx={12}>
                    <div style={{paddingTop: "6px"}}>
                    {uploadb &&
                    <Button sx={{ border: '1px solid gray' }} onClick={handleSave} size="small" variant="contained" color="success" style={{height: '38.3px', width: '10px'}}>
                        保存
                    </Button>
                    }
                    {!uploadb &&
                    <Button sx={{ border: '1px solid gray' }} onClick={handleAlertup} size="small" variant="contained" color="success" style={{height: '38.3px', width: '10px'}}>
                        保存
                    </Button>
                    }
                    </div>
                    </Grid>
                    
                  </Grid>
                </Paper>
                  </Grid>*/}
              
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

const AddPage = () => {
    return <DashboardContent />;
  };
  
  export default AddPage;