// TableEdit.jsに渡すヘッダー部分のコンポーネント
import React, {useState, useEffect, useRef} from "react";
import {usePopper} from "react-popper";
import ArrowUpIcon from "./img/ArrowUp";
import ArrowDownIcon from "./img/ArrowDown";
import TextIcon from "./Text";
import MultiIcon from "./img/Multi";
import HashIcon from "./img/Hash";
import PlusIcon from "./img/Plus";
import {shortId} from "./utils";
import {setcol} from "./colors";
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';
import FilterListOffSharpIcon from '@mui/icons-material/FilterListOffSharp';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import FilterAltOffTwoToneIcon from '@mui/icons-material/FilterAltOffTwoTone';
import Select from 'react-select';
const JapaneseHolidays = require('japanese-holidays');


export default function Header({
  column: {id, created, label, dataType, getResizerProps, getHeaderProps},
  rows,
  setSortBy,
  dataDispatch,
  globalFilter,
  setGlobalFilter,
  columnState,
  thisyear,
  nextmonth,
}) {

var lastDate = new Date(thisyear, nextmonth, 0);
var days = lastDate.getDate()
const isholiday = JapaneseHolidays.getHolidaysOf(lastDate.getFullYear(), true);

const holiday = []
for (let i = 0; i < isholiday.length; i++){
  if (isholiday[i].month===lastDate.getMonth()+1){
    holiday.push(isholiday[i].date)
  }
}

const weekend = []
const whatday = []
for (let i = 1; i <= days; i++){
  var day = new Date(lastDate.getFullYear(), lastDate.getMonth(), i)
  if ([0, 6].includes(day.getDay())){
    weekend.push(String(i))
  }
  if (holiday.includes(i)){
    if (!weekend.includes(i)){
      weekend.push(String(i))
    }
  }
  if ([0].includes(day.getDay())){
    whatday.push("日")
  }
  if ([1].includes(day.getDay())){
    whatday.push("月")
  }
  if ([2].includes(day.getDay())){
    whatday.push("火")
  }
  if ([3].includes(day.getDay())){
    whatday.push("水")
  }
  if ([4].includes(day.getDay())){
    whatday.push("木")
  }
  if ([5].includes(day.getDay())){
    whatday.push("金")
  }
  if ([6].includes(day.getDay())){
    whatday.push("土")
  }
}

  const [expanded, setExpanded] = useState(created || false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [inputRef, setInputRef] = useState(null);
  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    placement: "bottom",
    strategy: "absolute"
  });
  const [header, setHeader] = useState(label);
  const [showType, setShowType] = useState(false);
  const [btn, setBtn] = useState("default");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedTeam, setSelectedTeam] = useState(""); // 初期値は空文字列
  const [selectedSkill, setSelectedSkill] = useState("")

  const buttons = [
    {
        onClick: (e) => {
          dataDispatch({type: "update_column_header", columnId: id, label: header});
          setSortBy([]);
          setExpanded(false);
          setBtn("default")
        },
        icon: <FilterListOffSharpIcon style={{fontSize: "11px", width: "20.8px"}}/>,
        label: "解除"
      },
    {
      onClick: (e) => {
        dataDispatch({type: "update_column_header", columnId: id, label: header});
        setSortBy([{id: id, desc: false}]);
        localStorage.setItem("sort", JSON.stringify([{id: id, desc: false}]))
        setExpanded(false);
        setBtn('asc')
      },
      icon: <ArrowUpIcon />,
      label: "昇順"
    },
    {
      onClick: (e) => {
        dataDispatch({type: "update_column_header", columnId: id, label: header});
        setSortBy([{id: id, desc: true}]);
        setExpanded(false);
        setBtn('desc')
      },
      icon: <ArrowDownIcon />,
      label: "降順"
    },
  ];

  const types = [
    {
      onClick: (e) => {
        dataDispatch({type: "update_column_type", columnId: id, dataType: "select"});
        setShowType(false);
        setExpanded(false);
      },
      icon: <MultiIcon />,
      label: "Select"
    },
    {
      onClick: (e) => {
        dataDispatch({type: "update_column_type", columnId: id, dataType: "text"});
        setShowType(false);
        setExpanded(false);
      },
      icon: <TextIcon />,
      label: "Text"
    },
    {
      onClick: (e) => {
        dataDispatch({type: "update_column_type", columnId: id, dataType: "number"});
        setShowType(false);
        setExpanded(false);
      },
      icon: <HashIcon />,
      label: "Number"
    }
  ];

  let propertyIcon;
  switch (btn) {
    case "asc":
      propertyIcon = <ArrowUpIcon />;
      break;
    case  "desc":
      propertyIcon = <ArrowDownIcon />;
      break;
    default:
        propertyIcon = <FilterListOutlinedIcon style={{fontSize: "10px"}}/>;
  }

  // セレクトボックスの onChange ハンドラ
  const handleSelectChange = (e) => {
    setSelectedFilter(e.target.value);
    setGlobalFilter(e.target.value); // グローバルフィルターの値を更新
    setExpanded(false);
  };

  useEffect(() => {
    if (created) {
      setExpanded(true);
    }
  }, [created]);

  useEffect(() => {
    setHeader(label);
  }, [label]);

  useEffect(() => {
    if (inputRef) {
      inputRef.focus();
      inputRef.select();
    }
  }, [inputRef]);

  //useEffect(() => {
    // ソートされている列以外の場合にはデフォルトアイコンに戻す
  //  if (!columnState.isSorted || columnState.sortedIndex !== id) {
  //    setBtn("default");
  //  }
  //}, [columnState, id]);

  function getFilteredRows() {
    if (selectedFilter === "") {
      return rows;
    }
  
    return rows.filter((row) => {
      // 選択された列の値が選択されたフィルターと一致する行だけ表示
      if (row.values[id] === selectedFilter) {
        return true;
      }
  
      // 他の列に選択されたフィルターの値が含まれる場合、その行を非表示にする
      for (const colId in row.values) {
        if (colId !== id && row.values[colId] === selectedFilter) {
          return false;
        }
      }
    });
  }

  const filteredRows = getFilteredRows();

  function getUniqueValues(rows, id) {
    const uniqueValues = new Set();
    rows.forEach(row => {
      uniqueValues.add(row.values[id]);
    });
    return Array.from(uniqueValues);
  }

  const firstHeaderRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      if (firstHeaderRef.current) {
        firstHeaderRef.current.style.left = `${-scrollLeft}px`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return id !== 999999 ? (
    <>
      <div {...getHeaderProps({
        style: {
            display: "inline-block", 
            color: "#000000",
            backgroundColor: weekend.includes(label) ? setcol(3) : setcol(0)}})} className={`${'th noselect'} ${'th-h'}`}
            ref={id === 0 ? firstHeaderRef : null} >
        <div className='th-content' onClick={() => setExpanded(true)} ref={setReferenceElement}>
          {[0].map(function(value, index){{return whatday[Number(label)-1]}})}
          {/*dataType!="select" && <span className='svg-icon svg-gray icon-margin'>{propertyIcon}</span>*/}
          {/*dataType=="select" && (label!="Skill" && label!="Team" && label!="日勤L") && <span className='svg-icon svg-gray icon-margin'>
            {selectedFilter !== "" ? (
          <FilterAltTwoToneIcon style={{ fontSize: "10px" }} />
        ) : (
          <FilterAltOffTwoToneIcon style={{ fontSize: "10px" }} />
        )}</span>*/}
          {<br/>}
          {label}
        </div>
        <div />
      </div>
      
      {/*expanded && dataType!="select" && <div className='overlay' onClick={() => setExpanded(false)} />}
      {expanded && dataType!="select" && (
        <div ref={setPopperElement} style={{...styles.popper, zIndex: 3}} {...attributes.popper}>
          <div
            className='bg-white shadow-5 border-radius-md'
            style={{
              width: 100
            }}>
            <div
              key={shortId()}
              style={{
                borderTop: `2px solid ${setcol(200)}`,
                padding: "4px 0px"
              }}>
              {buttons.map((button) => (
                <button type='button' className='sort-button' onMouseDown={button.onClick}>
                  <span className='svg-icon svg-text icon-margin'>{button.icon}</span>
                  {button.label}
                </button>
              ))}
            </div>
          </div>
        </div>
              )*/}

      {/*expanded && dataType !== "select" && label === 'ID' && (
        <div ref={setPopperElement} style={{ ...styles.popper, zIndex: 3 }} {...attributes.popper}>
          <div className='bg-white shadow-5 border-radius-md' style={{ width: 100 }}>
            <div key={shortId()} style={{ borderTop: `2px solid ${setcol(200)}`, padding: "4px 0px" }}>
              {buttons.map((button) => (
                <button type='button' className='sort-button' onMouseDown={button.onClick}>
                  <span className='svg-icon svg-text icon-margin'>{button.icon}</span>
                  {button.label}
                </button>
              ))}

              <div>
                <label htmlFor='teamFilter'>チーム:</label>
                <select
                  id='teamFilter'
                  value={selectedTeam}
                  onChange={handleSelectTeam}
                >
                  <option value=''>全て</option>
                  <option value={"234 藤岡"}>234 藤岡</option>
                  {getUniqueValues(filteredRows, 'team').map((team) => (
                    <option key={team} value={team}>
                      {team}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor='skillFilter'>スキル:</label>
                <select
                  id='skillFilter'
                  value={selectedSkill}
                  onChange={handleSelectSkill}
                >
                  <option value=''>全て</option>
                  {getUniqueValues(filteredRows, 'skill').map((skill) => (
                    <option key={skill} value={skill}>
                      {skill}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      )*/}

      {/*
      {expanded && dataType=="select" && (label!="Skill" && label!="Team" && label!="日勤L") && <div className='overlay' onClick={() => setExpanded(false)} />}
      {expanded && dataType=="select" && (label!="Skill" && label!="Team" && label!="日勤L") && (
        <div ref={setPopperElement} style={{...styles.popper, zIndex: 3}} {...attributes.popper}>
          <div
            className='bg-white shadow-5 border-radius-md'
            style={{
              paddingLeft: 10,
              paddingRight: 10
            }}>
            <div
              key={shortId()}
              style={{
                borderTop: `2px solid ${setcol(200)}`,
                padding: "4px 0px"
              }}>
                <span className='svg-icon svg-text icon-margin'>
                <select
                  value={selectedFilter}
                  onChange={handleSelectChange}
                >
                  <option value="">全選択</option>
                  {getUniqueValues(filteredRows, id).map((value) => (
                    value !== undefined && (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    )
                  ))}
                </select>
                </span>
            </div>
          </div>
        </div>
      )}
    */}
    </>
  ) : (
    <div {...getHeaderProps({style: {display: "inline-block"}})} className='th noselect'>
      <div
        className='th-content'
        style={{display: "flex", justifyContent: "center"}}
        onClick={(e) => dataDispatch({type: "add_column_to_left", columnId: 999999, focus: true})}>
        <span className='svg-icon-sm svg-gray'>
          <PlusIcon />
        </span>
      </div>
    </div>
  );
}
