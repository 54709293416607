import React, {useEffect, useState} from "react";
import ContentEditable from "react-contenteditable";
import Relationship from "./Relationship";
import {usePopper} from "react-popper";
import {setcol} from "./colors";
import PlusIcon from "./img/Plus";
import {randomColor} from "./utils";
const JapaneseHolidays = require('japanese-holidays');
 
var date = new Date();
var lastDate = new Date(date.getFullYear(), date.getMonth()+2, 0);
var days = lastDate.getDate()
const isholiday = JapaneseHolidays.getHolidaysOf(lastDate.getFullYear(), true);

const holiday = []
for (let i = 0; i < isholiday.length; i++){
  if (isholiday[i].month===lastDate.getMonth()+1){
    holiday.push(isholiday[i].date)
  }
}

const weekend = []
for (let i = 1; i <= days; i++){
  var day = new Date(date.getFullYear(), date.getMonth()+2, i)
  if ([0, 6].includes(day.getDay())){
    weekend.push("D" + String(i))
  }
  if (holiday.includes(i)){
    if (!weekend.includes(i)){
      weekend.push("D" + String(i))
    }
  }
}

export default function Cell({value: initialValue, row: {index}, column: {id, dataType, options}, dataDispatch, edit, colname, idname, cellstaff, scroll}) {
  const [value, setValue] = useState({value: initialValue, update: false});
  const [selectRef, setSelectRef] = useState(null);
  const [selectPop, setSelectPop] = useState(null);
  const [showSelect, setShowSelect] = useState(false);
  const onChange = (e) => {
    setValue({value: e.target.value, update: false});
  };
  const [showAdd, setShowAdd] = useState(false);
  const [addSelectRef, setAddSelectRef] = useState(null);

  useEffect(() => {
    setValue({value: initialValue, update: false});
  }, [initialValue]);

  useEffect(() => {
    if (value.update) {
      dataDispatch({type: "update_cell", columnId: id, rowIndex: index, value: value.value});
    }
  }, [value, dataDispatch, id, index]);

  function handleOptionKeyDown(e) {
    if (e.key === "Enter") {
      if (e.target.value !== "") {
        dataDispatch({
          type: "add_option_to_column",
          option: e.target.value,
          backgroundColor: randomColor(),
          columnId: id
        });
      }
      setShowAdd(false);
    }
  }

  function handleAddOption(e) {
    setShowAdd(true);
  }

  function handleOptionBlur(e) {
    if (e.target.value !== "") {
      dataDispatch({
        type: "add_option_to_column",
        option: e.target.value,
        backgroundColor: randomColor(),
        columnId: id
      });
    }
    setShowAdd(false);
  }

  const {styles, attributes} = usePopper(selectRef, selectPop, {
    placement: "bottom-start",
    strategy: "fixed"
  });

  function getColor() {
    let match = options.find((option) => option.label === value.value);
    return (match && match.backgroundColor) || setcol(0);
  }

  useEffect(() => {
    if (addSelectRef && showAdd) {
      addSelectRef.focus();
    }
  }, [addSelectRef, showAdd]);

  const isSelected = (label) => {
    return value.value && Array.isArray(value.value) && value.value.includes(label);
  };
  
  const handleOptionClick = (label) => {
    // 複数の選択を可能にするため、選択された値が既に存在すれば削除、存在しなければ追加
    if (isSelected(label)) {
      setValue({ value: value.value.filter((v) => v !== label), update: true });
    } else {
      setValue({ value: [...(value.value || []), label], update: true });
    }
    setShowSelect(false)
  };

  const onChangeNumber = (e) => {
    const newValue = e.target.value;
    if (!/[^0-9-]/.test(newValue)) {
      setValue((old) => ({ ...old, value: newValue, update: false }));
    } else {
      alert("半角数字で入力してください")
    }
  };

  const onChangeNumber2 = (e) => {
    const newValue = e.target.value;
    const numberlist = Array.from({ length: 51 }, (_, index) => "" + (index + 1)).map((item,i)=>{
      if (i==0){
        return ""
      } else {
        return String(i)
      }
    })

    if (numberlist.includes(newValue)) {
      setValue((old) => ({ ...old, value: newValue, update: false }));
    } else {
      alert("1～50の半角整数で入力してください");
    }
  };


  let element;
  switch (dataType) {
    case "text":
      element = (
        edit!=="n" ? 
        <ContentEditable
          html={(value.value && value.value.toString()) || ""}
          onChange={onChange}
          onBlur={() => setValue((old) => ({value: old.value, update: true}))}
          className='data-input'
        />
        : <div className='data-input' style={{fontSize: '14px', overflowX: scroll ? "scroll" : "hidden", minHeight: '47px'}}>{value.value}</div>
      );
      break;
    case "number":
      element = (
        colname!=="cost" ?
        <ContentEditable
          html={(value.value && value.value.toString()) || ""}
          onChange={onChangeNumber}
          onBlur={() => setValue((old) => ({value: old.value, update: true}))}
          className='data-input text-align-right'
        />
        :
        <ContentEditable
          html={(value.value && value.value.toString()) || ""}
          onChange={onChangeNumber2}
          onBlur={() => setValue((old) => ({value: old.value, update: true}))}
          className='data-input text-align-right'
        />
      );
      break;
    case "select":
      if (colname.includes("comb")){
        element = (
          <>
            <div
          ref={setSelectRef}
          className='cell-padding d-flex cursor-default align-items-center flex-1'
          onClick={() => { if (edit !== "n") { setShowSelect(true) } }}
          style={{marginTop: '-0.5rem'}}
        >

        {Array.isArray(value.value) && value.value.length > 0 ? (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {value.value.map((selectedValue, index) => (
              <div key={index} style={{ marginRight: "0.5rem", marginTop: "0.5rem", cursor: 'pointer'}}>
                {selectedValue !== "" && <Relationship value={selectedValue} backgroundColor={"#DDA0DD"} />}
              </div>
            ))}
          </div>
        ) : (
          <div>{value.value && value.value.length>0 && <Relationship value={value.value} backgroundColor={getColor()} />}</div>
        )}
        </div>
        {showSelect && <div className='overlay' onClick={() => setShowSelect(false)} />}
        {showSelect && (
          <div
            className='shadow-5 bg-white border-radius-md'
            ref={setSelectPop}
            {...attributes.popper}
            style={{
              ...styles.popper,
              zIndex: 100,
              minWidth: 200,
              maxWidth: 320,
              padding: "0.75rem"
            }}
          >
            <div className='d-flex flex-wrap-wrap' style={{ marginTop: "-0.5rem" }}>
              {options.map((option) => {
                if (option.label!==idname){
                  return(
                <div
                  key={option.label}
                  className={`cursor-pointer ${isSelected(option.label) ? 'selected' : ''}`}
                  style={{ marginRight: "0.5rem", marginTop: "0.5rem" }}
                  onClick={() => handleOptionClick(option.label)}
                >
                  <Relationship value={option.label} backgroundColor={"#DDA0DD"} />
                </div>)}
              })}

            </div>
          </div>
            )}
          </>
        )
      } else {
      element = (
        <>
          <div
            ref={setSelectRef}
            className='cell-padding d-flex cursor-default align-items-center flex-1'
            onClick={() => {if (edit!=="n"){setShowSelect(true)}}}
            style={{cursor: 'pointer'}}
            >
            
            
            {value.value && <Relationship value={value.value} backgroundColor={getColor()} word={"☒削除"}/>}
          </div>
          {showSelect && <div className='overlay' onClick={() => setShowSelect(false)} />}
          {showSelect && (
            <div
              className='shadow-5 bg-white border-radius-md'
              ref={setSelectPop}
              {...attributes.popper}
              style={{
                ...styles.popper,
                zIndex: 100,
                minWidth: 200,
                maxWidth: 320,
                padding: "0.75rem",
                position: cellstaff=="s" ? "absolute" : "fixed", 
              }}>
              <div className='d-flex flex-wrap-wrap' style={{marginTop: "-0.5rem"}}>
                {options.map((option) => (
                  <div
                    className='cursor-pointer'
                    style={{marginRight: "0.5rem", marginTop: "0.5rem"}}
                    onClick={() => {
                      setValue({value: option.label, update: true});
                      setShowSelect(false);
                    }}>
                    <Relationship value={option.label} backgroundColor={option.backgroundColor} word={(colname=="count" || colname=="use") ? "未使用" : "☒削除"}/>
                  </div>
                ))}
                {showAdd && (
                  <div
                    style={{
                      marginRight: "0.5rem",
                      marginTop: "0.5rem",
                      width: 120,
                      padding: "2px 4px",
                      backgroundColor: setcol(0),
                      borderRadius: 4
                    }}>
                    <input
                      type='text'
                      className='option-input'
                      onBlur={handleOptionBlur}
                      ref={setAddSelectRef}
                      onKeyDown={handleOptionKeyDown}
                    />
                  </div>
                )}
                
              </div>
            </div>
          )}
        </>
      )}
      break;
    default:
      element = <span></span>;
      break;
  }

  return element;
}
