import React from "react";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SetAppbar from "./TopPage2";
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';

const mdTheme = createTheme();

function DashboardContent() {
  if (sessionStorage.getItem("Login")==="1"){
    sessionStorage.setItem("Login", "1")
  }
  return (
    <ThemeProvider theme={mdTheme}>
      <SetAppbar pagename={"ヘルプ"}/>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 2,
            height: '100%',
            overflow: 'hidden',
            marginTop: '40px' 
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
          {/*<Grid item xs={12} md={12} lg={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <Grid item xs={12}>
                    <h3>アプリについて</h3>
                  </Grid>
                  <Grid item xs={12}>
                  <br/>
                  このアプリでは、条件シートの登録、勤務希望の入力、作成された勤務表の修正、過去の勤務表の確認が行えます。
                  <br/>
                  <br/>
                  また、勤務表作成の際の連絡やカレンダーへの予定の登録ができます。
                  <br/>
                  <br/>
                  トップページのダッシュボードでは進捗情報や今月の予定を確認することができます。
                  </Grid>
                </Paper>
                </Grid>*/}

              <Grid item xs={12} md={12} lg={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '300px',
                    width: '100%',
                  }}
                >
                  <Grid item xs={12}>
                    <h3>勤務表作成手順</h3>
                  </Grid>
                  <br/>
                  <Grid item xs={12}>
                    <ol>
                      <li style={{marginLeft: '18px'}}>
                        <h5 style={{ color: 'blue' }}>＜スタッフページ＞</h5>
                        新規スタッフやシフト上下限回数など変更がある場合はスタッフ情報を更新する。
                      </li>
                      <br/>
                      <li style={{marginLeft: '18px'}}>
                        <h5 style={{ color: 'blue' }}>＜勤務希望入力ページ＞</h5>
                        勤務希望を入力する。
                      </li>
                      <br/>
                      <li style={{marginLeft: '18px'}}>
                        <h5 style={{ color: 'blue' }}>＜勤務条件ページ＞</h5>
                        勤務条件を追加し、使用可否を確認する。
                      </li>
                      <br/>
                      <li style={{marginLeft: '18px'}}>
                        <h5 style={{ color: 'blue' }}>＜勤務表作成ページ＞</h5>
                        手順に従って勤務表を作成する。</li>
                      <br/>
                      <li style={{marginLeft: '18px'}}>
                        <h5 style={{ color: 'blue' }}>＜勤務表修正ページ＞</h5>
                        「勤務表選択」ボタンから保存されている勤務表の種類を選択し画面に反映させ、勤務表を修正する。</li>
                      <br/>
                      <li style={{marginLeft: '18px'}}>
                        <h5 style={{ color: 'blue' }}>＜勤務表確認ページ＞</h5>
                        勤務表修正ページの「確認」ボタンを押して勤務表の全体を確認、PDFとしてダウンロードをする。
                      </li>
                    </ol>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12} lg={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '250px',
                    width: '100%',
                  }}
                >
                  <Grid item xs={12}>
                    <h3>初回ログイン時の手順</h3>
                  </Grid>
                  <br/>
                  <Grid item xs={12}>
                    <ol>
                      <li style={{marginLeft: '18px'}}>
                        <h5 style={{ color: 'blue' }}>＜スタッフページ＞</h5>
                        スタッフ情報をアップロードまたは入力する。
                      </li>
                      <br/>
                      <li style={{marginLeft: '18px'}}>
                        <h5 style={{ color: 'blue' }}>＜シフト・チーム・スキルページ＞</h5>
                        シフト記号やチーム、スキルを入力する。
                      </li>
                      <br/>
                      <li style={{marginLeft: '18px'}}>
                        <h5 style={{ color: 'blue' }}>＜勤務条件ページ＞</h5>
                        シフトごとの枠数を入力し、勤務条件を作成する。
                      </li>
                    </ol>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12} lg={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '300px',
                    width: '100%',
                  }}
                >
                  <Grid item xs={12}>
                    <div style={{display: 'flex'}}>
                    <LiveHelpOutlinedIcon/>
                    <h3>Q&A</h3>
                    </div>
                  </Grid>

                  <br/>
                  <Grid item xs={12}>
                    <div style={{border: '1px solid gray', borderRadius: 5, padding: '0.25rem'}}>
                      <ol className="custom-list">
                        <li style={{marginLeft: '7px'}} key={"Q1"}>
                          <div style={{paddingBottom: '0.25rem', display: 'flex'}}>
                            <div style={{paddingRight: '1rem', fontWeight: 'bold'}}>Q1</div>
                            <div>勤務表作成に失敗する。</div>
                          </div>
                          <div style={{paddingBottom: '0.25rem', display: 'flex'}}>
                            <div style={{paddingRight: '1rem', fontWeight: 'bold'}}>A1</div>
                            <div>勤務条件を正しく入力できているか確認してください。</div>
                          </div>
                        </li>
                      </ol>
                    </div>
                  </Grid>

                  <br/>
                  <Grid item xs={12}>
                    <div style={{border: '1px solid gray', borderRadius: 5, padding: '0.25rem'}}>
                      <ol className="custom-list">
                        <li style={{marginLeft: '7px'}} key={"Q1"}>
                          <div style={{paddingBottom: '0.25rem', display: 'flex'}}>
                            <div style={{paddingRight: '1rem', fontWeight: 'bold'}}>Q2</div>
                            <div>勤務表作成で良い結果が得られない。</div>
                          </div>
                          <div style={{paddingBottom: '0.25rem', display: 'flex'}}>
                            <div style={{paddingRight: '1rem', fontWeight: 'bold'}}>A2</div>
                            <div>勤務条件の重要度を調整してみてください。重要度が大きい条件が多すぎるなどの偏りがあるかもしれません。</div>
                          </div>
                        </li>
                      </ol>
                    </div>
                  </Grid>

                  <br/>
                  <Grid item xs={12}>
                    <div style={{border: '1px solid gray', borderRadius: 5, padding: '0.25rem'}}>
                      <ol className="custom-list">
                        <li style={{marginLeft: '7px'}} key={"Q1"}>
                          <div style={{paddingBottom: '0.25rem', display: 'flex'}}>
                            <div style={{paddingRight: '1rem', fontWeight: 'bold'}}>Q3</div>
                            <div>スタッフページでスタッフの順番を入れ替えたい。</div>
                          </div>
                          <div style={{paddingBottom: '0.25rem', display: 'flex'}}>
                            <div style={{paddingRight: '1rem', fontWeight: 'bold'}}>A3</div>
                            <div>No.列の番号を変更し画面をリロードすると入力した番号順にソートされます。</div>
                          </div>
                        </li>
                      </ol>
                    </div>
                  </Grid>
                  
                </Paper>
              </Grid>

            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

const HelpPage = () => {
    return <DashboardContent />;
  };
  
  export default HelpPage;