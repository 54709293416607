export function setcol(value) {
    let reference = {
      100: '#000000',
      0: '#F5FFFA',
      1: '#FFFFFF',
      2: '#F0FFF0',
      3: '#FFB6C1',
      4: '#FFA500',
      5: '#F0FFF0',
      6: '#FFFACD',
      7: '#F0E68C',
      8: '#EEE8AA',
      9: '#DCDCDC',
      10: '#DDA0DD',
      11: '#FF00FF',
      12: '#7B68EE',
      13: '#ADFF2F',
      14: '#3CB371',
      15: '#20B2AA',
      16: '#AFEEEE',
      17: '#00BFFF',
      18: '#E6E6FA',
      19: '#87CEFA',
      20: '#FFE4C4',      
    };
  
    return reference[value];
  }
  