import { setcol } from "../../../Table/colors";
import React, { useRef, useEffect, useReducer, useState } from "react";
import "../../../styles.css";
import TableEdit from "../../../Table/TableEdit";
import TableUneditable from "../Storage/TableS"
import { randomColor, shortId } from "../../../Table/utils";
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import Button from '@mui/material/Button';
import Select from 'react-select'
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import {Link} from "react-router-dom";
import Paper from '@mui/material/Paper';
import {usePopper} from "react-popper";
import Relationship from "../../../Table/Relationship";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useNavigate } from 'react-router-dom';
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import axios from 'axios';
import Optimization from "../Scheduling/MakeOptionsShow"
import Optimization2 from "../Scheduling/MakeOptionsShow2"
import Optimization3 from "../Scheduling/MakeOptionsShow3"

function Slider({ value, onValueChange }) {
  const handleChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    onValueChange(newValue);
  };

  return (
      <input
        type="range"
        min={50}
        max={150}
        value={value}
        onChange={handleChange}
        style={{ 
          width: '130px',
          background: 'gray',
          appearance: 'none', 
          outline: 'none', 
          height: '3px', 
          borderRadius: '5px',
        }}
      />
  );
}


class ExcelRequestsImport extends React.Component {
  state = {
    cols: [],
    rows: []
  };

  constructor(props) {
    super(props);
    this.state = {
      keyvalue: { ward: '', year: '', month: '' },
    };
  }

  componentDidMount() {
    const localvalue = JSON.parse(localStorage.getItem("Storage-show"));
    this.setState({ keyvalue: localvalue });
  }

  render() {
    if (sessionStorage.getItem("Login")==="1"){
      sessionStorage.setItem("Login", "1")
    }    
    let Byoutou = this.state.keyvalue.ward
    let byoutou = Byoutou.toLocaleLowerCase()

    const JapaneseHolidays = require('japanese-holidays');
    
    const thisyear = this.state.keyvalue.year
    const nextmonth = this.state.keyvalue.month
    const thismonth = (nextmonth=="1") ? "12" : String(Number(nextmonth) - 1)
    const nextnextmonth = (nextmonth=="12") ? "1" : String(Number(nextmonth) + 1)
    const nextyear = (nextmonth=="12") ? String(Number(thisyear) + 1) : thisyear
    var prelastDate = new Date(thisyear, thismonth, 0);
    const predays = prelastDate.getDate()
    var lastDate = new Date(thisyear, nextmonth, 0);
    var days = lastDate.getDate()
    const isholiday = JapaneseHolidays.getHolidaysOf(lastDate.getFullYear(), true);
    const preyear = (nextmonth==1) ? String(Number(thisyear)-1) : thisyear
    const today = new Date()

    const holiday = []
    for (let i = 0; i < isholiday.length; i++){
      if (isholiday[i].month===lastDate.getMonth()+1){
        holiday.push(isholiday[i].date)
      }
    }

    const weekend = []
    const whatday = []
    for (let i = 1; i <= days; i++){
      var day = new Date(thisyear, lastDate.getMonth(), i)
      if ([0, 6].includes(day.getDay())){
        weekend.push(String(i))
      }
      if (holiday.includes(i)){
        if (!weekend.includes(i)){
          weekend.push(String(i))
        }
      }
      if ([0].includes(day.getDay())){
        whatday.push("日")
      }
      if ([1].includes(day.getDay())){
        whatday.push("月")
      }
      if ([2].includes(day.getDay())){
        whatday.push("火")
      }
      if ([3].includes(day.getDay())){
        whatday.push("水")
      }
      if ([4].includes(day.getDay())){
        whatday.push("木")
      }
      if ([5].includes(day.getDay())){
        whatday.push("金")
      }
      if ([6].includes(day.getDay())){
        whatday.push("土")
      }
    }

    if (localStorage.getItem('e1' + byoutou + '-' + thisyear + '-' + nextmonth)!=2 || localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth)==null){
      localStorage.setItem('e1' + byoutou + '-' + thisyear + '-' + nextmonth, 1)
    }

    if (localStorage.getItem('Request-' + Byoutou  + thisyear + '-' + thismonth)==null){
      let mdata = [];
      var num = 0
        const staffdata = (JSON.parse(localStorage.getItem('Staff-' + Byoutou ))!==null) ? JSON.parse(localStorage.getItem('Staff-' + Byoutou )) : []
        for (let i = 1; i<=staffdata.length; i++) {
            if (staffdata[i-1].ID !== "" && staffdata[i-1].ID !== undefined){
                num = num + 1
            }
        }
    
        for (let i = 1; i <= num; i++) {
            const istaffdata = staffdata[i-1]
            let mrow = {
              ID: istaffdata.ID + " " + istaffdata.name,
            }
            for (let j = 1; j <= predays; j++){
              mrow["d" + String(j)] = ""
            }
            mdata.push(mrow);
          }
          localStorage.setItem('Request-' + Byoutou  + thisyear + '-' + thismonth, JSON.stringify(mdata))
    }

    const shiftdata = (JSON.parse(localStorage.getItem("Shift-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Shift-"+Byoutou)) : []
    const teamdata = (JSON.parse(localStorage.getItem("Team-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Team-"+Byoutou)) : []
    const skillleveldata = (JSON.parse(localStorage.getItem("Skill-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Skill-"+Byoutou)) : []

    let shiftoptions = [];
    let allowshifts = [];

    shiftdata.forEach((data,i) => {
      if (data.shift=="管"){
        allowshifts.push({label: data.shift, value: data.shift, backgroundColor: data.color, type: data.type})
      }
      if ((data.shift=="〇" || data.shift=="L" || data.shift.includes("/") || data.shift.includes("長")) && (data.type=="日勤系" || data.type=="準夜勤系" || data.type=="ロング系") || data.type=="連続系"){
        if (!data.shift.includes("専")){
          allowshifts.push({label: data.shift, value: data.shift, backgroundColor: data.color, type: data.type})
        }
        if (teamdata.length>0 && data.shift!=="L"){
          if (data.shift.includes("遅")){
            shiftoptions.push({ label: data.label, value: data.shift, backgroundColor: data.color, type: data.type});
          } else {
          for (const t in teamdata){
            if (data.type=="連続系"){
              let substring = data.label.split("→")
              let newstring = ""
              substring.forEach((string,s)=>{
                const foundOption = shiftdata.find(option => option.shift === string)
                if (s==0){
                  if (foundOption && ["日勤系","準夜勤系","ロング系"].includes(foundOption.type)){
                    newstring += string + teamdata[t].team
                  } else {
                    newstring += string
                  }
                } else {
                  if (foundOption && ["日勤系","準夜勤系","ロング系"].includes(foundOption.type)){
                    newstring += "→" + string + teamdata[t].team
                  } else {
                    newstring += "→" + string
                  }
                }
              })
              shiftoptions.push({value: data.shift + (teamdata.length>1 ? '-' + teamdata[t].team : ''), label: newstring, backgroundColor: data.color, type: data.type})
            } else {
              shiftoptions.push({label: data.shift + teamdata[t].team, value: data.shift + teamdata[t].team, backgroundColor: data.color, type: data.type, team: teamdata[t].team})
            }
          }
        }
        } else {
          if (data.type=="連続系" && !teamdata.length>0){
            shiftoptions.push({ label: data.label, value: data.shift, backgroundColor: data.color, type: data.type});
          }
          else {
            shiftoptions.push({label: data.shift, value: data.shift, backgroundColor: data.color, type: data.type})
          }
          if (!data.shift.includes("専")){
            allowshifts.push({label: data.shift, value: data.shift, backgroundColor: data.color, type: data.type})
          }
        }
      } else {
        if (data.shift=="*" || data.shift=="公"|| data.shift=="遅J" ){
          allowshifts.push({label: data.shift, value: data.shift, backgroundColor: data.color, type: data.type})
        }   
        if (data.type!=="禁止系" && data.type!=="選択系" && data.type!=="連続系"){
          shiftoptions.push({ label: data.shift, value: data.shift, backgroundColor: data.color, type: data.type});
        }
      }
    })
    const deleteoption = { label: "", value: "", backgroundColor: "#FFFFFF", type: "削除"}
    shiftoptions.push(deleteoption);

    const shifttypes = [...new Set(shiftoptions.map(option => option.type))];
    const shifttypescolor = [...new Set(shiftoptions.map(option => option.backgroundColor))];

    let teamoptions = teamdata.map((data,i) => {
      return { label: data.team, value: data.team, backgroundColor: data.color };
    })

    let skilloptions = skillleveldata.map((data,i) => {
      return { label: data.skill, value: data.skill, backgroundColor: data.color };
    })

    let checkoptions = [
      {label: "可", backgroundColor: setcol(9)},
      {label: "", backgroundColor: setcol(1)},
    ]

    let dayoptions = []
    for (let i = 1; i <= days; i++) {
      let ds = {
          label: String(i),
          value: i,
          };
      dayoptions.push(ds);
    }

    let daysoptions = [
      {label: "月", value: "mon"},
      {label: "火", value: "tue"},
      {label: "水", value: "wed"},
      {label: "木", value: "thu"},
      {label: "金", value: "fri"},
      {label: "土", value: "sat"},
      {label: "日", value: "sun"},
      {label: "土日", value: "satsun"},
      {label: "土日+祝日", value: "weekend"},
    ]
    let daysoptions2 = [
      {label: "月", value: "月"},
      {label: "火", value: "火"},
      {label: "水", value: "水"},
      {label: "木", value: "木"},
      {label: "金", value: "金"},
      {label: "土", value: "土"},
      {label: "日", value: "日"},
    ]
    let holidayoptions = [
      {label: "平日", value: "平日"},
      {label: "土日", value: "土日"},
      {label: "祝日", value: "祝日"},
    ]

    let sexoptions = [
      {label: "男性", backgroundColor: setcol(9)},
      {label: "女性", backgroundColor: setcol(9)},
      {label: "", backgroundColor: setcol(1)},
    ]

    function useLatest(value) {
        const ref = useRef(value);
        ref.current = value;
        return ref;
    }

    function useKeybind({
        altKey,
        ctrlKey,
        metaKey,
        shiftKey,
        key,
        onKeyDown,
        targetRef,
        }) {
        const onKeyDownLatest = useLatest(onKeyDown);

        useEffect(() => {
            const eventListener = (event) => {
            if (altKey && !event.altKey) return;
            if (ctrlKey && !event.ctrlKey) return;
            if (metaKey && !event.metaKey) return;
            if (shiftKey && !event.shiftKey) return;
            if (event.key !== key) return;

            event.preventDefault();
            onKeyDownLatest.current?.(event);
            };

            if (targetRef?.current) {
            const target = targetRef.current;

            target.addEventListener("keydown", eventListener);
            return () => target.removeEventListener("keydown", eventListener);
            } else {
            window.addEventListener("keydown", eventListener);
            return () => window.removeEventListener("keydown", eventListener);
            }
        }, [altKey, ctrlKey, key, metaKey, onKeyDownLatest, shiftKey, targetRef]);
    }
    

    const staffdata = (JSON.parse(localStorage.getItem('Staff-' + Byoutou ))!==null) ? JSON.parse(localStorage.getItem('Staff-' + Byoutou )) : []
    const staffcol = (JSON.parse(localStorage.getItem('Staff-Columns-' + Byoutou ))!==null) ? JSON.parse(localStorage.getItem('Staff-Columns-' + Byoutou )) : []

    // データをアップロード時に戻す関数
    function handleRemove(){
      const confirm = window.confirm('勤務表データをリセットしますか？')
        if (confirm) {
            alert('勤務表データをリセットしました')
            
            
              let mdata = [];

              var num = 0
              const staffdata = JSON.parse(localStorage.getItem('Staff-' + Byoutou))
              for (let i = 1; i<=staffdata.length; i++) {
                  if (staffdata[i-1].ID !== "" && staffdata[i-1].ID !== undefined){
                      num = num + 1
                  }
              }

              for (let i = 1; i <= num; i++) {
                  let mrow = {
                    ID: staffdata[i-1].ID + " " + staffdata[i-1].name,
                  }
                  for (let j = 1; j <= days; j++){
                    mrow["d" + String(j)] = ""
                  }
                  mdata.push(mrow);
                }
              
              let mcolumns = [{
                id: "ID",
                label: "ID",
                accessor: "ID",
                width: 110,
                dataType: "text",
                options: []
              }];
              
              for (let i = 1; i <= days; i++) {
                  let mcol = {
                      id: "d" + String(i),
                      label: String(i),
                      accessor: "d" + String(i),
                      dataType: "select",
                      width: 40,
                      options: shiftoptions
                      };
                  mcolumns.push(mcol);
              }
              localStorage.setItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth, JSON.stringify(mdata))
            
        
            window.location.reload()  
        }
      }

    // シフトのデータ作成
    let sdata = [];
    let mdata = [];
    let pdata = [];

    var num = 0
    let stlist = []
    const staffdatas = (localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth)!==null) ? JSON.parse(localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth)) : (JSON.parse(localStorage.getItem('Staff-' + Byoutou ))!==null) ? JSON.parse(localStorage.getItem('Staff-' + Byoutou )) : []
    for (let i = 1; i<=staffdatas.length; i++) {
        if (staffdatas[i-1].ID !== "" && staffdatas[i-1].ID !== undefined){
            num = num + 1
        }
        let n = 0
        for (let k = 1; k <= staffdata.length; k++){
          const preid = staffdata[k-1]
          if (preid.ID + " " + preid.name == staffdatas[i-1].ID){
            stlist.push(k-1)
            n = n + 1
          }
        }
        if (n==0 && localStorage.getItem('e1' + byoutou + '-' + thisyear + '-' + nextmonth)!=1){
          stlist.push("n")
        } else if (!stlist.includes(i-1)){
          stlist.push(i-1)
        }
    }

    const checkedstaff = (JSON.parse(localStorage.getItem("checkedstaff" + Byoutou))!==null) ? JSON.parse(localStorage.getItem("checkedstaff" + Byoutou)) : []
    let scolumns = []
    staffcol.forEach(item=>{
      if (checkedstaff.includes(item.label)){
        scolumns.push({
          id: item.id,
          label: item.label,
          accessor: item.accessor,
          dataType: item.dataType,
          width: 40,
          height: 40,
          options: item.options
        })
      }
    })
    for (let i = 1; i <= num; i++) {
      const istaffdata = (stlist[i-1]!=="n") ? staffdata[stlist[i-1]] : {ID: "", name: "", skill: "", TEAM: ""}
      const istaffdatas = staffdatas[i-1]
      let srow = {}
      scolumns.forEach(col=>{
        srow[col.id] = istaffdata.hasOwnProperty(col.id) ? istaffdata[col.id] : ""
      })

      let mrow = {
        ID: (localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth)!==null) ? istaffdatas.ID : istaffdata.ID + " " + istaffdata.name,
      }
      for (let j = 1; j <= days; j++){
          mrow["d" + String(j)] = ""
      }
      let prow = {
        ID: (localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth)!==null) ? istaffdatas.ID : istaffdata.ID + " " + istaffdata.name,
      }
      for (let j = 1; j <= predays; j++){
        prow["d" + String(predays+1-j)] = ""
        if (localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + thismonth)!==null){
          const prelocaldata = JSON.parse(localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + thismonth))
          for (let k = 1; k <= prelocaldata.length; k++){
            const preid = prelocaldata[k-1]
            if (istaffdata.ID + " " + istaffdata.name == preid.ID){
              prow["d" + String(predays+1-j)] = preid["d" + String(predays-(predays-j))]
            }
          }  
        }
      }
      sdata.push(srow);
      mdata.push(mrow);
      pdata.push(prow);
    }

    let mcolumns = [{
      id: "ID",
      label: "ID",
      accessor: "ID",
      width: 110,
      dataType: "text",
      options: []
    }];
    let mcolumns2 = [{
      id: "d" + String(5),
      label: "前5",
      accessor: "d" + String(5),
      dataType: "select",
      width: 40,
      options: shiftoptions
      },
      {
        id: "d" + String(4),
        label: "前4",
        accessor: "d" + String(4),
        dataType: "select",
        width: 40,
        options: shiftoptions
        },
        {
          id: "d" + String(3),
          label: "前3",
          accessor: "d" + String(3),
          dataType: "select",
          width: 40,
          options: shiftoptions
          },
          {
            id: "d" + String(2),
            label: "前2",
            accessor: "d" + String(2),
            dataType: "select",
            width: 40,
            options: shiftoptions
            },
            {
              id: "d" + String(1),
              label: "前1",
              accessor: "d" + String(1),
              dataType: "select",
              width: 40,
              options: shiftoptions
    },
        ];
    
    for (let i = 1; i <= days; i++) {
      let mcol = {
        id: "d" + String(i),
        label: String(i),
        accessor: "d" + String(i),
        dataType: "select",
        width: 40,
        options: shiftoptions
        };
      mcolumns.push(mcol);
    }

    // スタッフごとのシフト合計数のデータ作成
    const checkedrcolumns = (JSON.parse(localStorage.getItem("checkedrcolumns" + Byoutou))!==null) ? JSON.parse(localStorage.getItem("checkedrcolumns" + Byoutou)) : []
    let rightdaydata = []
    let rightdaycolumns = []
    checkedrcolumns.forEach(item=>{
      rightdaycolumns.push({
        id: item,
        label: item,
        accessor: item,
        dataType: "number",
        width: 40,
        height: 40,
        options: []
      })
    })

    const rcolumns = []

    let rcol = [{col : "公", ac: ""}, {col: "休公", ac: ""}]
    shiftdata.forEach((data)=>{
      if (data.type=="日勤系" || data.type=="ロング系" || data.type=="準夜勤系" || data.type=="深夜勤系"){
        rcol.push({col: data.shift, ac: ""})
      }
    })
    staffcol.forEach((data)=>{
      if (data.label.includes("上限") || data.label.includes("下限") || data.label.includes("差")){
        rcol.push({col: data.label, ac: data.id})
      }
      if (data.label.includes("上限")){
        rcol.push({col: data.label.slice(0,-2) + "幅", ac: ""})
      }
    })
    const isItemInRcolumns = (rcolumns, itemName) => {
      return rcolumns.some(column => column.key === itemName);
    }

    rcol.forEach(item=>{
      const itemExists = isItemInRcolumns(rcolumns, item.col);
      if (!itemExists){
        rcolumns.push({key: item.col})
      }
    })

    for (let i = 0; i < num; i++) {
      let rrow = {}
      rightdaycolumns.forEach(col=> {
        rrow[col.id] = "0"
      })
      rightdaydata.push(rrow)
    }

    // 日ごとのシフト合計数のデータ作成
    const checkedshift2 = (JSON.parse(localStorage.getItem("checkedshiftday" + Byoutou))!==null) ? JSON.parse(localStorage.getItem("checkedshiftday" + Byoutou)) : []
    
    let caldaydata = []
    let caldaycolumns = [{
        id: "ID",
        label: "",
        accessor: "ID",
        width: 110,
        dataType: "text",
        options: []
      },]
    
    let nikkin = ""
    let nikkinlist = []
    let long = ""
    let leader = ""
    let junya = ""
    let shinya = ""
    allowshifts.forEach(data=>{
      if (data.type=="日勤系" && !data.label.includes("L")){
        nikkinlist.push(data.label)
        if (data.label.includes("〇")){
          nikkin = data.label
        }
      }
      if (data.type=="日勤系" && data.label.includes("L")){
        leader = data.label
      } else
      if (data.type=="ロング系"){
        long = data.label
      } else
      if (data.type=="準夜勤系"){
        junya = data.label
      } else
      if (data.type=="深夜勤系"){
        shinya = data.label
      }
    })
    
    for (let j = 1; j <= days; j++) {
      let col = {
          id: "d"+String(j),
          label: "",
          accessor: "d"+String(j),
          dataType: "text",
          width: 50,
          options: []
        };
      caldaycolumns.push(col);
    }

    checkedshift2.forEach(item=>{
      let row = {ID: item}
      for (let j = 1; j <= days; j++){
        row["d" + String(j)] = "0"
      }
      caldaydata.push(row)
    })

    // 日ごとのスキル集計のデータ作成
    let skilldata = []
    let skillcolumns = [{
        id: "ID",
        label: "",
        accessor: "ID",
        width: 110,
        dataType: "text",
        options: []
      },]
    
    let srow1 = {ID: "TL+L2+L1"}
    let srow2 = {ID: "TL+L2+L1+L"}
    let srow3 = {ID: "M2+M1"}
    
    for (let j = 1; j <= days; j++) {
      let n1 = 0
      let n2 = 0
      let n3 = 0

      srow1[String(j)] = String(n1)
      srow2[String(j)] = String(n2)
      srow3[String(j)] = String(n3)
  
      let col = {
          id: "d"+String(j),
          label: "",
          accessor: "d"+String(j),
          dataType: "text",
          width: 50,
          options: []
        };
      skillcolumns.push(col);
    }
    
    skilldata.push(srow1)
    skilldata.push(srow2)
    skilldata.push(srow3)

    let jskilldata = []
    let jskillcolumns = [{
        id: "ID",
        label: "",
        accessor: "ID",
        width: 110,
        dataType: "text",
        options: []
      },]
    
    let jrow1 = {ID: "TL+L2+L1"}
    let jrow2 = {ID: "TL+L2+L1+L"}
    let jrow3 = {ID: "M2+M1"}
    
    for (let j = 1; j <= days; j++) {
      let n1 = 0
      let n2 = 0
      let n3 = 0

      jrow1[String(j)] = String(n1)
      jrow2[String(j)] = String(n2)
      jrow3[String(j)] = String(n3)
  
      let col = {
          id: "d"+String(j),
          label: "",
          accessor: "d"+String(j),
          dataType: "text",
          width: 50,
          options: []
        };
      jskillcolumns.push(col);
    }
    
    jskilldata.push(jrow1)
    jskilldata.push(jrow2)
    jskilldata.push(jrow3)


    // 管理画面でアップロードしたデータを成型
    if (localStorage.getItem('e1' + byoutou + '-' + thisyear + '-' + nextmonth)!=1 || localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth)!=null){
      for (let i = 0; i < num; i++){
        for (let j = 1; j <= days; j++) {
          mdata[i]["d"+String(j)] = JSON.parse(localStorage.getItem('Edit-' + Byoutou + thisyear + '-' + nextmonth))[i]["d"+String(j)]
          
        }
      }
    }
    
    // 表に適した形のデータ
    let smcdata = {columns: scolumns, data: sdata, skipReset: false}
    let mmcdata = (localStorage.getItem('e1' + byoutou + '-' + thisyear + '-' + nextmonth)==1 || localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth)==null) ? {columns: mcolumns, data: mdata, skipReset: false} : {columns: mcolumns, data: mdata, skipReset: false}
    let predata = {columns: mcolumns2, data: pdata, skipReset: false}
    let rightddata = {columns: rightdaycolumns, data: rightdaydata, skipReset: false}
    let calddata = {columns: caldaycolumns, data: caldaydata, skipReset: false}
    let skdata = {columns: skillcolumns, data: skilldata, skipReset: false}
    let skndata = {columns: jskillcolumns, data: jskilldata, skipReset: false}

    // 初回実行時にローカルストレージを設定
    localStorage.setItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth, JSON.stringify(mmcdata.data))
    if (localStorage.getItem('e1' + byoutou + '-' + thisyear + '-' + nextmonth)==1 && num!==0){
      localStorage.setItem('e1' + byoutou + '-' + thisyear + '-' + nextmonth, 2)
    }

    // 表のデータや行、列の変更
    function reducer(state, action) {
      switch (action.type) {
        case "update_cell":
          console.log(action)
            const valuelist = action.value.split("→")
            const historydata = [...state.history]
            const updatedData = state.data.map((row, index) => {
              if (index === action.rowIndex) {
                // 対象の行のセルを更新
                const updatedRow = {
                  ...row,
                };
                valuelist.forEach((value, valueIndex) => {
                  if (valueIndex==0){
                    updatedRow[action.columnId] = value
                    historydata.push(
                      {
                        type: "update_cell",
                        rowIndex: action.rowIndex,
                        columnId: action.columnId,
                        value: state.data[action.rowIndex][action.columnId],
                      }
                    )
                  }
                  else if (String(Number(action.columnId.slice(1)) + valueIndex)<=days) {
                    updatedRow["d" + String(Number(action.columnId.slice(1)) + valueIndex)] = value;
                    historydata.push(
                      {
                        type: "update_cell",
                        rowIndex: action.rowIndex,
                        columnId: "d" + String(Number(action.columnId.slice(1)) + valueIndex),
                        value: state.data[action.rowIndex]["d" + String(Number(action.columnId.slice(1)) + valueIndex)],
                      }
                    )
                  }
                });
                return updatedRow;
              }
              return row;
            });

            return {
              ...state,
              skipReset: true,
              data: updatedData,
              history: historydata,
              future: [], 
            };
        case "enable_reset":
          return {
            ...state,
            skipReset: false
          };
        case "UNDO_ACTION":
          if (state.history.length > 0) {
            const lastAction = state.history[state.history.length - 1];
            const updatedData = state.data.map((row, index) => {
              if (index === lastAction.rowIndex) {
                // 対象の行のセルを更新
                return {
                  ...row,
                  [lastAction.columnId]: lastAction.value
                };
              }
              return row;
            });
            return {
              ...state,
              skipReset: true,
              data: updatedData,
              history: [...state.history.slice(0, -1)],
              future: [state, ...state.future],
              ...lastAction,
            };
          }
          return state;
        case "REDO_ACTION":
          if (state.future.length > 0) {
            const nextAction = state.future[0];
            const updatedData = state.data.map((row, index) => {
              if (index === nextAction.rowIndex) {
                // 対象の行のセルを更新
                return {
                  ...row,
                  [nextAction.columnId]: nextAction.value
                };
              }
              return row;
            });
            return {
              ...state,
              skipReset: true,
              data: updatedData,
              history: [...state.history],
              future: [state.future.slice(1)],
              ...nextAction,
            };
          }
          return state;
        case "DELETE_ROWVALUE":
            const historydatad = [...state.history]
            const updatedDatad = state.data.map((row, index) => {

              if (index === action.payload.index) {
                // 対象の行のセルを更新
                const updatedRow = {
                  ...row,
                };

                Object.keys(updatedRow).forEach((key, i) => {
                  if (i !== 0) {
                    updatedRow[key] = "";
                    if (row[key]!==""){
                      historydatad.push({
                        type: "update_cell",
                        rowIndex: action.payload.index,
                        columnId: key,
                        value: row[key],
                      });
                    }
                  }
                });
                return updatedRow;
              }
              return row;
            });

            return {
              ...state,
              skipReset: true,
              data: updatedDatad,
              history: historydatad,
              future: [], 
            };
        case "PASTE_ROWVALUE":
                const historydatap = [...state.history]
                const updatedDatap = state.data.map((row, index) => {

                  if (index === action.payload.index) {
                    // 対象の行のセルを更新
                    const updatedRow = {
                      ...row,
                    };
    
                    Object.keys(updatedRow).forEach((key, i) => {
                      if (i !== 0) {
                        updatedRow[key] = action.payload.value;
                        if (row[key]!==action.payload.value){
                          historydatap.push({
                            type: "update_cell",
                            rowIndex: action.payload.index,
                            columnId: key,
                            value: row[key],
                          });
                        }
                      }
                    });
                    return updatedRow;
                  }
                  return row;
                });
    
                return {
                  ...state,
                  skipReset: true,
                  data: updatedDatap,
                  history: historydatap,
                  future: [], 
                };
    
        default:
          return state;
      }
    }

    
    const {keyvalue} = this.state;

    // 表、ボタン、セレクトボックスなどを表示する関数
    function TableShow() {
      const [sstate, sdispatch] = useReducer(reducer, smcdata);
      useEffect(() => {sdispatch({ type: "enable_reset" });
      }, [sstate.data, sstate.columns]);

      const [rstate, rdispatch] = useReducer(reducer, rightddata);
      useEffect(() => {rdispatch({ type: "enable_reset" });
      }, [rstate.data, rstate.columns]);

      const [state, dispatch] = useReducer(reducer, mmcdata);
      useEffect(() => {dispatch({ type: "enable_reset" });
      }, [state.data, state.columns]);

      const [pstate, pdispatch] = useReducer(reducer, predata);
      useEffect(() => {pdispatch({ type: "enable_reset" });
      }, [pstate.data, pstate.columns]);

      const [cstate, cdispatch] = useReducer(reducer, calddata);
      useEffect(() => {cdispatch({ type: "enable_reset" });
      }, [cstate.data, cstate.columns]);

      const [sknstate, skndispatch] = useReducer(reducer, skdata);
      useEffect(() => {skndispatch({ type: "enable_reset" });
      }, [sknstate.data, sknstate.columns]);

      const [skjstate, skjdispatch] = useReducer(reducer, skndata);
      useEffect(() => {skjdispatch({ type: "enable_reset" });
      }, [skjstate.data, skjstate.columns]);

      // ローカルストレージに保存するための関数
      function handleSave(){
        localStorage.setItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth, JSON.stringify(fstate.data))
        var today = new Date()
        let time = `${today.getFullYear()}年${today.getMonth() + 1}月${today.getDate()}日 ${today.getHours()}:${today.getMinutes() < 10 ? '0' : ''}${today.getMinutes()}`
        let tabledata = JSON.parse(localStorage.getItem('Generated-Table-' + Byoutou  + thisyear + '-' + nextmonth))!==null ? JSON.parse(localStorage.getItem('Generated-Table-' + Byoutou  + thisyear + '-' + nextmonth)) : []
        tabledata.push({id: tabledata.length+1, type: "修正画面で保存", time: time, score: "",  data: fstate.data})
        localStorage.setItem('Generated-Table-' + Byoutou  + thisyear + '-' + nextmonth, JSON.stringify(tabledata))
        alert('保存しました')
      }

      function handleSaveAuto(){
        localStorage.setItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth, JSON.stringify(fstate.data))
      }

      useKeybind({
        key: "s",
        ctrlKey: true,
        onKeyDown: () => handleSaveAuto(),
      });

      useKeybind({
        key: "z",
        ctrlKey: true,
        onKeyDown: () => fdispatch({ type: "UNDO_ACTION" }),
      });

      useKeybind({
        key: "y",
        ctrlKey: true,
        onKeyDown: () => fdispatch({ type: "REDO_ACTION" }),
      });

      useKeybind({
        key: "+",
        ctrlKey: true,
        shiftKey: true,
        onKeyDown: () => setZoom(zoomsize==150 ? zoomsize : pre=>pre+5),
      });

      useKeybind({
        key: "-",
        ctrlKey: true,
        onKeyDown: () => setZoom(zoomsize==50 ? zoomsize : pre=>pre-5),
      });

      const sname = "Edit"
      const checkedshift = (JSON.parse(localStorage.getItem("allowshift-" + sname + Byoutou))!==null) ? JSON.parse(localStorage.getItem("allowshift-" + sname + Byoutou)) : []
      const [checkedItemsshift, setCheckedItemsshift] = useState(checkedshift);
      
      useEffect(()=>{
        setCheckedItemsshift(checkedshift)
      },[])
      const handleCheckboxChangeShift = (event) => {
        const { value } = event.target;
        let updatedLabels = [...checkedItemsshift];

        if (checkedItemsshift.includes(value)) {
          updatedLabels = updatedLabels.filter((label) => label !== value);
        } else {
          updatedLabels.push(value);
        }
        setCheckedItemsshift(updatedLabels);
      }
      useEffect(()=>{
        localStorage.setItem("allowshift-" + sname + Byoutou, JSON.stringify(checkedItemsshift))
      }, [checkedItemsshift])
      
      let allowshift = [];

      shiftoptions.forEach((data,i) => {
        if (checkedshift.includes(data.label)){
          allowshift.push({label: data.label, value: data.value, backgroundColor: data.backgroundColor, type: data.type})
        }
      })

      const [showallowshift, setshowallowshift] = useState(false)

      
      // セレクトボックスハンドラ
      const [team, setTeam] = useState([])
      const [skill, setSkill] = useState([])
      const [day, setDay] = useState([])
      const [day2, setDay2] = useState(dayoptions.map(option=>(option.value)))
      const [ddays, setDdays] = useState([])
      const [ddays2, setDdays2] = useState(daysoptions2.map(option=>(option.value)))
      const [hddays, sethDdays] = useState(holidayoptions.map(option=>(option.value)))
      const handleTeamChange = (selectedOptions) => {
        setTeam(selectedOptions.map(option => option.value)); // value 部分だけを配列に保存
      };
      function DropdownTeam() {
        const handleClick = (
          event
        ) => {
          console.log(event);
        };
        handleSaveAuto()
        return (
          <Select 
          isMulti
          placeholder="チーム" 
          value={teamoptions.filter(option => team.includes(option.value))} 
          onChange={handleTeamChange} 
          options={teamoptions} 
          isClearable={true} 
          isSearchable={true}/>
        )
      }
      const handleSkillChange = (selectedOptions) => {
        setSkill(selectedOptions.map(option => option.value));
      };
      function DropdownSkill() {
        const handleClick = (
          event
        ) => {
          console.log(event);
        };
        handleSaveAuto()
        return (
          <Select
          isMulti 
          placeholder="スキル" 
          value={skilloptions.filter(option => skill.includes(option.value))} 
          onChange={handleSkillChange} 
          options={skilloptions} 
          isClearable={true} 
          isSearchable={true}/>
        )
      }
      const handleDayChange = (selectedOptions) => {
        setDay(selectedOptions.map(option => option.value));
      };
      function DropdownDay() {
        const handleClick = (
          event
        ) => {
          console.log(event);
        };
        handleSaveAuto()
        return (
          <Select
           isMulti
           placeholder="日にち" 
           value={dayoptions.filter(option => day.includes(option.value))} 
           onChange={handleDayChange} 
           options={dayoptions} 
           isClearable={true} 
           isSearchable={true}/>
        )
      }
      function DropdownDays() {
        const handleClick = (
          event
        ) => {
          console.log(event);
        };
        handleSaveAuto()
        return (
          <Select placeholder="曜日" onClick={handleClick} value={ddays} onChange={setDdays} options={daysoptions} isClearable={true} isSearchable={true}/>
        )
      }
      const handleDays2Change = (selectedOptions) => {
        setDdays2(selectedOptions.map(option => option.value));
      };
      function DropdownDays2() {
        const handleClick = (
          event
        ) => {
          console.log(event);
        };
        return (
          <Select
           isMulti
           placeholder="日にち" 
           value={daysoptions2.filter(option => ddays2.includes(option.value))} 
           onChange={handleDays2Change} 
           options={daysoptions2} 
           isClearable={true} 
           isSearchable={true}/>
        )
      }
      const handleHDaysChange = (selectedOptions) => {
        sethDdays(selectedOptions.map(option => option.value));
      };
      function DropdownHDays() {
        const handleClick = (
          event
        ) => {
          console.log(event);
        };
        return (
          <Select
           isMulti
           placeholder="平日・土日・祝日" 
           value={holidayoptions.filter(option => hddays.includes(option.value))} 
           onChange={handleHDaysChange} 
           options={holidayoptions} 
           isClearable={true} 
           isSearchable={true}/>
        )
      }

      const allowshiftoptions = allowshift.filter(shift=>{if (shift.label!=="公"){return shift}})
      const [shifttype, setShifttype] = useState(allowshiftoptions.map(shift=>(shift.value)))
      const handleshiftChange = (selectedOptions) => {
        setShifttype(selectedOptions.map(option => option.value));
      };
      function DropdownShift() {
        return (
          <Select
           isMulti
           placeholder="シフト" 
           value={allowshiftoptions.filter(option => shifttype.includes(option.value))} 
           onChange={handleshiftChange} 
           options={allowshiftoptions} 
           isClearable={true} 
           isSearchable={true}/>
        )
      }
      const handleDayChange2 = (selectedOptions) => {
        setDay2(selectedOptions.map(option => option.value));
      };
      function DropdownDay2() {
        const handleClick = (
          event
        ) => {
          console.log(event);
        };
        return (
          <Select
           isMulti
           placeholder="日にち" 
           value={dayoptions.filter(option => day2.includes(option.value))} 
           onChange={handleDayChange2} 
           options={dayoptions} 
           isClearable={true} 
           isSearchable={true}/>
        )
      }

      // ボタンハンドラ
      const [power, setPower] = useState(false);
      const [powers, setPowers] = useState(false);
      const [powerp, setPowerp] = useState(false);
      const [powerd, setPowerd] = useState(false);
      const [powersk, setPowersk] = useState(false);
      const [powerskn, setPowerskn] = useState(false);
      const [powercon, setPowercon] = useState(false);

      function handlestaff() {
        setPower(prevState => !prevState)
      }
      function handlepre() {
        setPowerp(prevState => !prevState)
      }
      function handleshukei() {
        setPowers(prevState => !prevState)

      }
      function handleday() {
        setPowerd(prevState => !prevState)
        setPowersk(false)
        setPowerskn(false)
        handleSaveAuto()
      }
      function handleskn() {
        setPowersk(prevState => !prevState)
        setPowerskn(false)
        setPowerd(false)
        handleSaveAuto()
      }
      function handleskj() {
        setPowerskn(prevState => !prevState)
        setPowersk(false)
        setPowerd(false)
        handleSaveAuto()
      }

      // 行データ削除、貼り付け
      const [cellState, setCellState] = useState({index: null, bl: false});
      const [cellStatepaste, setCellStatepaste] = useState({index: null, value: null, bl: false});

      const handleCellDataUpdate = (newValue) => {
        setCellState(newValue);
      };
      const handleCellDataUpdatePaste = (newValue) => {
        setCellStatepaste(newValue);
      };

      // 勤務表のシフトデータ
      const fmcdata = {columns: mcolumns, data: state.data, skipReset: false, history: [], future: []}

      const [fstate, fdispatch] = useReducer(reducer, fmcdata);
      useEffect(() => {
        fdispatch({ type: "enable_reset" });
      }, [fstate.data, fstate.columns]);
      useEffect(() => {
        fdispatch({ type: "DELETE_ROWVALUE", payload: cellState});
      }, [cellState, fdispatch]);
      useEffect(() => {
        fdispatch({ type: "PASTE_ROWVALUE", payload: cellStatepaste});
      }, [cellStatepaste, fdispatch]);

      //フィルタリング実装部分
      function FilteredColumns(col){
        let fildata = 
          ((day=="" || day==undefined) && (ddays=="" || ddays==undefined))
          ? col
          : (day!="" && day!=undefined)
          ? col.filter(item => {
            if (item.id=="ID"){
              return true
            }
            for (let j = 0; j < day.length; j++){
              if (item.id=="d"+day[j]){
                return true
              }
            }
            })
          : (ddays!="" && ddays!=undefined)
          ? col.filter((item,i) => {
            if (item.id=="ID"){
              return true
            }
            if (ddays.label==whatday[i-1]){
              return true
            }
            if (ddays.label=="土日" && ("土"==whatday[i-1] || "日"==whatday[i-1])){
              return true
            }
            if (ddays.label=="土日+祝日" && (weekend.includes(String(i)))){
              return true
            }
            else return false
            })
          : col
        return fildata
      }

      function FilterCancel() {
        if (team!=undefined && team!=""){
          setTeam([])
        }
        if (skill!=undefined && skill!=""){
          setSkill([])
        }
        if (day!=undefined && day!=""){
          setDay([])
        }
        if (ddays!=undefined && ddays!=""){
          setDdays([])
        }
      }

      const [modalOpen, setModalOpen] = useState(false);

      const checkedrcolumns = (JSON.parse(localStorage.getItem("checkedrcolumns" + Byoutou))!==null) ? JSON.parse(localStorage.getItem("checkedrcolumns" + Byoutou)) : []

      const [checkedItems, setCheckedItems] = useState(checkedrcolumns);

      const handleCheckboxChange = (event) => {
        const { value } = event.target;
        let updatedLabels = [...checkedItems];

        if (checkedItems.includes(value)) {
          updatedLabels = updatedLabels.filter((label) => label !== value);
        } else {
          updatedLabels.push(value);
        }

        setCheckedItems(updatedLabels);
      }
      localStorage.setItem("checkedrcolumns" + Byoutou, JSON.stringify(checkedItems))

      const [modalOpenstaff, setModalOpenstaff] = useState(false);
      const [modalOpenselect, setModalOpenselect] = useState(false);

      const checkedstaff = (JSON.parse(localStorage.getItem("checkedstaff" + Byoutou))!==null) ? JSON.parse(localStorage.getItem("checkedstaff" + Byoutou)) : []

      const [checkedItemsstaff, setCheckedItemsstaff] = useState(checkedstaff);

      const handleCheckboxChangeStaff = (event) => {
        const { value } = event.target;
        let updatedLabels = [...checkedItemsstaff];

        if (checkedItemsstaff.includes(value)) {
          updatedLabels = updatedLabels.filter((label) => label !== value);
        } else {
          updatedLabels.push(value);
        }

        setCheckedItemsstaff(updatedLabels);
      }
      localStorage.setItem("checkedstaff" + Byoutou, JSON.stringify(checkedItemsstaff))

      const [modalOpenday, setModalOpenday] = useState(false);

      const checkedday = (JSON.parse(localStorage.getItem("checkedshiftday" + Byoutou))!==null) ? JSON.parse(localStorage.getItem("checkedshiftday" + Byoutou)) : []

      const zoomstate = localStorage.getItem("zoom-Edit")!==null ? Number(localStorage.getItem("zoom-Edit")) : 80
      const [zoomsize, setZoom] = useState(zoomstate)
      const handleZoomChange = (newValue) => {
        setZoom(newValue);
      };
      localStorage.setItem("zoom-Edit", zoomsize)

      const [checkedItemsday, setCheckedItemsday] = useState(checkedday);
      let maxnum = 0
      if (zoomsize<=65){
        maxnum = 6
      } else
      if (65<zoomsize<=75){
        maxnum = 5
      } else
      if (75<zoomsize<=105){
        maxnum = 4
      } else 
      if (105<zoomsize){
        maxnum = 3
      }
      
      if (checkedItemsday.length>maxnum){
        alert(`選択可能なシフトは${maxnum}個までです。選択シフト数を減らすか表示倍率を変更してください。`)
        setCheckedItemsday(checkedItemsday.slice(0,checkedItemsday.length-1))
      }

      const handleCheckboxChangeday = (event) => {
        const { value } = event.target;
        let updatedLabels = [...checkedItemsday];

        if (checkedItemsday.includes(value)) {
          updatedLabels = updatedLabels.filter((label) => label !== value);
        } else {
          updatedLabels.push(value);
        }

        setCheckedItemsday(updatedLabels);
      }
      localStorage.setItem("checkedshiftday" + Byoutou, JSON.stringify(checkedItemsday))
      
      // スタッフごとのシフト合計数のデータ作成
      const [shukei, setShukei] = useState(rightdaydata)
      const [shift, setShift] = useState(caldaydata)
      const [skillnikkin, setSkillnikkin] = useState(skilldata)
      const [skillnight, setSkillnight] = useState(jskilldata)
      if (powers){
        const edata = JSON.parse(localStorage.getItem("Edit-" + Byoutou  + thisyear + '-' + nextmonth))
        for (let i = 0; i < num; i++) {
          rcol.forEach(col=>{
            if (checkedItems.includes(col.col)){
              let count = 0
              let count1 = 0
              let count2 = 0
              const IEditdata = edata[i]
              const istaffdata = staffdata[i]
              if (col.col=="休公"){
                for (let j = 0; j < weekend.length; j++) {
                  const Editdata = IEditdata["d"+String(weekend[j])]
                  if (Editdata!==undefined){
                    if (Editdata.includes("公")){
                      count = count + 1
                    }
                  }
                }
                shukei[i][col.col] = String(count)
              } else 
              if (!col.col.includes("差") && !col.col.includes("限") && !col.col.includes("幅")){
                for (let j = 1; j <= days; j++) {
                  const Editdata = IEditdata["d"+String(j)]
                  if (Editdata!==undefined){
                    if (Editdata.includes(col.col)){
                      count = count + 1
                    }
                  }
                }
                shukei[i][col.col] = String(count)
              } else 
              if (col.col.includes("限")){
                shukei[i][col.col] = String(istaffdata[col.ac])
              } else 
              if (col.col.includes("差")){
                const splitArray = col.col.split("・");
                const saA = splitArray[0];
                const saB = splitArray[1].replace("差", "");
                for (let j = 1; j <= days; j++) {
                  const Editdata = IEditdata["d"+String(j)]
                  if (Editdata!==undefined){
                    if (Editdata.includes(saA)){
                      count1 = count1 + 1
                    } else
                    if (Editdata.includes(saB)){
                      count2 = count2 + 1
                    }
                  }
                }
                shukei[i][col.col] = String(count1 - count2)
              } else 
              if (col.col.includes("幅")){
                let haba = col.col.replace("幅", "")
                for (let j = 1; j <= days; j++) {
                  const Editdata = IEditdata["d"+String(j)]
                  if (Editdata!==undefined){
                    if (Editdata.includes(haba)){
                      count = count + 1
                    }
                  }
                }
                shukei[i][col.col] = shukei[i].hasOwnProperty(haba + "上限") ? String(shukei[i][haba + "上限"] - count) : String(count)
              }
          }})
         }
      }
      if (powerd){
        // 日ごとのシフト合計数のデータ作成
        const edata = JSON.parse(localStorage.getItem("Edit-" + Byoutou  + thisyear + '-' + nextmonth))
        checkedshift2.forEach((item,s)=>{
          for (let j = 1; j <= days; j++) {
            let countnum = 0
            for (let i = 0; i < num; i++){
              const Editdata = edata[i]["d"+String(j)]
              if (Editdata!==undefined){
                if (item=="〇"){
                  if (Editdata.includes(item) || nikkinlist.some(sh=>Editdata.includes(sh))){
                    countnum += 1
                  }
                } else {
                  if (Editdata.includes(item)){
                    countnum += 1
                  }
                }
              }
            }
            shift[s]["d"+String(j)] = String(countnum)
          }
        })
      }
      if (powersk || powerskn){
        // 日ごとのスキル集計のデータ作成
        const edata = JSON.parse(localStorage.getItem("Edit-" + Byoutou  + thisyear + '-' + nextmonth))
        for (let j = 1; j <= days; j++) {
          let n1 = 0
          let n2 = 0
          let n3 = 0
          let j1 = 0
          let j2 = 0
          let j3 = 0

          for (let i = 0; i < num; i++){
            const istaffdata = staffdata[i]
            const Editdata = edata[i]["d"+String(j)]
            if (Editdata!==undefined){
              shiftoptions.forEach(option=>{
                if (option.type=="日勤系"){
                  if (Editdata.includes(option.label)){
                    if ((istaffdata.skill=="TL") || (istaffdata.skill=="L1") || (istaffdata.skill=="L2")){
                      n1 = n1 + 1
                      n2 = n2 + 1
                    }
                    if ((istaffdata.skill=="L")){
                      n2 = n2 + 1
                    }
                    if ((istaffdata.skill=="M2") || (istaffdata.skill=="M1")){
                      n3 = n3 + 1
                    }
                  }
                }
              })
              if (Editdata.includes(junya)){
                if ((istaffdata.skill=="TL") || (istaffdata.skill=="L1") || (istaffdata.skill=="L2")){
                  j1 = j1 + 1
                  j2 = j2 + 1
                }
                if ((istaffdata.skill=="L")){
                  j2 = j2 + 1
                }
                if ((istaffdata.skill=="M2") || (istaffdata.skill=="M1")){
                  j3 = j3 + 1
                }
              }
            }                                
          }
          skillnikkin[0]["d"+String(j)] = String(n1)
          skillnikkin[1]["d"+String(j)] = String(n2)
          skillnikkin[2]["d"+String(j)] = String(n3)
          skillnight[0]["d"+String(j)] = String(j1)
          skillnight[1]["d"+String(j)] = String(j2)
          skillnight[2]["d"+String(j)] = String(j3)
        }
      }

      const [showSelect, setShowSelect] = useState(false);
      const [selectRef, setSelectRef] = useState(null);
      const [selectPop, setSelectPop] = useState(null);
      const [selectedValue, setSelectedValue] = useState(null);
      const [selectedValues, setSelectedValues] = useState(null);

      const {styles, attributes} = usePopper(selectRef, selectPop, {
        placement: "bottom-start",
        strategy: "fixed"
      });

      const handleSelectClick = () => {
        setShowSelect(true); // モーダルを表示
      };
    
      const handleOptionClick = (option) => {
        setSelectedValues(option.value)
        setSelectedValue(option.label); // 選択した値を保持
        setShowSelect(false); // モーダルを閉じる
      };

      function getBackgroundColor(selectedValue) {
        const item = shiftoptions.find(item => item.label === selectedValue);
        if (item) {
          return item.backgroundColor;
        } else {
          return "#FF4500";
        }
      }

      // エスケープキーを押した場合
      document.addEventListener("keydown", function(event) {
        if (event.key === "Escape") { 
            setShowSelect(false)
        }
      });

      // カーソルが重なったときのアクション
      const [hoveredStates, setHoveredStates] = useState({});
      const handleMouseEnter = (shifttype) => {
        // カーソルが重なったときに対応するisHoveredステートをtrueに設定
        setHoveredStates((prevStates) => ({
          ...prevStates,
          [shifttype]: true,
        }));
      };
      const handleMouseLeave = (shifttype) => {
        // カーソルが離れたときに対応するisHoveredステートをfalseに設定
        setHoveredStates((prevStates) => ({
          ...prevStates,
          [shifttype]: false,
        }));
      };

      const optypes = shiftoptions.map(option => option.type);
      const typeCount = {};
      optypes.forEach(type => {
        typeCount[type] = (typeCount[type] || 0) + 1;
      });
      const typeCountList = Object.entries(typeCount);
      const typeCounts = typeCountList.map(([type, count]) => count);


      const [tableselect, setTTableselect] = useState(false)
      const storagedata = JSON.parse(localStorage.getItem('Generated-Table-' + Byoutou  + thisyear + '-' + nextmonth))!==null ? JSON.parse(localStorage.getItem('Generated-Table-' + Byoutou  + thisyear + '-' + nextmonth)) : []
      if (today.getMonth()+1>=5 && today.getMonth()+1<=12){
        localStorage.removeItem('Generated-Table-' + Byoutou  + String(today.getFullYear()) + '-' + String(today.getMonth()-3))
      } else 
      if (today.getMonth()+1<=4){
        localStorage.removeItem('Generated-Table-' + Byoutou  + String(today.getFullYear()-1) + '-' + String(12-(4-today.getMonth()+1)))
      }
      
      const handleSelect = (data) => {
        const confirm = window.confirm("この勤務表を修正画面に反映しますか？")
        if (confirm){
          localStorage.setItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth, JSON.stringify(data))
          window.location.reload()  
        }
      }

      const handleSelectDelete = (id) => {
        const confirm = window.confirm("この勤務表を削除しますか？")
        if (confirm){
          let newdata = []
          storagedata.forEach(data=>{
            if (data.id!==id){
              newdata.push(data)
            }
          })
          localStorage.setItem('Generated-Table-' + Byoutou  + thisyear + '-' + nextmonth, JSON.stringify(newdata))
          window.location.reload()  
        }
      }

      const handleRemoveall = () => {
        const confirm = window.confirm("履歴内の勤務表を全て削除しますか？入力中のシフトは削除されません。")
        if (confirm){
          localStorage.removeItem('Generated-Table-' + Byoutou  + thisyear + '-' + nextmonth)
          window.location.reload()  
        }
      }

      const handlereload = () => {
        window.location.reload()  
      }

      const [filterbutton, setfilterbutton] = useState((preyear==today.getFullYear() && thismonth==today.getMonth()+1) ? true : false)
      const [databutton, setdatabutton] = useState((preyear==today.getFullYear() && thismonth==today.getMonth()+1) ? true : false)
      const [filebutton, setfilebutton] = useState((preyear==today.getFullYear() && thismonth==today.getMonth()+1) ? true : false)
      const [editbutton, seteditbutton] = useState((preyear==today.getFullYear() && thismonth==today.getMonth()+1) ? true : false)
      const [optbutton, setoptbutton] = useState((preyear==today.getFullYear() && thismonth==today.getMonth()+1) ? false : false)
      const [expandbutton, setexpandbutton] = useState((preyear==today.getFullYear() && thismonth==today.getMonth()+1) ? true : false)

      const handleexpand = () => {
        if (filterbutton || databutton || filebutton || editbutton || optbutton){
          setfilterbutton(false)
          setdatabutton(false)
          seteditbutton(false)
          setfilebutton(false)
          setoptbutton(false)
          setexpandbutton(prevstate=>!prevstate)
        } else {
          setfilterbutton(true)
          setdatabutton(true)
          seteditbutton(true)
          setfilebutton(true)
          setexpandbutton(prevstate=>!prevstate)
        }
      }
      
      

      const [powershift, setPowershift] = useState(false)
      
      const todayshift = (JSON.parse(localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth))!==null) ? JSON.parse(localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth)) : []
      
      const getBackgroundColorSkill = (label) => {
        const match = skilloptions.find(item => item.label === label);
        return match ? match.backgroundColor : null;
      };

      const [editable, setEditable] = useState((preyear==today.getFullYear() && thismonth==today.getMonth()+1) ? true : false)

      function ToggleButton() {
      
        const handleToggle = () => {
          setEditable(!editable)
        };
      
        return (
          <div className={`toggle-button ${editable ? 'on' : 'off'}`} onClick={handleToggle}>
            <div className={`slider ${editable ? 'on' : 'off'}`} /> 
          </div>
        );
      }

      const navigate = useNavigate();

      const handleIconClick = () => {
        // ページ遷移
        navigate(`/Table/Storage/${keyvalue.ward}/PDF`);
      };

      function handlemonthchange(Byoutou, year, month){
        let keyvalue = {ward:Byoutou, year:year, month:month}
        localStorage.setItem('Storage-show', JSON.stringify(keyvalue))
        handlereload()
      }

      const [helpopen, setHelpopen] = useState(false)

      const [showmake, setShowmake] = useState(false)
      const [showmake2, setShowmake2] = useState(false)
      const [showmake3, setShowmake3] = useState(false)

      function handlekeysch(Byoutou, year, month){
        let keyvalue = {ward:Byoutou, year:year, month:month}
        localStorage.setItem('Schedule-key', JSON.stringify(keyvalue))
      }

      function handledownloadauto(){
        localStorage.setItem('Download', '1')
      }

      handleSaveAuto()

      return (
        <div onContextMenu={(e) => {e.preventDefault()}}>
          <div style={{paddingBottom: '1rem'}}>
            <div  style={{position: 'sticky', top: '50', width: '100%', zIndex: '10', border: '1.5px solid gray'}}>
              <div style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#DCDCDC', height: "25px"}}>
                <div style={{display: 'flex'}}>
                  {editable &&
                  <div style={{display: 'flex', paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
                    <div onClick={()=>setHelpopen(true)} style={{cursor: 'pointer', paddingRight: '0.3rem', display: 'flex', alignItems: 'center'}}>
                      <HelpOutlineOutlinedIcon style={{fontSize: '20px'}}/>
                    </div>
                    <div onClick={handleSave} style={{display: 'flex', alignItems: 'center', paddingRight: '0.2rem'}}>
                      <SaveOutlinedIcon style={{fontSize: '20px', cursor: 'pointer'}}/>
                    </div>
                    <div onClick={()=>{handleIconClick(); handledownloadauto();}} style={{display: 'flex', alignItems: 'center', paddingRight: '0.3rem'}}>
                      <FileDownloadOutlinedIcon style={{fontSize: '20px', cursor: 'pointer'}}/>
                    </div>
                    <div onClick={() => fdispatch({ type: "UNDO_ACTION" })} style={{display: 'flex', alignItems: 'center'}}>
                      <UndoOutlinedIcon style={{fontSize: '20px', cursor: 'pointer', color: fstate.history.length>0 ? 'black' : 'gray'}}/>
                    </div>
                    <div onClick={() => fdispatch({ type: "REDO_ACTION" })} style={{display: 'flex', alignItems: 'center', paddingLeft: '0.3rem'}}>
                      <RedoOutlinedIcon style={{fontSize: '20px', cursor: 'pointer', color: fstate.future.length>0 ? 'black' : 'gray'}}/>
                    </div>
                  </div>}
                  <div onClick={()=>{setfilebutton(prevstate=>!prevstate);setoptbutton(false);setShowmake(false);setShowmake2(false);setShowmake3(false)}} style={{height: '22px', color: "#2F4F4F", borderBottom: filebutton && '2px solid #008000', cursor: 'pointer', marginLeft: '0.5rem', marginRight: '0.25rem', fontSize: '15px'}}>
                    ファイル
                  </div>
                  {editable && <div onClick={()=>{seteditbutton(prevstate=>!prevstate);setoptbutton(false);setShowmake(false);setShowmake2(false);setShowmake3(false)}} style={{height: '22px', color: "#2F4F4F", borderBottom: editbutton && '2px solid #FF4500', cursor: 'pointer', marginLeft: '0.25rem', marginRight: '0.25rem', fontSize: '15px'}}>
                    入力
                  </div>}
                  <div onClick={()=>{setdatabutton(prevstate=>!prevstate);setoptbutton(false);setShowmake(false);setShowmake2(false);setShowmake3(false)}} style={{height: '22px', color: "#2F4F4F", borderBottom: databutton && '2px solid #2196F3', cursor: 'pointer', marginLeft: '0.25rem', marginRight: '0.25rem', fontSize: '15px'}}>
                    データ
                  </div>
                  <div onClick={()=>{setfilterbutton(prevstate=>!prevstate);setoptbutton(false);setShowmake(false);setShowmake2(false);setShowmake3(false)}} style={{height: '22px', color: "#2F4F4F", borderBottom: filterbutton && '2px solid gray', cursor: 'pointer', marginLeft: '0.25rem', marginRight: '0.25rem', fontSize: '15px'}}>
                    フィルター
                  </div>
                  {editable && <div onClick={()=>{setoptbutton(prevstate=>!prevstate); setfilebutton(false); seteditbutton(false); setdatabutton(false); setfilterbutton(false)}} style={{height: '22px', color: "#2F4F4F", borderBottom: optbutton && '2px solid #FF1493', cursor: 'pointer', marginLeft: '0.25rem', marginRight: '0.25rem', fontSize: '15px'}}>
                    自動作成
                  </div>}
                </div>

                <div style={{display: 'flex'}}>
                  <div style={{display: 'flex', alignItems: 'center', zoom: '0.8', paddingRight: '0.25rem'}}>
                    <div style={{marginRight: '0.5rem', fontSize: '14px'}}>編集モード</div>
                    <div><ToggleButton/></div>
                  </div>
                  <div onClick={handleexpand} style={{height: '22px', color: "#2F4F4F", cursor: 'pointer', marginLeft: '0.5rem', marginRight: '0.5rem', fontSize: '15px'}}>
                    {(filterbutton || databutton || filebutton || editbutton || optbutton) ? <ExpandLessOutlinedIcon/> : <ExpandMoreOutlinedIcon/>}
                  </div>
                </div>

              </div>
              <div style={{minHeight: (filterbutton || databutton || filebutton || editbutton || optbutton) && '30px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', zIndex: 2}}>
                
                  {!optbutton && editable && filebutton && <Button sx={{ border: '1px solid gray' }} size="small" variant="contained" style={{backgroundColor: "#008000", zoom: '80%'}} onClick={handleSave}>
                    保存
                  </Button>}
                  {!optbutton && filebutton && <Button sx={{ border: '1px solid gray' }} size="small" variant="contained"  style={{backgroundColor: "#008000", zoom: '80%'}} component={Link} to={`/Table/Storage/${keyvalue.ward}/PDF`} onClick={()=>{localStorage.setItem('progress-' + byoutou + '-' + thisyear + '-' + nextmonth, 4)}}>
                    確認
                  </Button>}
                  {!optbutton && editable && filebutton && <Button sx={{ border: '1px solid gray' }} size="small" variant="contained"  style={{backgroundColor: "#008000", zoom: '80%'}} onClick={()=>setTTableselect(prevState=>!prevState)}>
                    履歴
                  </Button>}
                  {!optbutton && editable && editbutton && <Button sx={{ border: '1px solid gray' }} size="small" variant="contained" style={{backgroundColor: powercon ? "#FFA07A" : "#FF4500" , color: !powercon ? '#ffffff' : '#000000', zoom: '80%'}} onClick={()=>setPowercon(prevState => !prevState)}>
                    条件違反
                  </Button>}
                  {!optbutton && editable && editbutton && <Button sx={{ border: '1px solid gray' }} onClick={handleSelectClick} size="small" variant="contained" style={{color: selectedValue===null ? "#FFFFFF" : "#000000", backgroundColor: getBackgroundColor(selectedValue), zoom: '80%'}}>
                    {selectedValue===null ? "シフト記号" : (selectedValues!=="") ? selectedValues : "☒削除"}
                </Button>}
                  {!optbutton && databutton&&  <div>
                    <Button onMouseEnter={()=>setModalOpenstaff(true)} onMouseLeave={()=>setModalOpenstaff(false)} sx={{ border: '1px solid gray' }} style={{ backgroundColor: !power ? '#2196F3' : '#B0C4DE', color: !power ? '#ffffff' : '#000000', zoom: '80%'}} size="small" variant="contained" color="info" onClick={handlestaff}>
                      スタッフ情報
                    </Button>
                    {modalOpenstaff && (
                      <div
                      className='shadow-5 bg-white border-radius-md'
                      style={{
                        zIndex: 15,
                        minWidth: 200,
                        maxWidth: 400,
                        position: "absolute",
                      }}
                      onMouseEnter={()=>setModalOpenstaff(true)}
                      onMouseLeave={()=>setModalOpenstaff(false)}
                      >
                        <div style={{display: 'flex', flexWrap: 'wrap', padding: '0.5rem'}}>
                        {staffcol.map((data) => {
                          if (!data.label.includes("No.") && !data.label.includes("仮番号") && !data.label.includes("限") && !data.label.includes("禁止") && !data.label.includes("姓")){
                            return(
                            <form>
                              <label style={{ paddingRight: '1rem', cursor: 'pointer' }}>
                                <input
                                  type="checkbox"
                                  name="options"
                                  value={data.label}
                                  checked={checkedItemsstaff.includes(data.label)}
                                  onChange={handleCheckboxChangeStaff}
                                  style={{ cursor: 'pointer' }}
                                />
                                {data.label}
                              </label>
                            </form>)}
                        })}
                        </div>
                        <div style={{padding: '0 0.5rem 0.5rem 0.5rem', display: 'flex', justifyContent: 'flex-end'}}>
                          <Button sx={{ border: '1px solid gray', height: '25px'}} variant="contained" style={{backgroundColor: "#008000"}} onClick={handlereload}>
                            更新
                          </Button>
                        </div>
                      </div>
                    )}
                    </div>}
                    {!optbutton && databutton&& <div>
                    <Button sx={{ border: '1px solid gray' }} style={{ backgroundColor: !powerp ? '#2196F3' : '#B0C4DE', color: !powerp ? '#ffffff' : '#000000' , zoom: '80%'}} size="small" variant="contained" color="info" onClick={handlepre}>
                    前月
                    </Button>
                    </div>}
                    {!optbutton && databutton&& <div>
                    <Button onMouseEnter={()=>setModalOpen(true)} onMouseLeave={()=>setModalOpen(false)} sx={{ border: '1px solid gray' }}  style={{ backgroundColor: !powers ? '#2196F3' : '#B0C4DE', color: !powers ? '#ffffff' : '#000000' , zoom: '80%'}} size="small" variant="contained" color="info" onClick={handleshukei}>
                    ヨコ集計
                    </Button>
                    {modalOpen && (
                      <div
                      className='shadow-5 bg-white border-radius-md'
                      style={{
                        zIndex: 15,
                        minWidth: 200,
                        maxWidth: 400,
                        position: "absolute",
                      }}
                      onMouseEnter={()=>setModalOpen(true)}
                      onMouseLeave={()=>setModalOpen(false)}
                      >
                        <div style={{display: 'flex', flexWrap: 'wrap', padding: '0.5rem'}}>
                        {rcolumns.map((data) => {
                          return(
                          <form>
                            <label style={{ paddingRight: '1rem', cursor: 'pointer' }}>
                              <input
                                type="checkbox"
                                name="options"
                                value={data.key}
                                checked={checkedItems.includes(data.key)}
                                onChange={handleCheckboxChange}
                                style={{ cursor: 'pointer' }}
                              />
                              {data.key}
                            </label>
                          </form>)
                        })}
                        </div>
                        <div style={{padding: '0 0.5rem 0.5rem 0.5rem', display: 'flex', justifyContent: 'flex-end'}}>
                          <Button sx={{ border: '1px solid gray', height: '25px'}} variant="contained" style={{backgroundColor: "#008000"}} onClick={handlereload}>
                            更新
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>}
                  {!optbutton && databutton&& <div>
                  <Button sx={{ border: '1px solid gray' }} style={{ backgroundColor: !powershift ? '#4169E1' : '#B0C4DE', color: !powershift ? '#ffffff' : '#000000' , zoom: '80%'}} size="small" variant="contained" color="info" onClick={()=>setPowershift(true)}>
                    シフト集計
                    </Button>
                    </div>}
                    {!optbutton && databutton&& <div>
                  <Button onMouseEnter={()=>setModalOpenday(true)} onMouseLeave={()=>setModalOpenday(false)}  sx={{ border: '1px solid gray' }} style={{ backgroundColor: !powerd ? '#2196F3' : '#B0C4DE', color: !powerd ? '#ffffff' : '#000000' , zoom: '80%'}} size="small" variant="contained" color="info" onClick={handleday}>
                    タテ集計
                    </Button>
                    {modalOpenday && (
                      <div
                      className='shadow-5 bg-white border-radius-md'
                      style={{
                        zIndex: 15,
                        minWidth: 200,
                        maxWidth: 400,
                        position: "absolute",
                      }}
                      onMouseEnter={()=>setModalOpenday(true)}
                      onMouseLeave={()=>setModalOpenday(false)}
                      >
                        <div style={{display: 'flex', flexWrap: 'wrap', padding: '0.5rem'}}>
                        {shiftoptions.map((data) => {
                          if (data.type!=="連続系" && data.label!==""){return(
                          <form>
                            <label style={{ paddingRight: '1rem', cursor: 'pointer' }}>
                              <input
                                type="checkbox"
                                name="options"
                                value={data.label}
                                checked={checkedItemsday.includes(data.label)}
                                onChange={handleCheckboxChangeday}
                                style={{ cursor: 'pointer' }}
                              />
                              {data.label}
                            </label>
                          </form>)}
                        })}
                        </div>
                        <div style={{padding: '0 0.5rem 0.5rem 0.5rem', display: 'flex', justifyContent: 'flex-end'}}>
                          <Button sx={{ border: '1px solid gray', height: '25px'}} variant="contained" style={{backgroundColor: "#008000"}} onClick={handlereload}>
                            更新
                          </Button>
                        </div>
                      </div>
                    )}
                    </div>}
                    {!optbutton && databutton&& <div>
                    <Button sx={{ border: '1px solid gray' }} style={{ backgroundColor: !powersk ? '#2196F3' : '#B0C4DE', color: !powersk ? '#ffffff' : '#000000' , zoom: '80%'}} size="small" variant="contained" color="info" onClick={handleskn}>
                    日勤スキル
                    </Button>
                    </div>}
                    {!optbutton && databutton&& <div>
                    <Button sx={{ border: '1px solid gray' }} style={{ backgroundColor: !powerskn ? '#2196F3' : '#B0C4DE', color: !powerskn ? '#ffffff' : '#000000' , zoom: '80%'}} size="small" variant="contained" color="info" onClick={handleskj}>
                    夜勤スキル
                    </Button>
                    </div>}
                    
                  {!optbutton && filterbutton&&  <div style={{zIndex:5, zoom: '80%'}}>
                  <Paper>
                  <DropdownTeam/>
                  </Paper>
                  </div>}
                    {!optbutton && filterbutton&&  <div style={{zIndex:5, zoom: '80%'}}>
                  <Paper>
                  <DropdownSkill/>
                  </Paper>
                  </div>}
                    {!optbutton && filterbutton&&  <div style={{zIndex:5, zoom: '80%'}}>
                  {(ddays==undefined || ddays=="") &&
                  <Paper>
                  <DropdownDay/>
                  </Paper>
                  }
                  </div>}
                    {!optbutton && filterbutton&&  <div style={{zIndex:5, zoom: '80%'}}>
                  {(day==undefined || day=="") &&
                  <Paper>
                  <DropdownDays/> 
                  </Paper>
                  }       
                  </div>}
                  {!optbutton && filterbutton&&  <div>
                  <Button sx={{ border: '1px solid gray' }} onClick={FilterCancel} size="small" variant="contained" color="inherit" style={{zoom: '80%'}}>
                    解除
                </Button>
                </div>}
                    
                {optbutton && editable && 
                <Button sx={{ border: '1px solid gray' }} size="small" variant="contained" style={{backgroundColor: showmake ? "#DB7093" : "#FF1493", zoom: '80%'}} onClick={()=>{setShowmake(prevState=>!prevState); setShowmake2(false); setShowmake3(false); handlekeysch(Byoutou,thisyear,nextmonth)}}>
                    勤務表作成
                  </Button>}
                  {optbutton && editable && 
                <Button sx={{ border: '1px solid gray' }} size="small" variant="contained" style={{backgroundColor: showmake2 ? "#DB7093" : "#FF1493", zoom: '80%'}} onClick={()=>{setShowmake2(prevState=>!prevState); setShowmake(false); setShowmake3(false); handlekeysch(Byoutou,thisyear,nextmonth)}}>
                    シフト埋め
                  </Button>}
                  {optbutton && editable && teamdata.length>0 && 
                <Button sx={{ border: '1px solid gray' }} size="small" variant="contained" style={{backgroundColor: showmake3 ? "#DB7093" : "#FF1493", zoom: '80%'}} onClick={()=>{setShowmake3(prevState=>!prevState); setShowmake(false); setShowmake2(false); handlekeysch(Byoutou,thisyear,nextmonth)}}>
                    チーム枠均等
                  </Button>}

                {showSelect && <div className='overlay' onClick={() => setShowSelect(false)} />}
                {showSelect && (
                  <div
                    className='shadow-5 bg-white border-radius-md'
                    ref={setSelectPop}
                    {...attributes.popper}
                    style={{
                      ...styles.popper,
                      zIndex: 10,
                      minWidth: 200,
                      maxWidth: 400,
                      padding: "0.75rem",
                      position: "absolute",
                      left: "50%",
                      transform: "translate(-50%, 30%)",
                      zoom: '0.85'
                    }}>
                    <div
                      className='cursor-pointer'
                      style={{marginBottom: "0.8rem", marginTop: "0.3rem", marginRight: "10rem"}}
                      >
                      <Button onClick={()=>{setSelectedValue(null); setShowSelect(false)}} sx={{ border: '1px solid gray' }} size="small" variant="contained" color="inherit">
                          連続入力解除
                      </Button>
                    </div>
                    <div className='d-flex flex-wrap-wrap' style={{padding: '0 0.2rem 0.5rem 0', justifyContent: 'center', flexWrap: 'wrap'}}>
                    {allowshift.map((option) => (
                                <div
                                  key={option.label}
                                  className='cursor-pointer'
                                  style={{ marginRight: "0.5rem", marginTop: "0.5rem", maxWidth: '150px'}}
                                  onClick={() => {
                                    setShowSelect(false);
                                    handleOptionClick(option);
                                  }}
                                >
                                  <Relationship value={option.value} backgroundColor={option.backgroundColor} word={"☒削除"}/>
                                </div>
                      ))}
                    </div>
                    <div onClick={()=>setshowallowshift(true)} style={{display: 'flex', justifyContent: 'flex-end', marginTop: '-1.5rem', paddingBottom: '0.5rem', cursor: 'pointer'}}>
                  <EditOutlinedIcon style={{fontSize: '18px'}}/>
                </div>
                    <hr/>
                    <div className='d-flex flex-wrap-wrap' style={{paddingTop: "0.75rem", justifyContent: 'center'}}>
                      
                      {shifttypes.map((shifttype, i) => (
                        <div style={{paddingBottom: '0.5rem'}}>
                        {shifttype!=="削除" &&
                        <div style={{border: "1px solid #C0C0C0", boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)', cursor: 'pointer', backgroundColor: shifttypescolor[i], borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80px', height: '40px', marginRight: "0.75rem"}}
                          onMouseEnter={() => handleMouseEnter(shifttype)}
                          onMouseLeave={() => handleMouseLeave(shifttype)}
                        >
                          {shifttype}
                        </div>}
                        {shifttype=="削除" &&
                          <div
                          key={deleteoption.label}
                          className='cursor-pointer'
                          style={{border: "1px solid #C0C0C0", boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)', cursor: 'pointer', backgroundColor: shifttypescolor[i], borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80px', height: '40px', marginRight: "0.75rem"}}                        onClick={() => {
                            setShowSelect(false);
                            handleOptionClick(deleteoption);
                          }}
                        >
                          <Relationship value={deleteoption.label} backgroundColor={deleteoption.backgroundColor} word={"☒削除"}/>
                        </div>
                        }
                        {hoveredStates[shifttype] && shifttype!=="削除" &&
                        <div
                        className='shadow-5 bg-white border-radius-md'
                        onMouseEnter={() => handleMouseEnter(shifttype)}
                        onMouseLeave={() => handleMouseLeave(shifttype)}
                        style={{
                          zIndex: 10,
                          padding: "0.25rem 0.75rem 0.75rem 0.75rem",
                          position: "absolute",
                          marginLeft: (typeCounts[i]>2) ? '-15%' : '-2%',
                          marginTop: (typeCounts[i]>8) ? '-25%' : (9>typeCounts[i]>4) ? '-20%' : '-15%',
                          maxWidth: '225px',
                        }}>
                        <div key={i} style={{display: 'inline-flex', flexWrap: 'wrap'}}>
                          {shiftoptions.map((option) => {
                            if (option.type === shifttype) {
                              return (
                                <div
                                  key={option.label}
                                  className='cursor-pointer'
                                  style={{ marginRight: "0.5rem", marginTop: "0.5rem", maxWidth: '150px'}}
                                  onClick={() => {
                                    setShowSelect(false);
                                    handleMouseLeave(shifttype);
                                    handleOptionClick(option);
                                  }}
                                >
                                  <Relationship value={option.value} backgroundColor={option.backgroundColor} word={"☒削除"}/>
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                        </div>
                        }
                        </div>

                      ))}

                {showallowshift && <div className='overlay' onClick={() => {setshowallowshift(false)}} />}
                {showallowshift && (
                  <div
                    className='shadow-5 bg-white border-radius-md'
                    style={{
                      zIndex: 101,
                      width: 410,
                      padding: "0.75rem",
                      position: "absolute",
                      left: "50%",
                      top: "40%",
                      transform: "translate(-50%, -50%)",
                    }}>
                      <div style={{display: 'flex', flexWrap: 'wrap', padding: '0.5rem'}}>
                        {shiftoptions.map((data) => {
                          if (data.label!==""){return(
                          <form>
                            <label style={{ paddingRight: '1rem', cursor: 'pointer' }}>
                              <input
                                type="checkbox"
                                name="options"
                                value={data.label}
                                checked={checkedItemsshift.includes(data.label)}
                                onChange={handleCheckboxChangeShift}
                                style={{ cursor: 'pointer' }}
                              />
                              {data.label}
                            </label>
                          </form>)
                        }})}
                        </div>
                  </div>)}


                    </div>
                  </div>
                )}
              </div>
            </div>   

            {showmake && 
              <div style={{overflowY: 'scroll', height: '600px', paddingBottom: '2rem'}}>
                <Optimization/>
              </div>
            }
            {showmake2 && 
              <div style={{overflowY: 'scroll', height: '600px', paddingBottom: '2rem'}}>
                <Optimization2/>
              </div>
            }
            {showmake3 && 
              <div style={{overflowY: 'scroll', height: '600px', paddingBottom: '2rem'}}>
                <Optimization3/>
              </div>
            }

            {!showmake && 
            <ScrollSync>
            <div style={{zoom: zoomsize*0.01, borderBottom: '1px solid gray'}}>

              <div className="body-container-r">

                {power && ( // スタッフ情報
                <div style={{borderLeft: '2px solid gray', borderBottom: '2px solid gray'}}>
                  <ScrollSyncPane group="h">
                    <div 
                    style={{
                      height: 
                      (!expandbutton && !(powerd || powersk || powerskn)) ? `${String(106/((zoomsize+zoomsize/4)/100))}vh` 
                      : (!expandbutton && (powerd || powersk || powerskn)) ? `${String(84.5/((zoomsize+zoomsize/4)/100))}vh` 
                      : (expandbutton && !(powerd || powersk || powerskn)) ? `${String(100/((zoomsize+zoomsize/4)/100))}vh` 
                      : (expandbutton && (powerd)) ? `${String(78/((zoomsize+zoomsize/4+(zoomsize/100)**8)/100))}vh`
                      : ((expandbutton && (powersk || powerskn)) && `${String(80/((zoomsize+zoomsize/4+(zoomsize/100)**8)/100))}vh`)
                    }}  className={`${(!powerd && !powersk && !powerskn) && "table-container-sub"} ${(powersk || powerskn) && "table-container-sub-skill"}  ${(powerd) && "table-container-sub-day"}`}>
                      <TableEdit
                        columns={sstate.columns}
                        data={sstate.data}
                        skipReset={sstate.skipReset}
                        dispatch={sdispatch}
                        filtervalue={[team,skill]}
                        Byoutou={Byoutou}
                        thisyear={thisyear}
                        nextmonth={nextmonth}
                        noncolor={"n"}
                        noselect={"n"}

                      />
                    </div>
                  </ScrollSyncPane>
                  {(powerd || powersk || powerskn) && ( 
                    <div style={{borderTop: '2px solid gray'}}>
                    </div>
                    )}
                </div>
                )}

                {powerp && ( // 前月
                <div style={{borderLeft: '2px solid gray', borderBottom: '2px solid gray'}}>
                  <ScrollSyncPane group="h">
                    <div 
                    style={{
                      height: 
                      (!expandbutton && !(powerd || powersk || powerskn)) ? `${String(106/((zoomsize+zoomsize/4)/100))}vh` 
                      : (!expandbutton && (powerd || powersk || powerskn)) ? `${String(84.5/((zoomsize+zoomsize/4)/100))}vh` 
                      : (expandbutton && !(powerd || powersk || powerskn)) ? `${String(100/((zoomsize+zoomsize/4)/100))}vh` 
                      : (expandbutton && (powerd)) ? `${String(78/((zoomsize+zoomsize/4+(zoomsize/100)**8)/100))}vh`
                      : ((expandbutton && (powersk || powerskn)) && `${String(80/((zoomsize+zoomsize/4+(zoomsize/100)**8)/100))}vh`)
                    }}  className={`${(!powerd && !powersk && !powerskn) && "table-container-sub"} ${(powersk || powerskn) && "table-container-sub-skill"}  ${(powerd) && "table-container-sub-day"}`}>
                      <TableEdit
                        columns={pstate.columns}
                        data={pstate.data}
                        skipReset={pstate.skipReset}
                        dispatch={pdispatch}
                        filtervalue={[team,skill]}
                        Byoutou={Byoutou}
                        noncolor={"n"}
                        noselect={"n"}
                      />
                    </div>
                  </ScrollSyncPane>

                  {(powerd || powersk || powerskn) && ( 
                    <div style={{borderTop: '2px solid gray'}}>
                    </div>
                    )}
                </div>
                )}

                {/*勤務表*/}
                <div onClick={handleSaveAuto} className="table-container-m" style={{borderLeft: '2px solid gray', borderRight: '2px solid gray', borderBottom: '2px solid gray'}}>
                  <ScrollSyncPane group="h">
                  <div 
                    style={{
                      height: 
                      (!expandbutton && !(powerd || powersk || powerskn)) ? `${String(106/((zoomsize+zoomsize/4)/100))}vh` 
                      : (!expandbutton && (powerd || powersk || powerskn)) ? `${String(84.5/((zoomsize+zoomsize/4)/100))}vh` 
                      : (expandbutton && !(powerd || powersk || powerskn)) ? `${String(100/((zoomsize+zoomsize/4)/100))}vh` 
                      : (expandbutton && (powerd)) ? `${String(78/((zoomsize+zoomsize/4+(zoomsize/100)**8)/100))}vh`
                      : ((expandbutton && (powersk || powerskn)) && `${String(80/((zoomsize+zoomsize/4+(zoomsize/100)**8)/100))}vh`)
                    }} 
                    className={`${(!powerd && !powersk && !powerskn) && "table-container-shift-f"} ${(powersk || powerskn) && "table-container-shift-skill-f"} ${(powerd && "table-container-shift-day-f")}`}>
                    <TableEdit
                      columns={FilteredColumns(fstate.columns)}
                      data={fstate.data}
                      skipReset={fstate.skipReset}
                      dispatch={fdispatch}
                      filtervalue={[team,skill]}
                      Byoutou={Byoutou}
                      thisyear={thisyear}
                      nextmonth={nextmonth}
                      noncolor={!powercon && "r"}
                      noselect={!editable && "n"}
                      staffdata={powercon && sstate.data}
                      predata={powercon && pstate.data}
                      cellvalue={selectedValue}
                      days={days}
                      localname={'Edit-' + Byoutou  + thisyear + '-' + nextmonth}
                      deleterowvalue={cellState.bl}
                      pasterowvalue={cellState.bl}
                      handledeleterow={handleCellDataUpdate}
                      handlepasterow={handleCellDataUpdatePaste}
                      />
                  </div>
                  </ScrollSyncPane>

                  {powerd && ( //日ごと集計
                    <div style={{borderTop: '2px solid gray'}}>
                      <ScrollSyncPane group="h">
                        <div className="hide-scroll" style={{overflowX: "scroll"}}>
                          <TableUneditable
                            columns={FilteredColumns(caldaycolumns)}
                            data={JSON.parse(JSON.stringify(shift))}
                            skipReset={cstate.skipReset}
                            dispatch={cdispatch}
                            noheader={"n"}
                            />
                        </div>
                        </ScrollSyncPane>
                      </div>
                    )}

                  {powersk && ( //日勤スキル集計
                    <div style={{borderTop: '2px solid gray'}}>
                    日勤スキル集計
                      <ScrollSyncPane group="h">
                      <div className="hide-scroll" style={{overflowX: "scroll"}}>
                          <TableUneditable
                            columns={FilteredColumns(skillcolumns)}
                            data={JSON.parse(JSON.stringify(skillnikkin))}
                            skipReset={sknstate.skipReset}
                            dispatch={skndispatch}
                            noheader={"n"}
                            />
                        </div>
                        </ScrollSyncPane>
                      </div>
                    )}
                    
                    {powerskn && ( //夜勤スキル集計
                    <div style={{borderTop: '2px solid gray'}}>
                      夜勤スキル集計
                      <ScrollSyncPane group="h">
                      <div className="hide-scroll" style={{overflowX: "scroll"}}>
                          <TableUneditable
                            columns={FilteredColumns(jskillcolumns)}
                            data={JSON.parse(JSON.stringify(skillnight))}
                            skipReset={skjstate.skipReset}
                            dispatch={skjdispatch}
                            noheader={"n"}
                            />
                        </div>
                        </ScrollSyncPane>
                      </div>
                    )}
                </div>         

                {powers && ( //スタッフ集計
                  <div style={{borderRight: '2px solid gray', borderBottom: '2px solid gray'}}>
                    <ScrollSyncPane group="h">
                    <div 
                    style={{
                      height: 
                      (!expandbutton && !(powerd || powersk || powerskn)) ? `${String(106/((zoomsize+zoomsize/4)/100))}vh` 
                      : (!expandbutton && (powerd || powersk || powerskn)) ? `${String(84.5/((zoomsize+zoomsize/4)/100))}vh` 
                      : (expandbutton && !(powerd || powersk || powerskn)) ? `${String(100/((zoomsize+zoomsize/4)/100))}vh` 
                      : (expandbutton && (powerd)) ? `${String(78/((zoomsize+zoomsize/4+(zoomsize/100)**8)/100))}vh`
                      : ((expandbutton && (powersk || powerskn)) && `${String(80/((zoomsize+zoomsize/4+(zoomsize/100)**8)/100))}vh`)
                    }} 
                    className={`${(!powerd && !powersk && !powerskn) && "table-container-sub"} ${(powersk || powerskn) && "table-container-sub-skill"}  ${(powerd) && "table-container-sub-day"}`}>
                      <TableEdit
                        columns={rightdaycolumns}
                        data={JSON.parse(JSON.stringify(shukei))}
                        skipReset={rstate.skipReset}
                        dispatch={rdispatch}
                        filtervalue={[team,skill]}
                        Byoutou={Byoutou}
                        noncolor={"n"}
                      />
                    </div>
                    </ScrollSyncPane>
                    {(powerd || powersk || powerskn) && ( 
                    <div style={{borderTop: '2px solid gray'}}>
                    </div>
                    )}
                  </div>
                )}
                
              </div>
            </div>
            </ScrollSync>
            }
          </div>

        {tableselect && <div className='overlay' onClick={() => setTTableselect(false)} />}
        {tableselect && (
          <div
            className='shadow-5 bg-white border-radius-md'
            style={{
              zIndex: 10,
              minWidth: 500,
              padding: "0.75rem",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              paddingBottom: '0.5rem',
              
            }}>
              <div style={{fontSize: 12}}>
                作成された勤務表または過去に保存した勤務表を修正画面に反映できます。リセットボタンを押すと現在入力されている全てのシフトが削除されます。過去に保存した勤務表は削除されません。
              </div>
              <div style={{display:'flex', justifyContent:'center', overflowY: 'scroll', maxHeight: '300px'}}>

                    <table className="styled-table" >
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>バージョン</th>
                          <th>種類</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {storagedata.map((row) => (
                          <tr key={row.id}>
                            <td>{row.id}</td>
                            <td>{row.time}</td>
                            <td>{row.type}</td>
                            <td>
                              <button onClick={() => handleSelect(row.data)}>選択</button>
                            </td>
                            <td>
                              <button onClick={() => handleSelectDelete(row.id)}>削除</button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  
              </div>
              <div style={{paddingTop: '0.5rem', display: 'flex', justifyContent: 'flex-end'}}>
              <Button sx={{ border: '1px solid gray' }} size="small" variant="contained" color="error" onClick={handleRemoveall} style={{ zoom: '80%'}}>
                  履歴全削除
              </Button>
              <Button sx={{ border: '1px solid gray' }} size="small" variant="contained" color="error" onClick={handleRemove} style={{ zoom: '80%'}}>
                  リセット
              </Button>
              </div>
            </div>
        )}

        {helpopen && <div className='overlay' onClick={() => setHelpopen(false)} />}
        {helpopen && (
          <div
            className='shadow-5 bg-white border-radius-md'
            style={{
              zIndex: 10,
              width: "400px",
              maxHeight: "500px",
              padding: "0.75rem",
              position: "absolute",
              top: "55%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              paddingBottom: '0.5rem',
            }}>
              ショートカット一覧
              <div style={{fontSize: '14px', paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
                <div>
                  <strong>「Ctrl + s」</strong>：データを履歴に保存
                </div>
                <div>
                  <strong>「Ctrl + z」</strong>：アンドゥ
                </div>
                <div>
                  <strong>「Ctrl + y」</strong>：リドゥ
                </div>
                <div>
                  <strong>「セル選択 + Ctrl + c」</strong>：セルの値をコピー
                </div>
                <div>
                  <strong>「セル選択 + Ctrl + v」</strong>：コピーした値を貼り付け
                </div>
                <div>
                  <strong>「セル選択 + Ctrl + x」</strong>：セルの値を切り取り
                  </div>
                <div>
                  <strong>「セル選択 + Ctrl + d」</strong>：セルの値を削除
                  </div>
                <div>
                  <strong>「セル選択 + Ctrl + q」</strong>：選択されたシフト記号を入力
                </div>
                <div>
                  <strong>「セル選択 + Ctrl + Alt + v」</strong>：行全ての要素に複製
                </div>
                <div>
                  <strong>「セル選択 + Ctrl + Alt + d」</strong>：行全ての要素を削除
                </div>
                <div>
                  <strong>「Ctrl + "+"」</strong>：表画面拡大
                </div>
                <div>
                  <strong>「Ctrl + "-"」</strong>：表画面縮小
                </div>
              </div>
            </div>)}

        {powershift && <div className='overlay' onClick={() => setPowershift(false)} />}
        {powershift && (
          <div
            className='shadow-5 bg-white border-radius-md'
            style={{
              zIndex: 10,
              minWidth: "90vh",
              maxWidth: "90vw",
              maxHeight: "80vh",
              padding: "0.75rem",
              position: "absolute",
              top: "55%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              paddingBottom: '0.5rem',
            }}>
              
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{paddingBottom: '1rem'}}>
                <Button onMouseEnter={()=>setModalOpenselect(true)} onMouseLeave={()=>setModalOpenselect(false)} sx={{ border: '1px solid gray' }} size="small" variant="contained" color="inherit">
                  フィルター
                </Button>
                {modalOpenselect && 
                <div
                      className='shadow-5 bg-white border-radius-md'
                      style={{
                        zIndex: 15,
                        minWidth: 500,
                        maxWidth: 500,
                        height: "65vh",
                        position: "absolute",
                        padding: '2rem',
                      }}
                      onMouseEnter={()=>setModalOpenselect(true)}
                      onMouseLeave={()=>setModalOpenselect(false)}>
                <div style={{zIndex:5, zoom: '80%', paddingBottom: '0.5rem'}}>
                シフト
                <Paper>
                <DropdownShift/>
                </Paper>
                </div>
                <div style={{zIndex:5, zoom: '80%', paddingBottom: '0.5rem'}}>
                  日にち
                <Paper>
                <DropdownDay2/>
                </Paper>
                </div>
                <div style={{zIndex:5, zoom: '80%', paddingBottom: '0.5rem'}}>
                  曜日
                <Paper>
                <DropdownDays2/> 
                </Paper>
                </div>
                <div style={{zIndex:5, zoom: '80%'}}>
                  平日・土日・祝日
                <Paper>
                <DropdownHDays/> 
                </Paper>
                </div>
                </div>}
                
              </div>
              <div onClick={()=>setPowershift(false)} style={{display: 'flex', backgroundColor: '#DC143C', color: "white", fontWeight: 'bold', justifyContent: 'center', fontSize: '15px', border: '1px solid gray', borderRadius: 1, cursor: 'pointer', height: '25px', width: '25px', padding: '0.1rem'}}>
                  ✕
              </div>
              </div>

          <div style={{zoom: "80%", overflowY: 'scroll', overflowX: 'auto', border: '1px solid black', height: '80vh'}}>
            {dayoptions.map((dayoption,d)=>{
              if (day2.includes(dayoption.value) && ddays2.includes(whatday[d]) && ((hddays.includes("平日") && ["月", "火", "水", "木", "金"].includes(whatday[d]) && !holiday.includes(d+1)) || (hddays.includes("土日") && ["土", "日"].includes(whatday[d])) || (hddays.includes("祝日") && holiday.includes(d+1)))){
                let todayshiftvalue = []
                todayshift.forEach((data,i)=>{
                  if (data["d" + dayoption.label]!=="" && allowshift.map(shift=>(shift.label)).includes(data["d" + dayoption.label])){
                    let val = {}
                    val["ID"] = data.ID
                    val["shift"] = data["d" + dayoption.label]
                    val["skill"] = staffdata[i].skill
                    todayshiftvalue.push(val)
                  }
                })
                let todaysshift = {}
                shiftoptions.forEach((data)=>{
                  todaysshift[data.label] = ""
                  todayshiftvalue.forEach((value)=>{
                    if (data.label==value.shift){
                      todaysshift[data.label] = todaysshift[data.label] + value.skill + '-' + value.ID.replace(/[0-9\s]/g, '') + ', '
                    }
                  })
                })
                return (
                  <div style={{display: 'flex'}}>
                    <div style={{backgroundColor: weekend.includes(String(d+1)) ? "#FFE4E1" : "#F0FFF0", minWidth: '30px', maxWidth: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 0.25rem 0 0.25rem', borderRight: '1px solid black', borderBottom: '1px solid black'}}>
                      {dayoption.label}
                    </div>
                    <div style={{backgroundColor: weekend.includes(String(d+1)) ? "#FFE4E1" : "#F0FFF0", minWidth: '30px', maxWidth: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 0.25rem 0 0.25rem', borderRight: '1px solid black', borderBottom: '1px solid black'}}>
                      {whatday[d]}
                    </div>
                    <div style={{width: '100%', borderRight: '1px solid black'}}>
                      {Object.keys(todaysshift).map((key, k)=>{
                        if (todaysshift[key]!=="" && key!=="公" && shifttype.includes(key)){
                          const stafflist = todaysshift[key].slice(0,-2).split(',')
                          const customOrder = skilloptions.map(option=>(option.label))
                          const extractKey = (str) => {
                            const match = str.match(/([A-Za-z]+)/);
                            return match ? match[0] : '';
                          };
                          const sortedData = [...stafflist].sort((a, b) => {
                            const aKey = extractKey(a);
                            const bKey = extractKey(b);
                      
                            return customOrder.indexOf(aKey) - customOrder.indexOf(bKey);
                          });
                          return(
                            <div style={{display: 'flex', borderBottom: '1px solid black'}}>
                              <div style={{backgroundColor: "#DCDCDC", minWidth: '50px', maxWidth: '50px', display: 'flex', justifyContent: 'center', borderRight: '1px solid black', alignItems: 'center'}}>
                                {key}
                              </div>
                              <div style={{display: 'flex'}}>
                                {sortedData.map((shift, s)=>(
                                      <div style={{width: '100px', borderRight: '1px solid black'}}>
                                        <div style={{backgroundColor: getBackgroundColorSkill(shift.split('-')[0].replace(' ','')), borderBottom: '1px solid black', display: 'flex', justifyContent: 'center'}}>
                                          {shift.split('-')[0].replace(' ','')}
                                        </div>
                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                          {shift.split('-')[1].replace(' ','')}
                                        </div>
                                      </div>
                                ))}
                              </div>
                            </div>
                          )
                        }
                      })}
                    </div>
                  </div>
                )
              }
            })}

          </div>

            </div>
        )}

        <div style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#DCDCDC', position: 'fixed', bottom: '0', width: '100vw', marginLeft: '-0.5rem', height: '20px'}}>
          <div style={{display: 'flex', paddingLeft: '0.5rem', fontWeight: 'bold', fontSize: '13px'}}>
            <div onClick={()=>handlemonthchange(Byoutou, preyear, thismonth)} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
              <ArrowLeftOutlinedIcon/>
            </div>
            <div style={{display: 'flex', alignItems: 'center', width: '80px', justifyContent: 'center'}}>
              {thisyear}年{nextmonth}月
            </div>
            <div onClick={()=>handlemonthchange(Byoutou, nextyear, nextnextmonth)} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
              <ArrowRightOutlinedIcon/>
            </div>
          </div>
          <div style={{paddingRight: '0.25rem', display: 'flex'}}>
            <div style={{alignItems: 'center', display: 'flex', cursor: 'pointer'}} onClick={()=>setZoom(zoomsize==50 ? zoomsize : pre=>pre-5)}>
              <RemoveOutlinedIcon style={{fontSize: '20px'}}/>
            </div>
            <div style={{alignItems: 'center', display: 'flex'}}>
              <Slider value={zoomsize} onValueChange={handleZoomChange}/>
            </div>
            <div style={{alignItems: 'center', display: 'flex', cursor: 'pointer'}} onClick={()=>setZoom(zoomsize==150 ? zoomsize : pre=>pre+5)}>
              <AddOutlinedIcon style={{fontSize: '20px'}}/>
            </div>
            <div style={{alignItems: 'center', display: 'flex',paddingLeft: '0.3rem', fontSize: '14px', width: '20px', paddingRight: '4rem'}}>
              {zoomsize}%
            </div>
          </div>
        </div>
        
      </div>
        );
      }

    return (
      <div className="excel-table-container">
        <div className="foo">
        </div>
        <div>
          <TableShow/>
        </div>
      </div>
    );
  }
}

export default ExcelRequestsImport;