import React, {useCallback} from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import SetAppbar from "./TopPage2";
import { registerLocale } from "react-datepicker";
import interactionPlugin from '@fullcalendar/interaction'
import ja from 'date-fns/locale/ja'

const mdTheme = createTheme();

registerLocale('ja', ja)

if (localStorage.getItem("c1")!=2){
  localStorage.setItem("c1", 1)
  let events =[{id: "",
      title: 'event sample',
      start: "2000-11-17",
      end: "2000-11-17",
      allDay: true}]
  localStorage.setItem("Calendar", JSON.stringify(events))
}

//let events =[]// localStorage.getItem("Calendar")

function DashboardContent() {
  if (sessionStorage.getItem("Login")==="1"){
    sessionStorage.setItem("Login", "1")
  }
  
  if (localStorage.getItem("c1")==1){
    localStorage.setItem("c1", 2)
  }
  
  let events = (localStorage.getItem("c1")==1) ? [{id: "",
      title: 'event sample',
      start: "2000-11-17",
      end: "2000-11-17",
      allDay: true}]
      : JSON.parse(localStorage.getItem("Calendar"))

  const handleSelect = useCallback((selectinfo) => {
    const startstring = selectinfo.startStr.replace('-', '/').replace('-', '/').replace('T', ' ').replace(':00', '')
    const endstring = selectinfo.endStr.replace('-', '/').replace('-', '/').replace('T', ' ').replace(':00', '')
    let title = prompt(startstring + " - " +  endstring.slice(0,-2) + String(Number(endstring.slice(-2))-1) + "\nイベントのタイトルを入力してください。")?.trim();
    let calendarApi = selectinfo.view.calendar;
    calendarApi.unselect();
    
    if (title) {
      let event = {
        allDay: selectinfo.allDay,
        title: title,
        start: selectinfo.startStr,
        end: selectinfo.endStr,
        id: title + selectinfo.startStr,
      }
      calendarApi.addEvent(event);
      
      events.push(event)
      localStorage.setItem("Calendar", JSON.stringify(events))
  }

  }, [])


  const handleEventClick = useCallback((clickInfo) => {
    if (
      window.confirm(`イベント「${clickInfo.event.title}」を削除しますか`)
    ) {
      clickInfo.event.remove();
      for (let i = 0; i < events.length; i++){
        if (events[i].id==clickInfo.event.id){
              events.splice(i, 1)
        }
      }
      localStorage.setItem("Calendar", JSON.stringify(events))
    }
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <SetAppbar pagename={"カレンダー"}/>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 2,
            height: '100vh',
            overflow: 'auto',
            marginTop: '40px' 
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
              <Grid item xs={12} md={8} lg={8}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '550px',
                  }}
                >

                <div>
                <FullCalendar
                  allDaySlot={false}
                  contentHeight={'450px'}
                  locale="ja"
                  plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]} 
                  initialView="dayGridMonth" 
                  slotDuration="00:30:00"
                  selectable={true} 
                  businessHours={{ 
                    daysOfWeek: [1, 2, 3, 4, 5], 
                    startTime: '00:00',
                    endTIme: '24:00'
                  }} 
                  weekends={true}
                  titleFormat={{ 
                    year: 'numeric',
                    month: 'short'
                  }}
                  headerToolbar={{ 
                    start: 'title',
                    center: 'prev, next, today',
                    end: 'dayGridMonth,timeGridWeek'
                  }}
                  eventClick={handleEventClick}
                  select={handleSelect}
                  initialEvents={events}
                  editable={true}
                />
                </div>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

const CalendarPage = () => {
    return <DashboardContent />;
  };
  
  export default CalendarPage;