import React, {useMemo} from "react";
import clsx from "clsx";
import {useTable, useFlexLayout, useResizeColumns, useSortBy} from "react-table";
import Cell from "./CellS";
import Header from "./HeaderS";

const defaultColumn = {
  minWidth: 1,
  width: 30,
  maxWidth: 110,
  Cell: Cell,
  Header: Header,
  sortType: "alphanumericFalsyLast"
};

export default function Table({columns, data, dispatch: dataDispatch, skipReset, filtervalue, Byoutou, thisyear, nextmonth, noncolor, noheader}) {
  const sortTypes = useMemo(
    () => ({
      alphanumericFalsyLast(rowA, rowB, columnId, desc) {
        if (!rowA.values[columnId] && !rowB.values[columnId]) {
          return 0;
        }

        if (!rowA.values[columnId]) {
          return desc ? -1 : 1;
        }

        if (!rowB.values[columnId]) {
          return desc ? 1 : -1;
        }

        return isNaN(rowA.values[columnId])
          ? rowA.values[columnId].localeCompare(rowB.values[columnId])
          : rowA.values[columnId] - rowB.values[columnId];
      }
    }),
    []
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable(
    {
      columns,
      data,
      defaultColumn,
      dataDispatch,
      autoResetSortBy: !skipReset,
      autoResetFilters: !skipReset,
      autoResetRowState: !skipReset,
      sortTypes
    },
    useFlexLayout,
    useResizeColumns,
    useSortBy
  );

  function isTableResizing() {
    for (let headerGroup of headerGroups) {
      for (let column of headerGroup.headers) {
        if (column.isResizing) {
          return true;
        }
      }
    }

    return false;
  }

  function SetCellBackgroundColor(){
    const requestData = (localStorage.getItem("Request-"  + Byoutou  + thisyear + '-' + nextmonth)!=null) ? JSON.parse(localStorage.getItem("Request-"  + Byoutou  + thisyear + '-' + nextmonth)) : null
    const white = "#FFFFFF"
    const red = "#FF4D4D"
    const orange = "#FFA500"
    const offshift = ["管", "*", "公", "年", "リ", "外", "婚", "産前", "産後", "育", "病", "積", "忌", "欠", "退", "出"];
    const bgcolor = rows.map((row, staffid) => {
      const objectkeys = Object.keys(row.original)
      if (noncolor=="n"){
        let colors = new Array(objectkeys.length-1).fill(white)
        return colors
      }
      else {
        let colors = [white] // IDのセル
        let message = [""]
        for (let i = 1; i < objectkeys.length; i++){
          let ayer = (row.original[objectkeys[i]]!==undefined) ? row.original[objectkeys[i]] : ""
          let hoy = (row.original[objectkeys[i+1]]!==undefined) ? row.original[objectkeys[i+1]] : ""
          const requestValue = 
            requestData===null ? "" 
            : (requestData[staffid]===null || requestData[staffid]===undefined) ? ""
            : requestData[staffid].hasOwnProperty(objectkeys[i]) ? requestData[staffid][objectkeys[i]] 
            : ""
          let imessage = ""
          if (noncolor!="r" && noncolor!="rr"){
            // 連勤
            if (ayer!="" && ayer!="公" && ayer!="外"  && ayer!="退" && ayer!="育"){
              if (i < objectkeys.length - 5) {
                let pd1 = (row.original[objectkeys[i+2]]!==undefined) ? row.original[objectkeys[i+2]] : ""
                let pd2 = (row.original[objectkeys[i+3]]!==undefined) ? row.original[objectkeys[i+3]] : ""
                let pd3 = (row.original[objectkeys[i+4]]!==undefined) ? row.original[objectkeys[i+4]] : ""
                let pd4 = (row.original[objectkeys[i+5]]!==undefined) ? row.original[objectkeys[i+5]] : ""
                let range5 = [ayer, hoy, pd1, pd2, pd3];
                if (!range5.some(element => element === "")) {
                  let shouldSetColor = true;
                  range5.forEach(element => {
                    if (offshift.some(char => element.includes(char))) {
                      shouldSetColor = false;
                    }
                  })
                  if (shouldSetColor) {
                    colors[i] = red
                    colors[i + 1] = red
                    colors[i + 2] = red
                    colors[i + 3] = red
                    colors[i + 4] = red
                    colors[0] = red
                    imessage = imessage + "5連勤超え\n"
                    message[i] = imessage 
                    message[i+1] = imessage
                    message[i+2] = imessage
                    message[i+3] = imessage 
                    message[i+4] = imessage 
                    if (pd4=="*"){
                      colors[i + 5] = red
                      message[i+5] = imessage 
                    }
                  }
                }
              }
            }
            // 夜勤連勤
            if (Byoutou=="ICU"){
              const yakin = ["5/", "*"]
              if (ayer.includes("5/") || (i==1 && ayer.includes("*"))){
                if (i < objectkeys.length - 10) {
                  let pd1 = (row.original[objectkeys[i+2]]!==undefined) ? row.original[objectkeys[i+2]] : ""
                  let pd2 = (row.original[objectkeys[i+3]]!==undefined) ? row.original[objectkeys[i+3]] : ""
                  let pd3 = (row.original[objectkeys[i+4]]!==undefined) ? row.original[objectkeys[i+4]] : ""
                  let pd4 = (row.original[objectkeys[i+5]]!==undefined) ? row.original[objectkeys[i+5]] : ""
                  let pd5 = (row.original[objectkeys[i+6]]!==undefined) ? row.original[objectkeys[i+6]] : ""
                  let pd6 = (row.original[objectkeys[i+7]]!==undefined) ? row.original[objectkeys[i+7]] : ""
                  let pd7 = (row.original[objectkeys[i+8]]!==undefined) ? row.original[objectkeys[i+8]] : ""
                  let pd8 = (row.original[objectkeys[i+9]]!==undefined) ? row.original[objectkeys[i+9]] : ""
                  let range10 = [ayer, hoy, pd1, pd2, pd3, pd4, pd5, pd6, pd7, pd8]
                  const yakinCount = yakin.reduce((count, element) => {
                    return count + range10.filter(item => item.includes(element)).length;
                  }, 0);
              
                  if (yakinCount >= 7) {
                    if (ayer.includes("5/")){
                      colors[i] = red
                      colors[i + 1] = red
                      colors[i + 2] = red
                      colors[i + 3] = red
                      colors[i + 4] = red
                      colors[i + 5] = red
                      colors[i + 6] = red
                      colors[i + 7] = red
                      colors[i + 8] = red
                      colors[i + 9] = red
                      colors[i + 10] = red
                      colors[0] = red
                      imessage = imessage + "夜勤連続\n"
                      message[i] = imessage
                      message[i+1] = imessage 
                      message[i+2] = imessage 
                      message[i+3] = imessage 
                      message[i+4] = imessage 
                      message[i+5] = imessage 
                      message[i+6] = imessage 
                      message[i+7] = imessage 
                      message[i+8] = imessage 
                      message[i+9] = imessage 
                      message[i+10] = imessage 
                    }
                    else if (ayer=="*"){
                      colors[i] = red
                      colors[i + 1] = red
                      colors[i + 2] = red
                      colors[i + 3] = red
                      colors[i + 4] = red
                      colors[i + 5] = red
                      colors[i + 6] = red
                      colors[i + 7] = red
                      colors[i + 8] = red
                      colors[i + 9] = red
                      colors[0] = red
                      imessage = imessage + "夜勤連続\n"
                      message[i] = imessage
                      message[i+1] = imessage 
                      message[i+2] = imessage 
                      message[i+3] = imessage 
                      message[i+4] = imessage 
                      message[i+5] = imessage 
                      message[i+6] = imessage 
                      message[i+7] = imessage 
                      message[i+8] = imessage 
                      message[i+9] = imessage 
                    }
                  }
                }
              }
            }
            else {
              const yakin = ["長1", "長2", "1/", "2/", "*"]
              if (i < objectkeys.length - 13) {
                let pd1 = (row.original[objectkeys[i+2]]!==undefined) ? row.original[objectkeys[i+2]] : ""
                let pd2 = (row.original[objectkeys[i+3]]!==undefined) ? row.original[objectkeys[i+3]] : ""
                let pd3 = (row.original[objectkeys[i+4]]!==undefined) ? row.original[objectkeys[i+4]] : ""
                let pd4 = (row.original[objectkeys[i+5]]!==undefined) ? row.original[objectkeys[i+5]] : ""
                let pd5 = (row.original[objectkeys[i+6]]!==undefined) ? row.original[objectkeys[i+6]] : ""
                let pd6 = (row.original[objectkeys[i+7]]!==undefined) ? row.original[objectkeys[i+7]] : ""
                let pd7 = (row.original[objectkeys[i+8]]!==undefined) ? row.original[objectkeys[i+8]] : ""
                let pd8 = (row.original[objectkeys[i+9]]!==undefined) ? row.original[objectkeys[i+9]] : ""
                let pd9 = (row.original[objectkeys[i+10]]!==undefined) ? row.original[objectkeys[i+10]] : ""
                let pd10 = (row.original[objectkeys[i+11]]!==undefined) ? row.original[objectkeys[i+11]] : ""
                let pd11 = (row.original[objectkeys[i+12]]!==undefined) ? row.original[objectkeys[i+12]] : ""
                let range13 = [ayer, hoy, pd1, pd2, pd3, pd4, pd5, pd6, pd7, pd8, pd9, pd10, pd11]
                const yakinCount = yakin.reduce((count, element) => {
                  return count + range13.filter(item => item.includes(element)).length;
                }, 0);
            
                if (yakinCount >= 10) {
                  if (ayer.includes("長1") || ayer.includes("長2")){
                    colors[i] = red
                    colors[i + 1] = red
                    colors[i + 2] = red
                    colors[i + 3] = red
                    colors[i + 4] = red
                    colors[i + 5] = red
                    colors[i + 6] = red
                    colors[i + 7] = red
                    colors[i + 8] = red
                    colors[i + 9] = red
                    colors[i + 10] = red
                    colors[i + 11] = red
                    colors[0] = red
                    imessage = imessage + "夜勤連続\n"
                    message[i] = imessage
                    message[i+1] = imessage 
                    message[i+2] = imessage 
                    message[i+3] = imessage 
                    message[i+4] = imessage 
                    message[i+5] = imessage 
                    message[i+6] = imessage 
                    message[i+7] = imessage 
                    message[i+8] = imessage 
                    message[i+9] = imessage 
                  }
                  else if (ayer=="*"){
                    colors[i] = red
                    colors[i + 1] = red
                    colors[i + 2] = red
                    colors[i + 3] = red
                    colors[i + 4] = red
                    colors[i + 5] = red
                    colors[i + 6] = red
                    colors[i + 7] = red
                    colors[i + 8] = red
                    colors[i + 9] = red
                    colors[i + 10] = red
                    colors[i + 11] = red
                    colors[i + 12] = red
                    colors[0] = red
                    imessage = imessage + "夜勤連続\n"
                    message[i] = imessage
                    message[i+1] = imessage 
                    message[i+2] = imessage 
                    message[i+3] = imessage 
                    message[i+4] = imessage 
                    message[i+5] = imessage 
                    message[i+6] = imessage 
                    message[i+7] = imessage 
                    message[i+8] = imessage 
                    message[i+9] = imessage 
                    message[i+10] = imessage
                    message[i+11] = imessage 
                    message[i+12] = imessage 
                  }
                }
              }
            }
            if (i < objectkeys.length-1){
              // ロングー準夜
              if (Byoutou!="ICU" && Byoutou!="ER" && (!requestValue.includes("禁") || (noncolor!="r" && requestValue.includes("禁"))) && (requestValue!="日・長" || (noncolor!="r" && requestValue=="日・長" && ayer.includes("長"))) && (( ayer.includes("長") && !(hoy.includes("5/") || hoy.includes("1/") || hoy.includes("2/"))) || (!ayer.includes("長") && (hoy.includes("5/") || hoy.includes("1/") || hoy.includes("2/"))))){
                colors[i] = red
                colors[i+1] = red
                colors[0] = red
                imessage = imessage + "長ー準夜\n"
                message[i] = imessage
                message[i+1] = imessage
              } 
              // 準夜ー深夜
              if (Byoutou!="ER" && ((!requestValue.includes("禁") || (noncolor!="r" && requestValue.includes("禁"))) && (((ayer.includes("5/") || ayer.includes("1/") || ayer.includes("2/")) && !(hoy=="*")) || (!(ayer.includes("5/") || ayer.includes("1/") || ayer.includes("2/")) && (hoy=="*"))))){
                colors[i] = red
                colors[i+1] = red
                colors[0] = red
                imessage = imessage + "準夜ー深夜\n"
                message[i] = imessage 
                message[i+1] = imessage 
              } 
              // 深夜ー公休、遅番ー公休
              if (Byoutou=="C3"){
                if ((!requestValue.includes("禁") || (noncolor!="r" && requestValue.includes("禁"))) && (((ayer.includes("遅")) && !(hoy.includes("公") || hoy.includes("年") || hoy.includes("リ") || hoy.includes("外") || hoy.includes("婚") || hoy.includes("産前") || hoy.includes("産後") || hoy.includes("育") || hoy.includes("病") || hoy.includes("積") || hoy.includes("忌") || hoy.includes("欠") || hoy.includes("退") || hoy.includes("〇/") || hoy.includes("/〇"))) || ((ayer.includes("遅")) && (hoy.includes("遅"))))){
                  colors[i] = red
                  colors[i+1] = red
                  colors[0] = red
                  imessage = imessage + "遅番ー公休\n"
                  message[i] = imessage
                  message[i+1] = imessage
                }
              } 
              if (Byoutou!="ER" &&  ((!requestValue.includes("禁") || (noncolor!="r" && requestValue.includes("禁"))) && !requestValue.includes("休・深夜") && (((ayer.includes("*")) && !(hoy.includes("公") || hoy.includes("年") || hoy.includes("リ") || hoy.includes("外") || hoy.includes("婚") || hoy.includes("産前") || hoy.includes("産後") || hoy.includes("育") || hoy.includes("病") || hoy.includes("積") || hoy.includes("忌") || hoy.includes("欠") || hoy.includes("退") || hoy.includes("〇/") || hoy.includes("/〇"))) || ((ayer.includes("*")) && (hoy.includes("*")) )))){
                  colors[i] = red
                  colors[i+1] = red
                  colors[0] = red
                  imessage = imessage + "深夜ー公休\n"
                  message[i] = imessage
                  message[i+1] = imessage
              }
            }
          }
          // 勤務希望
          if (requestValue!="" && noncolor!="req" && noncolor!="rr"){
            if (noncolor!="r" && ((!ayer.includes(requestValue) && (requestValue!="日・長" && requestValue!="禁日" && requestValue!="禁長" && requestValue!="禁準" && requestValue!="禁深" && requestValue!="休・深夜")) 
              || (requestValue=="〇" && !(ayer.includes("〇") || ayer.includes("L")))
              || ((requestValue=="日・長" && !(ayer.includes("〇") || ayer.includes("L") || ayer.includes("長")))
              || (requestValue=="禁日" && (ayer.includes("〇") || ayer.includes("L") || ayer=="")) 
              || (requestValue=="禁長" && (ayer.includes("長") || ayer=="")) 
              || (requestValue=="禁準" && (ayer.includes("5/") || ayer.includes("1/") || ayer.includes("2/") || ayer=="")) 
              || (requestValue=="禁深" && (ayer=="*" || ayer=="")) 
              || (requestValue=="休・深夜" && (ayer!="*" && ayer!="公" && ayer!="年" && ayer!="リ" && ayer!="外" && ayer!="婚" && ayer!="産前" && ayer!="産後" && ayer!="育" && ayer!="病" && ayer!="積" && ayer!="忌" && ayer!="欠" && ayer!="退" || ayer=="")))))
            {
              colors[i] = red
              colors[0] = red
              imessage = imessage + "勤務希望違反\n"
              message[i] = imessage 
            }else {
              colors[i] = orange
              message[i] = "希望：" + requestValue}
            }
          
        }
        return [colors, message]
      }
    })
    return bgcolor
  }


  const bgcolor = SetCellBackgroundColor()

  return (
    <>
    {noheader!=="n" &&
    <div {...getTableProps()} className={clsx("table", isTableResizing() && "noselect")}>
        <div>
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className='tr'>
              {headerGroup.headers.map((column) => column.render("Header", {
                thisyear,
                nextmonth
              }))}
            </div>
          ))}
        </div>
      </div>
      }
      <div {...getTableProps()} className={clsx("table", isTableResizing() && "noselect")}>
        <div {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className='tr'>
                {row.cells.map((cell, j) => (
                  <div {...cell.getCellProps()} className='td'>
                    {cell.render("Cell", {
                      bgcolor: bgcolor[i][0][j]
                    })}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
