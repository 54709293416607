import { setcol } from "../../../Table/colors";
import React, { useRef, useEffect, useReducer, useState, useCallback, ref } from "react";
import "../../../styles.css";
import Table from "../../../Table/TableS";
import { randomColor, shortId } from "../../../Table/utils";
import Button from '@mui/material/Button';
import Select from 'react-select'
import {usePopper} from "react-popper";
import Relationship from "../../../Table/Relationship";
import { ExcelRenderer } from "react-excel-renderer";
import { CSVLink } from "react-csv";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Papa from 'papaparse';

class ExcelRequestsImport extends React.Component {
  state = {
    cols: [],
    rows: []
  };

  constructor(props) {
    super(props);
    this.state = {
      keyvalue: { ward: '', year: '', month: '' },
    };
  }

  componentDidMount() {
    const localvalue = JSON.parse(localStorage.getItem('Staff-key'));
    this.setState({ keyvalue: localvalue });
  }

  render() {
    if (sessionStorage.getItem("Login")==="1"){
      sessionStorage.setItem("Login", "1")
    }    
    let Byoutou = localStorage.getItem("Byoutou")
    let byoutou = Byoutou.toLocaleLowerCase()

    const JapaneseHolidays = require('japanese-holidays');

    const thisyear = this.state.keyvalue.year
    const nextmonth = this.state.keyvalue.month
    const preyear = (nextmonth==1) ? thisyear-1 : thisyear
    const thismonth = (nextmonth=="1") ? "12" : nextmonth - 1
    const thismonth0 = thismonth.length==1 ? "0" + thismonth : thismonth

    var date = new Date();
    var lastDate = new Date(date.getFullYear(), date.getMonth()+2, 0);
    var days = lastDate.getDate()
    const isholiday = JapaneseHolidays.getHolidaysOf(lastDate.getFullYear(), true);
    const thisday = String(date.getDate()).length==1 ? "0" + String(date.getDate()) : date.getDate()

    const holiday = []
    for (let i = 0; i < isholiday.length; i++){
      if (isholiday[i].month===lastDate.getMonth()+1){
        holiday.push(isholiday[i].date)
      }
    }

    const weekend = []
    for (let i = 1; i <= days; i++){
      var day = new Date(date.getFullYear(), date.getMonth()+2, i)
      if ([0, 6].includes(day.getDay())){
        weekend.push(i)
      }
      if (holiday.includes(i)){
        if (!weekend.includes(i)){
          weekend.push(i)
        }
      }
    }

    if (localStorage.getItem('s1'+byoutou)!=2){
      localStorage.setItem('s1'+byoutou, 1)
    }

    const shiftdata = (JSON.parse(localStorage.getItem("Shift-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Shift-"+Byoutou)) : []
    const teamdata = (JSON.parse(localStorage.getItem("Team-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Team-"+Byoutou)) : []
    const skillleveldata = (JSON.parse(localStorage.getItem("Skill-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Skill-"+Byoutou)) : []

    let shiftoptions = [];
    let allowshift = [];

    shiftdata.forEach((data,i) => {
      if (data.shift=="管"){
        allowshift.push({label: data.shift, backgroundColor: data.color, type: data.type})
      }
      if ((data.shift=="〇" || data.shift.includes("/") || data.shift.includes("長")) && (data.type=="日勤系" || data.type=="準夜勤系" || data.type=="ロング系")){
        if (teamdata.length>0){
          for (const t in teamdata){
          shiftoptions.push({label: data.shift + teamdata[t].team, backgroundColor: data.color, type: data.type, team: teamdata[t].team})
          if (!data.shift.includes("専")){
            allowshift.push({label: data.shift + teamdata[t].team, backgroundColor: data.color, type: data.type})
          }        
        }} else {
          shiftoptions.push({label: data.shift, backgroundColor: data.color, type: data.type})
          allowshift.push({label: data.shift, backgroundColor: data.color, type: data.type})
      }
      } else {
        if (data.shift=="*" || data.shift=="公"|| data.shift=="遅J" ){
          allowshift.push({label: data.shift, backgroundColor: data.color, type: data.type})
        }   
        if (data.type!=="禁止系" && data.type!=="選択系"){
          shiftoptions.push({ label: data.shift, backgroundColor: data.color, type: data.type});
        }
      }
    })
    const deleteoption = { label: "", backgroundColor: "#FFFFFF", type: "削除"}
    shiftoptions.push(deleteoption);
    
    let teamoptions = teamdata.map((data,i) => {
      return { label: data.team, value: data.team, backgroundColor: data.color };
    })

    let skilloptions = []; 
    skillleveldata.forEach((data,i) => {
      skilloptions.push({ label: data.skill, value: data.skill, backgroundColor: data.color })
    })
    skilloptions.push({label: "", value: "", backgroundColor: setcol(1)})
    teamoptions.push({label: "", value: "", backgroundColor: setcol(1)})

    let checkoptions = [
      {label: "可", backgroundColor: setcol(9)},
      {label: "", backgroundColor: setcol(1)},
    ]

    let sexoptions = [
      {label: "男性", backgroundColor: setcol(9)},
      {label: "女性", backgroundColor: setcol(9)},
      {label: "", backgroundColor: setcol(1)},
    ]

    function useLatest(value) {
      const ref = useRef(value);
      ref.current = value;
      return ref;
    }

    function useKeybind({
      altKey,
      ctrlKey,
      metaKey,
      shiftKey,
      key,
      onKeyDown,
      targetRef,
    }) {
      const onKeyDownLatest = useLatest(onKeyDown);

      useEffect(() => {
        const eventListener = (event) => {
          if (altKey && !event.altKey) return;
          if (ctrlKey && !event.ctrlKey) return;
          if (metaKey && !event.metaKey) return;
          if (shiftKey && !event.shiftKey) return;
          if (event.key !== key) return;

          event.preventDefault();
          onKeyDownLatest.current?.(event);
        };

        if (targetRef?.current) {
          const target = targetRef.current;

          target.addEventListener("keydown", eventListener);
          return () => target.removeEventListener("keydown", eventListener);
        } else {
          window.addEventListener("keydown", eventListener);
          return () => window.removeEventListener("keydown", eventListener);
        }
      }, [altKey, ctrlKey, key, metaKey, onKeyDownLatest, shiftKey, targetRef]);
    }

    function handleRemove(){
      const confirm = window.confirm('スタッフデータを削除しますか？')
        if (confirm) {
          localStorage.removeItem('Staff-' + Byoutou)
          localStorage.removeItem('Staff-Columns' + Byoutou)
          alert('スタッフデータを削除しました')
          window.location.reload()  
        }
      }

    // シフトのデータ作成
    let mdata = [];

    let scolumns 
    = (Byoutou=="ICU")
    ? [  
      {
        id: "No",
        label: "No.",
        accessor: "No",
        width: 1,
        dataType: "number",
        options: []
      },
      {
        id: "ID",
        label: "仮番号",
        accessor: "ID",
        width: 6,
        dataType: "number",
        options: []
      },    
      {
        id: "name",
        label: "姓",
        accessor: "name",
        width: 8,
        dataType: "text",
        options: []
      },    
      {
        id: "skill",
        label: "SKILL",
        accessor: "skill",
        width: 3,
        dataType: "select",
        options: skilloptions
      },  
      {
        id: "hosoku",
        label: "性別",
        accessor: "hosoku",
        width: 1,
        dataType: "select",
        options: sexoptions
      },     
      {
        id: "TEAM",
        label: "TEAM",
        accessor: "TEAM",
        width: 1,
        dataType: "select",
        options: teamoptions
      },      
      {
        id: "all-Lok",
        label: "L",
        accessor: "all-Lok",
        width: 1,
        dataType: "select",
        options: checkoptions
      },    
      {
        id: "5/lowl",
        label: "5/下限",
        accessor: "5/lowl",
        width: 1,
        dataType: "number",
        options: []
      },   
      {
        id: "*lowl",
        label: "*下限",
        accessor: "*lowl",
        width: 1,
        dataType: "number",
        options: []
      },    
      {
        id: "5/upl",
        label: "5/上限",
        accessor: "5/upl",
        width: 1,
        dataType: "number",
        options: []
      }, 
      {
        id: "*upl",
        label: "*上限",
        accessor: "*upl",
        width: 1,
        dataType: "number",
        options: []
      },   

    ]
    : (Byoutou=="W2")
    ? [  
      {
        id: "No",
        label: "No.",
        accessor: "No",
        width: 1,
        dataType: "number",
        options: []
      },
      {
        id: "ID",
        label: "仮番号",
        accessor: "ID",
        width: 6,
        dataType: "number",
        options: []
      },    
      {
        id: "name",
        label: "姓",
        accessor: "name",
        width: 8,
        dataType: "text",
        options: []
      },    
      {
        id: "skill",
        label: "SKILL",
        accessor: "skill",
        width: 3,
        dataType: "select",
        options: skilloptions
      },  
      {
        id: "hosoku",
        label: "性別",
        accessor: "hosoku",
        width: 1,
        dataType: "select",
        options: sexoptions
      },   
      {
        id: "TEAM",
        label: "TEAM",
        accessor: "TEAM",
        width: 1,
        dataType: "select",
        options: teamoptions
      },      
      {
        id: "長2・/def",
        label: "長2・/差",
        accessor: "長2・2/",
        width: 6,
        dataType: "number",
        options: []
      },    
      {
        id: "2/lowl",
        label: "2/下限",
        accessor: "2/lowl",
        width: 1,
        dataType: "number",
        options: []
      },    
      {
        id: "2/upl",
        label: "2/上限",
        accessor: "2/upl",
        width: 1,
        dataType: "number",
        options: []
      },    
    ]
    : (Byoutou=="C5")
    ? [  
      {
        id: "No",
        label: "No.",
        accessor: "No",
        width: 1,
        dataType: "number",
        options: []
      },
      {
        id: "ID",
        label: "仮番号",
        accessor: "ID",
        width: 6,
        dataType: "number",
        options: []
      },    
      {
        id: "name",
        label: "姓",
        accessor: "name",
        width: 8,
        dataType: "text",
        options: []
      },    
      {
        id: "skill",
        label: "SKILL",
        accessor: "skill",
        width: 3,
        dataType: "select",
        options: skilloptions
      },  
      {
        id: "hosoku",
        label: "性別",
        accessor: "hosoku",
        width: 1,
        dataType: "select",
        options: sexoptions
      },    
      {
        id: "TEAM",
        label: "TEAM",
        accessor: "TEAM",
        width: 1,
        dataType: "select",
        options: teamoptions
      },      
      {
        id: "長1・1/def",
        label: "長1・1/差",
        accessor: "長1・1/def",
        width: 6.5,
        dataType: "number",
        options: []
      },    
      {
        id: "all-1/HCUok",
        label: "1/HCU",
        accessor: "all-1/HCUok",
        width: 7,
        dataType: "select",
        options: checkoptions
      },    
      {
        id: "1/lowl",
        label: "1/下限",
        accessor: "1/lowl",
        width: 1,
        dataType: "number",
        options: []
      },    
      {
        id: "1/upl",
        label: "1/上限",
        accessor: "1/upl",
        width: 1,
        dataType: "number",
        options: []
      },    
    ]
    : (Byoutou=="C3")
    ? [  
      {
        id: "No",
        label: "No.",
        accessor: "No",
        width: 1,
        dataType: "number",
        options: []
      },
      {
        id: "ID",
        label: "仮番号",
        accessor: "ID",
        width: 6,
        dataType: "number",
        options: []
      },    
      {
        id: "name",
        label: "姓",
        accessor: "name",
        width: 8,
        dataType: "text",
        options: []
      },    
      {
        id: "skill",
        label: "SKILL",
        accessor: "skill",
        width: 3,
        dataType: "select",
        options: skilloptions
      },  
      {
        id: "hosoku",
        label: "性別",
        accessor: "hosoku",
        width: 1,
        dataType: "select",
        options: sexoptions
      },    
      {
        id: "TEAM",
        label: "TEAM",
        accessor: "TEAM",
        width: 1,
        dataType: "select",
        options: teamoptions
      },      
      {
        id: "長1・1/def",
        label: "長1・1/差",
        accessor: "長1・1/def",
        width: 6.5,
        dataType: "number",
        options: []
      },    
      {
        id: "all-遅Jok",
        label: "遅J",
        accessor: "-遅Jok",
        width: 1,
        dataType: "select",
        options: checkoptions
      },    
      {
        id: "1/lowl",
        label: "1/下限",
        accessor: "1/lowl",
        width: 1,
        dataType: "number",
        options: []
      },    
      {
        id: "1/upl",
        label: "1/上限",
        accessor: "1/upl",
        width: 1,
        dataType: "number",
        options: []
      },     
    ] 
    : (Byoutou=="ER")
    ? [  
      {
        id: "No",
        label: "No.",
        accessor: "No",
        width: 1,
        dataType: "number",
        options: []
      },
      {
        id: "ID",
        label: "仮番号",
        accessor: "ID",
        width: 6,
        dataType: "number",
        options: []
      },    
      {
        id: "name",
        label: "姓",
        accessor: "name",
        width: 8,
        dataType: "text",
        options: []
      },    
      {
        id: "skill",
        label: "SKILL",
        accessor: "skill",
        width: 3,
        dataType: "select",
        options: skilloptions
      },  
      {
        id: "hosoku",
        label: "性別",
        accessor: "hosoku",
        width: 1,
        dataType: "select",
        options: sexoptions
      },    
      {
        id: "TEAM",
        label: "TEAM",
        accessor: "TEAM",
        value: "TEAM",
        width: 1,
        dataType: "select",
        options: teamoptions
      },         
      {
        id: "all-*ok",
        label: "*",
        accessor: "all-*ok",
        width: 1,
        dataType: "select",
        options: checkoptions
      },  
      {
        id: "all-遅Jok",
        label: "遅J",
        accessor: "all-遅Jok",
        width: 1,
        dataType: "select",
        options: checkoptions
      },   
      {
        id: "all-ｶﾃok",
        label: "ｶﾃ",
        accessor: "all-ｶﾃok",
        width: 1,
        dataType: "select",
        options: checkoptions
      },   
      {
        id: "all-IVRok",
        label: "IVR",
        accessor: "all-IVRok",
        width: 1,
        dataType: "select",
        options: checkoptions
      },   
      {
        id: "all-車ok",
        label: "車",
        accessor: "all-車ok",
        width: 1,
        dataType: "select",
        options: checkoptions
      },   
      {
        id: "all-ﾄﾘok",
        label: "ﾄﾘ",
        accessor: "all-ﾄﾘok",
        width: 1,
        dataType: "select",
        options: checkoptions
      },   
      {
        id: "all-RRTok",
        label: "RRT",
        accessor: "all-RRTok",
        width: 1,
        dataType: "select",
        options: checkoptions
      },   
      {
        id: "all-ﾊﾘｰok",
        label: "ﾊﾘｰ",
        accessor: "all-ﾊﾘｰok",
        width: 1,
        dataType: "select",
        options: checkoptions
      },   
      {
        id: "all-WIok",
        label: "WI",
        accessor: "all-WIok",
        width: 1,
        dataType: "select",
        options: checkoptions
      },  
      {
        id: "5/lowl",
        label: "5/下限",
        accessor: "5/lowl",
        width: 1,
        dataType: "number",
        options: []
      },    
      {
        id: "5/upl",
        label: "5/上限",
        accessor: "5/upl",
        width: 1,
        dataType: "number",
        options: []
      },  
      {
        id: "*lowl",
        label: "*下限",
        accessor: "*lowl",
        width: 1,
        dataType: "number",
        options: []
      },    
      {
        id: "*upl",
        label: "*上限",
        accessor: "*upl",
        width: 1,
        dataType: "number",
        options: []
      },     
    ] : [  
      {
        id: "No",
        label: "No.",
        accessor: "No",
        width: 1,
        dataType: "number",
        options: []
      },
      {
        id: "ID",
        label: "仮番号",
        accessor: "ID",
        width: 6,
        dataType: "number",
        options: []
      },    
      {
        id: "name",
        label: "姓",
        accessor: "name",
        width: 8,
        dataType: "text",
        options: []
      },    
      {
        id: "skill",
        label: "SKILL",
        accessor: "skill",
        width: 3,
        dataType: "select",
        options: skilloptions
      },  
      {
        id: "hosoku",
        label: "性別",
        accessor: "hosoku",
        width: 1,
        dataType: "select",
        options: sexoptions
      },    
      {
        id: "TEAM",
        label: "TEAM",
        accessor: "TEAM",
        width: 1,
        dataType: "select",
        options: teamoptions
      },              
    ]
 
    let staffData = localStorage.getItem('Staff-'+Byoutou)==null ? [] : JSON.parse(localStorage.getItem('Staff-'+Byoutou))
    const columndata = JSON.parse(localStorage.getItem('Staff-Columns-'+Byoutou))!==null ? JSON.parse(localStorage.getItem('Staff-Columns-'+Byoutou)) : scolumns
    staffData.forEach((data,i)=>{
      if (data.ID!=="" && data.ID!==null && data.ID!==undefined){
        let row = {}
        columndata.forEach((key,j)=>{
          if (key.id.includes("comb") && (data[key.id]==null || data[key.id]==undefined || data[key.id].length==0)){
            row[key.id] = []
          } else {
            if (data[key.id]==null || data[key.id]==undefined){
              row[key.id] = ""
            } else {
              row[key.id] = data[key.id]
            }
          }
        })
        mdata.push(row);  
      }
    })
    mdata.sort((a, b) => a.No - b.No);

    let staffoptions = []
    staffData.forEach((data,i)=>{
      staffoptions.push({label: data.ID, backgroundColor: "#DDA0DD"})
    })


    let width = 0
    columndata.forEach((col,i)=>{
      if (col.id=="No"){
        width = width + 20
      }
      else if (col.id=="ID"){
        width = width + 150
      }
      else if (col.id=="name"){
        width = width + 200
      }
      else if (col.id=="skill"){
        width = width + 150
      }
      else if (col.id=="hosoku"){
        width = width + 50
      }
      else if (col.id=="TEAM"){
        width = width + 60
      }
      else if (col.label.includes("同時")){
        width = width + 250
      }
      else if (col.dataType=="select" || col.dataType=="text"){
        width = width + 60
      }
    })

    let sscolumns = []
    columndata.forEach(col=>{
      sscolumns.push(
        {
          id: col.id,
          label: col.label,
          accessor: col.accessor,
          width: col.width,
          dataType: col.dataType,
          options: col.id=="skill" ? skilloptions : col.id=="TEAM" ? teamoptions : col.options
        }
      )
    })

    let mmcdata = (localStorage.getItem('s1'+byoutou)==1 || localStorage.getItem('Staff-'+Byoutou)==null) ? {columns: scolumns, data: mdata, skipReset: false} : {columns: sscolumns, data: mdata, skipReset: false}
    
    function reducer(state, action) {
      switch (action.type) {
        case "add_option_to_column":
          const optionIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, optionIndex),
              {
                ...state.columns[optionIndex],
                options: [
                  ...state.columns[optionIndex].options,
                  { label: action.option, backgroundColor: action.backgroundColor }
                ]
              },
              ...state.columns.slice(optionIndex + 1, state.columns.length)
            ]
          };
        case "add_row":
          return {
            ...state,
            skipReset: true,
            data: [...state.data, {}]
          };
        case "delete_row":
          return {
            ...state,
            skipReset: true,
            data: state.data.filter((_, index) => index !== action.rowIndex)
          };
        case "update_column_type":
          const typeIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          switch (action.dataType) {
            case "number":
              if (state.columns[typeIndex].dataType === "number") {
                return state;
              } else {
                return {
                  ...state,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  data: state.data.map((row) => ({
                    ...row,
                    [action.columnId]: isNaN(row[action.columnId])
                      ? ""
                      : Number.parseInt(row[action.columnId])
                  }))
                };
              }
            case "select":
              if (state.columns[typeIndex].dataType === "select") {
                return {
                  ...state,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  skipReset: true
                };
              } else {
                let options = [];
                state.data.forEach((row) => {
                  if (row[action.columnId]) {
                    options.push({
                      label: row[action.columnId],
                      backgroundColor: randomColor()
                    });
                  }
                });
                return {
                  ...state,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    {
                      ...state.columns[typeIndex],
                      dataType: action.dataType,
                      options: [...state.columns[typeIndex].options, ...options]
                    },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  skipReset: true
                };
              }
            case "text":
              if (state.columns[typeIndex].dataType === "text") {
                return state;
              } else if (state.columns[typeIndex].dataType === "select") {
                return {
                  ...state,
                  skipReset: true,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ]
                };
              } else {
                return {
                  ...state,
                  skipReset: true,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  data: state.data.map((row) => ({
                    ...row,
                    [action.columnId]: row[action.columnId] + ""
                  }))
                };
              }
            default:
              return state;
          }
        case "update_column_header":
          const index = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, index),
              { ...state.columns[index], label: action.label },
              ...state.columns.slice(index + 1, state.columns.length)
            ]
          };
        case "update_cell":
          return {
            ...state,
            skipReset: true,
            data: state.data.map((row, index) => {
              if (index === action.rowIndex) {
                return {
                  ...state.data[action.rowIndex],
                  [action.columnId]: action.value
                };
              }
              return row;
            })
          };
        case "add_column_to_left":
          const leftIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          let leftId = shortId();
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, leftIndex),
              {
                id: leftId,
                label: "Column",
                accessor: leftId,
                dataType: "text",
                created: action.focus && true,
                options: []
              },
              ...state.columns.slice(leftIndex, state.columns.length)
            ]
          };
        case "add_column_to_right":
          const rightIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          const rightId = shortId();
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, rightIndex + 1),
              {
                id: rightId,
                label: "Column",
                accessor: rightId,
                dataType: "text",
                created: action.focus && true,
                options: []
              },
              ...state.columns.slice(rightIndex + 1, state.columns.length)
            ]
          };
        case "delete_column":
          const deleteIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, deleteIndex),
              ...state.columns.slice(deleteIndex + 1, state.columns.length)
            ]
          };
        case "enable_reset":
          return {
            ...state,
            skipReset: false
          };
        default:
          return state;
      }
    }

    function TableShow() {
      const [state, dispatch] = useReducer(reducer, mmcdata);
      useEffect(() => {dispatch({ type: "enable_reset" });
      }, [state.data, state.columns]);
      
      if (localStorage.getItem('s1'+byoutou)==1){
        localStorage.setItem('Staff-Columns-'+Byoutou, JSON.stringify( scolumns))
        localStorage.setItem('Staff-'+Byoutou, JSON.stringify(mdata))
        localStorage.setItem('s1'+byoutou, 2)
      }

      function handleSave(){
        localStorage.setItem('Staff-Columns-'+Byoutou, JSON.stringify(state.columns))
        localStorage.setItem('Staff-'+Byoutou, JSON.stringify(state.data))
        localStorage.setItem('progress-' + byoutou + '-' + thisyear + '-' + nextmonth, 1)
        alert('スタッフ情報を保存しました')
      }

      function handleSaveAuto(){
        localStorage.setItem('Staff-Columns-'+Byoutou, JSON.stringify(state.columns))
        localStorage.setItem('Staff-'+Byoutou, JSON.stringify(state.data))
      }

      const [open, setOpen] = useState(false)
      const [open2, setOpen2] = useState(false)
      let typeoptions = [
        {label: "上限", value: "upl"},
        {label: "下限", value: "lowl"},
        {label: "可否", value: "ok"},
        {label: "差", value: "def"},
        {label: "組み合わせ", value: "comb"},
      ]
      const [stype, setStype] = useState(null)
      function DropdownShift() {
        const handleClick = (event) => {
          setStype(event);
        };
    
        return (
          <Select
            placeholder="種類"
            value={stype}
            onChange={handleClick} 
            options={typeoptions}
          />
        );
      }

      const [steam, setSteam] = useState(null)
      function DropdownTeam() {
        const handleClick = (event) => {
          setSteam(event);
        };
    
        return (
          <Select
            placeholder="チーム"
            value={steam}
            onChange={handleClick} 
            options={teamoptions}
          />
        );
      }

      const [styped, setStyped] = useState(null)
      function DropdownDelete() {
        const handleClick = (event) => {
          setStyped(event);
        };
    
        return (
          <Select
            placeholder="列名"
            value={styped}
            onChange={handleClick} 
            options={rowoptions}
          />
        );
      }

      const handleAdd = () => {
        if (stype.value===null || selectedValue1===null || (selectedValue1===null && selectedValue2===null)){
          alert("種類、シフトを選択してください")
        }
        else {
          const confirm = window.confirm("列を追加しますか？")
          if (confirm){
            let row = {
              id: (week!==null ? week.value + "-" : "") + selectedValue1 + ((selectedValue2!==null) ? "・" + selectedValue2 : "") + ((steam!==null) ? steam.label + "" : "") + stype.value,
              label: (stype.label=="上限" || stype.label=="下限" || stype.label=="可否") ? ((week===null ? "" : (week.value==="all" ? "" : week.label)) + selectedValue1 + ((steam!==null) ? steam.label : "") + (stype.label!=="可否" ? stype.label : "")) : (stype.label=="差") ? selectedValue1 + "・" + selectedValue2 + "差" : "同時" + selectedValue1 + "禁止",
              accessor: (week!==null ? week.value + "-" : "") + selectedValue1 + ((selectedValue2!==null) ? "・" + selectedValue2 : "") + ((steam!==null) ? steam.label + "" : "") + stype.value,
              width: stype.label=="組み合わせ" ? 25 : 1,
              dataType: (stype.label=="上限" || stype.label=="下限" || stype.label=="差") ? "number" : "select",
              options: (stype.label=="上限" || stype.label=="下限") ? [] : stype.label=="組み合わせ" ? staffoptions : checkoptions
            }
            let newcolumns = state.columns
            let IDs = newcolumns.map(item => item.id);
            if (IDs.includes(row.id)){
              alert("この列は既に存在しています")
            }
            else {
            newcolumns.push(row)
            localStorage.setItem('Staff-Columns-'+Byoutou, JSON.stringify(newcolumns))
            setOpen(false)
            window.location.reload()  }
          }
        }
      }

      function removeItemsByLabel(data, labelToRemove) {
        return data.filter((item) => item.label !== labelToRemove);
      }

      let rowoptions = []
      const Scolumn = JSON.parse(localStorage.getItem('Staff-Columns-'+Byoutou))!==null ? JSON.parse(localStorage.getItem('Staff-Columns-'+Byoutou)) : []
      Scolumn.forEach((item)=>{
        let row = {
          label: item.label,
          value: item.label
        }
        rowoptions.push(row)
      })
      const handleDelete = () => {
        const confirm = window.confirm("列を削除しますか？")
          if (confirm){
            let newcolumns = state.columns
            const newData = removeItemsByLabel(newcolumns, styped.value)
            localStorage.setItem('Staff-Columns-'+Byoutou, JSON.stringify(newData))
            setOpen2(false)
            window.location.reload()  
          }
      }

      let showshiftoptions = []
        shiftdata.forEach((data,i) => {
          if (data.type!=="禁止系" && data.type!=="選択系"){
            showshiftoptions.push(data);
          }
        })
      function ShowOptions({name, onOptionSelected, selectedValue, color, setColor}){
        const [showSelect, setShowSelect] = useState(false);
        const [selectRef, setSelectRef] = useState(null);
        const [selectPop, setSelectPop] = useState(null);

        const {styles, attributes} = usePopper(selectRef, selectPop, {
          placement: "bottom-start",
          strategy: "fixed"
        });
      
        const handleOptionClick = (option) => {
          setColor(option.color)
          onOptionSelected(option.shift); 
          setShowSelect(false); 
        };
        
          return (
            <div>
                <div style={{ border: '1px solid gray', width: '100px', cursor: 'pointer', borderRadius: 5, backgroundColor: selectedValue===null ? "white" : color}} onClick={() => setShowSelect(true)}>
                  <div style={{color: 'black', fontSize: '20px', display: 'flex', justifyContent: 'center'}}>
                    {selectedValue==null ? name : selectedValue}
                  </div>
                </div>

                {showSelect && <div className='overlay' onClick={() => setShowSelect(false)} />}
                {showSelect && (
                  <div
                    className='shadow-5 bg-white border-radius-md'
                    ref={setSelectPop}
                    {...attributes.popper}
                    style={{
                      ...styles.popper,
                      zIndex: 10,
                      minWidth: 350,
                      maxWidth: 400,
                      padding: "0.75rem",
                      position: "absolute",
                      top: "45%",
                      left: "50%",
                      transform: "translate(-50%, -50%)"
                    }}>
                    <div className='d-flex flex-wrap-wrap' style={{marginTop: "-0.5rem"}}>
                      {showshiftoptions.map((option) => (
                        <div
                          className='cursor-pointer'
                          style={{marginRight: "0.5rem", marginTop: "0.5rem"}}
                          onClick={() => {
                            setShowSelect(false);
                            handleOptionClick(option)
                          }}>
                          <Relationship value={option.shift} backgroundColor={option.color} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
            </div>
          )
      }
      const [selectedValue1, setSelectedValue1] = useState(null);
      const [color1, setColor1] = useState(null)
      const handleOptionSelected1 = (selectedOption) => {
        setSelectedValue1(selectedOption); // selectedValue1を更新
      }
      const [selectedValue2, setSelectedValue2] = useState(null);
      const [color2, setColor2] = useState(null)
      const handleOptionSelected2 = (selectedOption) => {
        setSelectedValue2(selectedOption); 
      }

      let daysoptions = [
        {label: "全て", value: "all"},
        {label: "平日", value: "weekday"},
        {label: "休日", value: "holiday"},
        {label: "月曜日", value: "0"},
        {label: "火曜日", value: "1"},
        {label: "水曜日", value: "2"},
        {label: "木曜日", value: "3"},
        {label: "金曜日", value: "4"},
        {label: "土曜日", value: "5"},
        {label: "日曜日", value: "6"},
      ]
      const [week, setWeek] = useState(null)
      function DropdownWeek() {
        const handleClick = (event) => {
          setWeek(event);
        };
        return (
          <Select
            placeholder="曜日"
            value={week}
            onChange={handleClick} 
            options={daysoptions}
          />
        );
      }

      useKeybind({
        key: "s",
        ctrlKey: true,
        onKeyDown: () => handleSave(),
      });

      const [sstate, setSstate] = useState({cols: [], rows: []});
      const [upstaff, setUpstaff] = useState(false)
      const [uploads, setUploads] = useState(false);
      const [updatas, setDatas] = useState([])
      const [fn, setFn] = useState([""])
      function uploadStaff(event) {
        let fileObj = event.target.files[0];
        setFn(fileObj.name);
      
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileContent = event.target.result;
      
          if (fileObj.name.endsWith('.csv')) {
            // CSVファイルの場合の処理
            const cleanedContent = fileContent.replace(/\\(.)/g, '$1'); // バックスラッシュをエスケープ
            const parsedData = Papa.parse(cleanedContent, { header: false });
            const rows = parsedData.data;
            const cols = rows.length > 0 ? rows[0] : [];
      
            setSstate(
              {
                cols: cols,
                rows: rows
              },
              () => {
                const data = [...rows];
                data.shift();
                this.props.uploadHandler(data);
              }
            );
      
            setUploads(true);
          } else if (fileObj.name.endsWith('.xlsx')) {
            // Excelファイルの場合の処理
            ExcelRenderer(fileObj, (err, resp) => {
              if (err) {
                console.log(err);
              } else {
                const { cols, rows } = resp;
                setSstate(
                  {
                    cols: cols,
                    rows: rows
                  },
                  () => {
                    const data = [...rows];
                    data.shift();
                    this.props.uploadHandler(data);
                  }
                );
      
                setUploads(true);
              }
            });
          } else {
            console.log('Unsupported file format');
          }
        };
      
        reader.readAsText(fileObj, 'UTF-8');
      };

      function handleAlertup() {
        alert("送信するファイルを選択してください。")
      }

      if (uploads){
        let sdata = []
        let sjson = []
        for (let i = 0; i < sstate.rows[0].length; i++) {
          var ss = sstate.rows.map(function(item, index){return item[i]})
          sjson.push(ss);
        }
        for (let j = 1; j <= sstate.rows.length; j++) {
          var row = {}
          for (let i = 0; i < sstate.rows[0].length; i++) {
            if (String(sstate.rows[0][i]).includes("comb") && sjson[i][j]!=="" && sjson[i][j]!==undefined && sjson[i][j]!==null){
              const comblist = sjson[i][j].replace('"', "").split(',')
              let subrow = []
              comblist.forEach(item=>{
                subrow.push(item)
              })
              row[String(sstate.rows[0][i])] = subrow
            } else {
              row[String(sstate.rows[0][i])] = sjson[i][j]
            }
          }
          updatas.push(row)
        }
      }

      function handleSaveStaff(){
        const confirm = window.confirm('スタッフ情報を保存しますか？')
        if (confirm) {
          localStorage.setItem('Staff-' + Byoutou, JSON.stringify(updatas.slice(0, sstate.rows.length-1)))
          localStorage.setItem('progress-' + byoutou + '-' + thisyear + '-' + nextmonth, 1)
          localStorage.setItem('s1'+byoutou, 2)
          alert("スタッフ情報を登録しました。")
          window.location.reload()  
      }}

      let dldata = "Staff-" + Byoutou
      const saveddata = (localStorage.getItem(dldata)!=null) ? JSON.parse(localStorage.getItem(dldata)) : []
      const filename = "スタッフデータ_" + Byoutou + "_" + preyear + thismonth0 + thisday

      return (
        <div>
          <div style={{zoom: "80%", display: 'flex'}}>
            <Button size="small" variant="contained" color="success" onClick={handleSave}>
              保存
            </Button>
            <Button size="small" variant="contained" color="success" onClick={()=>setUpstaff(prevState=>!prevState)}>
              アップロード
            </Button>
            <CSVLink data={saveddata} filename={filename} className="text-deco">
              <Button sx={{ border: '1px solid gray' }} size="small" variant="contained" color="success">
                ダウンロード<FileDownloadOutlinedIcon/>
              </Button>
            </CSVLink>
            <Button sx={{ border: '1px solid gray' }} size="small" variant="contained" color="error" onClick={handleRemove}>
                  全削除
                </Button>

            {upstaff && <div className='overlay' onClick={() => setUpstaff(false)} />}
            {upstaff && (
                <div
                  className='shadow-5 bg-white border-radius-md'
                  style={{
                    zIndex: 10,
                    width: "500px",
                    padding: "0.75rem",
                    position: "absolute",
                    left: "50%",
                    top: "40%",
                    transform: "translate(-50%, -50%)",
                  }}>

                    <div style={{fontSize: '13px', paddingBottom: '0.25rem'}}>
                      スタッフ情報のCSVまたはエクセルファイルをアップロードできます。
                    </div>

                <div className="excel-table-container" style={{width: "100%", paddingBottom: '0.25rem'}}>
                  <div className="file-upload">
                    <input type="file" onChange={uploadStaff} accept=".xlsx,.csv,.xls"/>
                    <button>+</button>
                  </div>
                </div>

                <div style={{paddingTop: "0.25rem", display: 'flex', justifyContent: 'flex-end'}}>
                {uploads &&
                <Button sx={{ border: '1px solid gray' }} onClick={handleSaveStaff} size="small" variant="contained" color="success" style={{height: '38.3px', width: '10px'}}>
                    保存
                </Button>
                }
                {!uploads &&
                <Button sx={{ border: '1px solid gray' }} onClick={handleAlertup} size="small" variant="contained" color="success" style={{height: '38.3px', width: '10px'}}>
                    保存
                </Button>
                }
                </div>
                </div>)}

            <Button size="small" variant="contained" color="info" onClick={()=>{setOpen(prevState=>!prevState)}}>
              列の追加
            </Button>
            <Button size="small" variant="contained" color="info" onClick={()=>{setOpen2(prevState=>!prevState)}}>
              列の削除
            </Button>
          </div>
          <div className="body-container-r" style={{zoom: "80%"}}>
            <div className="table-container-r" onClick={handleSaveAuto}>
              <div>
                <div className="table-container-r1" style={{width: width}}>
                  <Table
                    columns={state.columns}
                    data={state.data}
                    skipReset={state.skipReset}
                    dispatch={dispatch}
                    cellstaff={"s"}
                    />
                </div>
              </div>
            </div>
          </div>

          {open && <div className='overlay' onClick={() => setOpen(false)} />}
          {open && (
            <div
              className='shadow-5 bg-white border-radius-md'
              style={{
                zIndex: 10,
                minWidth: 320,
                minWidth: 400,
                padding: "0.75rem",
                position: "absolute",
                top: "45%",
                left: "50%",
                transform: "translate(-50%, -50%)"
              }}>
              <div style={{paddingBottom: '0.5rem'}}>
                追加する列の種類を選択してください
              </div>
              <div style={{width: '200px'}}>
                <DropdownShift/>
              </div>

              {stype!==null && stype.label=="上限" && (
                <div style={{paddingTop: '2rem'}}>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ShowOptions 
                      name={"シフト"} 
                      onOptionSelected={handleOptionSelected1} 
                      selectedValue={selectedValue1}
                      color={color1}
                      setColor={setColor1}/>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'center', paddingTop: '1rem'}}>
                    列名： {selectedValue1!==null ? selectedValue1 : "シフト"}上限
                  </div>
                </div>
              )}
              {stype!==null && stype.label=="下限" && (
                <div style={{paddingTop: '2rem'}}>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ShowOptions 
                      name={"シフト"} 
                      onOptionSelected={handleOptionSelected1} 
                      selectedValue={selectedValue1}
                      color={color1}
                      setColor={setColor1}/>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'center', paddingTop: '1rem'}}>
                    列名： {selectedValue1!==null ? selectedValue1 : "シフト"}下限
                  </div>
                </div>
              )}
              {stype!==null && stype.label=="可否" && (
                <div style={{paddingTop: '2rem'}}>
                  <div style={{fontSize: '13px', paddingLeft: '1rem', paddingBottom: '1rem'}}>チームの選択は任意です</div>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <DropdownWeek/>
                    <div>　</div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                    <ShowOptions 
                      name={"シフト"} 
                      onOptionSelected={handleOptionSelected1} 
                      selectedValue={selectedValue1}
                      color={color1}
                      setColor={setColor1}/>
                      </div>
                    <div>　</div>
                    <DropdownTeam/>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'center', paddingTop: '1rem'}}>
                    列名： {week===null ? "(曜日)" : week.value==="all" ? "" : week.label}{selectedValue1!==null ? selectedValue1 : "シフト"}{steam!==null ? steam.label : ""}
                  </div>
                </div>
              )}
              {stype!==null && stype.label=="差" && (
                <div style={{paddingTop: '2rem'}}>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ShowOptions 
                      name={"シフトA"} 
                      onOptionSelected={handleOptionSelected1} 
                      selectedValue={selectedValue1}
                      color={color1}
                      setColor={setColor1}/>
                      <div>　</div>
                     <ShowOptions 
                      name={"シフトB"} 
                      onOptionSelected={handleOptionSelected2} 
                      selectedValue={selectedValue2}
                      color={color2}
                      setColor={setColor2}/>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'center', paddingTop: '1rem'}}>
                    列名： {selectedValue1!==null ? selectedValue1 : "シフトA"}・{selectedValue2!==null ? selectedValue2 : "シフトB"}差
                  </div>
                </div>
              )}
              {stype!==null && stype.label=="組み合わせ" && (
                <div style={{paddingTop: '2rem'}}>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ShowOptions 
                      name={"シフト"} 
                      onOptionSelected={handleOptionSelected1} 
                      selectedValue={selectedValue1}
                      color={color1}
                      setColor={setColor1}/>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'center', paddingTop: '1rem'}}>
                    列名： 同時{selectedValue1!==null ? selectedValue1 : "シフト"}禁止
                  </div>
                </div>
              )}

              <div style={{paddingBottom: '1rem'}}>
              </div>
              <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: '1rem', paddingBottom: '1rem'}}>
                <Button size="small" variant="contained" color="info" onClick={handleAdd}>
                  追加
                </Button>
              </div>
            </div>
          )}

          {open2 && <div className='overlay' onClick={() => setOpen2(false)} />}
          {open2 && (
            <div
              className='shadow-5 bg-white border-radius-md'
              style={{
                zIndex: 10,
                minWidth: 320,
                minWidth: 400,
                padding: "0.75rem",
                position: "absolute",
                top: "45%",
                left: "50%",
                transform: "translate(-50%, -50%)"
              }}>
              <div style={{paddingBottom: '0.5rem'}}>
                削除する列を選択してください
              </div>
              <div style={{width: '200px'}}>
                <DropdownDelete/>
              </div>

              <div style={{paddingBottom: '1rem'}}>
              </div>
              <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: '1rem', paddingBottom: '1rem'}}>
                <Button size="small" variant="contained" color="info" onClick={handleDelete}>
                  削除
                </Button>
              </div>
            </div>
          )}

        </div>
      );
    }
    
    return (
      <div className="excel-table-container">
        <div className="foo">
        </div>
        <div>
          <TableShow/>
        </div>
      </div>
    );
  }
}

export default ExcelRequestsImport;