import React, { useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import EditShift from './StatsShow';
import SetAppbar from "./TopPage2";

var today = new Date()
const year = today.getFullYear() + '年'
const month = today.getMonth()+2 + '月'

const mdTheme = createTheme();

function DashboardContent() {
  const [data, setData] = useState([]);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 2,
            height: '100vh',
            overflowY: 'hidden',
            marginTop: '50px',
          }}
        >
         <SetAppbar pagename={"統計"}/> 
          <div style={{margin: '10px'}}>
            <Paper>
              <EditShift  />
            </Paper>
          </div>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

const Stats = () => {
    return <DashboardContent />;
  };
  
  export default Stats;