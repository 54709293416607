import React, { useRef, useState, useEffect } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom'
import SetAppbar from "./TopPage2";
import Button from '@mui/material/Button';
import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useNavigate } from "react-router-dom"; 
import Papa from 'papaparse';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';

const JapaneseHolidays = require('japanese-holidays');

var today = new Date()
var date = new Date();
var lastDate = new Date(date.getFullYear(), date.getMonth()+2, 0);
var days = lastDate.getDate()
const year = today.getFullYear()
const month = today.getMonth() + 2
const thismonth = date.getMonth()+1
const nextmonth = (thismonth==12) ? 1 : date.getMonth()+2
const thisyear = (thismonth==12) ? date.getFullYear() + 1 : date.getFullYear()
const thisday = date.getDate()
const preyear = (nextmonth==1) ? thisyear-1 : thisyear
const isholiday = JapaneseHolidays.getHolidaysOf(lastDate.getFullYear(), true);

const holiday = []
for (let i = 0; i < isholiday.length; i++){
  if (isholiday[i].month===lastDate.getMonth()+1){
    holiday.push(isholiday[i].date)
  }
}

const weekend = []
const whatday = []
for (let i = 1; i <= days; i++){
  var day = new Date(thisyear, lastDate.getMonth(), i)
  if ([0, 6].includes(day.getDay())){
    weekend.push(String(i))
  }
  if (holiday.includes(i)){
    if (!weekend.includes(i)){
      weekend.push(String(i))
    }
  }
  if ([0].includes(day.getDay())){
    whatday.push("日")
  }
  if ([1].includes(day.getDay())){
    whatday.push("月")
  }
  if ([2].includes(day.getDay())){
    whatday.push("火")
  }
  if ([3].includes(day.getDay())){
    whatday.push("水")
  }
  if ([4].includes(day.getDay())){
    whatday.push("木")
  }
  if ([5].includes(day.getDay())){
    whatday.push("金")
  }
  if ([6].includes(day.getDay())){
    whatday.push("土")
  }
}

if (localStorage.getItem('toppage-status')==null){
  let top = {status: 1, calendar: 1, table: 1, shift: 1, staff: 1, message: 1}
  localStorage.setItem('toppage-status', JSON.stringify(top))
}

let schedule = []
const start = []
const calendar = (JSON.parse(localStorage.getItem("Calendar"))!==null) ? JSON.parse(localStorage.getItem("Calendar")) : ""
const sortedEventData = JSON.parse(localStorage.getItem("Calendar"))!==null ? calendar.sort((a, b) => new Date(a.start) - new Date(b.start)) : ""
for (let i = 1; i < sortedEventData.length; i++) {
  if (new Date(sortedEventData[i].start)>=today){
    schedule.push(sortedEventData[i].title)
    let st = (!(sortedEventData[i].title).includes("ミーティング")) ? format(Date.parse(sortedEventData[i].start), "M月d日", {locale: ja}) : format(Date.parse(sortedEventData[i].start), "M月d日 HH:mm", {locale: ja})
    start.push(st)
  }
}
if (schedule.length==0){
  schedule.push("予定が登録されていません")
}

const mdTheme = createTheme();

function DashboardContent() {
  const navigate = useNavigate();

  if (sessionStorage.getItem("Login")==null || sessionStorage.getItem("Login")=="0"){
    navigate("/Login");
  }

  if (localStorage.getItem("first-login")==="0" || localStorage.getItem("first-login")===null){
    localStorage.setItem("first-login", "1")
    window.location.reload()  
  }

  if (sessionStorage.getItem("Login")==="1"){
    sessionStorage.setItem("Login", "1")
  }

  const Byoutou = localStorage.getItem("Byoutou")
  const byoutou = Byoutou.toLocaleLowerCase()

  localStorage.removeItem('progress-' + byoutou + '-' + thisyear + '-' + thismonth)
  const status = (localStorage.getItem('progress-' + byoutou + '-' + thisyear + '-' + nextmonth)!==null) ? localStorage.getItem('progress-' + byoutou +'-' + thisyear + '-' + nextmonth) : 0

  if (localStorage.getItem('Staff-' + Byoutou )!==null && localStorage.getItem('Request-' + Byoutou  + thisyear + '-' + nextmonth)==null){
    let mdata = [];
    var num = 0
      const staffdata = (JSON.parse(localStorage.getItem('Staff-' + Byoutou ))!==null) ? JSON.parse(localStorage.getItem('Staff-' + Byoutou )) : []
      for (let i = 1; i<=staffdata.length; i++) {
          if (staffdata[i-1].ID !== "" && staffdata[i-1].ID !== undefined){
              num = num + 1
          }
      }
  
      for (let i = 1; i <= num; i++) {
          const istaffdata = staffdata[i-1]
          let mrow = {
            ID: istaffdata.ID + " " + istaffdata.name,
          }
          for (let j = 1; j <= days; j++){
            mrow["d" + String(j)] = ""
          }
          mdata.push(mrow);
        }
        localStorage.setItem('Request-' + Byoutou  + thisyear + '-' + nextmonth, JSON.stringify(mdata))
  }

  function handlekeyEdit(ward){
    let keyvalue = {ward:ward, year:thisyear, month:nextmonth}
    localStorage.setItem('Storage-show', JSON.stringify(keyvalue))
  }

  function handlekeyEditT(ward){
    let keyvalue = {ward:ward, year:preyear, month:thismonth}
    localStorage.setItem('Storage-show', JSON.stringify(keyvalue))
  }

  function handlekeyRequest(ward){
    let keyvalue = {ward:ward, year:thisyear, month:nextmonth}
    localStorage.setItem('Request-key', JSON.stringify(keyvalue))
  }

  function handlekeyStaff(ward){
    let keyvalue = {ward:ward, year:thisyear, month:nextmonth}
    localStorage.setItem('Staff-key', JSON.stringify(keyvalue))
  }

  function handlekeysch(ward){
    let keyvalue = {ward:ward, year:thisyear, month:nextmonth}
    localStorage.setItem('Schedule-key', JSON.stringify(keyvalue))
  }

  const [setting, setSetting] = useState(false)

  const [tos, setTos] = useState(JSON.parse(localStorage.getItem('toppage-status')))

  function ToggleButton({keys}) {
    const toppagestatus = JSON.parse(localStorage.getItem('toppage-status'))
    const top = toppagestatus[keys]
    const [isOn, setIsOn] = useState((top==1) ? true : false);
  
    const handleToggle = () => {
      toppagestatus[keys] = (!isOn) ? 1 : 0 ;
      localStorage.setItem('toppage-status', JSON.stringify(toppagestatus))
      setTos(toppagestatus)
      setIsOn(!isOn);
    };
  
    return (
      <div className={`toggle-button ${isOn ? 'on' : 'off'}`} onClick={handleToggle}>
        <div className={`slider ${isOn ? 'on' : 'off'}`} /> 
      </div>
    );
  }

  // 画面サイズ取得
  const [width, setWidth] = useState(null)
  useEffect(() => {
    setWidth(window.innerWidth)
  }, ([]))
  const updateWidth = (event) => {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener(`resize`, updateWidth, {
      capture: false,
      passive: true,
    })

    return () => window.removeEventListener(`resize`, updateWidth)
  })

  function containsJpn(inputString) {
    const japaneseCharactersRegex = /[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF\u3400-\u4DBF]/;
  
    return japaneseCharactersRegex.test(inputString);
  }

  let ByoutouName = null;

  JSON.parse(localStorage.getItem("UserID")).forEach(item => {
    if (item.code === Byoutou) {
      ByoutouName = item.name;
    }
  });

  const shiftdata = (JSON.parse(localStorage.getItem("Shift-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Shift-"+Byoutou)) : []
  const limitdata = (JSON.parse(localStorage.getItem("Limit-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Limit-"+Byoutou)) : []
  const teamdata = (JSON.parse(localStorage.getItem("Team-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Team-"+Byoutou)) : []

  let shiftoptions = [];
  shiftdata.forEach((data,i) => {
    if ((data.shift=="〇" || data.shift.includes("/") || data.shift.includes("長")) && (data.type=="日勤系" || data.type=="準夜勤系" || data.type=="ロング系")){
      if (teamdata.length>0) {for (const t in teamdata){
        shiftoptions.push({label: data.shift + teamdata[t].team, backgroundColor: data.color, type: data.type, team: teamdata[t].team})
      }} else {
        shiftoptions.push({label: data.shift, backgroundColor: data.color, type: data.type, team: ""})
      }
    } else {
      if (data.type!=="禁止系" && data.type!=="選択系"){
        shiftoptions.push({ label: data.shift, backgroundColor: data.color, type: data.type});
      }
    }
  })

  let offshift = []
  shiftdata.forEach((data)=>{
    if (!(data.type!=="公休" && data.type!=="休み系" && data.type!=="禁止系" && data.type!=="選択系")){
      offshift.push(data.shift)
    }
  })
  const todayshift = (JSON.parse(localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + thismonth))!==null) ? JSON.parse(localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + thismonth)) : []
  let todayshiftvalue = {}
  todayshift.forEach((data,i)=>{
    if (data["d" + date.getDate()]!=="" && !offshift.includes(data["d" + date.getDate()])){
      todayshiftvalue[data.ID] = data["d" + date.getDate()]
    }
  })

  let todaysshift = {}
  let todayscolor = []
  shiftoptions.forEach((data)=>{
    todayscolor.push(data.backgroundColor)
    todaysshift[data.label] = ""
    Object.keys(todayshiftvalue).forEach((key)=>{
      if (data.label==todayshiftvalue[key]){
        todaysshift[data.label] = todaysshift[data.label] + key.replace(/[0-9\s]/g, '') + ', '
      }
    })
  })

  let incompleteTodo = (JSON.parse(localStorage.getItem("message"))!==null) ? JSON.parse(localStorage.getItem("message")) : []
  if (incompleteTodo.length==0){
    incompleteTodo.push(
      "\nメッセージはありません"
    )
  }
  
  function useLatest(value) {
    const ref = useRef(value);
    ref.current = value;
    return ref;
  }
  function useKeybind({
    altKey,
    ctrlKey,
    metaKey,
    shiftKey,
    key,
    onKeyDown,
    targetRef,
    }) {
    const onKeyDownLatest = useLatest(onKeyDown);

    useEffect(() => {
        const eventListener = (event) => {
        if (altKey && !event.altKey) return;
        if (ctrlKey && !event.ctrlKey) return;
        if (metaKey && !event.metaKey) return;
        if (shiftKey && !event.shiftKey) return;
        if (event.key !== key) return;

        event.preventDefault();
        onKeyDownLatest.current?.(event);
        };

        if (targetRef?.current) {
        const target = targetRef.current;

        target.addEventListener("keydown", eventListener);
        return () => target.removeEventListener("keydown", eventListener);
        } else {
        window.addEventListener("keydown", eventListener);
        return () => window.removeEventListener("keydown", eventListener);
        }
    }, [altKey, ctrlKey, key, metaKey, onKeyDownLatest, shiftKey, targetRef]);
  }
  const [jset, setJset] = useState(false)
  useKeybind({
    key: "o",
    ctrlKey: true,
    onKeyDown: () => setJset(true),
  });

  const FileUpload = () => {
    const [file, setFile] = useState(null);
  
    const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
    };
  
    const handleUpload = () => {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const Text = e.target.result;
          const jsondata = JSON.parse(Text)
          jsondata.forEach(data=>{
            //if (localStorage.getItem(data.key)==null){
              localStorage.setItem(data.key, JSON.stringify(data.value))
            //}
          })
        };
        reader.readAsText(file);
        setJset(false)
        alert("ローカルストレージをアップロードしました")

      } else {
        alert("アップロードするファイルを選択してください")
      }
    };
  
    return (
      <div style={{display: 'flex', justifyContent:'flex-between', paddingTop: '0.25rem'}}>
        <input type="file" onChange={handleFileChange} />
        <button onClick={handleUpload}>Upload</button>
      </div>
    );
  };

  const LocalStorageDownloader = () => {
    const [localStorageData, setLocalStorageData] = useState([]);
  
    useEffect(() => {
      // ローカルストレージの全てのデータを取得
      const keys = Object.keys(localStorage);
      const allLocalStorageData = keys.map(key => {
        const value = localStorage.getItem(key);
  
        // キーに Byoutou を含む場合のみオブジェクトを追加
        if (key.includes(Byoutou)) {
          return { key, value: JSON.parse(value) }; // value を JSON パース
        }
        return null; // Byoutou を含まない場合は null を返す
      }).filter(item => item !== null);
  
      // ステートにセット
      setLocalStorageData(allLocalStorageData);
    }, [Byoutou]); // Byoutou が変更されるたびに実行
  
    const downloadLocalStorageData = () => {
      const textString = JSON.stringify(localStorageData, null, 2);
      const blob = new Blob([textString], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
  
      // ダウンロード用のリンクを作成し、クリックしてダウンロード
      const link = document.createElement('a');
      link.href = url;
      link.download = 'localstorage_' + Byoutou + '.txt'; // 拡張子を.txtに変更
      document.body.appendChild(link);
      link.click();
  
      // 作成した要素を削除
      document.body.removeChild(link);
      setJset(false);
    };
  
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button onClick={downloadLocalStorageData}>Download</button>
      </div>
    );
  };

  let ws = 700

  let showoption = []
  limitdata.forEach(item=>{
    if (weekend.includes(date.getDate())){
      if (item.endtotal==1){
        showoption.push(item.shift)
      }
    } else {
      if (item.daytotal==1){
        showoption.push(item.shift)
      }
    }
  })
  
  return (
    <ThemeProvider theme={mdTheme}>
      <SetAppbar/>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 2,
            height: 'calc(100vh - 45px)',
            overflow: 'auto',
            marginTop: '45px',
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>

            <Grid item xs={6} md={6} lg={4} container spacing={3} style={{maxHeight: '250px'}}>
            <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{p: 2,display: 'flex',height: 80, backgroundColor: 'lightgray', boxShadow: 5}}>
                  <Grid container rowSpacing={0.5} columnSpacing={0.5}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{
                          mr: 0,
                          flexGrow: 2,
                          fontFamily: 'monospace',
                          fontWeight: 550,
                          fontSize: (ByoutouName.length>5) ? '25px' : (width>ws) ? '50px' : '40px',
                          color: 'inherit',
                          textDecoration: 'none',
                          display: 'flex',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <div style={{display: 'flex', flexWrap: 'wrap', lineHeight: '1.2', justifyContent: 'center'}}>
                        <div style={{}}>
                        {ByoutouName}
                        </div>
                        <div style={{ width: '100%' }} />
                         <div style={{fontSize: '20px', display: 'flex', alignItems: 'center'}}>
                          {preyear}年{thismonth}月
                          </div>
                         </div>
                      </Typography>
                    </Grid>

                  </Grid>
                </Paper>
              </Grid>

            {tos.status==1 &&
            <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{p: 2,display: 'flex',height: 145, boxShadow: 5}}>
                  <Grid container rowSpacing={0.5} columnSpacing={0.5}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{
                          mr: 0,
                          flexGrow: 2,
                          fontFamily: 'monospace',
                          fontWeight: 550,
                          color: 'inherit',
                          textDecoration: 'none',
                        }}
                      >
                        <div style={{display: 'flex'}}>
                        <div style={{display: 'flex', alignItems: 'center', paddingRight: '0.1rem'}}>
                          <AccessTimeOutlinedIcon/>
                        </div>
                        進捗状況
                        </div>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <br/>
                    </Grid>

                    <Grid item xs={12}>
   
                      <Grid item xs={12}>
                      <ol class="progress-bar">
                        <li class={`${status<0 && ""} ${status==0 && "is-active"} ${status>0 && "is-complete"}`}><span>勤務情報更新</span></li>  
                        <li class={`${status<1 && ""} ${status==1 && "is-active"} ${status>1 && "is-complete"}`}><span>勤務希望入力</span></li>  
                        <li class={`${status<2 && ""} ${status==2 && "is-active"} ${status>2 && "is-complete"}`}><span>勤務表作成</span></li>
                        <li class={`${status<3 && ""} ${status==3 && "is-active"} ${status>3 && "is-complete"}`}><span>勤務表修正</span></li>  
                        <li class={`${status<4 && ""} ${status==4 && "is-active"} ${status>4 && "is-complete"}`}><span>完了</span></li>  
                      </ol>
                      </Grid>
                        {/* {status<4 && (
                          <Grid item xs={12}>
                          <div style={{display: 'flex', justifyContent: 'center', marginTop: '-0.25rem'}}>
                          <div style={{display: 'flex', alignItems: 'center', fontSize: '11px'}}>
                          {status==0 && "スタッフ情報、勤務条件を確認・修正してください"}
                          {status==1 && "勤務希望を入力してください"}
                          {status==2 && "勤務表作成プロセスを実行してください"}
                          {status==3 && "作成した勤務表を修正してください"}
                          </div>
                          </div>
                          </Grid>
                        )} */}
                      
                    </Grid>
                      
                  </Grid>
                </Paper>
              </Grid>}
            </Grid>
              
              {tos.calendar==1 &&
                <Grid item xs={6} md={6} lg={4}>
                <Paper sx={{p: 2,display: 'flex', minHeight: 250, boxShadow: 5}}>
                  <Grid container rowSpacing={0.5} columnSpacing={0.5}>
                    <Grid item xs={12}>
                    <a href="/Calendar" style={{textDecoration: 'none', color: 'inherit'}}>
                      <Typography
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{
                          mr: 0,
                          flexGrow: 2,
                          fontFamily: 'monospace',
                          fontWeight: 550,
                          color: 'inherit',
                          textDecoration: 'none',
                        }}
                      >
                        <div style={{display: 'flex'}}>
                        <div style={{display: 'flex', alignItems: 'center', paddingRight: '0.1rem'}}>
                          <CalendarMonthOutlinedIcon/>
                        </div>
                        今月の予定
                        </div>
                      </Typography>
                      </a>
                    </Grid>
                    
                      {schedule.map((item, index) =>
                      <Grid item xs={12}>
                      <div style={{
                          padding: "0.5px",
                          paddingLeft: item!=="予定が登録されていません" && "5px",
                          display: item==="予定が登録されていません" && "flex",
                          justifyContent: item==="予定が登録されていません" && "center",
                        }}>
                        {item!=="予定が登録されていません"  ? 
                        <strong>
                        {start[index] + "　"}
                        {item }
                        </strong> :
                        <div>
                        {item }
                        </div>
                        }
                      </div>
                      </Grid>
                      )}
                      
                  </Grid>
                </Paper>
              </Grid>}

              {tos.shift==1 &&
                <Grid item xs={6} md={6} lg={4}>
                <Paper sx={{p: 2,display: 'flex',minHeight: 250, boxShadow: 5}}>
                <Grid container rowSpacing={1} columnSpacing={1.5}>
                  <Grid item xs={12}>
                  <a href={`/Table/Storage/${Byoutou}`} onClick={()=>handlekeyEditT(Byoutou)} style={{textDecoration: 'none', color: 'inherit'}}>
                    <Typography
                      variant="h6"
                      color="inherit"
                      noWrap
                      sx={{
                        mr: 0,
                        flexGrow: 2,
                        fontFamily: 'monospace',
                        fontWeight: 550,
                        color: 'inherit',
                        textDecoration: 'none',
                      }}
                    >
                      {thismonth}月{date.getDate()}日のシフト
                    </Typography>
                  </a>
                  </Grid>

                  <div style={{paddingLeft: '1rem', display: 'flex', flexWrap: 'wrap'}}>
                  {Object.keys(todaysshift).map((key,index)=>{
                    if (todaysshift[key]!=="" || showoption.includes(key)){
                      return(
                        <Grid item xs={6} md={6} lg={6}>
                        <div style={{display: 'flex', paddingBottom: '0.25rem'}}>
                        <div style={{display: 'flex'}}>
                          <div style={{backgroundColor: todayscolor[index], fontSize: '14px', padding: '0.25rem', minWidth: '35px' , height: '24px', borderRadius: 4, display: 'flex', justifyContent: 'center'}}>
                            {key}
                            </div>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', fontSize: '14px', paddingLeft: '0.35rem'}}>
                          {todaysshift[key]!=="" ? todaysshift[key].slice(0,-2) : 
                          (<div style={{color: 'red', display: 'flex', alignItems: 'center'}}>
                            不足
                          </div>)}
                          </div>
                        </div>
                        </Grid>
                        )
                    }
                  })}
                  </div>

                  </Grid>
                </Paper>
              </Grid>}
              
              {tos.table==1 &&
                <Grid item xs={5} md={5} lg={3}>
                <Paper sx={{p: 2,display: 'flex',height: 250, boxShadow: 5}}>
                <Grid container rowSpacing={1} columnSpacing={1.5} >
                  <Grid item xs={12}>
                  <a href="/Table" style={{textDecoration: 'none', color: 'inherit'}}>
                    <Typography
                      variant="h6"
                      color="inherit"
                      sx={{
                        mr: 0,
                        flexGrow: 2,
                        fontFamily: 'monospace',
                        fontWeight: 550,
                        color: 'inherit',
                        textDecoration: 'none',
                      }}
                    >
                      <div style={{display: 'flex'}}>
                        <div style={{display: 'flex', alignItems: 'center', paddingRight: '0.1rem'}}>
                          <TableChartOutlinedIcon/>
                        </div>
                      勤務表
                      </div>
                    </Typography>
                  </a>
                  </Grid>

                  <Grid item xs={12} lg={12}>
                  <Button sx={{ border: '1px solid gray' }} onClick={()=>handlekeyRequest(Byoutou)} size="large" variant="contained" color="inherit" style={{width: "100%", backgroundColor: 'lightskyblue'}} component={Link} to={`/Table/Request/${Byoutou}`}>
                  勤務希望登録
                  </Button>
                  </Grid>

                  <Grid item xs={12} lg={12}>
                  <Button sx={{ border: '1px solid gray' }} onClick={()=>handlekeysch(Byoutou)} size="large" variant="contained" color="inherit" style={{width: "100%", backgroundColor: 'lightskyblue'}} component={Link} to={`/Table/Schedule/${Byoutou}`}>
                  勤務表作成
                  </Button>
                  </Grid>

                  <Grid item xs={12} lg={12}>
                  <Button sx={{ border: '1px solid gray' }} onClick={()=>handlekeyEdit(Byoutou)} size="large" variant="contained" color="inherit" style={{width: "100%", backgroundColor: 'lightskyblue'}} component={Link} to={`/Table/Storage/${Byoutou}`}>
                  勤務表修正
                  </Button>
                  </Grid>
                  
                </Grid>
                </Paper>
              </Grid>}


              {tos.staff==1 &&
                <Grid item xs={5} md={5} lg={3}>
                <Paper sx={{p: 2,display: 'flex',height: 250, boxShadow: 5}}>
                <Grid container rowSpacing={1} columnSpacing={1.5}>
                  <Grid item xs={12}>
                  <Typography
                      variant="h6"
                      color="inherit"
                      noWrap
                      sx={{
                        mr: 0,
                        flexGrow: 2,
                        fontFamily: 'monospace',
                        fontWeight: 550,
                        color: 'inherit',
                        textDecoration: 'none',
                      }}
                    >
                      <div style={{display: 'flex'}}>
                        <div style={{display: 'flex', alignItems: 'center', paddingRight: '0.1rem'}}>
                          <PostAddOutlinedIcon/>
                        </div>
                      勤務情報
                      </div>
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={12} lg={12}>
                  <Button sx={{ border: '1px solid gray' }} onClick={()=>handlekeyStaff(Byoutou)} size="large" variant="contained" color="inherit" style={{width: "100%", backgroundColor: 'lightsalmon'}} component={Link} to={`/Staff/${Byoutou}`}>
                  スタッフ情報
                  </Button>
                  </Grid>

                  <Grid item xs={12} lg={12}>
                  <Button sx={{ border: '1px solid gray' }} onClick={()=>handlekeysch(Byoutou)} size="large" variant="contained" color="inherit" style={{width: "100%", backgroundColor: 'lightsalmon'}} component={Link} to={`/Setting/Shift/${Byoutou}`}>
                  シフト・チーム・スキル
                  </Button>
                  </Grid>

                  <Grid item xs={12} lg={12}>
                  <Button sx={{ border: '1px solid gray' }} onClick={()=>handlekeyEdit(Byoutou)} size="large" variant="contained" color="inherit" style={{width: "100%", backgroundColor: 'lightsalmon'}} component={Link} to={`/Setting/Const/${Byoutou}`}>
                  勤務条件・枠数
                  </Button>
                  </Grid>

                  </Grid>
                </Paper>
              </Grid>}

              {tos.message==1 &&
                <Grid item xs={6} md={6} lg={4}>
                <Paper sx={{p: 2,display: 'flex',height: 250, boxShadow: 5}}>
                <Grid container rowSpacing={1} columnSpacing={1.5}>
                  <Grid item xs={12}>
                  <a href="/Message" style={{textDecoration: 'none', color: 'inherit'}}>
                    <Typography
                      variant="h6"
                      color="inherit"
                      noWrap
                      sx={{
                        mr: 0,
                        flexGrow: 2,
                        fontFamily: 'monospace',
                        fontWeight: 550,
                        color: 'inherit',
                        textDecoration: 'none',
                      }}
                    >
                       <div style={{display: 'flex'}}>
                        <div style={{display: 'flex', alignItems: 'center', paddingRight: '0.1rem'}}>
                          <EmailOutlinedIcon/>
                        </div>
                      連絡事項
                      </div>
                    </Typography>
                  </a>
                  </Grid>
                  
                  <Grid item xs={12}>
                  {incompleteTodo.map((todo, index) => (
                    <div key={index}>
                      <div>
                      <Grid item xs={12} md={12} lg={12}>
                        <div
                          style={{
                            padding: "0.5px",
                            paddingLeft: "0.5rem",
                            display: 'flex', 
                            justifyContent: todo === "\nメッセージはありません" && 'center',
                            marginBottom: '0.25rem'
                          }}
                        >
                          <div style={{display: 'flex', alignItems: 'center', fontWeight: 'bold'}}>{todo !== "\nメッセージはありません" && "・"}</div>
                          {todo.split('\n').map((line, index) => (
                            <span key={index}>
                              {index > 0 && line}
                              {index < todo.split('\n').length - 1 && <br />}
                            </span>
                          ))}
                        </div>
                      </Grid>
                      </div>
                    </div>
                  ))}
                  </Grid>

                  </Grid>
                </Paper>
              </Grid>}

            </Grid>
          </Container>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container rowSpacing={0}>
              
            </Grid>
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '-1.5rem', marginTop: '-1.3rem'}}>
            <div style={{display: 'flex', alignItems: 'end'}}>
            <a href="https://www.freepik.com/icon/calendar_3643956#fromView=resource_detail&position=10" style={{fontSize: '10px'}}>Icon by Freepik</a>
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '-3%'}}>
            <SettingsOutlinedIcon onClick={()=>setSetting(prevstate=>!prevstate)} style={{cursor: 'pointer', color: '#696969'}}/>
          </div>
          </div>

          </Container>


          {jset && <div className='overlay' onClick={() => setJset(false)} />}
          {jset && (
            <div className='shadow-5 bg-white border-radius-md'
            style={{
              zIndex: 10,
              minWidth: 250,
              maxWidth: 320,
              padding: "0.75rem",
              position: "fixed",
              top: "45%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}>
              <div>
              初期設定JSONデータのアップロード
              <div style={{paddingTop: '0.5rem'}}>
                <FileUpload />
              </div>
              </div>

              <div style={{paddingTop: '3rem'}}>
              現在のJSONデータのダウンロード
              <div style={{paddingTop: '0.5rem'}}>
                <LocalStorageDownloader />
              </div>
              </div>
              
            </div>
          )}

          {setting && <div className='overlay' onClick={() => setSetting(false)} />}
          {setting && (
            <div className='shadow-5 bg-white border-radius-md'
            style={{
              zIndex: 10,
              minWidth: 200,
              maxWidth: 320,
              padding: "0.75rem",
              position: "fixed",
              top: "45%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}>
              トップページ表示設定
              <div style={{padding: '0.75rem 0 0 0', display: 'flex'}}>
                <ToggleButton keys={"status"}/> <div style={{paddingLeft: '0.5rem'}}>進捗状況</div>
              </div>
              <div style={{padding: '0.75rem 0 0 0', display: 'flex'}}>
                <ToggleButton keys={"calendar"}/> <div style={{paddingLeft: '0.5rem'}}>カレンダー</div>
              </div>
              <div style={{padding: '0.75rem 0 0 0', display: 'flex'}}>
                <ToggleButton keys={"table"}/> <div style={{paddingLeft: '0.5rem'}}>勤務表</div>
              </div>
              <div style={{padding: '0.75rem 0 0 0', display: 'flex'}}>
                <ToggleButton keys={"shift"}/> <div style={{paddingLeft: '0.5rem'}}>今日のシフト</div>
              </div>
              <div style={{padding: '0.75rem 0 0 0', display: 'flex'}}>
                <ToggleButton keys={"staff"}/> <div style={{paddingLeft: '0.5rem'}}>勤務情報</div>
              </div>
              <div style={{padding: '0.75rem 0 0 0', display: 'flex'}}>
                <ToggleButton keys={"message"}/> <div style={{paddingLeft: '0.5rem'}}>連絡事項</div>
              </div>
              
            </div>
          )}
        </Box>
    </ThemeProvider>
  );
}

const TopPage = () => {
    return <DashboardContent />;
  };
  
export default TopPage;