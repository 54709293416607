import { setcol } from "../../Table/colors";
import React, { useRef, useEffect, useReducer, useState } from "react";
import "../../styles.css";
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import { BarChart, XAxis, YAxis, Bar, Tooltip, Legend, PieChart, Pie, Cell, Label, LabelList } from 'recharts';

function Slider({ value, onValueChange }) {
  const handleChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    onValueChange(newValue);
  };

  return (
      <input
        type="range"
        min={50}
        max={150}
        value={value}
        onChange={handleChange}
        style={{ 
          width: '130px',
          background: 'gray',
          appearance: 'none', 
          outline: 'none', 
          height: '3px', 
          borderRadius: '5px',
        }}
      />
  );
}


class ExcelRequestsImport extends React.Component {
  state = {
    cols: [],
    rows: []
  };

  constructor(props) {
    super(props);
    this.state = {
      keyvalue: { ward: '', year: '', month: '' },
    };
  }

  componentDidMount() {
    const localvalue = JSON.parse(localStorage.getItem("Storage-show"));
    this.setState({ keyvalue: localvalue });
  }

  render() {
    if (sessionStorage.getItem("Login")==="1"){
      sessionStorage.setItem("Login", "1")
    }    
    let Byoutou = this.state.keyvalue.ward
    let byoutou = Byoutou.toLocaleLowerCase()

    const JapaneseHolidays = require('japanese-holidays');
    
    const thisyear = this.state.keyvalue.year
    const nextmonth = this.state.keyvalue.month
    const thismonth = (nextmonth=="1") ? "12" : String(Number(nextmonth) - 1)
    const nextnextmonth = (nextmonth=="12") ? "1" : String(Number(nextmonth) + 1)
    const nextyear = (nextmonth=="12") ? String(Number(thisyear) + 1) : thisyear
    var prelastDate = new Date(thisyear, thismonth, 0);
    const predays = prelastDate.getDate()
    var lastDate = new Date(thisyear, nextmonth, 0);
    var days = lastDate.getDate()
    const isholiday = JapaneseHolidays.getHolidaysOf(lastDate.getFullYear(), true);
    const preyear = (nextmonth==1) ? String(Number(thisyear)-1) : thisyear
    const today = new Date()

    const holiday = []
    for (let i = 0; i < isholiday.length; i++){
      if (isholiday[i].month===lastDate.getMonth()+1){
        holiday.push(isholiday[i].date)
      }
    }

    const weekend = []
    const whatday = []
    for (let i = 1; i <= days; i++){
      var day = new Date(thisyear, lastDate.getMonth(), i)
      if ([0, 6].includes(day.getDay())){
        weekend.push(String(i))
      }
      if (holiday.includes(i)){
        if (!weekend.includes(i)){
          weekend.push(String(i))
        }
      }
      if ([0].includes(day.getDay())){
        whatday.push("日")
      }
      if ([1].includes(day.getDay())){
        whatday.push("月")
      }
      if ([2].includes(day.getDay())){
        whatday.push("火")
      }
      if ([3].includes(day.getDay())){
        whatday.push("水")
      }
      if ([4].includes(day.getDay())){
        whatday.push("木")
      }
      if ([5].includes(day.getDay())){
        whatday.push("金")
      }
      if ([6].includes(day.getDay())){
        whatday.push("土")
      }
    }

    let optionsyear = [
      { value: '2023', label: '2023' },
    ]
    const ydev = thisyear - 2023
    for (let i = 1; i <= ydev; i++){
      optionsyear.push({value: String(2023+i), label: String(2023+i)})
    }

    if (localStorage.getItem('e1' + byoutou + '-' + thisyear + '-' + nextmonth)!=2 || localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth)==null){
      localStorage.setItem('e1' + byoutou + '-' + thisyear + '-' + nextmonth, 1)
    }

    if (localStorage.getItem('Request-' + Byoutou  + thisyear + '-' + thismonth)==null){
      let mdata = [];
      var num = 0
        const staffdata = (JSON.parse(localStorage.getItem('Staff-' + Byoutou ))!==null) ? JSON.parse(localStorage.getItem('Staff-' + Byoutou )) : []
        for (let i = 1; i<=staffdata.length; i++) {
            if (staffdata[i-1].ID !== "" && staffdata[i-1].ID !== undefined){
                num = num + 1
            }
        }
    
        for (let i = 1; i <= num; i++) {
            const istaffdata = staffdata[i-1]
            let mrow = {
              ID: istaffdata.ID + " " + istaffdata.name,
            }
            for (let j = 1; j <= predays; j++){
              mrow["d" + String(j)] = ""
            }
            mdata.push(mrow);
          }
          localStorage.setItem('Request-' + Byoutou  + thisyear + '-' + thismonth, JSON.stringify(mdata))
    }

    const shiftdata = (JSON.parse(localStorage.getItem("Shift-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Shift-"+Byoutou)) : []
    const teamdata = (JSON.parse(localStorage.getItem("Team-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Team-"+Byoutou)) : []
    const skillleveldata = (JSON.parse(localStorage.getItem("Skill-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Skill-"+Byoutou)) : []

    let shiftoptions = [];
    let offshift = [];

    shiftdata.forEach((data,i) => {
      if ((data.shift=="〇" || data.shift=="L" || data.shift.includes("/") || data.shift.includes("長")) && (data.type=="日勤系" || data.type=="準夜勤系" || data.type=="ロング系")){
        if (teamdata.length>0 && data.shift!=="L"){
          if (data.shift.includes("遅")){
            shiftoptions.push(data.label);
          } else {
          for (const t in teamdata){
              shiftoptions.push(data.shift + teamdata[t].team)
          }
        }
        } else {
            shiftoptions.push(data.shift)
        }
      } else {
        if (data.type!=="禁止系" && data.type!=="選択系" && data.type!=="連続系" && data.type!=="公休" && data.type!=="休み系"){
          shiftoptions.push(data.shift);
        }
        if (data.type=="公休" || data.type=="休み系"){
          offshift.push(data.shift);
        }
      }
    })

    const types = shiftdata
    .filter(item => !["連続系", "禁止系", "選択系"].includes(item.type))
    .map(item => item.type);   
    const colors = shiftdata
    .filter(item => !["連続系", "禁止系", "選択系"].includes(item.type))
    .map(item => item.color);    
    const shifttypes = [...new Set(types)];
    const typecolor = [...new Set(colors)];

    let teamoptions = teamdata.map((data,i) => {
      return { label: data.team, value: data.team, backgroundColor: data.color };
    })

    let skilloptions = skillleveldata.map((data,i) => {
      return { label: data.skill, value: data.skill, backgroundColor: data.color };
    })

    let checkoptions = [
      {label: "可", backgroundColor: setcol(9)},
      {label: "", backgroundColor: setcol(1)},
    ]

    let dayoptions = []
    for (let i = 1; i <= days; i++) {
      let ds = {
          label: String(i),
          value: i,
          };
      dayoptions.push(ds);
    }

    let daysoptions = [
      {label: "月", value: "mon"},
      {label: "火", value: "tue"},
      {label: "水", value: "wed"},
      {label: "木", value: "thu"},
      {label: "金", value: "fri"},
      {label: "土", value: "sat"},
      {label: "日", value: "sun"},
      {label: "土日", value: "satsun"},
      {label: "土日+祝日", value: "weekend"},
    ]
    let daysoptions2 = [
      {label: "月", value: "月"},
      {label: "火", value: "火"},
      {label: "水", value: "水"},
      {label: "木", value: "木"},
      {label: "金", value: "金"},
      {label: "土", value: "土"},
      {label: "日", value: "日"},
    ]
    let holidayoptions = [
      {label: "平日", value: "平日"},
      {label: "土日", value: "土日"},
      {label: "祝日", value: "祝日"},
    ]

    let sexoptions = [
      {label: "男性", backgroundColor: setcol(9)},
      {label: "女性", backgroundColor: setcol(9)},
      {label: "", backgroundColor: setcol(1)},
    ]

    function useLatest(value) {
        const ref = useRef(value);
        ref.current = value;
        return ref;
    }

    function useKeybind({
        altKey,
        ctrlKey,
        metaKey,
        shiftKey,
        key,
        onKeyDown,
        targetRef,
        }) {
        const onKeyDownLatest = useLatest(onKeyDown);

        useEffect(() => {
            const eventListener = (event) => {
            if (altKey && !event.altKey) return;
            if (ctrlKey && !event.ctrlKey) return;
            if (metaKey && !event.metaKey) return;
            if (shiftKey && !event.shiftKey) return;
            if (event.key !== key) return;

            event.preventDefault();
            onKeyDownLatest.current?.(event);
            };

            if (targetRef?.current) {
            const target = targetRef.current;

            target.addEventListener("keydown", eventListener);
            return () => target.removeEventListener("keydown", eventListener);
            } else {
            window.addEventListener("keydown", eventListener);
            return () => window.removeEventListener("keydown", eventListener);
            }
        }, [altKey, ctrlKey, key, metaKey, onKeyDownLatest, shiftKey, targetRef]);
    }
    
    const staffdata = (JSON.parse(localStorage.getItem('Staff-' + Byoutou ))!==null) ? JSON.parse(localStorage.getItem('Staff-' + Byoutou )) : []
    const staffcol = (JSON.parse(localStorage.getItem('Staff-Columns-' + Byoutou ))!==null) ? JSON.parse(localStorage.getItem('Staff-Columns-' + Byoutou )) : []

    const {keyvalue} = this.state;

    // 表、ボタン、セレクトボックスなどを表示する関数
    function TableShow() {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;

      const [selectedOption, setSelectedOption] = useState('month');
      const [startYear, setStartYear] = useState(currentYear);
      const [endYear, setEndYear] = useState(currentYear);
      const [startMonth, setStartMonth] = useState(currentMonth);
      const [endMonth, setEndMonth] = useState(currentMonth);

      const [period, setPeriod] = useState([])

      const [activeTab, setActiveTab] = useState('全体');

      const [activeStaff, setActiveStaff] = useState(null);
      
      const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
      };

      const handleMoveLeft = () => {
        if (startMonth === 1 && endMonth === 1) {
          setStartMonth(12);
          setEndMonth(12);
          setStartYear(startYear - 1);
          setEndYear(endYear - 1);
        } else {
          setStartMonth((prev) => prev === 1 ? 12 : prev - 1);
          setEndMonth((prev) => prev === 1 ? 12 : prev - 1);
        }
      };
    
      const handleMoveRight = () => {
        if (startMonth === 12 && endMonth === 12) {
          setStartMonth(1);
          setEndMonth(1);
          setStartYear(startYear + 1);
          setEndYear(endYear + 1);
        } else {
          setStartMonth((prev) => prev === 12 ? 1 : prev + 1);
          setEndMonth((prev) => prev === 12 ? 1 : prev + 1);
        }
      };

      useEffect(() => {
        setStartYear(selectedOption === 'fiscalYear' ? currentYear - 1 : currentYear)
        setStartMonth(selectedOption === 'year' ? 1 : selectedOption === 'fiscalYear' ? 4 : currentMonth)
        setEndYear(selectedOption === 'year' ? currentYear : selectedOption === 'fiscalYear' ? startYear + 1 : currentYear)
        setEndMonth(selectedOption === 'year' ? 12 : selectedOption === 'fiscalYear' ? 3 : currentMonth)
      }, [selectedOption]);

      const dateList = [];
      for (let year = startYear; year <= endYear; year++) {
        for (let month = (year === startYear ? startMonth : 1); 
            month <= (year === endYear ? endMonth : 12); 
            month++) {
          dateList.push({ year, month });
        }
      }

      let tabledata = []
      staffdata.forEach((data, i)=>{
        if (activeTab=='全体'){
          let row = {
            スタッフID: data.ID + ' ' + data.name
          }
  
          let count = 0;
          let offcount = 0;
          dateList.forEach(list=>{
            const localdata = localStorage.getItem('Edit-' + Byoutou  + list.year + '-' + list.month)
            if (localdata){
              const ilocaldata = JSON.parse(localdata)[i]
              for (const key in ilocaldata) {
                if (ilocaldata.hasOwnProperty(key) && shiftoptions.some(item => ilocaldata[key].includes(item))) {
                  count++;
                }
              }
              for (const key in ilocaldata) {
                if (ilocaldata.hasOwnProperty(key) && offshift.some(item => ilocaldata[key].includes(item))) {
                  offcount++;
                }
              }
            }
          })
  
          row["勤務日数"] = count        
          row["休暇日数"] = offcount        
          tabledata.push(row)
        } else 
        if (activeTab=='シフトジャンル別'){
          let row = {
            スタッフID: data.ID + ' ' + data.name
          }

          shifttypes.forEach(stype=>{
            let count = 0;
            const selectedtype = shiftdata.filter(item => item.type === stype);
            const selectedtypelabels = selectedtype.map(item => item.label);
            dateList.forEach(list=>{
              const localdata = localStorage.getItem('Edit-' + Byoutou  + list.year + '-' + list.month)
              if (localdata){
                const ilocaldata = JSON.parse(localdata)[i]
                for (const key in ilocaldata) {
                  if (ilocaldata.hasOwnProperty(key) && selectedtypelabels.some(item => ilocaldata[key].includes(item))) {
                    count++;
                  }
                }

              }
            })
    
            row[stype] = count        
          })
       
          tabledata.push(row)
        } else 
        if (activeTab=='シフト別'){
          let row = {
            スタッフID: data.ID + ' ' + data.name
          }

          shiftoptions.forEach(option=>{
            let count = 0;
            dateList.forEach(list=>{
              const localdata = localStorage.getItem('Edit-' + Byoutou  + list.year + '-' + list.month)
              if (localdata){
                const ilocaldata = JSON.parse(localdata)[i]
                for (const key in ilocaldata) {
                  if (ilocaldata.hasOwnProperty(key) && ilocaldata[key].includes(option)) {
                    count++;
                  }
                }

              }
            })
    
            row[option] = count        
          })

          let count = 0;
          dateList.forEach(list=>{
            const localdata = localStorage.getItem('Edit-' + Byoutou  + list.year + '-' + list.month)
            if (localdata){
              const ilocaldata = JSON.parse(localdata)[i]
              for (const key in ilocaldata) {
                if (ilocaldata.hasOwnProperty(key) && shiftoptions.some(item => ilocaldata[key].includes(item))) {
                  count++;
                }
              }
            }
          })
  
          row["合計"] = count        
       
          tabledata.push(row)
        } else 
        if (activeTab=='休暇'){
          let row = {
            スタッフID: data.ID + ' ' + data.name
          }

          offshift.forEach(option=>{
            let count = 0;
            dateList.forEach(list=>{
              const localdata = localStorage.getItem('Edit-' + Byoutou  + list.year + '-' + list.month)
              if (localdata){
                const ilocaldata = JSON.parse(localdata)[i]
                for (const key in ilocaldata) {
                  if (ilocaldata.hasOwnProperty(key) && ilocaldata[key].includes(option)) {
                    count++;
                  }
                }

              }
            })
    
            row[option] = count        
          })

          let offcount = 0;
          dateList.forEach(list=>{
            const localdata = localStorage.getItem('Edit-' + Byoutou  + list.year + '-' + list.month)
            if (localdata){
              const ilocaldata = JSON.parse(localdata)[i]
              for (const key in ilocaldata) {
                if (ilocaldata.hasOwnProperty(key) && offshift.some(item => ilocaldata[key].includes(item))) {
                  offcount++;
                }
              }
            }
          })
  
          row["合計"] = offcount  
       
          tabledata.push(row)
        } else 
        if (activeTab=='勤務時間'){

        }
      })

      const maxTotal = Math.max(...tabledata.map(item => item['勤務日数'] + item['休暇日数']));
      const ticks = [];
      for (let i = 0; i <= maxTotal; i += 5) {
        ticks.push(i);
      }

      

      const keysArray = [];
      for (const key in tabledata[0]) {
        keysArray.push(key);
      }
      
      const [showTooltip, setShowTooltip] = useState(false);

      const handleMouseEnter = () => {
        setShowTooltip(true);
      };

      const handleMouseLeave = () => {
        setShowTooltip(false);
      };

      const [showTooltip2, setShowTooltip2] = useState(false);

      const handleMouseEnter2 = () => {
        setShowTooltip2(true);
      };

      const handleMouseLeave2 = () => {
        setShowTooltip2(false);
      };

      const handleTabClick = (tab) => {
        setActiveTab(tab);
      };

      const [modalPosition, setModalPosition] = useState(0);

      const handleMouseOver = (event, staffData) => {
        setActiveStaff(staffData);
        const cellRect = event.target.getBoundingClientRect();
        setModalPosition(cellRect.top);
      };
      
      const calvalue = tabledata.map(staff => ({
        staffname: staff['スタッフID'],
        勤務日数: String(((staff['勤務日数'] / (staff['勤務日数'] + staff['休暇日数'])) * 100).toFixed(0)) + '%',
        休暇日数: String(((staff['休暇日数'] / (staff['勤務日数'] + staff['休暇日数'])) * 100).toFixed(0)) + '%',
      }));

      const cv = (staff, name) => {
        if (staff){
          if (activeTab=='全体'){
            return ((staff[name] / (staff['勤務日数'] + staff['休暇日数'])) * 100).toFixed(0)
          } else
          if (activeTab=='シフトジャンル別'){
            return ((100*activeStaff[name])/Object.values(activeStaff).filter((value, index) => index !== 0 && typeof value === 'number').reduce((acc, curr) => acc + curr, 0))
          }
        } else {
          return 0
        }
      }

      const angle = cv(activeStaff, '勤務日数') / 100 / 2 * 360; 
      const angle2 = - ((100 - cv(activeStaff, '勤務日数') / 100) / 2 * 360); 
      const radius = 50; 
      
      const x = Math.cos(angle * Math.PI / 180) * radius; 
      const y = Math.sin(angle * Math.PI / 180) * radius; 

      const x2 = Math.cos(angle2 * Math.PI / 180) * radius; 
      const y2 = Math.sin(angle2 * Math.PI / 180) * radius; 

      const rgbToHex = (r, g, b) => {
        return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
      };
      
      // ランダムなRGB値を生成してHTMLカラーコード（Hex形式）に変換する関数
      const getRandomHexColor = () => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return rgbToHex(r, g, b);
      };
      
      // 指定された数のランダムHexカラーコードを生成する関数
      const generateRandomHexColors = (count) => {
        const colors = [];
        for (let i = 0; i < count; i++) {
          colors.push(getRandomHexColor());
        }
        return colors;
      };

      //const typecolor = generateRandomHexColors(shifttypes.length)

      const [activeCell, setActiveCell] = useState(null);

      const handleMouseOvercell = (event, row, column) => {
        setActiveCell({ row, column });
      };

      const handleMouseLeavecell = () => {
        setActiveCell(null);
      };

      return (
        <div onContextMenu={(e) => {e.preventDefault()}}>
          <div style={{height: '100%'}}>

            <div style={{padding: '1rem 1rem 0 1rem', paddingBottom: '1rem'}}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{display: 'flex'}}>
                  <label style={{cursor: 'pointer'}}>
                    <input
                      type="radio"
                      value="year"
                      checked={selectedOption === 'year'}
                      onChange={handleOptionChange}
                    />
                    年
                  </label>

                  <label style={{paddingLeft: '0.3rem',cursor: 'pointer'}}>
                    <input
                      type="radio"
                      value="fiscalYear"
                      checked={selectedOption === 'fiscalYear'}
                      onChange={handleOptionChange}
                    />
                    年度
                  </label>

                  <label style={{paddingLeft: '0.3rem',cursor: 'pointer'}}>
                    <input
                      type="radio"
                      value="month"
                      checked={selectedOption === 'month'}
                      onChange={handleOptionChange}
                    />
                    月
                  </label>
                </div>

                <div>
                  <button
                    onClick={() => handleTabClick('全体')}
                    className={activeTab === '全体' ? 'active-tab' : 'inactive-tab'}
                  >
                    &nbsp;&nbsp;全体&nbsp;&nbsp;
                  </button>
                  <button
                    onClick={() => handleTabClick('シフトジャンル別')}
                    className={activeTab === 'シフトジャンル別' ? 'active-tab' : 'inactive-tab'}
                  >
                    &nbsp;&nbsp;シフトジャンル別&nbsp;&nbsp;
                  </button>
                  <button
                    onClick={() => handleTabClick('シフト別')}
                    className={activeTab === 'シフト別' ? 'active-tab' : 'inactive-tab'}
                  >
                    &nbsp;&nbsp;シフト別&nbsp;&nbsp;
                  </button>
                  <button
                    onClick={() => handleTabClick('休暇')}
                    className={activeTab === '休暇' ? 'active-tab' : 'inactive-tab'}
                  >
                    &nbsp;&nbsp;休暇&nbsp;&nbsp;
                  </button>
                  {/*<button
                    onClick={() => handleTabClick('勤務時間')}
                    className={activeTab === '勤務時間' ? 'active-tab' : 'inactive-tab'}
                  >
                    &nbsp;&nbsp;勤務時間&nbsp;&nbsp;
                  </button>*/}
                </div>
              </div>

              <div style={{paddingTop: '0.3rem'}}>
                {selectedOption === 'year' || selectedOption === 'fiscalYear' ? (
                  <div style={{display: 'flex'}}>
                    <label style={{display: 'flex', alignItems: 'center'}}>期間：</label>
                    <select
                      value={startYear}
                      onChange={(e) => {
                        setStartYear(parseInt(e.target.value));
                      }}
                    >
                      {optionsyear.map((year) => (
                        <option key={year.label} value={year.label}>
                          {year.label} 
                        </option>
                      ))}
                    </select>
                    
                    <div style={{padding: '0 0.1rem 0 0.1rem'}}>
                      年{selectedOption === 'fiscalYear' && "度"}
                    </div>
                  </div>
                ) : null}

                {selectedOption === 'month' ? (
                  <div style={{display: 'flex'}}>
                    <label style={{display: 'flex', alignItems: 'center'}}>期間：</label>

                    <div style={{position: 'relative', display: 'inline-block'}}>
                    <ArrowLeftOutlinedIcon 
                      onClick={handleMoveLeft} 
                      style={{ cursor: 'pointer', marginRight: '-0.2rem'}}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave} />
                    
                    {showTooltip && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '100%',
                          left: '50%',
                          transform: 'translateX(-50%)',
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          color: '#fff',
                          padding: '8px',
                          borderRadius: '4px',
                          zIndex: 10,
                          fontSize: 12,
                          width: 40,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        前月
                      </div>
                    )}
                    </div>

                    <select
                      value={startYear}
                      onChange={(e) => setStartYear(parseInt(e.target.value))}
                    >
                      {optionsyear.map((year) => (
                        <option key={year.label} value={year.label}>
                          {year.label}
                        </option>
                      ))}
                    </select>

                    <div style={{padding: '0 0.1rem 0 0.1rem', display: 'flex', alignItems: 'center'}}>
                      年
                    </div>

                    <select
                      value={startMonth}
                      onChange={(e) => setStartMonth(parseInt(e.target.value))}
                    >
                      {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                    <div style={{padding: '0 0.1rem 0 0.1rem', display: 'flex', alignItems: 'center'}}>
                      月～
                    </div>

                    <select
                      value={endYear}
                      onChange={(e) => setEndYear(parseInt(e.target.value))}
                    >
                      {optionsyear.map((year) => (
                        <option key={year.label} value={year.label}>
                          {year.label}
                        </option>
                      ))}
                    </select>

                    <div style={{padding: '0 0.1rem 0 0.1rem', display: 'flex', alignItems: 'center'}}>
                      年
                    </div>

                    <select
                      value={endMonth}
                      onChange={(e) => setEndMonth(parseInt(e.target.value))}
                    >
                      {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                    <div style={{padding: '0 0.1rem 0 0.1rem', display: 'flex', alignItems: 'center'}}>
                      月
                    </div>

                    <div style={{position: 'relative', display: 'inline-block'}}>
                    <ArrowRightOutlinedIcon 
                      onClick={handleMoveRight} 
                      style={{ cursor: 'pointer', marginRight: '-0.2rem'}}
                      onMouseEnter={handleMouseEnter2}
                      onMouseLeave={handleMouseLeave2} />
                    
                    {showTooltip2 && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '100%',
                          left: '50%',
                          transform: 'translateX(-50%)',
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          color: '#fff',
                          padding: '8px',
                          borderRadius: '4px',
                          zIndex: 10,
                          fontSize: 12,
                          width: 40,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        次月
                      </div>
                    )}
                    </div>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                    <button style={{cursor: 'pointer',display: 'flex', alignItems: 'center'}} onClick={()=>{setStartYear(currentYear); setStartMonth(currentMonth); setEndYear(currentYear); setEndMonth(currentMonth)}}>
                      今月
                    </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              {<div style={{margin: '0 1rem 1rem 1rem', height: '500px', overflowY: 'auto'}}>
                <table className="styled-table2">
                  <thead>
                    <tr>
                      {keysArray.map((key) => (
                        <th >{key}</th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                  {tabledata.map((row, index) => (
                    <tr key={index}>
                      {keysArray.map((key, subIndex) => (
                        <td 
                          key={index + '-' + subIndex} 
                          onMouseOver={(e) => {subIndex==0 && handleMouseOver(e, row); handleMouseOvercell(e, index, subIndex)}} 
                          onMouseLeave={() => {setActiveStaff(null); handleMouseLeavecell()}}
                          className={(activeCell && activeCell.row === index && activeCell.column === subIndex) ? 'active-cell' : (activeCell && activeCell.row === index && activeCell.column !== subIndex)  ? 'active-row' : activeCell && activeCell.column === subIndex && activeCell.row !== index ? 'active-column' : ''}
                          >
                          {row[key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>}

                {activeTab=='全体' && activeStaff && (
                  <div 
                    className='shadow-5 bg-white border-radius-md'
                    style={{
                      zIndex: 100,
                      position: 'absolute',
                      top: modalPosition - modalPosition/4.4,
                      left: '180px',
                      width: '180px',
                    }}>
                    <div style={{marginTop: '0.5rem', display: 'flex', justifyContent: 'center'}}>
                      {activeStaff['スタッフID']}
                    </div>
                    
                    <div style={{
                      position: 'absolute',
                      top: `calc(50% + 13px - ${y}px)`,
                      left: `calc(50% + ${x}px)`,
                      transform: `translate(-50%, -50%)`,
                      backgroundColor: 'white',
                      padding: '2px', 
                      borderRadius: '5px',
                      fontSize: '12px',
                      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
                      zIndex: 5,
                      width: '60px',
                      color: '#8884d8'
                    }}>
                      {'勤務: ' + cv(activeStaff, '勤務日数') + '%'}
                    </div>
                    <div style={{
                      position: 'absolute',
                      top: `calc(50% + 13px + ${y2}px)`,
                      left: `calc(50% - ${x2}px)`,
                      transform: `translate(-50%, -50%)`,
                      backgroundColor: 'white',
                      padding: '2px', 
                      borderRadius: '5px',
                      fontSize: '12px',
                      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
                      zIndex: 5,
                      width: '60px',
                      color: '#82ca9d'
                    }}>
                      {'休暇: ' + cv(activeStaff, '休暇日数') + '%'}
                    </div>
                  
                  <div style={{display: 'flex', justifyContent: 'center', marginTop: '-3rem', paddingBottom: '1.3rem', marginLeft: '-55px'}}>
                  <PieChart width={150} height={150} isAnimationActive={false}>
                    <Pie
                      data={[
                        { name: `勤務: ${calvalue.find(item => item.staffname === activeStaff['スタッフID'])["勤務日数"]}`, value: activeStaff['勤務日数'] },
                        { name: `休暇: ${calvalue.find(item => item.staffname === activeStaff['スタッフID'])["休暇日数"]}`, value: activeStaff['休暇日数'] }
                      ]}
                      dataKey="value"
                      cx={100}
                      cy={100}
                      outerRadius={40}
                      innerRadius={20}
                      fill="#8884d8"
                    >
                      {['#8884d8', '#82ca9d'].map((color, index) => (
                        <Cell key={`cell-${index}`} fill={color} />
                      ))}
                    </Pie>
                  </PieChart>
                  </div>
                  </div>
                )}

              {activeTab=='全体' && (
                <div style={{overflowX: 'auto', overflowY: 'hidden'}}>
                  <BarChart
                    width={600}
                    height={375}
                    data={tabledata}
                    margin={{ top: 20, right: 20, left: 0, bottom: 50 }}
                  >
                    <XAxis dataKey="スタッフID" tick={{ fontSize: 11 }} angle={-45} textAnchor="end" interval={0} />
                    <YAxis domain={[0, maxTotal]} ticks={ticks} />
                    <Tooltip />
                    <Legend align="right" verticalAlign="top"/>
                    <Bar dataKey="勤務日数" fill="#8884d8" />
                    <Bar dataKey="休暇日数" fill="#82ca9d" />
                  </BarChart>
                </div>
              )}

              {activeTab=='シフトジャンル別' && activeStaff && (
                  <div 
                    className='shadow-5 bg-white border-radius-md'
                    style={{
                      zIndex: 100,
                      position: 'absolute',
                      top: modalPosition - modalPosition/2.5,
                      left: '180px',
                      width: '300px',
                    }}>
                    <div style={{marginTop: '0.5rem', display: 'flex', justifyContent: 'center'}}>
                      {activeStaff['スタッフID']}
                    </div>

                  <div style={{
                    display: 'flex', 
                    justifyContent: 'center', 
                    marginTop: '-3rem', 
                    paddingBottom: '0.2rem'
                    }}>
                  <PieChart width={315} height={260} isAnimationActive={false}>
                    <Pie
                      data={
                        shifttypes.map(type=>{
                          return { name: type, value: cv(activeStaff, type) }
                        })
                      }
                      cx={150}
                      cy={150}
                      label={(entry) => {
                        if (entry.value.toFixed(0)!=0){
                          const textContent = `${entry.name}: ${entry.value.toFixed(0)}%`;

                          return (
                            <g>
                            <rect
                              x={entry.x - 40} // x座標を調整してテキストの左側に配置
                              y={entry.y - 9} // y座標を調整してテキストの上側に配置
                              width={80} // 四角形の幅
                              height={18} // 四角形の高さ
                              fill={typecolor[shifttypes.findIndex(element => element === entry.name)]} // 背景色
                              stroke="#000" // 枠線の色
                              strokeWidth={0.5} // 枠線の幅
                            />
                            <text x={entry.x} y={entry.y-4} dy={8} textAnchor="middle" fontSize={12}>
                              {textContent}
                            </text>
                            </g>
                          )
                        }
                      }}
                      outerRadius={70}
                      innerRadius={40}
                      fill="#8884d8"
                      dataKey="value"
                      labelLine={false}
                    >
                      {typecolor.map((color, index) => (
                        <Cell key={`cell-${index}`} fill={color} />
                      ))}
                      </Pie>
                  </PieChart>
                  </div>
                  </div>
                )}

            </div>

          </div>
        </div>
        );
      }

    return (
      <div className="excel-table-container">
        <div className="foo">
        </div>
        <div>
          <TableShow/>
        </div>
      </div>
    );
  }
}

export default ExcelRequestsImport;