import { setcol } from "../../../Table/colors";
import React, { useRef, useEffect, useReducer, useState } from "react";
import "../../../styles.css";
import TableEdit from "../../../Table/TableEdit";
import TableEditPre from "../../../Table/TablePre";
import TableUneditable from "../Storage/TableS"
import { randomColor, shortId } from "../../../Table/utils";
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import Button from '@mui/material/Button';
import Select from 'react-select'
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import {Link} from "react-router-dom";
import Paper from '@mui/material/Paper';
import {usePopper} from "react-popper";
import Relationship from "../../../Table/Relationship";
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Loader from "react-spinners/BarLoader";

if (sessionStorage.getItem("Login")==="1"){
  sessionStorage.setItem("Login", "1")
}

const localvalue = JSON.parse(localStorage.getItem("Schedule-key"));

let Byoutou = localvalue==null ? "" : localvalue.ward
let byoutou = Byoutou.toLocaleLowerCase()

const JapaneseHolidays = require('japanese-holidays');

const thisyear = localvalue==null ? "" : localvalue.year
const nextmonth = localvalue==null ? "" : localvalue.month
const thismonth = (nextmonth=="1") ? "12" : nextmonth - 1
var prelastDate = new Date(thisyear, thismonth, 0);
const predays = prelastDate.getDate()
var lastDate = new Date(thisyear, nextmonth, 0);
var days = lastDate.getDate()
const isholiday = JapaneseHolidays.getHolidaysOf(lastDate.getFullYear(), true);

var today = new Date()

const holiday = []
for (let i = 0; i < isholiday.length; i++){
    if (isholiday[i].month===lastDate.getMonth()+1){
    holiday.push(isholiday[i].date)
    }
}

const weekend = []
const whatday = []
for (let i = 1; i <= days; i++){
    var day = new Date(thisyear, lastDate.getMonth(), i)
    if ([0, 6].includes(day.getDay())){
    weekend.push(String(i))
    }
    if (holiday.includes(i)){
    if (!weekend.includes(i)){
        weekend.push(String(i))
    }
    }
    if ([0].includes(day.getDay())){
    whatday.push("日")
    }
    if ([1].includes(day.getDay())){
    whatday.push("月")
    }
    if ([2].includes(day.getDay())){
    whatday.push("火")
    }
    if ([3].includes(day.getDay())){
    whatday.push("水")
    }
    if ([4].includes(day.getDay())){
    whatday.push("木")
    }
    if ([5].includes(day.getDay())){
    whatday.push("金")
    }
    if ([6].includes(day.getDay())){
    whatday.push("土")
    }
}

function Slider({ value, onValueChange }) {
  const handleChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    onValueChange(newValue);
  };

  return (
    <div>
      <input
        type="range"
        min={1}
        max={90}
        value={value}
        onChange={handleChange}
        style={{ width: '200px' }}
      />
      <p style={{display: 'flex', justifyContent: 'center'}}>実行時間: {value}分</p>
    </div>
  );
}
    

class ExcelRequestsImport extends React.Component {
  state = {
    cols: [],
    rows: []
  };

  constructor(props) {
    super(props);
    this.state = {
      keyvalue: { ward: '', year: '', month: '' },
    };
  }

  componentDidMount() {
    const localvalue = JSON.parse(localStorage.getItem("Schedule-key"));
    this.setState({ keyvalue: localvalue });
  }

  render() {
    let Byoutou = this.state.keyvalue.ward
    let byoutou = Byoutou.toLocaleLowerCase()

    const JapaneseHolidays = require('japanese-holidays');
    
    const thisyear = this.state.keyvalue.year
    const nextmonth = this.state.keyvalue.month
    const thismonth = (nextmonth=="1") ? "12" : nextmonth - 1
    var prelastDate = new Date(thisyear, thismonth, 0);
    const predays = prelastDate.getDate()
    var lastDate = new Date(thisyear, nextmonth, 0);
    var days = lastDate.getDate()
    const isholiday = JapaneseHolidays.getHolidaysOf(lastDate.getFullYear(), true);

    const holiday = []
    for (let i = 0; i < isholiday.length; i++){
      if (isholiday[i].month===lastDate.getMonth()+1){
        holiday.push(isholiday[i].date)
      }
    }

    const weekend = []
    const whatday = []
    for (let i = 1; i <= days; i++){
      var day = new Date(thisyear, lastDate.getMonth(), i)
      if ([0, 6].includes(day.getDay())){
        weekend.push(String(i))
      }
      if (holiday.includes(i)){
        if (!weekend.includes(i)){
          weekend.push(String(i))
        }
      }
      if ([0].includes(day.getDay())){
        whatday.push("日")
      }
      if ([1].includes(day.getDay())){
        whatday.push("月")
      }
      if ([2].includes(day.getDay())){
        whatday.push("火")
      }
      if ([3].includes(day.getDay())){
        whatday.push("水")
      }
      if ([4].includes(day.getDay())){
        whatday.push("木")
      }
      if ([5].includes(day.getDay())){
        whatday.push("金")
      }
      if ([6].includes(day.getDay())){
        whatday.push("土")
      }
    }

    const shiftdata = (JSON.parse(localStorage.getItem("Shift-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Shift-"+Byoutou)) : []
    const teamdata = (JSON.parse(localStorage.getItem("Team-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Team-"+Byoutou)) : []
    const skillleveldata = (JSON.parse(localStorage.getItem("Skill-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Skill-"+Byoutou)) : []
    const constdata = JSON.parse(localStorage.getItem('Const-'  + Byoutou))!==null ? JSON.parse(localStorage.getItem('Const-'  + Byoutou)) : []
    const limitdata = JSON.parse(localStorage.getItem('Limit-'  + Byoutou))!==null ? JSON.parse(localStorage.getItem('Limit-'  + Byoutou)) : []
    const requestdata = JSON.parse(localStorage.getItem('Edit-'  + Byoutou  + thisyear + '-' + nextmonth))!==null ? JSON.parse(localStorage.getItem('Edit-'  + Byoutou  + thisyear + '-' + nextmonth)) : []

    let shiftoptions = [];

    shiftdata.forEach((data,i) => {
      if (data.shift=="〇" || data.shift=="1/" ||  data.shift=="2/" ||  data.shift=="5/" ||  data.shift=="長1" ||   data.shift=="長2"){
        if (teamdata.length>0 && !(Array.isArray(teamdata) && teamdata.length===1 && Object.keys(teamdata[0]).length===0)){
          for (const t in teamdata){
            shiftoptions.push({label: data.shift + teamdata[t].team, backgroundColor: data.color})
          }
        } else {
          shiftoptions.push({label: data.shift, backgroundColor: data.color})
        }
      } else {
        shiftoptions.push({ label: data.shift, backgroundColor: data.color });
      }
    })

    let teamoptions = teamdata.map((data,i) => {
      return { label: data.team, value: data.team, backgroundColor: data.color };
    })

    let skilloptions = skillleveldata.map((data,i) => {
      return { label: data.skill, value: data.skill, backgroundColor: data.color };
    })


    let dayoptions = []
    for (let i = 1; i <= days; i++) {
      let ds = {
          label: String(i),
          value: i,
          };
      dayoptions.push(ds);
    }

    let daysoptions = [
      {label: "月", value: "mon"},
      {label: "火", value: "tue"},
      {label: "水", value: "wed"},
      {label: "木", value: "thu"},
      {label: "金", value: "fri"},
      {label: "土", value: "sat"},
      {label: "日", value: "sun"},
      {label: "土日", value: "satsun"},
      {label: "祝日", value: "weekend"},
    ]

    function useLatest(value) {
        const ref = useRef(value);
        ref.current = value;
        return ref;
    }

    function useKeybind({
        altKey,
        ctrlKey,
        metaKey,
        shiftKey,
        key,
        onKeyDown,
        targetRef,
        }) {
        const onKeyDownLatest = useLatest(onKeyDown);

        useEffect(() => {
            const eventListener = (event) => {
            if (altKey && !event.altKey) return;
            if (ctrlKey && !event.ctrlKey) return;
            if (metaKey && !event.metaKey) return;
            if (shiftKey && !event.shiftKey) return;
            if (event.key !== key) return;

            event.preventDefault();
            onKeyDownLatest.current?.(event);
            };

            if (targetRef?.current) {
            const target = targetRef.current;

            target.addEventListener("keydown", eventListener);
            return () => target.removeEventListener("keydown", eventListener);
            } else {
            window.addEventListener("keydown", eventListener);
            return () => window.removeEventListener("keydown", eventListener);
            }
        }, [altKey, ctrlKey, key, metaKey, onKeyDownLatest, shiftKey, targetRef]);
    }

    // シフトのデータ作成
    let sdata = [];
    let mdata = [];
    let pdata = [];

    var num = 0
    let stlist = []
    const staffdatas = (localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth)!==null) ? JSON.parse(localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth)) : (JSON.parse(localStorage.getItem('Staff-' + Byoutou ))!==null) ? JSON.parse(localStorage.getItem('Staff-' + Byoutou )) : []
    const staffdata = (JSON.parse(localStorage.getItem('Staff-' + Byoutou ))!==null) ? JSON.parse(localStorage.getItem('Staff-' + Byoutou )) : []
    for (let i = 1; i<=staffdatas.length; i++) {
        if (staffdatas[i-1].ID !== "" && staffdatas[i-1].ID !== undefined){
            num = num + 1
        }
        let n = 0
        for (let k = 1; k <= staffdata.length; k++){
          const preid = staffdata[k-1]
          if (preid.ID + " " + preid.name == staffdatas[i-1].ID){
            stlist.push(k-1)
            n = n + 1
          }
        }
        if (n==0){
          stlist.push("n")
        }
    }
    for (let i = 1; i <= num; i++) {
      const istaffdata = (stlist[i-1]!=="n") ? staffdata[stlist[i-1]] : {ID: "", name: "", skill: "", TEAM: "", NL: "", long: "", oso: "", hcu: ""}
      const istaffdatas = staffdatas[i-1]
      let srow = {}
        let mrow = {
          ID: (localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth)!==null) ? istaffdatas.ID : istaffdata.ID + " " + istaffdata.name,
        }
        let prow = {
          ID: (localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth)!==null) ? istaffdatas.ID : istaffdata.ID + " " + istaffdata.name,
        }
        for (let j = 1; j <= days; j++){
          srow["d" + String(j)] = ""
          mrow["d" + String(j)] = ""
        }
        for (let j = 1; j <= predays; j++){
          prow["d" + String(predays+1-j)] = ""
          if (localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + thismonth)!==null){
            const prelocaldata = JSON.parse(localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + thismonth))
            for (let k = 1; k <= prelocaldata.length; k++){
              const preid = prelocaldata[k-1]
              if (istaffdata.ID + " " + istaffdata.name == preid.ID){
                prow["d" + String(predays+1-j)] = preid["d" + String(predays-(predays-j))]
              }
            }  
          }
        }
        sdata.push(srow);
        mdata.push(mrow);
        pdata.push(prow);
      }
    

    let mcolumns = [{
      id: "ID",
      label: "ID",
      accessor: "ID",
      width: 110,
      dataType: "text",
      options: []
    }];

    for (let i = 1; i <= days; i++) {
      let mcol = {
        id: "d" + String(i),
        label: String(i),
        accessor: "d" + String(i),
        dataType: "select",
        width: 40,
        options: shiftoptions
        };
      mcolumns.push(mcol);
    }


    // 表のデータや行、列の変更
    function reducer(state, action) {
      switch (action.type) {
        case "add_option_to_column":
          const optionIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, optionIndex),
              {
                ...state.columns[optionIndex],
                options: [
                  ...state.columns[optionIndex].options,
                  { label: action.option, backgroundColor: action.backgroundColor }
                ]
              },
              ...state.columns.slice(optionIndex + 1, state.columns.length)
            ]
          };
        case "add_row":
          return {
            ...state,
            skipReset: true,
            data: [...state.data, {}]
          };
        case "update_column_type":
          const typeIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          switch (action.dataType) {
            case "number":
              if (state.columns[typeIndex].dataType === "number") {
                return state;
              } else {
                return {
                  ...state,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  data: state.data.map((row) => ({
                    ...row,
                    [action.columnId]: isNaN(row[action.columnId])
                      ? ""
                      : Number.parseInt(row[action.columnId])
                  }))
                };
              }
            case "select":
              if (state.columns[typeIndex].dataType === "select") {
                return {
                  ...state,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  skipReset: true
                };
              } else {
                let options = [];
                state.data.forEach((row) => {
                  if (row[action.columnId]) {
                    options.push({
                      label: row[action.columnId],
                      backgroundColor: randomColor()
                    });
                  }
                });
                return {
                  ...state,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    {
                      ...state.columns[typeIndex],
                      dataType: action.dataType,
                      options: [...state.columns[typeIndex].options, ...options]
                    },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  skipReset: true
                };
              }
            case "text":
              if (state.columns[typeIndex].dataType === "text") {
                return state;
              } else if (state.columns[typeIndex].dataType === "select") {
                return {
                  ...state,
                  skipReset: true,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ]
                };
              } else {
                return {
                  ...state,
                  skipReset: true,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  data: state.data.map((row) => ({
                    ...row,
                    [action.columnId]: row[action.columnId] + ""
                  }))
                };
              }
            default:
              return state;
          }
        case "update_column_header":
          const index = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, index),
              { ...state.columns[index], label: action.label },
              ...state.columns.slice(index + 1, state.columns.length)
            ]
          };
        case "update_cell":
          return {
            ...state,
            skipReset: true,
            data: state.data.map((row, index) => {
              if (index === action.rowIndex) {
                return {
                  ...state.data[action.rowIndex],
                  [action.columnId]: action.value
                };
              }
              return row;
            })
          };
        case "add_column_to_left":
          const leftIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          let leftId = shortId();
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, leftIndex),
              {
                id: leftId,
                label: "Column",
                accessor: leftId,
                dataType: "text",
                created: action.focus && true,
                options: []
              },
              ...state.columns.slice(leftIndex, state.columns.length)
            ]
          };
        case "add_column_to_right":
          const rightIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          const rightId = shortId();
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, rightIndex + 1),
              {
                id: rightId,
                label: "Column",
                accessor: rightId,
                dataType: "text",
                created: action.focus && true,
                options: []
              },
              ...state.columns.slice(rightIndex + 1, state.columns.length)
            ]
          };
        case "delete_column":
          const deleteIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, deleteIndex),
              ...state.columns.slice(deleteIndex + 1, state.columns.length)
            ]
          };
        case "enable_reset":
          return {
            ...state,
            skipReset: false
          };
        default:
          return state;
      }
    }

    const {keyvalue} = this.state;

    if (teamdata.length!==0){
      teamdata.push({team: "", color: "#FFFFFF"})
    }

    // 表、ボタン、セレクトボックスなどを表示する関数
    function TableShow() {
      // 画面サイズ取得
      const [width, setWidth] = useState(null)
      useEffect(() => {
        setWidth(window.innerWidth)
      }, ([]))
      const updateWidth = (event) => {
        setWidth(window.innerWidth)
      }
      useEffect(() => {
        window.addEventListener(`resize`, updateWidth, {
          capture: false,
          passive: true,
        })
  
        return () => window.removeEventListener(`resize`, updateWidth)
      })
      
      // ローカルストレージに保存するための関数
      function handleSave(){
        var today = new Date()
        let time = `${today.getFullYear()}年${String((Number(today.getMonth())) + 1)}月${today.getDate()}日 ${today.getHours()}:${Number(today.getMinutes()) < 10 ? '0' : ''}${today.getMinutes()}`
        let tabledata = JSON.parse(localStorage.getItem('Generated-Table-' + Byoutou  + thisyear + '-' + nextmonth))!==null ? JSON.parse(localStorage.getItem('Generated-Table-' + Byoutou  + thisyear + '-' + nextmonth)) : []
        let title = "シフト埋め"
        tabledata.push({id: tabledata.length+1, type: title, time: time, score: score,  data: JSON.parse(localStorage.getItem("flask"))})
        localStorage.setItem('Generated-Table-' + Byoutou  + thisyear + '-' + nextmonth, JSON.stringify(tabledata))
        localStorage.setItem('progress-' + byoutou + '-' + thisyear + '-' + nextmonth, 3)
        alert('保存しました')
      }
      
      // セレクトボックスハンドラ
      const [team, setTeam] = useState([])
      const [skill, setSkill] = useState([])

      const [tableshow, setTableshow] = useState(false)
      const [load, setLoad] = useState(false)
      const [pydata, setPydata] = useState(mdata)

      const [executionTime, setExecutionTime] = useState();
      const [finaltime, setFinaltime] = useState(0);

      function handlestart() {
        clearInterval(null)
        let startTime = null;
        const confirm = window.confirm('自動作成プログラムでの勤務表作成を開始しますか？')
        if (confirm) {
          startTime = performance.now();
          intervalId = setInterval(() => {
            const currentTime = performance.now();
            const totalSeconds = Math.floor((currentTime - startTime) / 1000); // ミリ秒から秒に変換し、小数点以下を省略
            const minutes = Math.floor(totalSeconds / 60); // 分を計算
            const seconds = totalSeconds % 60; // 秒を計算
            const timeString = (minutes==0) ? `${seconds}秒` : `${minutes}分 ${seconds}秒`; // 分と秒を表示用の文字列に結合
            setExecutionTime(timeString); // 実行時間をセット
          }, 1000);
          setTableshow(false)
          setLoad(true)
          // pythonでの実行部分
          // 送信するデータ
          const inputData = {
              Byoutou: Byoutou,
              year: thisyear,
              month: nextmonth,
              tabletype: selectedLabel,
              koukyu: selectedoff!==null ? selectedoff.label : 0,
              constrains: selectedconst,
              time: time,
              predata: pdata,
              requestdata : requestdata,
              staffdata: staffdata,
              shiftdata: shiftdata,
              teamdata: teamdata,
              skilldata: skillleveldata,
              limitdata: limitdata,
              constdata: constdata,
              useshiftday: selectedshift,
              useshiftend: selectedshiftend,
              offcheck: filloff,
              type: "Shift",
          };  

          //localStorage.removeItem("flask")
          //axios.post('http://localhost:5000/api/nurse-scheduling-endpoint', inputData)  // オブジェクト内にデータを含める
          axios.post('https://ns-demo-api.medimpl.jp/api/nurse-scheduling-endpoint', inputData)  // オブジェクト内にデータを含める
              .then(response => {
                  // Pythonからのレスポンスを処理する
                  localStorage.setItem("flask", JSON.stringify(response.data)); 
                  setPydata(response.data)
                  setLoad(false)
                  setTableshow(true)
                  CalcurateScore(response.data)
                  clearInterval(intervalId);
                  setExecutionTime(null)
                  const currentTime = performance.now();
                  const totalSeconds = Math.floor((currentTime - startTime) / 1000);
                  const minutes = Math.floor(totalSeconds / 60);
                  const seconds = totalSeconds % 60;
                  const final = (minutes === 0) ? `${seconds}秒` : `${minutes}分 ${seconds}秒`;
                  setFinaltime(final);
                  handleSave()
              })
              .catch(error => {
                setLoad(false)
                clearInterval(intervalId);
                alert("勤務表の作成に失敗しました。")
              });
        }
      }

      // 勤務表のシフトデータ
      const fmcdata = {columns: mcolumns, data: pydata, skipReset: false}
      const [fstate, fdispatch] = useReducer(reducer, fmcdata);
      useEffect(() => {fdispatch({ type: "enable_reset" });
      }, [fstate.data, fstate.columns]);

      const [selectedValue, setSelectedValue] = useState(null);

      const [selectedLabel, setSelectedLabel] = useState(["管理", "夜勤"]); 
      
      const handleOptionChangeLabel = (event) => {
        const { value } = event.target;
        let updatedLabels = [...selectedLabel];

        if (selectedLabel.includes(value)) {
          updatedLabels = updatedLabels.filter((label) => label !== value);
        } else {
          updatedLabels.push(value);
        }

        setSelectedLabel(updatedLabels);
      };

      const [selectedoff, setSelectedoff] = useState({ value: 10, label: 10 });
    
      const offoptions = [];
      for (let i = 0; i <= days; i++) {
        offoptions.push({ value: i, label: i });
      }

      const handleClick = (selectedOption) => {
        setSelectedoff(selectedOption);
        localStorage.setItem("NumberofOff" + Byoutou + thisyear + nextmonth, selectedOption.value)
      };

      function DropdownDayoff() {
        return (
          <Select
           placeholder="選択" 
           value={selectedoff} 
           onChange={handleClick}
           options={offoptions} 
           isSearchable={true}
           />
        )
      }


      const [status, setStatus] = useState(0)

      const handlestatus = () => {
        if (status==1){
          if (selectedLabel!==null){
            let st = status + 1
            setStatus(st)
          } else {
            alert("作成する勤務表の種類を選択してください")
          }
        } else if (status==2){
          if (selectedoff!==null){
            let st = status + 1
            setStatus(st)
          } else {
            alert("公休数を選択してください")
          }
        } else {
          let st = status + 1
          setStatus(st)
        }
      }

      const handlestatusback = () => {
        let st = status - 1
        setStatus(st)
      }

      let intervalId = null;

      const [score, setScore] = useState(0)
      const [error, setError] = useState({
        "勤務希望違反": 0,
        "枠数違反": 0,
        "回数違反": 0,
        "順序違反": 0,
        "禁順序違反": 0,
      })       

      const [time, setTime] = useState(30);
      const handleTimeChange = (newValue) => {
        setTime(newValue);
      };

      const useshift = JSON.parse(localStorage.getItem("Limit-" + Byoutou))!==null ? JSON.parse(localStorage.getItem("Limit-" + Byoutou)).map(item=>{return item.shift}) : []
      const shifts = JSON.parse(localStorage.getItem("Shift-" + Byoutou))!==null ? JSON.parse(localStorage.getItem("Shift-" + Byoutou)).filter(item=>(!useshift.includes(item.shift) && !(item.type=="公休" || item.type=="休み系" || item.type=="選択系" || item.type=="禁止系" || item.type=="連続系"))) : []
      const unuseshift = shifts.map(item=>item.shift)

      let constlist = []
      constdata.forEach((data,i)=>{
        if (data.use=="使用" && !unuseshift.some(shift => data.code.includes(shift))){
          constlist.push(data.code)
        }
      })

      const [selectedconst, setSelectedConst] = useState(constlist);

      const handleOptionChangeConst = (event) => {
        const { value } = event.target;
        let updatedLabels = [...selectedconst];

        if (selectedconst.includes(value)) {
          updatedLabels = updatedLabels.filter((label) => label !== value);
        } else {
          updatedLabels.push(value);
        }

        setSelectedConst(updatedLabels);
      };

      const [shiftlist, setshiftlist] = useState([])
      const [shiftlistend, setshiftlistend] = useState([])

      const [selectedshift, setSelectedShift] = useState(shiftlist);

      const handleOptionChangeShift = (event) => {
        const { value } = event.target;
        let updatedLabels = [...selectedshift];

        if (selectedshift.includes(value)) {
          updatedLabels = updatedLabels.filter((label) => label !== value);
        } else {
          updatedLabels.push(value);
        }

        setSelectedShift(updatedLabels);
      };

      const [selectedshiftend, setSelectedShiftend] = useState(shiftlistend);

      const handleOptionChangeShiftend = (event) => {
        const { value } = event.target;
        let updatedLabels = [...selectedshiftend];

        if (selectedshiftend.includes(value)) {
          updatedLabels = updatedLabels.filter((label) => label !== value);
        } else {
          updatedLabels.push(value);
        }

        setSelectedShiftend(updatedLabels);
      };

      const [filloff, setfilloff] = useState("");

      const handleOptionChangeofffill = (event) => {
        const { value } = event.target;
        let updatedLabels = [...filloff];

        if (filloff.includes(value)) {
          updatedLabels = updatedLabels.filter((label) => label !== value);
        } else {
          updatedLabels.push(value);
        }

        setfilloff(updatedLabels);
      };

      function handlekeych(Byoutou, year, month){
        let keyvalue = {ward:Byoutou, year:year, month:month}
        localStorage.setItem('Storage-show', JSON.stringify(keyvalue))
      }

      function CalcurateScore(data){
        let penalty = 1

        let errors = {
          "勤務希望違反": 0,
          "枠数違反": 0,
          "回数違反": 0,
          "順序違反": 0,
          "禁順序違反": 0,
        }

        const predata = pdata
        const thismonth = (nextmonth==1) ? 12 : Number(nextmonth) - 1
    
        const shiftData = (localStorage.getItem("Shift-"  + Byoutou)!=null) ? JSON.parse(localStorage.getItem("Shift-"  + Byoutou)) : []
        const teamData = (localStorage.getItem("Team-"  + Byoutou)!=null) ? JSON.parse(localStorage.getItem("Team-"  + Byoutou)) : []
        const constData = (localStorage.getItem("Const-"  + Byoutou)!=null) ? JSON.parse(localStorage.getItem("Const-"  + Byoutou)) : []
        const requestData = (localStorage.getItem("Request-"  + Byoutou  + thisyear + '-' + nextmonth)!=null) ? JSON.parse(localStorage.getItem("Request-"  + Byoutou  + thisyear + '-' + nextmonth)) : null
    
        // シフトの種類による区別
        let offshift = [] 
        shiftData.filter(shift=>{
          if (shift.type=="公休" || shift.type=="休み系"){
            offshift.push(shift.shift)
          }
        })
        let selectshift = [] 
        shiftData.map(shift=>{
          if (shift.type=="選択系"){
            selectshift.push(shift.shift)
          }
        })
        let notshift = []
        shiftData.map(shift=>{
          if (shift.type=="禁止系"){
            notshift.push(shift.shift)
          }
        })
        let nikkinshift = []
        shiftData.map(shift=>{
          if (shift.type=="日勤系"){
            nikkinshift.push(shift.shift)
          }
        })
    
        // 背景色とエラーメッセージの設定
        const scores = data.map((row, staffid) => {
          let score = 0 

          const objectkeys = Object.keys(row)

            const prestaff = 
                predata===null ? {}
                : (predata[staffid]===null || predata[staffid]===undefined) ? {}
                : predata[staffid]
    
            // 条件違反チェック
            let orderlists = []
            let phorderlists = []
            let timeslists = []
            let orderuse = 0
            let phorderuse = 0
            let timesuse = 0
            for (let j = 0; j < constData.length; j++){
              const jconst = constData[j]
              if (jconst.use=="使用"){
                // 順序条件
                if (jconst.type=="順序"){
                  const orderlist = jconst.code.split('-')
                  orderlists.push(orderlist)
                  orderuse += 1
                } else
    
                // 禁順序条件
                if (jconst.type=="禁順序"){
                  const phorderlist = jconst.code.split('-')
                  phorderlists.push(phorderlist)
                  phorderuse += 1
                } else
    
                // 回数条件
                if (jconst.type=="回数"){
                  const timeslist = jconst.code.split('-')
                  if (timeslist[0]=="none" && timeslist[1]=="always" && timeslist[2]=="all"){
                    if (timeslists.length==0){
                      timeslists.push(timeslist)
                      timesuse += 1
                    } else {
                      let subtimesnum = 0
                      timeslists.forEach((list,l)=>{
                        if (list.slice(0,-1).every((element, index) => element === timeslist.slice(0,-1)[index])){
                          if (list[15] < timeslist[15]){
                            timeslists[l] = timeslist
                            timesuse += 1
                            subtimesnum += 1
                          }
                        }
                      })
                      if (subtimesnum==0){
                        timeslists.push(timeslist)
                        timesuse += 1
                      }
                    }
                  }
                } else 
    
                // 勤務希望
                if (jconst.code=="request"){
                  for (let i = 1; i < objectkeys.length; i++){
                    // 基準日のシフト
                    let today = (row[objectkeys[i]]!==undefined) ? row[objectkeys[i]] : ""
                    // 希望シフト
                    const requestValue = 
                      (requestData===null) ? "" 
                      : (requestData[staffid]===null || requestData[staffid]===undefined) ? ""
                      : requestData[staffid].hasOwnProperty(objectkeys[i]) ? requestData[staffid][objectkeys[i]] 
                      : ""
                    let imessage = "" // 仮メッセージ
    
                      let requestnum = 0
                      // 日勤系の違反
                      if (nikkinshift.includes(requestValue)){
                        if (!nikkinshift.some(item => today.includes(item))){
                          requestnum += 1 
                        }
                      } else
                      // 禁止系の違反
                      if (notshift.includes(requestValue)){
                        if (today.includes(requestValue.slice(1))){
                          requestnum += 1
                        }
                      } else
                      // 選択系の違反
                      if (selectshift.includes(requestValue)){
                        const selectlist = requestValue.split("・")
                        if (
                          // 日勤系の違反
                          ((nikkinshift.includes(selectlist[0]) || nikkinshift.includes(selectlist[1])) && !nikkinshift.some(item => today.includes(item)))
                          // 公休系の違反
                          && ((offshift.includes(selectlist[0]) || offshift.includes(selectlist[1])) && !offshift.some(item => today.includes(item)))
                          // その他の違反
                          && (!selectlist.some(item => today.includes(item)))
                        ){
                          requestnum += 1
                        }
                      } else 
                      // シフトが勤務希望と違うかつ選択系、禁止系でない場合
                      if (!today.includes(requestValue)){
                        requestnum += 1
                      }
                      // 違反がある場合は赤色、条件違反メッセージ
                      if (requestnum!==0){
                        score += penalty * 1000
                        errors['勤務希望違反'] += 1
                      }
                    
                  }
                }
              }
            }
    
            // 順序違反
            if (orderuse!==0){
              let unorderedElements = []
    
              for (let o = 1; o <= Object.keys(row).length; o++) {
                let orderindex = 0
                let preorderindex = 0
                let suborderkeylist = []
                let suborder = []
                let presuborderkeylist = []
                let presuborder = []
    
                orderlists.forEach(order => {
                  if (o <= Object.keys(row).length - order.length + 1){
                    
                    if (o < 5){
                        const sliceOfRow = Object.values(prestaff).slice(-5+o).concat(Object.values(row).slice(1, 1 + o)).slice(0, order.length)
                        const sliceOfRowKey = Object.keys(row).slice(1, 1 + o)
    
                        if (!sliceOfRow[0].includes("・") && sliceOfRow[0].includes(order[0]) && !nikkinshift.includes(sliceOfRow[0])) {
                          if ((sliceOfRow.every((element, index) => {
                            return element.includes(order[index]) || (offshift.includes(element) && offshift.includes(order[index]));
                          }))){
                            preorderindex += 1
                          } else {
                            if ((presuborderkeylist.length==0 || (presuborderkeylist.length > sliceOfRowKey))){
                              presuborderkeylist = sliceOfRowKey
                              presuborder = order
                            }
                          }
                        }
                    }
    
                    const sliceOfRow = Object.values(row).slice(o, o + order.length); 
                    const sliceOfRowKey = Object.keys(row).slice(o, o + order.length);
                          
                    if (order.length <=5 && !sliceOfRow[0].includes("・") && sliceOfRow[0].includes(order[0]) && !nikkinshift.includes(sliceOfRow[0])) {
                      if ((sliceOfRow.every((element, index) => {
                        return element.includes(order[index]) || (offshift.includes(element) && offshift.includes(order[index]));
                      }))){
                        orderindex += 1
                      } else {
                        if (suborderkeylist.length==0 || (suborderkeylist.length > sliceOfRowKey)){
                          suborderkeylist = sliceOfRowKey
                          suborder = order
                        }
                      }
                    }
                  }
                })
    
                if (preorderindex==0 && presuborderkeylist.length!==0){
                  unorderedElements.push({key: presuborderkeylist, error: presuborder});
                }
                if (orderindex==0 && suborderkeylist.length!==0){
                  unorderedElements.push({key: suborderkeylist, error: suborder});
                }
              }
              
              unorderedElements.forEach(list=>{
                score += penalty * 100
                errors['順序違反'] += 1
              })
            }
    
            // 禁順序違反
            if (phorderuse!==0){
              let unorderedElements = []
    
              for (let o = 1; o <= Object.keys(row).length; o++) {
                let orderindex = 0
                let preorderindex = 0
                let suborderkeylist = []
                let suborder = []
                let presuborderkeylist = []
                let presuborder = []
    
                phorderlists.forEach(order => {
                  if (o <= Object.keys(row).length - order.length + 1){
                    
                    if (o < 5){
                      const sliceOfRow = Object.values(prestaff).slice(-5+o).concat(Object.values(row).slice(1, 1 + o)).slice(0, order.length)
                      const sliceOfRowKey = Object.keys(row).slice(1, 1 + o)
    
                      if ((sliceOfRow.every((element, index) => {
                        return element.includes(order[index]) || (offshift.includes(element) && offshift.includes(order[index])) || (nikkinshift.includes(element) && nikkinshift.includes(order[index]));
                      }))){
                        suborderkeylist = sliceOfRowKey
                        suborder = order
                      }
                    }
    
                    const sliceOfRow = Object.values(row).slice(o, o + order.length); 
                    const sliceOfRowKey = Object.keys(row).slice(o, o + order.length);
                          
                    if (order.length <=5) {
                      if ((sliceOfRow.every((element, index) => {
                        return element.includes(order[index]) || (offshift.includes(element) && offshift.includes(order[index])) || (nikkinshift.includes(element) && nikkinshift.includes(order[index]));
                      }))){
                        suborderkeylist = sliceOfRowKey
                        suborder = order
                      }
                    }
                  }
                })
    
                if (presuborderkeylist.length!==0){
                  unorderedElements.push({key: presuborderkeylist, error: presuborder});
                }
                if (suborderkeylist.length!==0){
                  unorderedElements.push({key: suborderkeylist, error: suborder});
                }
              }
              
              unorderedElements.forEach(list=>{
                score += penalty * 300
                errors['禁順序違反'] += 1
              })
            }
          
            // 回数違反
            if (timesuse!==0){
              let overtimesElements = []
              
              for (let o = 1; o <= Object.keys(row).length; o++) {
                timeslists.forEach(list=>{
                  const shiftlist = list.slice(4,14)
                  const limit = Number(list[15])
    
                  if (list[3]=="repeat" && limit!==0){
                    const replength = shiftlist.filter(element => element !== "").length * limit + 1;
    
                    if (o < replength){
                      const sliceOfRow = Object.values(prestaff).slice(5 - replength + o).concat(Object.values(row).slice(1, 1 + o))
                      const sliceOfRowKey = Object.keys(row).slice(1, 1 + o)
    
                      if (!sliceOfRow.some(element => element === "") && !sliceOfRow.some(element => element === "・")) {
                        let shouldSetColor = false;
                        if (shiftlist[0]=="全て"){
                          shouldSetColor = true;
                        }
                        sliceOfRow.forEach(element => {
                          if (shiftlist[0]=="全て"){
                            if (offshift.some(char => element.includes(char))) {
                              shouldSetColor = false;
                            }
                          } else {
                            const timescount = shiftlist.filter(element => (element !== "" && !offshift.includes(element))).reduce((count, element) => {
                              return count + sliceOfRow.filter(item => item.includes(element)).length;
                            }, 0);
                            const newlimit = shiftlist.filter(element => (element !== "" && !offshift.includes(element))).length * limit + 1;
                            if (timescount >= newlimit) {
                              shouldSetColor = true;
                            }
                          }
                        })
                        if (shouldSetColor) {
                          overtimesElements.push({key: sliceOfRowKey, error: shiftlist.filter(element => element !== "").join(",")})
                        }
                      }
                    }
    
                    const sliceOfRow = Object.values(row).slice(o, o + replength); 
                    const sliceOfRowKey = Object.keys(row).slice(o, o + replength);
    
                    if (!sliceOfRow.some(element => element === "") && !sliceOfRow.some(element => element.includes("・"))) {
                      let shouldSetColor = false;
                      if (shiftlist[0]=="全て"){
                        shouldSetColor = true;
                      }
                      sliceOfRow.forEach(element => {
                        if (shiftlist[0]=="全て"){
                          if (offshift.some(char => element.includes(char))) {
                            shouldSetColor = false;
                          }
                        } else {
                          const timescount = shiftlist.filter(element => (element !== "" && !offshift.includes(element))).reduce((count, element) => {
                            return count + sliceOfRow.filter(item => item.includes(element)).length;
                          }, 0);
                          const newlimit = shiftlist.filter(element => (element !== "" && !offshift.includes(element))).length * limit + 1;
                          if (timescount >= newlimit) {
                            shouldSetColor = true;
                          }
                        }
                      })
                      if (shouldSetColor) {
                        overtimesElements.push({key: sliceOfRowKey, error: shiftlist.filter(element => element !== "").join(",")})
                      }
                    }
    
                  } else 
                  if (list[3]=="which"){
                    const replength = limit + 1;
    
                    if (o < replength){
                      const sliceOfRow = Object.values(prestaff).slice(5 - replength + o).concat(Object.values(row).slice(1, 1 + o))
                      const sliceOfRowKey = Object.keys(row).slice(1, 1 + o)
    
                      if (!sliceOfRow.some(element => element === "") && sliceOfRow.length==replength && !sliceOfRow.some(element => element.includes("・"))) {
                        let shouldSetColor = false;
                        if (shiftlist[0]=="全て"){
                          shouldSetColor = true;
                        }
                        sliceOfRow.forEach(element => {
                          if (shiftlist[0]=="全て"){
                            if (offshift.some(char => element.includes(char))) {
                              shouldSetColor = false;
                            }
                          } else {
                            const timescount = shiftlist.filter(element => (element !== "" && !offshift.includes(element))).reduce((count, element) => {
                              return count + sliceOfRow.filter(item => item.includes(element)).length;
                            }, 0);
                            if (timescount >= replength) {
                              shouldSetColor = true;
                            }
                          }
                        })
                        if (shouldSetColor) {
                          overtimesElements.push({key: sliceOfRowKey, error: shiftlist.filter(element => element !== "").join(",")})
                        }
                      }                  
                    }
    
                    const sliceOfRow = Object.values(row).slice(o, o + replength); 
                    const sliceOfRowKey = Object.keys(row).slice(o, o + replength);
    
                    if (!sliceOfRow.some(element => element === "") && sliceOfRow.length==replength) {
                      let shouldSetColor = false;
                      if (shiftlist[0]=="全て"){
                        shouldSetColor = true;
                      }
                      sliceOfRow.forEach(element => {
                        if (shiftlist[0]=="全て"){
                          if (offshift.some(char => element.includes(char))) {
                            shouldSetColor = false;
                          }
                        } else {
                          const timescount = shiftlist.filter(element => (element !== "" && !offshift.includes(element))).reduce((count, element) => {
                            return count + sliceOfRow.filter(item => item.includes(element)).length;
                          }, 0);
                          if (timescount >= replength) {
                            shouldSetColor = true;
                          }
                        }
                      })
                      if (shouldSetColor) {
                        overtimesElements.push({key: sliceOfRowKey, error: shiftlist.filter(element => element !== "").join(",")})
                      }
                    }
                  }
                })
              }
    
              overtimesElements.forEach(list=>{
                score += penalty * 50
                errors['回数違反'] += 1
              })
            }
          
            return score
        })

        let finalscore = scores.reduce((total, num) => total + num, 0)

        // 枠数
        let lscore = 0
        const limitvalue = constdata.find(item => item.code === "limit");
        if (limitvalue && limitvalue.use=="使用"){
          for (let d = 1; d <= days; d++){
            const dayList = data.map(item => item["d" + d]);
            limitdata.forEach(ldata=>{

              let svalue = ldata.shift
              if (selectedshift.includes(svalue)){
                const limitcount = dayList.filter(item => item.includes(svalue)).length;
                const limitnum = weekend.includes(String(d)) ? ldata.endtotal : ldata.daytotal
                if (limitcount!=limitnum){
                  lscore += penalty * 5000
                  errors['枠数違反'] += 1
                }
              }
            })
          }
        }

        finalscore += lscore

        setScore(finalscore)
        setError(errors)
      }

      return (
        <div onContextMenu={(e) => {
          e.preventDefault(); 
        }}>
        <div style={{justifyContent: "center", paddingTop: "1rem", paddingBottom: "3rem"}}>
        {status==0 && (
            <div>
                <Typography
                    variant="h6"
                    color="inherit"
                    sx={{
                    mr: 0,
                    flexGrow: 2,
                    fontFamily: 'monospace',
                    fontWeight: 550,
                    color: "#0062ca",
                    textDecoration: 'none',
                    paddingLeft: '1rem',
                    paddingTop: '0.5rem',
                    display:"flex", justifyContent: "center"
                    }}
                >
                {Byoutou} {thisyear}年{nextmonth}月の勤務表にシフトを追加します
                </Typography>
                <div style={{display:"flex", justifyContent: "center",paddingLeft: '1rem'}}>
                    勤務表修正画面に現在入力されている勤務表の空白部分に未追加のシフトを埋める際のプロセスです。
                </div>
                <div style={{display:"flex", justifyContent: "center", paddingTop: "2rem"}}>
                <Button sx={{ border: '1px solid gray' }} variant="contained" color="info" style={{ height: "50px", width: "150px", fontSize: "20px"}} onClick={handlestatus}>
                    開始
                </Button>
                </div>
            </div>
        )}

        {status==1 && (
        <div>
          <Typography
              variant="h6"
              color="inherit"
              sx={{
              mr: 0,
              flexGrow: 2,
              fontFamily: 'monospace',
              fontWeight: 550,
              color: "#0062ca",
              textDecoration: 'none',
              paddingLeft: '2rem',
              paddingTop: '0.5rem',
              }}
          >
          1. 追加シフト選択
          </Typography>
        <div style={{paddingLeft: '2rem', paddingTop: '1rem', paddingBottom: '0.75rem'}}>
            追加するシフトを選択してください。すでに入力または調整が完了しているシフトにチェックを入れる必要はありません。
        </div>

        <div style={{display: 'flex', paddingTop: '1rem'}}>
          <div style={{paddingLeft: '3rem'}}>
            <div style={{fontWeight: 'bold'}}>平日</div>
            {shiftdata.map((data)=>{
              if (data.count=="使用"){
                return (
                  <form>
                    <label style={{ paddingRight: '1rem', cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        name="options"
                        value={data.shift}
                        checked={selectedshift.includes(data.shift)}
                        onChange={handleOptionChangeShift}
                        style={{ cursor: 'pointer' }}
                      />
                      {data.shift}
                    </label>
                  </form>
            )}})}
          </div>

          <div style={{paddingLeft: '3rem'}}>
          <div style={{fontWeight: 'bold'}}>休日</div>
            {shiftdata.map((data)=>{
              if (data.count=="使用"){
                return (
                  <form>
                    <label style={{ paddingRight: '1rem', cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        name="options"
                        value={data.shift}
                        checked={selectedshiftend.includes(data.shift)}
                        onChange={handleOptionChangeShiftend}
                        style={{ cursor: 'pointer' }}
                      />
                      {data.shift}
                    </label>
                  </form>
            )}})}
          </div>
        </div>

        
        </div>)}

        {status==2 && (
        <div>
          <Typography
              variant="h6"
              color="inherit"
              sx={{
              mr: 0,
              flexGrow: 2,
              fontFamily: 'monospace',
              fontWeight: 550,
              color: "#0062ca",
              textDecoration: 'none',
              paddingLeft: '2rem',
              paddingTop: '0.5rem',
              }}
          >
          2. 公休数設定
          </Typography>
        <div style={{paddingLeft: '2rem', paddingTop: '1rem', paddingBottom: '0.75rem'}}>
            公休数を選択してください。
        </div>

        <div style={{paddingLeft: '3rem', width: '150px'}}>
            <DropdownDayoff/>
        </div>

        <div style={{paddingLeft: '2rem', paddingTop: '2rem'}}>
          <form>
            <label style={{ paddingRight: '1rem', cursor: 'pointer' }}>
              <input
                type="checkbox"
                name="options"
                value={"offcheck"}
                checked={filloff.includes("offcheck")}
                onChange={handleOptionChangeofffill}
                style={{ cursor: 'pointer' }}
              />
              {" プログラムによるシフト埋め完了後に、残りの空白部分を公休で埋める場合はチェックしてください。"}
            </label>
          </form>
        </div>

        </div>)}

        {status==3 && (
        <div>
          <Typography
              variant="h6"
              color="inherit"
              sx={{
              mr: 0,
              flexGrow: 2,
              fontFamily: 'monospace',
              fontWeight: 550,
              color: "#0062ca",
              textDecoration: 'none',
              paddingLeft: '2rem',
              paddingTop: '0.5rem',
              }}
          >
          3. 条件設定
          </Typography>
        <div style={{paddingLeft: '2rem', paddingTop: '1rem', paddingBottom: '0.75rem'}}>
            使用する勤務条件を選択してください。
        </div>

        <div style={{ paddingLeft: '3rem' }}>
          {["順序","禁順序","回数","スキル","チーム","その他"].map((item)=>{
           return ( 
            <div>
              <div style={{paddingTop: '0.3rem', paddingBottom: '0.2rem', fontSize: 18, fontWeight: 'bold'}}>
                {item}
              </div>
              
              {constdata.map((data)=>{
                if (data.use=="使用" && data.type==item && !unuseshift.some(shift => data.code.includes(shift))){
                  return (
                    <form>
                      <label style={{ paddingRight: '1rem', cursor: 'pointer' }}>
                        <input
                          type="checkbox"
                          name="options"
                          value={data.code}
                          checked={selectedconst.includes(data.code)}
                          onChange={handleOptionChangeConst}
                          style={{ cursor: 'pointer' }}
                        />
                        {data.const}
                      </label>
                    </form>
              )}})}
            </div>)
          })}

        </div>
        

        <div style={{paddingLeft: '3rem', width: '150px'}}>
        </div>
        </div>)}

        {status==4 && (
        <div>
          <Typography
              variant="h6"
              color="inherit"
              sx={{
              mr: 0,
              flexGrow: 2,
              fontFamily: 'monospace',
              fontWeight: 550,
              color: "#0062ca",
              textDecoration: 'none',
              paddingLeft: '2rem',
              paddingTop: '0.5rem',
              }}
          >
          4. 実行時間選択
          </Typography>
          <div style={{paddingLeft: '2rem', paddingTop: '1rem', paddingBottom: '0.75rem'}}>
            実行時間を選択してください。実行時間が短いと条件を満たしきれず、期待する結果が得られないことがあります。30分程度が望ましいです。実際の実行時間は条件の数に応じて、選択した時間よりも最大30秒ほど長くなります。
          </div>

          <div style={{display: 'flex', justifyContent: 'center', paddingTop: '1rem'}}>
            <Slider value={time} onValueChange={handleTimeChange}/>
          </div>
        
        </div>)}

        {status==5 && (
        <div>
          <Typography
              variant="h6"
              color="inherit"
              sx={{
              mr: 0,
              flexGrow: 2,
              fontFamily: 'monospace',
              fontWeight: 550,
              color: "#0062ca",
              textDecoration: 'none',
              paddingLeft: '2rem',
              paddingTop: '0.5rem',
              }}
          >
          5. プログラム実行
          </Typography>
        <div style={{paddingLeft: '2rem', paddingTop: '1rem', paddingBottom: '0.75rem'}}>
            作成条件を確認して作成ボタンを押してください。作成ボタンを押した後ロードバーが表示されている間はそのままの画面で待機してください。
        </div>
        
        <div style={{paddingLeft: '3rem'}}>
          作成年月：{thisyear}年{nextmonth}月
        </div>
        <div style={{paddingLeft: '3rem'}}>
          平日追加シフト：{selectedshift.map((item,i)=>{if (selectedshift.length-1!==i){return (item + ", ")}else{return (item)}})}
        </div>
        <div style={{paddingLeft: '3rem'}}>
          休日追加シフト：{selectedshiftend.map((item,i)=>{if (selectedshiftend.length-1!==i){return (item + ", ")}else{return (item)}})}
        </div>
        <div style={{paddingLeft: '3rem'}}>
          公休数：{status>2 && selectedoff.label}日
        </div>
        <div style={{paddingLeft: '3rem'}}>
          実行時間：{status>4 && time}分
        </div>
        </div>)}

        {status>0 && !load && (
          <div style={{ display: "flex", justifyContent: "space-between", padding: '2rem 3rem'}}>
          <Button sx={{ border: '1px solid gray' }} size="small" variant="contained" color="inherit" onClick={handlestatusback}>
              戻る
          </Button>
          {status<5 && 
          <Button sx={{ border: '1px solid gray' }} size="small" variant="contained" style={{ backgroundColor: "#008000" }} onClick={handlestatus}>
              次へ
          </Button>}
          {status==5 && !tableshow && 
          <Button sx={{ border: '1px solid gray' }} size="small" variant="contained" style={{ backgroundColor: "#008000" }} onClick={handlestart}>
              作成
          </Button>}
        </div>)}

        {status==10 && !load && (
        <div style={{paddingTop: '2rem', display: 'flex', justifyContent: 'center'}}>
            <Button sx={{ border: '1px solid gray' }} size="small" variant="contained" style={{backgroundColor: "#008000"}} onClick={handlestart}>
                {!tableshow ? "仮作成ボタン" : "再作成"}
            </Button>
        </div>)}

        {load && (
        <div>
          <div style={{display: "flex", justifyContent: "center", paddingTop: '3rem'}}>
          <strong>実行時間：</strong>{executionTime}
          </div>
          <div style={{display: "flex", justifyContent: "center", paddingTop: '3rem', paddingBottom: '0.5rem'}}>
            <h4>　作成中...</h4>
          </div>
        <div style={{ display: "flex", justifyContent: "center"}}>
          <Loader
           size={20} color={"#36D7B7"} loading={true} 
           width={300}
           height={8}
          />
        </div>
        </div>)}

        {(tableshow && (status==0 || status==5)) && (
        <div>
          <div style={{display: 'flex', justifyContent: 'center', paddingTop: '1rem'}}>
          <strong>実行時間：</strong> {finaltime}
          </div>
          <div style={{display: 'flex', justifyContent: 'center', paddingTop: '1rem'}}>
          <strong>スコア：</strong>{score}
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
          スコアは低いほど精度が高くなります。
          </div>
          <div style={{display: 'flex', justifyContent: 'center', paddingTop: '1rem'}}>
            <div>
              {Object.entries(error).map(([key, value]) => (
                <div key={key}>
                  <strong>{key}：</strong> {value}
                </div>
              ))}
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'center', paddingTop: '1rem'}}>
          <Button sx={{ border: '1px solid gray' }} onClick={() => {handlekeych(Byoutou,String(thisyear),String(nextmonth)); window.location.reload()  }} size="small" variant="contained" color="info" component={Link} to={`/Table/Storage/${Byoutou}`}>
                    勤務表修正画面に移動
              </Button>
            </div>

        <div className="table-container-m" style={{height: '100%', paddingTop: '2rem', zoom: "0.7"}}>
            <div style={{height: '100'}} className="table-container-shift-f">
            <TableEdit
                columns={mcolumns}
                data={pydata}
                skipReset={fstate.skipReset}
                dispatch={fdispatch}
                filtervalue={[team,skill]}
                Byoutou={Byoutou}
                thisyear={thisyear}
                nextmonth={nextmonth}
                noncolor={"n"}
                noselect={"n"}
                cellvalue={selectedValue}
                days={days}
                localname={'Edit-' + Byoutou  + thisyear + '-' + nextmonth}
                />
            </div>
        </div>
        </div>)}
        </div>
        
      </div>
        );
      }

    return (
      <div className="excel-table-container">
        <div className="foo">
        </div>
        <div>
          <TableShow/>
        </div>
      </div>
    );
  }
}

export default ExcelRequestsImport;