import { setcol } from "../../../Table/colors";
import React, { useEffect, useReducer } from "react";
import "../../../styles.css";
import { randomColor, shortId } from "../../../Table/utils";
import Button from '@mui/material/Button';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import Table from "./TableS";
import { CSVLink } from "react-csv";

class ExcelRequestsImport extends React.Component {
  state = {
    cols: [],
    rows: []
  };

  constructor(props) {
    super(props);
    this.state = {
      keyvalue: { ward: '', year: '', month: '' },
    };
  }

  componentDidMount() {
    const localvalue = JSON.parse(localStorage.getItem("Storage-show"));
    this.setState({ keyvalue: localvalue });
  }

  render() {
    if (sessionStorage.getItem("Login")==="1"){
      sessionStorage.setItem("Login", "1")
    }    
    let Byoutou = this.state.keyvalue.ward
    let byoutou = Byoutou.toLocaleLowerCase()

    const JapaneseHolidays = require('japanese-holidays');
    
    const thisyear = this.state.keyvalue.year
    const nextmonth = this.state.keyvalue.month
    const thismonth = (nextmonth=="1") ? "12" : nextmonth - 1
    var prelastDate = new Date(thisyear, thismonth, 0);
    const predays = prelastDate.getDate()
    var lastDate = new Date(thisyear, nextmonth, 0);
    var days = lastDate.getDate()
    var date = new Date();
    const thisday = String(date.getDate()).length==1 ? "0" + date.getDate() : date.getDate()
    const preyear = (nextmonth==1) ? thisyear-1 : thisyear
      const thismonth0 = String(thismonth).length==1 ? "0" + thismonth : thismonth
    const isholiday = JapaneseHolidays.getHolidaysOf(lastDate.getFullYear(), true);

    const holiday = []
    for (let i = 0; i < isholiday.length; i++){
      if (isholiday[i].month===lastDate.getMonth()+1){
        holiday.push(isholiday[i].date)
      }
    }

    const weekend = []
    const whatday = []
    for (let i = 1; i <= days; i++){
      var day = new Date(thisyear, lastDate.getMonth(), i)
      if ([0, 6].includes(day.getDay())){
        weekend.push(String(i))
      }
      if (holiday.includes(i)){
        if (!weekend.includes(i)){
          weekend.push(String(i))
        }
      }
      if ([0].includes(day.getDay())){
        whatday.push("日")
      }
      if ([1].includes(day.getDay())){
        whatday.push("月")
      }
      if ([2].includes(day.getDay())){
        whatday.push("火")
      }
      if ([3].includes(day.getDay())){
        whatday.push("水")
      }
      if ([4].includes(day.getDay())){
        whatday.push("木")
      }
      if ([5].includes(day.getDay())){
        whatday.push("金")
      }
      if ([6].includes(day.getDay())){
        whatday.push("土")
      }
    }

    if (localStorage.getItem('e1' + byoutou + '-' + thisyear + '-' + nextmonth)!=2 || localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth)==null){
      localStorage.setItem('e1' + byoutou + '-' + thisyear + '-' + nextmonth, 1)
    }

    const shiftdata = (JSON.parse(localStorage.getItem("Shift-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Shift-"+Byoutou)) : []
    const teamdata = (JSON.parse(localStorage.getItem("Team-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Team-"+Byoutou)) : []
    const skillleveldata = (JSON.parse(localStorage.getItem("Skill-"+Byoutou))!==null) ? JSON.parse(localStorage.getItem("Skill-"+Byoutou)) : []

    let shiftoptions = [];
    let allowshift = [];

    shiftdata.forEach((data,i) => {
      if (data.shift=="管"){
        allowshift.push({label: data.shift, backgroundColor: data.color, type: data.type})
      }
      if ((data.shift=="〇" || data.shift.includes("/") || data.shift.includes("長")) && (data.type=="日勤系" || data.type=="準夜勤系" || data.type=="ロング系")){
        if (teamdata.length>0){
          for (const t in teamdata){
          shiftoptions.push({label: data.shift + teamdata[t].team, backgroundColor: data.color, type: data.type, team: teamdata[t].team})
          if (!data.shift.includes("専")){
            allowshift.push({label: data.shift + teamdata[t].team, backgroundColor: data.color, type: data.type})
          }        
        }} else {
          shiftoptions.push({label: data.shift, backgroundColor: data.color, type: data.type})
          allowshift.push({label: data.shift, backgroundColor: data.color, type: data.type})
      }
      } else {
        if (data.shift=="*" || data.shift=="公"|| data.shift=="遅J" ){
          allowshift.push({label: data.shift, backgroundColor: data.color, type: data.type})
        }   
        if (data.type!=="禁止系" && data.type!=="選択系"){
          shiftoptions.push({ label: data.shift, backgroundColor: data.color, type: data.type});
        }
      }
    })
    const deleteoption = { label: "", backgroundColor: "#FFFFFF", type: "削除"}
    shiftoptions.push(deleteoption);
    
    const shifttypes = [...new Set(shiftoptions.map(option => option.type))];
    const shifttypescolor = [...new Set(shiftoptions.map(option => option.backgroundColor))];

    let teamoptions = teamdata.map((data,i) => {
      return { label: data.team, value: data.team, backgroundColor: data.color };
    })

    let skilloptions = skillleveldata.map((data,i) => {
      return { label: data.skill, value: data.skill, backgroundColor: data.color };
    })

    let checkoptions = [
      {label: "可", backgroundColor: setcol(9)},
      {label: "", backgroundColor: setcol(1)},
    ]

    let dayoptions = []
    for (let i = 1; i <= days; i++) {
      let ds = {
          label: String(i),
          value: i,
          };
      dayoptions.push(ds);
    }

    let daysoptions = [
      {label: "月", value: "mon"},
      {label: "火", value: "tue"},
      {label: "水", value: "wed"},
      {label: "木", value: "thu"},
      {label: "金", value: "fri"},
      {label: "土", value: "sat"},
      {label: "日", value: "sun"},
      {label: "土日", value: "satsun"},
      {label: "祝日", value: "weekend"},
    ]

    // シフトのデータ作成
    let sdata = [];
    let mdata = [];
    let pdata = [];

    var num = 0
    let stlist = []
    const staffdatas = (localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth)!==null) ? JSON.parse(localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth)) : (JSON.parse(localStorage.getItem('Staff-' + Byoutou ))!==null) ? JSON.parse(localStorage.getItem('Staff-' + Byoutou )) : []
    const staffdata = (JSON.parse(localStorage.getItem('Staff-' + Byoutou ))!==null) ? JSON.parse(localStorage.getItem('Staff-' + Byoutou )) : []
    for (let i = 1; i<=staffdatas.length; i++) {
        if (staffdatas[i-1].ID !== "" && staffdatas[i-1].ID !== undefined){
            num = num + 1
        }
        let n = 0
        for (let k = 1; k <= staffdata.length; k++){
          const preid = staffdata[k-1]
          if (preid.ID + " " + preid.name == staffdatas[i-1].ID){
            stlist.push(k-1)
            n = n + 1
          }
        }
        if (n==0){
          stlist.push("n")
        }
    }
    for (let i = 1; i <= num; i++) {
      const istaffdata = (stlist[i-1]!=="n") ? staffdata[stlist[i-1]] : {ID: "", name: "", skill: "", TEAM: "", NL: "", long: "", oso: "", hcu: ""}
      const istaffdatas = staffdatas[i-1]
      let srow =(Byoutou=="ICU")
        ? {
          skill: istaffdata.skill,
          //hosoku: JSON.parse(localStorage.getItem('Staff-' + Byoutou ))[i-1].hosoku,
          team: istaffdata.TEAM,
          leader: istaffdata.NL,
          //lowl: JSON.parse(localStorage.getItem('Staff-' + Byoutou))[i-1].lowl,
          //upl: JSON.parse(localStorage.getItem('Staff-' + Byoutou))[i-1].upl,
          //lw: JSON.parse(localStorage.getItem('Staff-' + Byoutou))[i-1].lw,
        }
        : (Byoutou=="W2")
        ? {
          skill: istaffdata.skill,
          //hosoku: JSON.parse(localStorage.getItem('Staff-' + Byoutou ))[i-1].hosoku,
          team: istaffdata.TEAM,
          //lowl: JSON.parse(localStorage.getItem('Staff-' + Byoutou))[i-1].lowl,
          //upl: JSON.parse(localStorage.getItem('Staff-' + Byoutou))[i-1].upl,
          //lw: JSON.parse(localStorage.getItem('Staff-' + Byoutou))[i-1].lw,
        }
        : (Byoutou=="C5")
        ? {
          skill: istaffdata.skill,
          //hosoku: JSON.parse(localStorage.getItem('Staff-' + Byoutou ))[i-1].hosoku,
          team: istaffdata.TEAM,
          hcu: istaffdata.hcu,
          //lowl: JSON.parse(localStorage.getItem('Staff-' + Byoutou))[i-1].lowl,
          //upl: JSON.parse(localStorage.getItem('Staff-' + Byoutou))[i-1].upl,
          //lw: JSON.parse(localStorage.getItem('Staff-' + Byoutou))[i-1].lw,
        }
        : (Byoutou=="C3")
        ? {
          skill: istaffdata.skill,
          //hosoku: JSON.parse(localStorage.getItem('Staff-' + Byoutou ))[i-1].hosoku,
          team: istaffdata.TEAM,
          oso: istaffdata.oso,
          //lowl: JSON.parse(localStorage.getItem('Staff-' + Byoutou))[i-1].lowl,
          //upl: JSON.parse(localStorage.getItem('Staff-' + Byoutou))[i-1].upl,
          //lw: JSON.parse(localStorage.getItem('Staff-' + Byoutou))[i-1].lw,
        }
        : {}
        let mrow = {
          ID: (localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth)!==null) ? istaffdatas.ID : istaffdata.ID + " " + istaffdata.name,
        }
        let prow = {}
        for (let j = 1; j <= days; j++){
          srow["d" + String(j)] = ""
          mrow["d" + String(j)] = ""
          if (j <= 5){
            prow["d" + String(6-j)] = ""
            if (localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + thismonth)!==null){
              const prelocaldata = JSON.parse(localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + thismonth))
              for (let k = 1; k <= prelocaldata.length; k++){
                const preid = prelocaldata[k-1]
                if (istaffdata.ID + " " + istaffdata.name == preid.ID){
                  prow["d" + String(6-j)] = preid["d" + String(predays-(5-j))]
                }
              }
            }
          }
        }
        sdata.push(srow);
        mdata.push(mrow);
        pdata.push(prow);
      }

      let scolumns 
    = (Byoutou=="ICU")
    ? [  
        {
        id: "skill",
        label: "Skill",
        accessor: "skill",
        width: 8,
        dataType: "select",
        options: skilloptions
        },   
        //{
        //id: "hosoku",
        //label: "補足",
        //accessor: "hosoku",
        //width: 12,
        //dataType: "text",
        //options: []
        //},
        {
        id: "team",
        label: "Team",
        accessor: "team",
        width: 8,
        dataType: "select",
        options: teamoptions
        }, 
        {
        id: "leader",
        label: "日勤L",
        accessor: "leader",
        width: 8,
        dataType: "select",
        options: checkoptions
        },       
        //{
        //id: "lw",
        //label: "自由記述",
        //accessor: "lw",
        //width: 300,
        //dataType: "text",
        //options: []
        //},   
    ]
    : (Byoutou=="W2")
    ? [  
      {
      id: "skill",
      label: "Skill",
      accessor: "skill",
      width: 8,
      dataType: "select",
      options: skilloptions
      },   
      //{
      //id: "hosoku",
      //label: "補足",
      //accessor: "hosoku",
      //width: 12,
      //dataType: "text",
      //options: []
      //},
      {
      id: "team",
      label: "Team",
      accessor: "team",
      width: 8,
      dataType: "select",
      options: teamoptions
      },    
      //{
      //id: "lw",
      //label: "自由記述",
      //accessor: "lw",
      //width: 300,
      //dataType: "text",
      //options: []
      //},   
    ]
    : (Byoutou=="C5")
    ? [  
      {
      id: "skill",
      label: "Skill",
      accessor: "skill",
      width: 8,
      dataType: "select",
      options: skilloptions
      },   
      //{
      //id: "hosoku",
      //label: "補足",
      //accessor: "hosoku",
      //width: 12,
      //dataType: "text",
      //options: []
      //},
      {
      id: "team",
      label: "Team",
      accessor: "team",
      width: 8,
      dataType: "select",
      options: teamoptions
      }, 
      {
      id: "hcu",
      label: "HCU1/",
      accessor: "hcu",
      width: 8,
      dataType: "select",
      options: checkoptions
      },    
      //{
      //id: "lw",
      //label: "自由記述",
      //accessor: "lw",
      //width: 300,
      //dataType: "text",
      //options: []
      //},   
    ]
    : (Byoutou=="C3")
    ? [  
      {
      id: "skill",
      label: "Skill",
      accessor: "skill",
      width: 8,
      dataType: "select",
      options: skilloptions
      },   
      //{
      //id: "hosoku",
      //label: "補足",
      //accessor: "hosoku",
      //width: 12,
      //dataType: "text",
      //options: []
      //},
      {
      id: "team",
      label: "Team",
      accessor: "team",
      width: 8,
      dataType: "select",
      options: teamoptions
      }, 
      {
      id: "oso",
      label: "遅番",
      accessor: "oso",
      width: 8,
      dataType: "select",
      options: checkoptions
      },       
      //{
      //id: "lw",
      //label: "自由記述",
      //accessor: "lw",
      //width: 300,
      //dataType: "text",
      //options: []
      //},   
    ] : [  
      {
      id: "skill",
      label: "Skill",
      accessor: "skill",
      width: 8,
      dataType: "select",
      options: skilloptions
      },   
      //{
      //id: "hosoku",
      //label: "補足",
      //accessor: "hosoku",
      //width: 12,
      //dataType: "text",
      //options: []
      //},
      {
      id: "team",
      label: "Team",
      accessor: "team",
      width: 8,
      dataType: "select",
      options: teamoptions
      },    
      //{
      //id: "lw",
      //label: "自由記述",
      //accessor: "lw",
      //width: 300,
      //dataType: "text",
      //options: []
      //},   
    ]
  
      let mcolumns = [{
        id: "ID",
        label: "ID",
        accessor: "ID",
        width: 110,
        dataType: "text",
        options: []
      }];
      let mcolumns2 = [{
        id: "d" + String(5),
        label: "前5",
        accessor: "d" + String(5),
        dataType: "select",
        width: 40,
        options: shiftoptions
        },
        {
          id: "d" + String(4),
          label: "前4",
          accessor: "d" + String(4),
          dataType: "select",
          width: 40,
          options: shiftoptions
          },
          {
            id: "d" + String(3),
            label: "前3",
            accessor: "d" + String(3),
            dataType: "select",
            width: 40,
            options: shiftoptions
            },
            {
              id: "d" + String(2),
              label: "前2",
              accessor: "d" + String(2),
              dataType: "select",
              width: 40,
              options: shiftoptions
              },
              {
                id: "d" + String(1),
                label: "前1",
                accessor: "d" + String(1),
                dataType: "select",
                width: 40,
                options: shiftoptions
      },
          {
            id: "a0",
            label: "",
            accessor: "a0",
            dataType: "text",
            width: 1,
            options: []
      }];
      
      for (let i = 1; i <= days; i++) {
        let mcol = {
          id: "d" + String(i),
          label: String(i),
          accessor: "d" + String(i),
          dataType: "select",
          width: 40,
          options: shiftoptions
          };
        mcolumns.push(mcol);
      }

    // 管理画面でアップロードしたデータを成型
    if (localStorage.getItem('e1' + byoutou + '-' + thisyear + '-' + nextmonth)!=1 || localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth)!=null){
      for (let i = 0; i < num; i++){
        for (let j = 1; j <= days; j++) {
          mdata[i]["d"+String(j)] = JSON.parse(localStorage.getItem('Edit-' + Byoutou + thisyear + '-' + nextmonth))[i]["d"+String(j)]
          
        }
      }
    }
    
    // 表に適した形のデータ
    let mmcdata = (localStorage.getItem('e1' + byoutou + '-' + thisyear + '-' + nextmonth)==1 || localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth)==null) ? {columns: mcolumns, data: mdata, skipReset: false} : {columns: mcolumns, data: mdata, skipReset: false}
   
    // 初回実行時にローカルストレージを設定
    localStorage.setItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth, JSON.stringify(mmcdata.data))
    if (localStorage.getItem('e1' + byoutou + '-' + thisyear + '-' + nextmonth)==1){
      localStorage.setItem('e1' + byoutou + '-' + thisyear + '-' + nextmonth, 2)
    }

    // 表のデータや行、列の変更
    function reducer(state, action) {
      switch (action.type) {
        case "add_option_to_column":
          const optionIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, optionIndex),
              {
                ...state.columns[optionIndex],
                options: [
                  ...state.columns[optionIndex].options,
                  { label: action.option, backgroundColor: action.backgroundColor }
                ]
              },
              ...state.columns.slice(optionIndex + 1, state.columns.length)
            ]
          };
        case "add_row":
          return {
            ...state,
            skipReset: true,
            data: [...state.data, {}]
          };
        case "update_column_type":
          const typeIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          switch (action.dataType) {
            case "number":
              if (state.columns[typeIndex].dataType === "number") {
                return state;
              } else {
                return {
                  ...state,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  data: state.data.map((row) => ({
                    ...row,
                    [action.columnId]: isNaN(row[action.columnId])
                      ? ""
                      : Number.parseInt(row[action.columnId])
                  }))
                };
              }
            case "select":
              if (state.columns[typeIndex].dataType === "select") {
                return {
                  ...state,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  skipReset: true
                };
              } else {
                let options = [];
                state.data.forEach((row) => {
                  if (row[action.columnId]) {
                    options.push({
                      label: row[action.columnId],
                      backgroundColor: randomColor()
                    });
                  }
                });
                return {
                  ...state,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    {
                      ...state.columns[typeIndex],
                      dataType: action.dataType,
                      options: [...state.columns[typeIndex].options, ...options]
                    },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  skipReset: true
                };
              }
            case "text":
              if (state.columns[typeIndex].dataType === "text") {
                return state;
              } else if (state.columns[typeIndex].dataType === "select") {
                return {
                  ...state,
                  skipReset: true,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ]
                };
              } else {
                return {
                  ...state,
                  skipReset: true,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  data: state.data.map((row) => ({
                    ...row,
                    [action.columnId]: row[action.columnId] + ""
                  }))
                };
              }
            default:
              return state;
          }
        case "update_column_header":
          const index = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, index),
              { ...state.columns[index], label: action.label },
              ...state.columns.slice(index + 1, state.columns.length)
            ]
          };
        case "update_cell":
          return {
            ...state,
            skipReset: true,
            data: state.data.map((row, index) => {
              if (index === action.rowIndex) {
                return {
                  ...state.data[action.rowIndex],
                  [action.columnId]: action.value
                };
              }
              return row;
            })
          };
        case "add_column_to_left":
          const leftIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          let leftId = shortId();
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, leftIndex),
              {
                id: leftId,
                label: "Column",
                accessor: leftId,
                dataType: "text",
                created: action.focus && true,
                options: []
              },
              ...state.columns.slice(leftIndex, state.columns.length)
            ]
          };
        case "add_column_to_right":
          const rightIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          const rightId = shortId();
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, rightIndex + 1),
              {
                id: rightId,
                label: "Column",
                accessor: rightId,
                dataType: "text",
                created: action.focus && true,
                options: []
              },
              ...state.columns.slice(rightIndex + 1, state.columns.length)
            ]
          };
        case "delete_column":
          const deleteIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, deleteIndex),
              ...state.columns.slice(deleteIndex + 1, state.columns.length)
            ]
          };
        case "enable_reset":
          return {
            ...state,
            skipReset: false
          };
        default:
          return state;
      }
    }

    const {keyvalue} = this.state;

    // 表、ボタン、セレクトボックスなどを表示する関数
    function TableShow() {
      const [state, dispatch] = useReducer(reducer, mmcdata);
      useEffect(() => {dispatch({ type: "enable_reset" });
      }, [state.data, state.columns]);
      
      
      const filename = Byoutou + "勤務表" + '_' + String(thisyear) + "年" + String(nextmonth) + "月" + "_" + preyear + thismonth0 + thisday

      function pdhDownloadHandler(filename) {
        const target = document.getElementById('pdf-id');
        if (target === null) return;
        html2canvas(target, { scale: 2.5 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/svg', 1.0);
        let pdf = new jsPDF();
        pdf.addImage(imgData, 'SVG', 5, 10, canvas.width / 18, canvas.height / 18);
        pdf.save(filename + `.pdf`);
        });
        localStorage.setItem("Download", '0')
      };

      function handleDownload(){
        const eddata = JSON.parse(localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth))
        return eddata
      }

      if (localStorage.getItem('Download')=="1"){
        pdhDownloadHandler(filename)
      }

      return (
        <div style={{width:"1370px", backgroundColor: "white"}}>
        <div>
            <div style={{zoom: '0.8'}}>
                <strong>
                  　{thisyear}年{nextmonth}月　　
                </strong>
                <CSVLink data={handleDownload()} filename={filename} className="text-deco">
                <Button size="small" variant="contained" color="success">
                  CSVダウンロード
                </Button>
                </CSVLink>
                <Button size="small" variant="contained" color="success" onClick={() => pdhDownloadHandler(filename)}>
                  PDFダウンロード
                </Button>
            </div>
            <div>
            </div>            
        </div>
        
        <div id='pdf-id' className="body-container-r-img" style={{zoom: '0.8'}}>
        <div className="table-container-m-img">
            <div>
                <Table
                columns={state.columns}
                data={JSON.parse(localStorage.getItem('Edit-' + Byoutou  + thisyear + '-' + nextmonth))}
                dispatch={dispatch}
                filtervalue={[]}
                Byoutou={Byoutou}
                thisyear={thisyear}
                nextmonth={nextmonth}
                noncolor={"r"}
                />
            </div>
        </div>
        </div>
    </div>
      );
      }

    return (
      <div className="excel-table-container">
        <div className="foo">
        </div>
        <div>
          <TableShow/>
        </div>
      </div>
    );
  }
}

export default ExcelRequestsImport;