import React, { useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SetAppbar from "./TopPage2";
import Button from '@mui/material/Button';
import Select from 'react-select'
import { setcol } from "../../Table/colors";

const mdTheme = createTheme();

// シーザー暗号のシフト値
const shift = 5; 

function DashboardContent() {
  if (sessionStorage.getItem("Login")==="1"){
    sessionStorage.setItem("Login", "1")
  }
  const [isEditing, setIsEditing] = useState(false);

  const Byoutou = localStorage.getItem("Byoutou")
  const UserData  = JSON.parse(localStorage.getItem("UserID"))
  let AccountData = null;
  let wardindex = null
  UserData.forEach((item, i) => {
    if (item.code === Byoutou) {
      AccountData = item;
      wardindex = i
    }
  });

  const [user, setUser] = useState(AccountData.userid);
  const [code, setCode] = useState(AccountData.code);
  const [ward, setWard] = useState(AccountData.name);
  const [pwd, setpwd] = useState(decrypt(AccountData.password));
  const [rpwd, setrPwd] = useState("");

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    if (user==="" || code==="" || ward==="" || pwd==="" || rpwd===""){
      alert("病棟名、病棟識別コード、ユーザー名またはパスワードを入力してください")
    } else {
      if (pwd!==rpwd){
        alert("パスワードが正しくありません")
        setrPwd("")
      }
      else if (pwd.length<6){
        alert("パスワードが短すぎます")
      } else {
        UserData[wardindex] = {code: code, name: ward, userid: user, password: encrypt(pwd)}
        localStorage.setItem("UserID", JSON.stringify(UserData))
        setIsEditing(false);
        alert("アカウント情報を更新しました")
      }
    } 
  };

  // 文字列を暗号化する関数
  function encrypt(text) {
    let encryptedText = '';
    for (let i = 0; i < text.length; i++) {
      let char = text[i];
      let code = text.charCodeAt(i);

      if (char.match(/[a-z]/i)) {
        const isUpperCase = char === char.toUpperCase();
        code = code - (isUpperCase ? 65 : 97);
        code = (code + shift) % 26;
        if (code < 0) {
          code += 26;
        }
        code = code + (isUpperCase ? 65 : 97);
        char = String.fromCharCode(code);
      } else if (char.match(/[0-9]/)) {
        code = code - 48;
        code = (code + shift) % 10;
        if (code < 0) {
          code += 10;
        }
        code = code + 48;
        char = String.fromCharCode(code);
      }

      encryptedText += char;
    }
    return encryptedText;
  }

  // 暗号文を復号化する関数
  function decrypt(encryptedText) {
    let decryptedText = '';
    for (let i = 0; i < encryptedText.length; i++) {
      let char = encryptedText[i];
      let code = encryptedText.charCodeAt(i);

      if (char.match(/[a-z]/i)) {
        const isUpperCase = char === char.toUpperCase();
        code = code - (isUpperCase ? 65 : 97);
        code = (code - shift) % 26;
        if (code < 0) {
          code += 26;
        }
        code = code + (isUpperCase ? 65 : 97);
        char = String.fromCharCode(code);
      } else if (char.match(/[0-9]/)) {
        code = code - 48;
        code = (code - shift) % 10;
        if (code < 0) {
          code += 10;
        }
        code = code + 48;
        char = String.fromCharCode(code);
      }

      decryptedText += char;
    }
    return decryptedText;
  }
  

  return (
    <ThemeProvider theme={mdTheme}>
      <SetAppbar pagename={"アカウント"}/>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 2,
            height: '100vh',
            overflow: 'auto',
            marginTop: '40px',
          }}
        >
              <div style={{display: 'flex', justifyContent: 'center', paddingTop: '3rem'}}>
                <Paper sx={{p: 2, height: '100%', padding:'30px', width: '550px'}}>
                      <Typography
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{
                          mr: 0,
                          flexGrow: 2,
                          fontFamily: 'monospace',
                          fontWeight: 550,
                          color: 'inherit',
                          textDecoration: 'none',
                        }}
                      >
                        アカウント情報
                      </Typography>

                      <div style={{display: 'flex', alignItems: "center", justifyContent:'space-between', paddingTop: '1rem'}}>
                        <div style={{paddingRight: '2rem'}}>
                          病棟名： 
                        </div>
                        {isEditing ? (
                          <div style={{width: '250px'}}>
                            <input
                              type="text"
                              value={ward}
                              onChange={(e) => setWard(e.target.value)}
                              style={{width: '250px'}}
                            />
                          </div>
                        ) : (
                          <div style={{border: '0.5px solid black', width: '250px', paddingLeft: '0.5rem'}}>
                            {ward}
                          </div>
                        )}
                      </div>

                      <div style={{display: 'flex',alignItems: "center",justifyContent:'space-between', paddingTop: '1rem'}}>
                        <div style={{paddingRight: '2rem'}}>
                          病棟識別コード： 
                        </div>
                        {isEditing ? (
                          <div style={{width: '250px'}}>
                            <input
                              type="text"
                              value={code}
                              onChange={(e) => setCode(e.target.value)}
                              style={{width: '250px'}}
                            />
                          </div>
                        ) : (
                          <div style={{border: '0.5px solid black', width: '250px', paddingLeft: '0.5rem'}}>
                            {code}
                          </div>
                        )}
                      </div>

                      <div style={{display: 'flex',alignItems: "center",justifyContent:'space-between', paddingTop: '1rem'}}>
                        <div style={{paddingRight: '2rem'}}>
                          ユーザー名： 
                        </div>
                        {isEditing ? (
                          <div style={{width: '250px'}}>
                            <input
                              type="text"
                              value={user}
                              onChange={(e) => setUser(e.target.value)}
                              style={{width: '250px'}}
                            />
                          </div>
                        ) : (
                          <div style={{border: '0.5px solid black', width: '250px', paddingLeft: '0.5rem'}}>
                            {user}
                          </div>
                        )}
                      </div>
                      
                      <div style={{display: 'flex',alignItems: "center",justifyContent:'space-between', paddingTop: '1rem'}}>
                        <div style={{paddingRight: '2rem'}}>
                          パスワード： 
                        </div>
                        {isEditing ? (
                          <div style={{width: '250px'}}>
                            <input
                              type="password"
                              value={pwd}
                              onChange={(e) => setpwd(e.target.value)}
                              style={{width: '250px'}}
                            />
                          </div>
                        ) : (
                          <div style={{border: '0.5px solid black', width: '250px', paddingLeft: '0.5rem'}}>
                            {Array(pwd.length).fill('●').join('')}
                          </div>
                        )}
                      </div>
                      
                      {isEditing &&
                      <div style={{display: 'flex',alignItems: "center",justifyContent:'space-between', paddingTop: '1rem'}}>
                        <div style={{paddingRight: '2rem'}}>
                          パスワード再入力： 
                        </div>
                        {isEditing ? (
                          <div style={{width: '250px'}}>
                            <input
                              type="password"
                              value={rpwd}
                              onChange={(e) => setrPwd(e.target.value)}
                              style={{width: '250px'}}
                            />
                          </div>
                        ) : (
                          <div style={{border: '0.5px solid black', width: '250px', paddingLeft: '0.5rem'}}>
                            {rpwd}
                          </div>
                        )}
                      </div>}

                    <div style={{paddingTop: '4rem', display: 'flex', justifyContent: 'center'}}>
                      <Button color="inherit" sx={{ border: '1px solid gray' }} size="small" variant="contained" onClick={isEditing ? handleSaveClick : handleEditClick}>
                        {isEditing ? '保存' : '変更'}
                      </Button>
                    </div>
                </Paper>
              </div>

        </Box>
      </Box>
    </ThemeProvider>
  );
}

const ManagePage = () => {
    return <DashboardContent />;
  };
  
  export default ManagePage;