// 編集可能な表を表示するコンポーネント
import React, {useMemo, useState, useEffect} from "react";
import clsx from "clsx";
import {useTable, useFlexLayout, useResizeColumns, useSortBy, useGlobalFilter} from "react-table";
import Cell from "./Cell";
import Header from "./HeaderSort";

const defaultColumn = {
  minWidth: 50,
  width: 150,
  maxWidth: 400,
  Cell: Cell,
  Header: Header,
  sortType: "alphanumericFalsyLast"
};


export default function Table(
  {
    columns, 
    data, 
    dispatch: dataDispatch, 
    skipReset, 
    filtervalue, 
    cellvalue,
    Byoutou, 
    thisyear, 
    nextmonth, 
    days,
    noncolor,
    localname,
    noselect,
    staffdata,
    predata,
    deleterowvalue,
    handledeleterow,
    pasterowvalue,
    handlepasterow,
  }) {
  const sortTypes = useMemo(
    () => ({
      alphanumericFalsyLast(rowA, rowB, columnId, desc) {
        if (!rowA.values[columnId] && !rowB.values[columnId]) {
          return 0;
        }

        if (!rowA.values[columnId]) {
          return desc ? -1 : 1;
        }

        if (!rowB.values[columnId]) {
          return desc ? 1 : -1;
        }

        return isNaN(rowA.values[columnId])
          ? rowA.values[columnId].localeCompare(rowB.values[columnId])
          : rowA.values[columnId] - rowB.values[columnId];
      }
    }),
    []
  );

  const {
    getTableProps, 
    getTableBodyProps, 
    headerGroups, 
    rows, 
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      dataDispatch,
      autoResetSortBy: !skipReset,
      autoResetFilters: !skipReset,
      autoResetRowState: !skipReset,
      sortTypes,
    },
    useFlexLayout,
    useResizeColumns,
    useGlobalFilter, 
    useSortBy,
  );

  function isTableResizing() {
    for (let headerGroup of headerGroups) {
      for (let column of headerGroup.headers) {
        if (column.isResizing) {
          return true;
        }
      }
    }

    return false;
  }

  function getFilteredRows() {
    const staffdatas = JSON.parse(localStorage.getItem("Staff-" + Byoutou))
    if (filtervalue[0].length === 0 && filtervalue[1].length === 0){
      return rows
    }

    else if (filtervalue[0].length!==0 && filtervalue[1].length===0){
      return rows.filter((row) => {
        const staff = staffdatas[row.id];
        return (
          (filtervalue[0].length !== 0 && filtervalue[0].includes(staff.TEAM))
        );
      });
    }
    
    else if (filtervalue[0].length===0 && filtervalue[1].length!==0){
      return rows.filter((row,i) => {
        const staff = staffdatas[row.id];
        return (
          (filtervalue[1].length !== 0 && filtervalue[1].includes(staff.skill))
        );
      });
    }

    else if (filtervalue[0].length!==0 && filtervalue[1].length!==0){
      return rows.filter((row) => {
        const staff = staffdatas[row.id];
        return (
          (filtervalue[0].length !== 0 && filtervalue[0].includes(staff.TEAM)) &&
          (filtervalue[1].length !== 0 && filtervalue[1].includes(staff.skill))
        );
      });
    }
  }
  
  const filteredRows = getFilteredRows();

  function SetCellBackgroundColor(){
    const requestData = (localStorage.getItem("Request-"  + Byoutou  + thisyear + '-' + nextmonth)!=null) ? JSON.parse(localStorage.getItem("Request-"  + Byoutou  + thisyear + '-' + nextmonth)) : null
    const white = "#FFFFFF"
    const red = "#FF4D4D"
    const orange = "#FFA500"
    const offshift = ["管", "*", "公", "年", "リ", "外", "婚", "産前", "産後", "育", "病", "積", "忌", "欠", "退", "出"];
    const bgcolor = filteredRows.map((row, staffid) => {
      const objectkeys = Object.keys(row.original)
      if (noncolor=="n"){
        let colors = new Array(objectkeys.length-1).fill(white)
        return colors
      }
      else {
        let colors = [white] // IDのセル
        let message = [""]
        for (let i = 1; i < objectkeys.length; i++){
          let ayer = (row.original[objectkeys[i]]!==undefined) ? row.original[objectkeys[i]] : ""
          let hoy = (row.original[objectkeys[i+1]]!==undefined) ? row.original[objectkeys[i+1]] : ""
          const requestValue = 
            requestData===null ? "" 
            : (requestData[staffid]===null || requestData[staffid]===undefined) ? ""
            : requestData[staffid].hasOwnProperty(objectkeys[i]) ? requestData[staffid][objectkeys[i]] 
            : ""
          let imessage = ""
          if (noncolor!="r" && noncolor!="rr"){
            // 連勤
            if (ayer!="" && ayer!="公" && ayer!="外"  && ayer!="退" && ayer!="育"){
              if (i < objectkeys.length - 5) {
                let pd1 = (row.original[objectkeys[i+2]]!==undefined) ? row.original[objectkeys[i+2]] : ""
                let pd2 = (row.original[objectkeys[i+3]]!==undefined) ? row.original[objectkeys[i+3]] : ""
                let pd3 = (row.original[objectkeys[i+4]]!==undefined) ? row.original[objectkeys[i+4]] : ""
                let pd4 = (row.original[objectkeys[i+5]]!==undefined) ? row.original[objectkeys[i+5]] : ""
                let range5 = [ayer, hoy, pd1, pd2, pd3];
                if (!range5.some(element => element === "")) {
                  let shouldSetColor = true;
                  range5.forEach(element => {
                    if (offshift.some(char => element.includes(char))) {
                      shouldSetColor = false;
                    }
                  })
                  if (shouldSetColor) {
                    colors[i] = red
                    colors[i + 1] = red
                    colors[i + 2] = red
                    colors[i + 3] = red
                    colors[i + 4] = red
                    colors[0] = red
                    imessage = imessage + "5連勤超え\n"
                    message[i] = imessage 
                    message[i+1] = imessage
                    message[i+2] = imessage
                    message[i+3] = imessage 
                    message[i+4] = imessage 
                    if (pd4=="*"){
                      colors[i + 5] = red
                      message[i+5] = imessage 
                    }
                  }
                }
              }
            }
            // 夜勤連勤
            if (Byoutou=="ICU"){
              const yakin = ["5/", "*"]
              if (ayer.includes("5/") || (i==1 && ayer.includes("*"))){
                if (i < objectkeys.length - 10) {
                  let pd1 = (row.original[objectkeys[i+2]]!==undefined) ? row.original[objectkeys[i+2]] : ""
                  let pd2 = (row.original[objectkeys[i+3]]!==undefined) ? row.original[objectkeys[i+3]] : ""
                  let pd3 = (row.original[objectkeys[i+4]]!==undefined) ? row.original[objectkeys[i+4]] : ""
                  let pd4 = (row.original[objectkeys[i+5]]!==undefined) ? row.original[objectkeys[i+5]] : ""
                  let pd5 = (row.original[objectkeys[i+6]]!==undefined) ? row.original[objectkeys[i+6]] : ""
                  let pd6 = (row.original[objectkeys[i+7]]!==undefined) ? row.original[objectkeys[i+7]] : ""
                  let pd7 = (row.original[objectkeys[i+8]]!==undefined) ? row.original[objectkeys[i+8]] : ""
                  let pd8 = (row.original[objectkeys[i+9]]!==undefined) ? row.original[objectkeys[i+9]] : ""
                  let range10 = [ayer, hoy, pd1, pd2, pd3, pd4, pd5, pd6, pd7, pd8]
                  const yakinCount = yakin.reduce((count, element) => {
                    return count + range10.filter(item => item.includes(element)).length;
                  }, 0);
              
                  if (yakinCount >= 7) {
                    if (ayer.includes("5/")){
                      colors[i] = red
                      colors[i + 1] = red
                      colors[i + 2] = red
                      colors[i + 3] = red
                      colors[i + 4] = red
                      colors[i + 5] = red
                      colors[i + 6] = red
                      colors[i + 7] = red
                      colors[i + 8] = red
                      colors[i + 9] = red
                      colors[i + 10] = red
                      colors[0] = red
                      imessage = imessage + "夜勤連続\n"
                      message[i] = imessage
                      message[i+1] = imessage 
                      message[i+2] = imessage 
                      message[i+3] = imessage 
                      message[i+4] = imessage 
                      message[i+5] = imessage 
                      message[i+6] = imessage 
                      message[i+7] = imessage 
                      message[i+8] = imessage 
                      message[i+9] = imessage 
                      message[i+10] = imessage 
                    }
                    else if (ayer=="*"){
                      colors[i] = red
                      colors[i + 1] = red
                      colors[i + 2] = red
                      colors[i + 3] = red
                      colors[i + 4] = red
                      colors[i + 5] = red
                      colors[i + 6] = red
                      colors[i + 7] = red
                      colors[i + 8] = red
                      colors[i + 9] = red
                      colors[0] = red
                      imessage = imessage + "夜勤連続\n"
                      message[i] = imessage
                      message[i+1] = imessage 
                      message[i+2] = imessage 
                      message[i+3] = imessage 
                      message[i+4] = imessage 
                      message[i+5] = imessage 
                      message[i+6] = imessage 
                      message[i+7] = imessage 
                      message[i+8] = imessage 
                      message[i+9] = imessage 
                    }
                  }
                }
              }
            }
            else {
              const yakin = ["長1", "長2", "1/", "2/", "*"]
              if (i < objectkeys.length - 13) {
                let pd1 = (row.original[objectkeys[i+2]]!==undefined) ? row.original[objectkeys[i+2]] : ""
                let pd2 = (row.original[objectkeys[i+3]]!==undefined) ? row.original[objectkeys[i+3]] : ""
                let pd3 = (row.original[objectkeys[i+4]]!==undefined) ? row.original[objectkeys[i+4]] : ""
                let pd4 = (row.original[objectkeys[i+5]]!==undefined) ? row.original[objectkeys[i+5]] : ""
                let pd5 = (row.original[objectkeys[i+6]]!==undefined) ? row.original[objectkeys[i+6]] : ""
                let pd6 = (row.original[objectkeys[i+7]]!==undefined) ? row.original[objectkeys[i+7]] : ""
                let pd7 = (row.original[objectkeys[i+8]]!==undefined) ? row.original[objectkeys[i+8]] : ""
                let pd8 = (row.original[objectkeys[i+9]]!==undefined) ? row.original[objectkeys[i+9]] : ""
                let pd9 = (row.original[objectkeys[i+10]]!==undefined) ? row.original[objectkeys[i+10]] : ""
                let pd10 = (row.original[objectkeys[i+11]]!==undefined) ? row.original[objectkeys[i+11]] : ""
                let pd11 = (row.original[objectkeys[i+12]]!==undefined) ? row.original[objectkeys[i+12]] : ""
                let range13 = [ayer, hoy, pd1, pd2, pd3, pd4, pd5, pd6, pd7, pd8, pd9, pd10, pd11]
                const yakinCount = yakin.reduce((count, element) => {
                  return count + range13.filter(item => item.includes(element)).length;
                }, 0);
            
                if (yakinCount >= 10) {
                  if (ayer.includes("長1") || ayer.includes("長2")){
                    colors[i] = red
                    colors[i + 1] = red
                    colors[i + 2] = red
                    colors[i + 3] = red
                    colors[i + 4] = red
                    colors[i + 5] = red
                    colors[i + 6] = red
                    colors[i + 7] = red
                    colors[i + 8] = red
                    colors[i + 9] = red
                    colors[i + 10] = red
                    colors[i + 11] = red
                    colors[0] = red
                    imessage = imessage + "夜勤連続\n"
                    message[i] = imessage
                    message[i+1] = imessage 
                    message[i+2] = imessage 
                    message[i+3] = imessage 
                    message[i+4] = imessage 
                    message[i+5] = imessage 
                    message[i+6] = imessage 
                    message[i+7] = imessage 
                    message[i+8] = imessage 
                    message[i+9] = imessage 
                  }
                  else if (ayer=="*"){
                    colors[i] = red
                    colors[i + 1] = red
                    colors[i + 2] = red
                    colors[i + 3] = red
                    colors[i + 4] = red
                    colors[i + 5] = red
                    colors[i + 6] = red
                    colors[i + 7] = red
                    colors[i + 8] = red
                    colors[i + 9] = red
                    colors[i + 10] = red
                    colors[i + 11] = red
                    colors[i + 12] = red
                    colors[0] = red
                    imessage = imessage + "夜勤連続\n"
                    message[i] = imessage
                    message[i+1] = imessage 
                    message[i+2] = imessage 
                    message[i+3] = imessage 
                    message[i+4] = imessage 
                    message[i+5] = imessage 
                    message[i+6] = imessage 
                    message[i+7] = imessage 
                    message[i+8] = imessage 
                    message[i+9] = imessage 
                    message[i+10] = imessage
                    message[i+11] = imessage 
                    message[i+12] = imessage 
                  }
                }
              }
            }
            if (i < objectkeys.length-1){
              // ロングー準夜
              if (Byoutou!="ICU" && Byoutou!="ER" && (!requestValue.includes("禁") || (noncolor!="r" && requestValue.includes("禁"))) && (requestValue!="日・長" || (noncolor!="r" && requestValue=="日・長" && ayer.includes("長"))) && (( ayer.includes("長") && !(hoy.includes("5/") || hoy.includes("1/") || hoy.includes("2/"))) || (!ayer.includes("長") && (hoy.includes("5/") || hoy.includes("1/") || hoy.includes("2/"))))){
                colors[i] = red
                colors[i+1] = red
                colors[0] = red
                imessage = imessage + "長ー準夜\n"
                message[i] = imessage
                message[i+1] = imessage
              } 
              // 準夜ー深夜
              if (Byoutou!="ER" && ((!requestValue.includes("禁") || (noncolor!="r" && requestValue.includes("禁"))) && (((ayer.includes("5/") || ayer.includes("1/") || ayer.includes("2/")) && !(hoy=="*")) || (!(ayer.includes("5/") || ayer.includes("1/") || ayer.includes("2/")) && (hoy=="*"))))){
                colors[i] = red
                colors[i+1] = red
                colors[0] = red
                imessage = imessage + "準夜ー深夜\n"
                message[i] = imessage 
                message[i+1] = imessage 
              } 
              // 深夜ー公休、遅番ー公休
              if (Byoutou=="C3"){
                if ((!requestValue.includes("禁") || (noncolor!="r" && requestValue.includes("禁"))) && (((ayer.includes("遅")) && !(hoy.includes("公") || hoy.includes("年") || hoy.includes("リ") || hoy.includes("外") || hoy.includes("婚") || hoy.includes("産前") || hoy.includes("産後") || hoy.includes("育") || hoy.includes("病") || hoy.includes("積") || hoy.includes("忌") || hoy.includes("欠") || hoy.includes("退") || hoy.includes("〇/") || hoy.includes("/〇"))) || ((ayer.includes("遅")) && (hoy.includes("遅"))))){
                  colors[i] = red
                  colors[i+1] = red
                  colors[0] = red
                  imessage = imessage + "遅番ー公休\n"
                  message[i] = imessage
                  message[i+1] = imessage
                }
              } 
              if (Byoutou!="ER" &&  ((!requestValue.includes("禁") || (noncolor!="r" && requestValue.includes("禁"))) && !requestValue.includes("休・深夜") && (((ayer.includes("*")) && !(hoy.includes("公") || hoy.includes("年") || hoy.includes("リ") || hoy.includes("外") || hoy.includes("婚") || hoy.includes("産前") || hoy.includes("産後") || hoy.includes("育") || hoy.includes("病") || hoy.includes("積") || hoy.includes("忌") || hoy.includes("欠") || hoy.includes("退") || hoy.includes("〇/") || hoy.includes("/〇"))) || ((ayer.includes("*")) && (hoy.includes("*")) )))){
                  colors[i] = red
                  colors[i+1] = red
                  colors[0] = red
                  imessage = imessage + "深夜ー公休\n"
                  message[i] = imessage
                  message[i+1] = imessage
              }
            }
          }
          // 勤務希望
          if (requestValue!="" && noncolor!="req" && noncolor!="rr"){
            if (noncolor!="r" && ((!ayer.includes(requestValue) && (requestValue!="日・長" && requestValue!="禁日" && requestValue!="禁長" && requestValue!="禁準" && requestValue!="禁深" && requestValue!="休・深夜")) 
              || (requestValue=="〇" && !(ayer.includes("〇") || ayer.includes("L")))
              || ((requestValue=="日・長" && !(ayer.includes("〇") || ayer.includes("L") || ayer.includes("長")))
              || (requestValue=="禁日" && (ayer.includes("〇") || ayer.includes("L") || ayer=="")) 
              || (requestValue=="禁長" && (ayer.includes("長") || ayer=="")) 
              || (requestValue=="禁準" && (ayer.includes("5/") || ayer.includes("1/") || ayer.includes("2/") || ayer=="")) 
              || (requestValue=="禁深" && (ayer=="*" || ayer=="")) 
              || (requestValue=="休・深夜" && (ayer!="*" && ayer!="公" && ayer!="年" && ayer!="リ" && ayer!="外" && ayer!="婚" && ayer!="産前" && ayer!="産後" && ayer!="育" && ayer!="病" && ayer!="積" && ayer!="忌" && ayer!="欠" && ayer!="退" || ayer=="")))))
            {
              colors[i] = red
              colors[0] = red
              imessage = imessage + "勤務希望違反\n"
              message[i] = imessage 
            }else {
              colors[i] = orange
              message[i] = "希望：" + requestValue}
            }
          
        }
        return [colors, message]
      }
    })
    return bgcolor
  }

  function SetCellColorMessage(){
    const thismonth = (nextmonth==1) ? 12 : Number(nextmonth) - 1

    const shiftData = (localStorage.getItem("Shift-"  + Byoutou)!=null) ? JSON.parse(localStorage.getItem("Shift-"  + Byoutou)) : []
    const teamData = (localStorage.getItem("Team-"  + Byoutou)!=null) ? JSON.parse(localStorage.getItem("Team-"  + Byoutou)) : []
    const constData = (localStorage.getItem("Const-"  + Byoutou)!=null) ? JSON.parse(localStorage.getItem("Const-"  + Byoutou)) : []
    const requestData = (localStorage.getItem("Request-"  + Byoutou  + thisyear + '-' + nextmonth)!=null) ? JSON.parse(localStorage.getItem("Request-"  + Byoutou  + thisyear + '-' + nextmonth)) : null

    // シフトの種類による区別
    let offshift = [] 
    shiftData.filter(shift=>{
      if (shift.type=="公休" || shift.type=="休み系"){
        offshift.push(shift.shift)
      }
    })
    let selectshift = [] 
    shiftData.map(shift=>{
      if (shift.type=="選択系"){
        selectshift.push(shift.shift)
      }
    })
    let notshift = []
    shiftData.map(shift=>{
      if (shift.type=="禁止系"){
        notshift.push(shift.shift)
      }
    })
    let nikkinshift = []
    shiftData.map(shift=>{
      if (shift.type=="日勤系"){
        nikkinshift.push(shift.shift)
      }
    })

    // 色コード
    const white = "#FFFFFF"
    const red = "#FF4D4D"
    const orange = "#FFA500"

    // 背景色とエラーメッセージの設定
    const bgcolor = filteredRows.map((row, staffid) => {
      const objectkeys = Object.keys(row.original)

      // 色付けなしの場合
      if (noncolor=="n"){
        let colors = new Array(objectkeys.length-1).fill(white)
        return colors
      }
      // 色付けありの場合
      else {
        const prestaff = 
            predata===null ? {}
            : (predata[staffid]===null || predata[staffid]===undefined) ? {}
            : predata[staffid]
        
        let colors = [white] // IDのセル
        let message = Array.from({ length: objectkeys.length }, () => "") // エラーメッセージまたは希望シフトメッセージ

        // 条件違反チェック
        let orderlists = []
        let phorderlists = []
        let timeslists = []
        let orderuse = 0
        let phorderuse = 0
        let timesuse = 0
        for (let j = 0; j < constData.length; j++){
          const jconst = constData[j]
          if (jconst.use=="使用"){
            // 順序条件
            if (jconst.type=="順序"){
              const orderlist = jconst.code.split('-')
              orderlists.push(orderlist)
              orderuse += 1
            } else

            // 禁順序条件
            if (jconst.type=="禁順序"){
              const phorderlist = jconst.code.split('-')
              phorderlists.push(phorderlist)
              phorderuse += 1
            } else

            // 回数条件
            if (jconst.type=="回数"){
              const timeslist = jconst.code.split('-')
              if (timeslist[0]=="none" && timeslist[1]=="always" && timeslist[2]=="all"){
                if (timeslists.length==0){
                  timeslists.push(timeslist)
                  timesuse += 1
                } else {
                  let subtimesnum = 0
                  timeslists.forEach((list,l)=>{
                    if (list.slice(0,-1).every((element, index) => element === timeslist.slice(0,-1)[index])){
                      if (list[15] < timeslist[15]){
                        timeslists[l] = timeslist
                        timesuse += 1
                        subtimesnum += 1
                      }
                    }
                  })
                  if (subtimesnum==0){
                    timeslists.push(timeslist)
                    timesuse += 1
                  }
                }
              }
            } else 

            // 勤務希望
            if (jconst.code=="request"){
              for (let i = 1; i < objectkeys.length; i++){
                // 基準日のシフト
                let today = (row.original[objectkeys[i]]!==undefined) ? row.original[objectkeys[i]] : ""
                // 希望シフト
                const requestValue = 
                  (requestData===null) ? "" 
                  : (requestData[staffid]===null || requestData[staffid]===undefined) ? ""
                  : requestData[staffid].hasOwnProperty(objectkeys[i]) ? requestData[staffid][objectkeys[i]] 
                  : ""
                let imessage = "" // 仮メッセージ

                if (requestValue!="" && noncolor!="req" && noncolor!="rr"){
                  let requestnum = 0
                  // 日勤系の違反
                  if (nikkinshift.includes(requestValue)){
                    if (!nikkinshift.some(item => today.includes(item))){
                      requestnum += 1 
                    }
                  } else
                  // 禁止系の違反
                  if (notshift.includes(requestValue)){
                    if (today.includes(requestValue.slice(1))){
                      requestnum += 1
                    }
                  } else
                  // 選択系の違反
                  if (selectshift.includes(requestValue)){
                    const selectlist = requestValue.split("・")
                    if (
                      // 日勤系の違反
                      ((nikkinshift.includes(selectlist[0]) || nikkinshift.includes(selectlist[1])) && !nikkinshift.some(item => today.includes(item)))
                      // 公休系の違反
                      && ((offshift.includes(selectlist[0]) || offshift.includes(selectlist[1])) && !offshift.some(item => today.includes(item)))
                      // その他の違反
                      && (!selectlist.some(item => today.includes(item)))
                    ){
                      requestnum += 1
                    }
                  } else 
                  // シフトが勤務希望と違うかつ選択系、禁止系でない場合
                  if (!today.includes(requestValue)){
                    requestnum += 1
                  }
                  // 違反がない場合はオレンジ色、勤務希望シフトのメッセージ
                  if (noncolor!=="rr"){  
                    colors[i] = orange
                    message[i] = "希望：" + requestValue
                  }
                  // 違反がある場合は赤色、条件違反メッセージ
                  if (noncolor!="r" && requestnum!==0){
                    colors[i] = red
                    colors[0] = red
                    imessage = imessage + "勤務希望違反\n"
                    message[i] = imessage 
                  }
                }
              }
            }
          }
        }

        // 順序違反
        if (orderuse!==0 && noncolor!="r" && noncolor!="rr"){
          let unorderedElements = []

          for (let o = 1; o <= Object.keys(row.original).length; o++) {
            let orderindex = 0
            let preorderindex = 0
            let suborderkeylist = []
            let suborder = []
            let presuborderkeylist = []
            let presuborder = []

            orderlists.forEach(order => {
              if (o <= Object.keys(row.original).length - order.length){
                
                if (o < 5){
                    const sliceOfRow = Object.values(prestaff).slice(-5+o).concat(Object.values(row.original).slice(1, 1 + o)).slice(0, order.length)
                    const sliceOfRowKey = Object.keys(row.original).slice(1, 1 + o)
                    
                    if (!sliceOfRow[0].includes("・") && sliceOfRow[0].includes(order[0]) && !nikkinshift.includes(sliceOfRow[0])) {
                      if ((sliceOfRow.every((element, index) => {
                        return element.includes(order[index]) || (offshift.includes(element) && offshift.includes(order[index]));
                      }))){
                        preorderindex += 1
                      } else {
                        if ((presuborderkeylist.length==0 || (presuborderkeylist.length > sliceOfRowKey))){
                          presuborderkeylist = sliceOfRowKey
                          presuborder = order
                        }
                      }
                    }
                }

                const sliceOfRow = Object.values(row.original).slice(o, o + order.length); 
                const sliceOfRowKey = Object.keys(row.original).slice(o, o + order.length);
                      
                if (order.length <=5 && !sliceOfRow[0].includes("・") && sliceOfRow[0].includes(order[0]) && !nikkinshift.includes(sliceOfRow[0])) {
                  if ((sliceOfRow.every((element, index) => {
                    return element.includes(order[index]) || (offshift.includes(element) && offshift.includes(order[index]));
                  }))){
                    orderindex += 1
                  } else {
                    if (suborderkeylist.length==0 || (suborderkeylist.length > sliceOfRowKey)){
                      suborderkeylist = sliceOfRowKey
                      suborder = order
                    }
                  }
                }
              }
            })

            if (preorderindex==0 && presuborderkeylist.length!==0){
              unorderedElements.push({key: presuborderkeylist, error: presuborder});
            }
            if (orderindex==0 && suborderkeylist.length!==0){
              unorderedElements.push({key: suborderkeylist, error: suborder});
            }
          }
          
          unorderedElements.forEach(list=>{
            for (let l = 0; l < list.key.length; l++){
              colors[Number(list.key[l].slice(1))] = red
              message[Number(list.key[l].slice(1))] = (message[Number(list.key[l].slice(1))].includes("希望") && !message[Number(list.key[l].slice(1))].includes("違反")) 
                ? "順序違反：" + list.error.join('→') + "\n" 
                : (!message[Number(list.key[l].slice(1))].includes("順序違反：" + list.error.join('→')))
                ? message[Number(list.key[l].slice(1))] + "順序違反：" + list.error.join('→') + "\n"
                : message[Number(list.key[l].slice(1))]
            }
            colors[0] = red
          })
        }

        // 禁順序違反
        if (phorderuse!==0 && noncolor!="r" && noncolor!="rr" && noncolor!="req"){
          let unorderedElements = []

          for (let o = 1; o <= Object.keys(row.original).length; o++) {
            let orderindex = 0
            let preorderindex = 0
            let suborderkeylist = []
            let suborder = []
            let presuborderkeylist = []
            let presuborder = []

            phorderlists.forEach(order => {
              if (o <= Object.keys(row.original).length - order.length){
                
                if (o < 5){
                  const sliceOfRow = Object.values(prestaff).slice(-5+o).concat(Object.values(row.original).slice(1, 1 + o)).slice(0, order.length)
                  const sliceOfRowKey = Object.keys(row.original).slice(1, 1 + o)

                  if ((sliceOfRow.every((element, index) => {
                    return element.includes(order[index]) || (offshift.includes(element) && offshift.includes(order[index])) || (nikkinshift.includes(element) && nikkinshift.includes(order[index]));
                  }))){
                    suborderkeylist = sliceOfRowKey
                    suborder = order
                  }
                }

                const sliceOfRow = Object.values(row.original).slice(o, o + order.length); 
                const sliceOfRowKey = Object.keys(row.original).slice(o, o + order.length);
                      
                if (order.length <=5) {
                  if ((sliceOfRow.every((element, index) => {
                    return element.includes(order[index]) || (offshift.includes(element) && offshift.includes(order[index])) || (nikkinshift.includes(element) && nikkinshift.includes(order[index]));
                  }))){
                    suborderkeylist = sliceOfRowKey
                    suborder = order
                  }
                }
              }
            })

            if (presuborderkeylist.length!==0){
              unorderedElements.push({key: presuborderkeylist, error: presuborder});
            }
            if (suborderkeylist.length!==0){
              unorderedElements.push({key: suborderkeylist, error: suborder});
            }
          }
          
          unorderedElements.forEach(list=>{
            for (let l = 0; l < list.key.length; l++){
              colors[Number(list.key[l].slice(1))] = red
              message[Number(list.key[l].slice(1))] = (message[Number(list.key[l].slice(1))].includes("希望") && !message[Number(list.key[l].slice(1))].includes("違反")) 
                ? "禁順序違反：" + list.error.join('→') + "\n" 
                : (!message[Number(list.key[l].slice(1))].includes("禁順序違反：" + list.error.join('→')))
                ? message[Number(list.key[l].slice(1))] + "禁順序違反：" + list.error.join('→') + "\n"
                : message[Number(list.key[l].slice(1))]
            }
            colors[0] = red
          })
        }
      
        // 回数違反
        if (timesuse!==0 && noncolor!="r" && noncolor!="rr"){
          let overtimesElements = []
          
          for (let o = 1; o <= Object.keys(row.original).length; o++) {
            timeslists.forEach(list=>{
              const shiftlist = list.slice(4,14)
              const limit = Number(list[15])

              if (list[3]=="repeat" && limit!==0){
                const replength = shiftlist.filter(element => element !== "").length * limit + 1;

                if (o < replength){
                  const sliceOfRow = Object.values(prestaff).slice(5 - replength + o).concat(Object.values(row.original).slice(1, 1 + o))
                  const sliceOfRowKey = Object.keys(row.original).slice(1, 1 + o)

                  if (!sliceOfRow.some(element => element === "") && !sliceOfRow.some(element => element === "・")) {
                    let shouldSetColor = false;
                    if (shiftlist[0]=="全て"){
                      shouldSetColor = true;
                    }
                    sliceOfRow.forEach(element => {
                      if (shiftlist[0]=="全て"){
                        if (offshift.some(char => element.includes(char))) {
                          shouldSetColor = false;
                        }
                      } else {
                        const timescount = shiftlist.filter(element => (element !== "" && !offshift.includes(element))).reduce((count, element) => {
                          return count + sliceOfRow.filter(item => item.includes(element)).length;
                        }, 0);
                        const newlimit = shiftlist.filter(element => (element !== "" && !offshift.includes(element))).length * limit + 1;
                        if (timescount >= newlimit) {
                          shouldSetColor = true;
                        }
                      }
                    })
                    if (shouldSetColor) {
                      overtimesElements.push({key: sliceOfRowKey, error: shiftlist.filter(element => element !== "").join(",")})
                    }
                  }
                }

                const sliceOfRow = Object.values(row.original).slice(o, o + replength); 
                const sliceOfRowKey = Object.keys(row.original).slice(o, o + replength);

                if (!sliceOfRow.some(element => element === "") && !sliceOfRow.some(element => element.includes("・"))) {
                  let shouldSetColor = false;
                  if (shiftlist[0]=="全て"){
                    shouldSetColor = true;
                  }
                  sliceOfRow.forEach(element => {
                    if (shiftlist[0]=="全て"){
                      if (offshift.some(char => element.includes(char))) {
                        shouldSetColor = false;
                      }
                    } else {
                      const timescount = shiftlist.filter(element => (element !== "" && !offshift.includes(element))).reduce((count, element) => {
                        return count + sliceOfRow.filter(item => item.includes(element)).length;
                      }, 0);
                      const newlimit = shiftlist.filter(element => (element !== "" && !offshift.includes(element))).length * limit + 1;
                      if (timescount >= newlimit) {
                        shouldSetColor = true;
                      }
                    }
                  })
                  if (shouldSetColor) {
                    overtimesElements.push({key: sliceOfRowKey, error: shiftlist.filter(element => element !== "").join(",")})
                  }
                }

              } else 
              if (list[3]=="which"){
                const replength = limit + 1;

                if (o < replength){
                  const sliceOfRow = Object.values(prestaff).slice(5 - replength + o).concat(Object.values(row.original).slice(1, 1 + o))
                  const sliceOfRowKey = Object.keys(row.original).slice(1, 1 + o)

                  if (!sliceOfRow.some(element => element === "") && sliceOfRow.length==replength && !sliceOfRow.some(element => element.includes("・"))) {
                    let shouldSetColor = false;
                    if (shiftlist[0]=="全て"){
                      shouldSetColor = true;
                    }
                    sliceOfRow.forEach(element => {
                      if (shiftlist[0]=="全て"){
                        if (offshift.some(char => element.includes(char))) {
                          shouldSetColor = false;
                        }
                      } else {
                        const timescount = shiftlist.filter(element => (element !== "" && !offshift.includes(element))).reduce((count, element) => {
                          return count + sliceOfRow.filter(item => item.includes(element)).length;
                        }, 0);
                        if (timescount >= replength) {
                          shouldSetColor = true;
                        }
                      }
                    })
                    if (shouldSetColor) {
                      overtimesElements.push({key: sliceOfRowKey, error: shiftlist.filter(element => element !== "").join(",")})
                    }
                  }                  
                }

                const sliceOfRow = Object.values(row.original).slice(o, o + replength); 
                const sliceOfRowKey = Object.keys(row.original).slice(o, o + replength);

                if (!sliceOfRow.some(element => element === "") && sliceOfRow.length==replength) {
                  let shouldSetColor = false;
                  if (shiftlist[0]=="全て"){
                    shouldSetColor = true;
                  }
                  sliceOfRow.forEach(element => {
                    if (shiftlist[0]=="全て"){
                      if (offshift.some(char => element.includes(char))) {
                        shouldSetColor = false;
                      }
                    } else {
                      const timescount = shiftlist.filter(element => (element !== "" && !offshift.includes(element))).reduce((count, element) => {
                        return count + sliceOfRow.filter(item => item.includes(element)).length;
                      }, 0);
                      if (timescount >= replength) {
                        shouldSetColor = true;
                      }
                    }
                  })
                  if (shouldSetColor) {
                    overtimesElements.push({key: sliceOfRowKey, error: shiftlist.filter(element => element !== "").join(",")})
                  }
                }
              }
            })
          }

          overtimesElements.forEach(list=>{
            for (let l = 0; l < list.key.length; l++){
              colors[Number(list.key[l].slice(1))] = red
              message[Number(list.key[l].slice(1))] = (message[Number(list.key[l].slice(1))].includes("希望") && !message[Number(list.key[l].slice(1))].includes("違反")) 
                ? "連続回数違反：" + list.error + "\n" 
                : (!message[Number(list.key[l].slice(1))].includes("連続回数違反：" + list.error))
                ? message[Number(list.key[l].slice(1))] + "連続回数違反：" + list.error + "\n"
                : message[Number(list.key[l].slice(1))]
            }
            colors[0] = red
          })
        }
      
        return [colors, message]
      }
    })
    return bgcolor
  }

  //console.time("backgroundcolor");
  const bgcolor = SetCellColorMessage()
  //console.timeEnd("backgroundcolor");

  const [cellState, setCellState] = useState({index: null, bl: false});
  const [cellStatepaste, setCellStatepaste] = useState({index: null, value: null, bl: false});

  // セルコンポーネントからのデータ更新を受け取る関数
  const handleCellDataUpdate = (newValue) => {
    // 更新された true または false の値を受け取り、テーブルコンポーネント内で利用できるようにする
    setCellState(newValue);
  };

  // セルコンポーネントからのデータ更新を受け取る関数
  const handleCellDataUpdatePaste = (newValue) => {
    // 更新された true または false の値を受け取り、テーブルコンポーネント内で利用できるようにする
    setCellStatepaste(newValue);
  };

  const [hoveredCell, setHoveredCell] = useState({rowIndex: null, columnIndex: null});

  const handleCellHover = React.useCallback(({ id, index }) => {
    setHoveredCell({
      rowIndex: id,
      columnIndex: index,
    });
  }, [setHoveredCell]);

  const handleCellLeave = () => {
    setHoveredCell({rowIndex: null, columnIndex: null});
  };
 
  return (
    <>
      <div {...getTableProps()} className={clsx("table", isTableResizing() && "noselect")}>
        <div className="thead">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className='tr-header'>
              {headerGroup.headers.map((column) => column.render("Header", {
                globalFilter: state.globalFilter,
                setGlobalFilter,
                columnState: state,
                thisyear,
                nextmonth
              }))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()} >
          {filteredRows.map((row, i) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className='tr'>
                {row.cells.map((cell, j) => (
                  <div {...cell.getCellProps()} className='td'>
                    {cell.render("Cell", {
                      bgcolor: ((cell.column.id==hoveredCell.rowIndex || cell.row.id==hoveredCell.columnIndex) && bgcolor[i][0][j]!=='#FFA500' && bgcolor[i][0][j]!=='#FF4D4D') ? '#FFFFE0' : noncolor=="n" ? "#FFFFFF" : bgcolor[i][0][j],
                      //bgcolor: noncolor=="n" ? "#FFFFFF" : bgcolor[i][0][j],
                      cellvalue: cellvalue,
                      error: noncolor=="n" ? "" : bgcolor[i][1][j],
                      noncolor: noncolor,
                      deleterow: deleterowvalue,
                      updateDelete: handledeleterow,
                      pasterow: pasterowvalue,
                      updatePaste: handlepasterow,
                      noselect: noselect,
                      Byoutou: Byoutou,
                      localname: localname,
                      onCellHover: handleCellHover,
                      onCellLeave: handleCellLeave
                    })}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

