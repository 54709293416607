import React, {useEffect, useState} from "react";
import ContentEditable from "react-contenteditable";
import Relationship from "../../../Table/Relationship";
import {usePopper} from "react-popper";
import {setcol} from "../../../Table/colors";
import PlusIcon from "../../../Table/img/Plus";
import {randomColor} from "../../../Table/utils";
const JapaneseHolidays = require('japanese-holidays');
 
var date = new Date();
var lastDate = new Date(date.getFullYear(), date.getMonth()+2, 0);
var days = lastDate.getDate()
const isholiday = JapaneseHolidays.getHolidaysOf(lastDate.getFullYear(), true);

const holiday = []
for (let i = 0; i < isholiday.length; i++){
  if (isholiday[i].month===lastDate.getMonth()+1){
    holiday.push(isholiday[i].date)
  }
}

const weekend = []
for (let i = 1; i <= days; i++){
  var day = new Date(date.getFullYear(), date.getMonth()+2, i)
  if ([0, 6].includes(day.getDay())){
    weekend.push("D" + String(i))
  }
  if (holiday.includes(i)){
    if (!weekend.includes(i)){
      weekend.push("D" + String(i))
    }
  }
}

export default function Cell({value: initialValue, row: {index}, column: {id, dataType, options}, dataDispatch, bgcolor}) {
  const [value, setValue] = useState({value: initialValue, update: false});
  const [selectRef, setSelectRef] = useState(null);
  const [selectPop, setSelectPop] = useState(null);
  const [showSelect, setShowSelect] = useState(false);
  const onChange = (e) => {
    setValue({value: e.target.value, update: false});
  };
  const [showAdd, setShowAdd] = useState(false);
  const [addSelectRef, setAddSelectRef] = useState(null);

  useEffect(() => {
    setValue({value: initialValue, update: false});
  }, [initialValue]);

  useEffect(() => {
    if (value.update) {
      dataDispatch({type: "update_cell", columnId: id, rowIndex: index, value: value.value});
    }
  }, [value, dataDispatch, id, index]);

  function handleOptionKeyDown(e) {
    if (e.key === "Enter") {
      if (e.target.value !== "") {
        dataDispatch({
          type: "add_option_to_column",
          option: e.target.value,
          backgroundColor: randomColor(),
          columnId: id
        });
      }
      setShowAdd(false);
    }
  }

  function handleAddOption(e) {
    setShowAdd(true);
  }

  function handleOptionBlur(e) {
    if (e.target.value !== "") {
      dataDispatch({
        type: "add_option_to_column",
        option: e.target.value,
        backgroundColor: randomColor(),
        columnId: id
      });
    }
    setShowAdd(false);
  }

  const {styles, attributes} = usePopper(selectRef, selectPop, {
    placement: "bottom-start",
    strategy: "fixed"
  });

  function getColor() {
    let match = options.find((option) => option.label === value.value);
    return (match && match.backgroundColor) || setcol(0);
  }

  useEffect(() => {
    if (addSelectRef && showAdd) {
      addSelectRef.focus();
    }
  }, [addSelectRef, showAdd]);

  let element;
  switch (dataType) {
    case "text":
      element = (
        <div
          className='data-input'
          style={{backgroundColor: `
            ${((value.value)<0) ? "#FF4500" : "#FFFFFF"}
            `}} 
            >
              {value.value}
        </div>
      );
      break;
    case "number":
      element = (
        <ContentEditable
          html={(value.value && value.value.toString()) || ""}
          onBlur={() => setValue((old) => ({value: old.value, update: true}))}
          className='data-input text-align-right'
        />
      );
      break;
    case "select":
      element = (
        <>
          <div
            ref={setSelectRef}
            className='cell-padding d-flex cursor-default align-items-center flex-1'
            style={{backgroundColor: bgcolor}}>
            

            {value.value && <Relationship value={value.value} backgroundColor={getColor()} />}
          </div>
        </>
      );
      break;
    default:
      element = <span></span>;
      break;
  }

  return element;
}
