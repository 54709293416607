import React, {useState, useEffect} from "react";
import {usePopper} from "react-popper";
import {setcol} from "./colors";
const JapaneseHolidays = require('japanese-holidays');
 
var date = new Date();
var lastDate = new Date(date.getFullYear(), date.getMonth()+2, 0);
var days = lastDate.getDate()
const isholiday = JapaneseHolidays.getHolidaysOf(lastDate.getFullYear(), true);

const holiday = []
for (let i = 0; i < isholiday.length; i++){
  if (isholiday[i].month===lastDate.getMonth()+1){
    holiday.push(isholiday[i].date)
  }
}

const weekend = []
const whatday = []
for (let i = 1; i <= days; i++){
  var day = new Date(date.getFullYear(), date.getMonth()+1, i)
  if ([0, 6].includes(day.getDay())){
    weekend.push(String(i))
  }
  if (holiday.includes(i)){
    if (!weekend.includes(i)){
      weekend.push(String(i))
    }
  }
  if ([0].includes(day.getDay())){
    whatday.push("日")
  }
  if ([1].includes(day.getDay())){
    whatday.push("月")
  }
  if ([2].includes(day.getDay())){
    whatday.push("火")
  }
  if ([3].includes(day.getDay())){
    whatday.push("水")
  }
  if ([4].includes(day.getDay())){
    whatday.push("木")
  }
  if ([5].includes(day.getDay())){
    whatday.push("金")
  }
  if ([6].includes(day.getDay())){
    whatday.push("土")
  }
}

export default function Header({
  column: {id, created, label, dataType, getResizerProps, getHeaderProps},
  setSortBy,
  dataDispatch
}) {
  const [expanded, setExpanded] = useState(created || false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [inputRef, setInputRef] = useState(null);
  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    placement: "bottom",
    strategy: "absolute"
  });
  const [header, setHeader] = useState(label);
  const [typeReferenceElement, setTypeReferenceElement] = useState(null);
  const [typePopperElement, setTypePopperElement] = useState(null);
  const [showType, setShowType] = useState(false);

  let propertyIcon;

  useEffect(() => {
    if (created) {
      setExpanded(true);
    }
  }, [created]);

  useEffect(() => {
    setHeader(label);
  }, [label]);

  useEffect(() => {
    if (inputRef) {
      inputRef.focus();
      inputRef.select();
    }
  }, [inputRef]);

  const typePopper = usePopper(typeReferenceElement, typePopperElement, {
    placement: "right",
    strategy: "fixed"
  });

  return id !== 999999 ? (
    <>
      <div className='th custom-class' {...getHeaderProps(
        {style: {
          display: "inline-block", 
          color: "#000000",
          backgroundColor: weekend.includes(label) ? setcol(3) : setcol(0),
        }
          })}>
        <div className='th-content' onClick={() => setExpanded(true)} ref={setReferenceElement}>
          <span className='svg-icon svg-gray icon-margin'>{propertyIcon}</span>
          {[0].map(function(value, index){{return whatday[Number(label)-1]}})}
          {!label.includes("日") && label!=="計" && <br/>}
          {!label.includes("日") && label}
          {label.includes("日") && 
          <>
            {label.slice(0,2)}
            {<br/>}
            {label.slice(2)}
            </>}
        </div>
        <div  />
      </div>
    </>
  ) : (
    <div {...getHeaderProps({style: {display: "inline-block"}})} className='th noselect'>
    </div>
  );
}
