import { setcol } from "../../../Table/colors";
import React, { useRef, useEffect, useReducer, useState, useCallback, ref } from "react";
import "../../../styles.css";
import Table from "../../../Table/TableS";
import { randomColor, shortId } from "../../../Table/utils";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {usePopper} from "react-popper";
import Relationship from "../../../Table/Relationship";
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import Select from 'react-select'

function Slider({ value, onValueChange }) {
  const handleChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    onValueChange(newValue);
  };

  return (
    <div>
      <input
        type="range"
        min={1}
        max={50}
        value={value}
        onChange={handleChange}
        style={{ width: '200px' }}
      />
      <p style={{display: 'flex', justifyContent: 'center'}}>重要度: {value}</p>
    </div>
  );
}

class ExcelRequestsImport extends React.Component {
  state = {
    cols: [],
    rows: []
  };

  constructor(props) {
    super(props);
    this.state = {
      keyvalue: { ward: '', year: '', month: '' },
    };
  }

  componentDidMount() {
    const localvalue = JSON.parse(localStorage.getItem('Const-key'));
    this.setState({ keyvalue: localvalue });
  }

  render() {
    if (sessionStorage.getItem("Login")==="1"){
      sessionStorage.setItem("Login", "1")
    }

    let Byoutou = localStorage.getItem("Byoutou")
    let byoutou = Byoutou.toLocaleLowerCase()

    const JapaneseHolidays = require('japanese-holidays');
 
    var date = new Date();
    var lastDate = new Date(date.getFullYear(), date.getMonth()+2, 0);
    var days = lastDate.getDate()
    const isholiday = JapaneseHolidays.getHolidaysOf(lastDate.getFullYear(), true);

    const holiday = []
    for (let i = 0; i < isholiday.length; i++){
      if (isholiday[i].month===lastDate.getMonth()+1){
        holiday.push(isholiday[i].date)
      }
    }

    const weekend = []
    for (let i = 1; i <= days; i++){
      var day = new Date(date.getFullYear(), date.getMonth()+2, i)
      if ([0, 6].includes(day.getDay())){
        weekend.push(i)
      }
      if (holiday.includes(i)){
        if (!weekend.includes(i)){
          weekend.push(i)
        }
      }
    }

    if (localStorage.getItem('con1'+byoutou)!=2){
      localStorage.setItem('con1'+byoutou, 1)
    }

    let typeoptions = [
      {label: "順序", backgroundColor: "#FFC0CB"},
      {label: "禁順序", backgroundColor: "#DDA0DD"},
      {label: "回数", backgroundColor: setcol(20)},
      {label: "スキル", backgroundColor: setcol(7)},
      {label: "チーム", backgroundColor: "#90EE90"},
      {label: "その他", backgroundColor: setcol(16)},
    ]

    let periodoptions = [
      {label: "使用", backgroundColor: setcol(9)},
      {label: "", backgroundColor: setcol(9)},
    ]

    let allowshift = [
      "管",
      "〇",
      "L",
      "長1",
      "長2",
      "1/",
      "2/",
      "5/",
      "*",
      "遅J",
    ]

    function useLatest(value) {
      const ref = useRef(value);
      ref.current = value;
      return ref;
    }

    function useKeybind({
      altKey,
      ctrlKey,
      metaKey,
      shiftKey,
      key,
      onKeyDown,
      targetRef,
    }) {
      const onKeyDownLatest = useLatest(onKeyDown);

      useEffect(() => {
        const eventListener = (event) => {
          if (altKey && !event.altKey) return;
          if (ctrlKey && !event.ctrlKey) return;
          if (metaKey && !event.metaKey) return;
          if (shiftKey && !event.shiftKey) return;
          if (event.key !== key) return;

          event.preventDefault();
          onKeyDownLatest.current?.(event);
        };

        if (targetRef?.current) {
          const target = targetRef.current;

          target.addEventListener("keydown", eventListener);
          return () => target.removeEventListener("keydown", eventListener);
        } else {
          window.addEventListener("keydown", eventListener);
          return () => window.removeEventListener("keydown", eventListener);
        }
      }, [altKey, ctrlKey, key, metaKey, onKeyDownLatest, shiftKey, targetRef]);
    }

    const shift = (localStorage.getItem("Shift-"+Byoutou)!==null) ? JSON.parse(localStorage.getItem("Shift-"+Byoutou)) : []
    const team = (localStorage.getItem("Team-"+Byoutou)!==null) ? JSON.parse(localStorage.getItem("Team-"+Byoutou)) : []
    const skill = (localStorage.getItem("Skill-"+Byoutou)!==null) ? JSON.parse(localStorage.getItem("Skill-"+Byoutou)) : []

    let shiftoptions = [];
    let preshiftoptions = [];

    shift.forEach((data,i) => {
      if (data.shift=="〇" || data.shift=="1/" ||  data.shift=="2/" ||  data.shift=="5/" ||  data.shift=="長1" ||   data.shift=="長2"){
        for (const t in team){
          preshiftoptions.push({label: data.shift + team[t].team, backgroundColor: data.color})
        }
      } else {
        preshiftoptions.push({ label: data.shift, backgroundColor: data.color });
      }
      shiftoptions.push({ label: data.shift, backgroundColor: data.color });
    })

    let mdata = [];
    let scolumns = [
      {
        id: "shift",
        label: "シフト",
        accessor: "shift",
        width: 10,
        dataType: "text",
        options: []
      },    
    ]
    team.forEach((data,i) => {
      if (team.length==1){
        scolumns.push(
          {
            id: "daytotal",
            label: "平日",
            accessor: "daytotal",
            width: 5,
            dataType: "number",
            options: []
          },
          {
            id: "endtotal",
            label: "休日",
            accessor: "endtotal",
            width: 5,
            dataType: "number",
            options: []
          }
        )
      } else {
        scolumns.push(
          {
            id: "weekday" + data.team,
            label: "平日" + data.team,
            accessor: "weekday" + data.team,
            width: 5,
            dataType: "number",
            options: []
          },    
        )
      }
    })
    let limitdata = JSON.parse(localStorage.getItem('Limit-'+Byoutou))!==null ? JSON.parse(localStorage.getItem('Limit-'+Byoutou)) : []

    if (team.length!==1){
      scolumns.push(
        {
          id: "daytotal",
          label: "平日　計",
          accessor: "daytotal",
          width: 5,
          dataType: "number",
          options: []
        }
      )
      team.forEach((data,i) => {
          scolumns.push(
            {
              id: "weekend" + data.team,
              label: "休日" + data.team,
              accessor: "weekend" + data.team,
              width: 5,
              dataType: "number",
              options: []
            }
          )
        })
      scolumns.push(
        {
          id: "endtotal",
          label: "休日　計",
          accessor: "endtotal",
          width: 5,
          dataType: "number",
          options: []
        }
      )
    }
    shift.forEach((data,j) => {
      if (data.count=="使用"){
        let row = {
          shift: data.shift,
        }

        const Index = limitdata.findIndex(item => item.shift === data.shift);
        team.forEach((data,i) => {
          if (team.length!==1){
            row["weekday" + data.team] = Index==-1 ? "0" : (limitdata[Index]["weekday" + data.team]===null || limitdata[Index]["weekday" + data.team]==="") ? "0" : limitdata[Index]["weekday" + data.team] 
          }
        })
    
        if (team.length!==1){
          team.forEach((data,i) => {
            row["weekend" + data.team] = Index==-1 ? "0" : (limitdata[Index]["weekend" + data.team]===null || limitdata[Index]["weekend" + data.team]==="") ? "0" : limitdata[Index]["weekend" + data.team] 
          })
        }
        row["daytotal"] = Index==-1 ? "0" : (limitdata[Index]["daytotal"]==="" || limitdata[Index]["daytotal"]===null) ? "0" : limitdata[Index]["daytotal"] 
        row["endtotal"] = Index==-1 ? "0" : (limitdata[Index]["endtotal"]==="" || limitdata[Index]["endtotal"]===null) ? "0" : limitdata[Index]["endtotal"] 
        mdata.push(row);
      }
    })

    let teamoptions = [];
    team.forEach((data,i) => {
      if (data.team!==""){
        teamoptions.push(
          {label: data.team, value: data.team}
      )}
    })
    teamoptions.push({label: "全て", value: "all"})
    if (teamoptions.length>1){
      teamoptions.push({label: "各チーム", value: "each"})
    }


    let skilloptions = [];
    let skilloptions2 = [];
    skill.forEach((data,i) => {
      if (data.skill!==""){
        skilloptions.push(
          {label: data.skill, value: data.skill}
      )
      skilloptions2.push(
        {label: data.skill, value: data.skill}
    )}
    })
    skilloptions.push({label: "男性", value: "Male"},{label: "全て", value: "all"})
    skilloptions2.push({label: "全て", value: "all"})
    
    let constdata = (JSON.parse(localStorage.getItem('Const-'+Byoutou))!==null && localStorage.getItem('con1'+byoutou)!=1) ? JSON.parse(localStorage.getItem('Const-'+Byoutou)) 
      : (Byoutou=="ER") 
      ? [
        {
            "ID": "1",
            "type": "順序",
            "const": "5/→公",
            "use": "使用",
            "color": "",
            "code": "5/-公",
            "cost": "45"
        },
        {
            "ID": "2",
            "type": "順序",
            "const": "5/→*→公",
            "use": "使用",
            "color": "",
            "code": "5/-*-公",
            "cost": "45"
        },
        {
            "ID": "3",
            "type": "順序",
            "const": "5/→5/→*→公",
            "use": "使用",
            "color": "",
            "code": "5/-5/-*-公",
            "cost": "45"
        },
        {
            "ID": "4",
            "type": "順序",
            "const": "5/→*→*→公→公",
            "use": "使用",
            "color": "",
            "code": "5/-*-*-公-公",
            "cost": "45"
        },
        {
            "ID": "5",
            "type": "順序",
            "const": "5/→5/→公",
            "use": "使用",
            "color": "",
            "code": "5/-5/-公",
            "cost": "45"
        },
        {
            "ID": "6",
            "type": "順序",
            "const": "〇→*→5/→公",
            "use": "使用",
            "color": "",
            "code": "〇-*-5/-公",
            "cost": "1"
        },
        {
            "ID": "7",
            "type": "順序",
            "const": "遅J→公",
            "use": "使用",
            "color": "",
            "code": "遅J-公",
            "cost": "44"
        },
        {
            "ID": "8",
            "type": "順序",
            "const": "遅J→遅J→公",
            "use": "使用",
            "color": "",
            "code": "遅J-遅J-公",
            "cost": "43"
        },
        {
            "ID": "9",
            "type": "順序",
            "const": "遅J→5/→*→公",
            "use": "使用",
            "color": "",
            "code": "遅J-5/-*-公",
            "cost": "43"
        },
        {
            "ID": "10",
            "type": "順序",
            "const": "遅J→5/→5/→公",
            "use": "使用",
            "color": "",
            "code": "遅J-5/-5/-公",
            "cost": "43"
        },
        {
            "ID": "11",
            "type": "順序",
            "const": "5/→5/→5/→公",
            "use": "使用",
            "color": "",
            "code": "5/-5/-5/-公",
            "cost": "41"
        },
        {
            "ID": "12",
            "type": "順序",
            "const": "5/→*→*→公",
            "use": "使用",
            "color": "",
            "code": "5/-*-*-公",
            "cost": "44"
        },
        {
            "ID": "14",
            "type": "禁順序",
            "const": "禁〇→*",
            "use": "使用",
            "color": "",
            "code": "〇-*",
            "cost": "45"
        },
        {
            "ID": "15",
            "type": "禁順序",
            "const": "禁公→*",
            "use": "使用",
            "color": "",
            "code": "公-*",
            "cost": "45"
        },
        {
            "ID": "16",
            "type": "回数",
            "const": "1ヶ月間のうち連続公・公の繰り返しは1回以上",
            "use": "使用",
            "color": "",
            "code": "1-month-all-repeat-公-公--------->=-1",
            "cost": "10"
        },
        {
            "ID": "17",
            "type": "回数",
            "const": "5/・*のいずれかが連続するのは3回以下",
            "use": "使用",
            "color": "",
            "code": "none-always-all-which-5/-*---------<=-3",
            "cost": "49"
        },
        {
            "ID": "18",
            "type": "回数",
            "const": "1ヶ月間のうち休日で公は均等",
            "use": "使用",
            "color": "",
            "code": "1-month-holiday-none-公----------==-均等",
            "cost": "10"
        },
        {
            "ID": "19",
            "type": "回数",
            "const": "1ヶ月間のうち休日で〇・L・ｶﾃ・車は均等",
            "use": "使用",
            "color": "",
            "code": "1-month-holiday-none-〇-L-ｶﾃ-車-------==-均等",
            "cost": "10"
        },
        {
            "ID": "20",
            "type": "回数",
            "const": "〇・L・ｶﾃ・車のいずれかがか連続するのは4回以下",
            "use": "使用",
            "color": "",
            "code": "none-always-all-which-〇-L-ｶﾃ-車-------<=-4",
            "cost": "47"
        },
        {
            "ID": "21",
            "type": "回数",
            "const": "全てのいずれかが連続するのは5回以下",
            "use": "使用",
            "color": "",
            "code": "none-always-all-which-全て----------<=-5",
            "cost": "45"
        },
        {
            "ID": "23",
            "type": "回数",
            "const": "連続5/の繰り返しは2回以下",
            "use": "使用",
            "color": "",
            "code": "none-always-all-repeat-5/----------<=-2",
            "cost": "35"
        },
        {
            "ID": "24",
            "type": "回数",
            "const": "連続5/の繰り返しは3回以下",
            "use": "使用",
            "color": "",
            "code": "none-always-all-repeat-5/----------<=-3",
            "cost": "47"
        },
        {
            "ID": "25",
            "type": "スキル",
            "const": "もし5/でM1が1人以上なら遅JではTLと,L2と,L1と,Lと,Mの合計が1人以上",
            "use": "使用",
            "color": "",
            "code": "none-always-all-5/----------全て-M1->=--1-遅J----------全て-TL,L2,L1,L,M->=--1",
            "cost": "15"
        },
        {
            "ID": "26",
            "type": "スキル",
            "const": "管はHEADが1人",
            "use": "使用",
            "color": "",
            "code": "none-always-all-管----------全て-HEAD-==--1---------------",
            "cost": "48"
        },
        {
            "ID": "27",
            "type": "スキル",
            "const": "もし管でTLが0人なら管ではTLが1人",
            "use": "使用",
            "color": "",
            "code": "none-always-all-管----------全て-TL-==--0-管----------全て-TL-==--1",
            "cost": "45"
        },
        {
            "ID": "28",
            "type": "スキル",
            "const": "休日で全てはHEADが0人",
            "use": "使用",
            "color": "",
            "code": "none-always-holiday-全て----------全て-HEAD-==--0---------------",
            "cost": "50"
        },
        {
            "ID": "29",
            "type": "スキル",
            "const": "L・ｶﾃ・車・大・RRT・IVR・WI・5/・*・遅JはHEADが0人",
            "use": "使用",
            "color": "",
            "code": "none-always-all-L-ｶﾃ-車-大-RRT-IVR-WI-5/-*-遅J-全て-HEAD-==--0---------------",
            "cost": "50"
        },
        {
            "ID": "30",
            "type": "スキル",
            "const": "平日で〇・L・ｶﾃ・車・大・RRT・IVR・WIはTLと,L2と,L1の合計が2人以上",
            "use": "使用",
            "color": "",
            "code": "none-always-weekday-〇-L-ｶﾃ-車-大-RRT-IVR-WI---全て-TL,L2,L1->=--2---------------",
            "cost": "25"
        },
        {
            "ID": "31",
            "type": "スキル",
            "const": "〇・L・ｶﾃ・大・車・RRT・IVR・WIはM1が1人以下",
            "use": "使用",
            "color": "",
            "code": "none-always-all-〇-L-ｶﾃ-大-車-RRT-IVR-WI---全て-M1-<=--1---------------",
            "cost": "20"
        },
        {
            "ID": "32",
            "type": "スキル",
            "const": "5/はTLと,L1と,L2の合計が1人以上",
            "use": "使用",
            "color": "",
            "code": "none-always-all-5/----------全て-TL,L1,L2->=--1---------------",
            "cost": "20"
        },
        {
            "ID": "33",
            "type": "スキル",
            "const": "5/はTLと,L2と,L1と,Lの合計が1人以上",
            "use": "使用",
            "color": "",
            "code": "none-always-all-5/----------全て-TL,L2,L1,L->=--1---------------",
            "cost": "25"
        },
        {
            "ID": "34",
            "type": "スキル",
            "const": "もし5/でL1が1人以下なら5/ではLが1人以上",
            "use": "使用",
            "color": "",
            "code": "none-always-all-5/----------全て-L1-<=--1-5/----------全て-L->=--1",
            "cost": "20"
        },
        {
            "ID": "35",
            "type": "スキル",
            "const": "5/はM1が1人以下",
            "use": "使用",
            "color": "",
            "code": "none-always-all-5/----------全て-M1-<=--1---------------",
            "cost": "20"
        },
        {
            "ID": "36",
            "type": "スキル",
            "const": "5/はMと,M2と,M1の合計が1人以下",
            "use": "使用",
            "color": "",
            "code": "none-always-all-5/----------全て-M,M2,M1-<=--1---------------",
            "cost": "20"
        },
        {
            "ID": "37",
            "type": "スキル",
            "const": "もし5/でM1が1人以上なら遅JではM1が0人",
            "use": "使用",
            "color": "",
            "code": "none-always-all-5/----------全て-M1->=--1-遅J----------全て-M1-==--0",
            "cost": "35"
        },
        {
            "ID": "38",
            "type": "スキル",
            "const": "もし5/でM1が1人以上なら5/では全てのスタッフが枠数+1",
            "use": "",
            "color": "",
            "code": "none-always-all-5/----------全て-M1->=--1-5/----------全て-全て-==-+-1",
            "cost": "30"
        },
        {
            "ID": "39",
            "type": "スキル",
            "const": "もし5/でM2が2人以上なら5/ではTLと,L2の合計が1人以上",
            "use": "使用",
            "color": "",
            "code": "none-always-all-5/----------全て-M2->=--2-5/----------全て-TL,L2->=--1",
            "cost": "30"
        },
        {
            "ID": "40",
            "type": "スキル",
            "const": "5/はM2と,M1の合計が2人以下",
            "use": "使用",
            "color": "",
            "code": "none-always-all-5/----------全て-M2,M1-<=--2---------------",
            "cost": "30"
        },
        {
            "ID": "41",
            "type": "スキル",
            "const": "もし5/でMが1人以上なら5/ではTLと,L2と,L1と,Lの合計が1人以上",
            "use": "使用",
            "color": "",
            "code": "none-always-all-5/----------全て-M->=--1-5/----------全て-TL,L2,L1,L->=--1",
            "cost": "20"
        },
        {
            "ID": "42",
            "type": "スキル",
            "const": "5/は男性が4人以下",
            "use": "使用",
            "color": "",
            "code": "none-always-all-5/----------全て-男性-<=--4---------------",
            "cost": "20"
        },
        {
            "ID": "43",
            "type": "スキル",
            "const": "*は男性が3人以下",
            "use": "使用",
            "color": "",
            "code": "none-always-all-*----------全て-男性-<=--3---------------",
            "cost": "20"
        },
        {
            "ID": "44",
            "type": "スキル",
            "const": "休日で〇・L・ｶﾃ・車・大・RRT・IVR・WIはTLと,L2の合計が1人以上",
            "use": "使用",
            "color": "",
            "code": "none-always-holiday-〇-L-ｶﾃ-車-大-RRT-IVR-WI---全て-TL,L2->=--1---------------",
            "cost": "18"
        },
        {
            "ID": "45",
            "type": "スキル",
            "const": "休日でもし〇・L・ｶﾃ・車・大・RRT・IVR・WIでTLと,L2の合計が1人以下なら〇・L・ｶﾃ・車・大・RRT・IVR・WIではTLと,L2と,L1と,Lの合計が1人以上",
            "use": "使用",
            "color": "",
            "code": "none-always-holiday-〇-L-ｶﾃ-車-大-RRT-IVR-WI---全て-TL,L2-<=--1-〇-L-ｶﾃ-車-大-RRT-IVR-WI---全て-TL,L2,L1,L->=--1",
            "cost": "18"
        },
        {
            "ID": "46",
            "type": "スキル",
            "const": "休日で〇・L・ｶﾃ・車・RRT・IVR・WI・大はM1が1人以下",
            "use": "使用",
            "color": "",
            "code": "none-always-holiday-〇-L-ｶﾃ-車-RRT-IVR-WI-大---全て-M1-<=--1---------------",
            "cost": "18"
        },
        {
            "ID": "47",
            "type": "スキル",
            "const": "休日でもし〇・L・ｶﾃ・車・大・RRT・IVR・WIでM1が1人以上なら〇・L・ｶﾃ・車・大・RRT・IVR・WIでは全てのスタッフが枠数+1",
            "use": "使用",
            "color": "",
            "code": "none-always-holiday-〇-L-ｶﾃ-車-大-RRT-IVR-WI---全て-M1->=--1-〇-L-ｶﾃ-車-大-RRT-IVR-WI---全て-全て-==-+-1",
            "cost": "18"
        },
        {
            "ID": "48",
            "type": "スキル",
            "const": "休日で〇・L・ｶﾃ・車・大・RRT・IVR・WIはM2と,M1の合計が1人以下",
            "use": "使用",
            "color": "",
            "code": "none-always-holiday-〇-L-ｶﾃ-車-大-RRT-IVR-WI---全て-M2,M1-<=--1---------------",
            "cost": "18"
        },
        {
            "ID": "49",
            "type": "スキル",
            "const": "休日で〇・L・車・ｶﾃ・大・RRT・IVR・WIはTLと,L2と,L1と,Lの合計が枠数÷3以上",
            "use": "使用",
            "color": "",
            "code": "none-always-holiday-〇-L-車-ｶﾃ-大-RRT-IVR-WI---全て-TL,L2,L1,L->=-//-3---------------",
            "cost": "5"
        },
        {
            "ID": "50",
            "type": "スキル",
            "const": "全てはMと,M2と,M1の合計が枠数÷2以下",
            "use": "使用",
            "color": "",
            "code": "none-always-all-全て----------全て-M,M2,M1-<=-//-2---------------",
            "cost": "5"
        },
        {
            "ID": "51",
            "type": "スキル",
            "const": "5/はM2と,M1の合計が1人以下",
            "use": "使用",
            "color": "",
            "code": "none-always-all-5/----------全て-M2,M1-<=--1---------------",
            "cost": "30"
        },
        {
            "ID": "52",
            "type": "その他",
            "const": "勤務希望を満たす",
            "use": "使用",
            "color": "",
            "code": "request",
            "cost": "50"
        },
        {
            "ID": "53",
            "type": "その他",
            "const": "枠数を満たす",
            "use": "使用",
            "color": "",
            "code": "limit",
            "cost": "50"
        },
        {
            "ID": "54",
            "type": "その他",
            "const": "公休数の設定",
            "use": "使用",
            "color": "",
            "code": "off",
            "cost": "30"
        },
        {
            "ID": "55",
            "type": "その他",
            "const": "スタッフページの上下限を満たす",
            "use": "使用",
            "color": "",
            "code": "uplow",
            "cost": "49"
        },
        {
            "ID": "56",
            "type": "その他",
            "const": "スタッフページのシフト可否を満たす",
            "use": "使用",
            "color": "",
            "code": "ok",
            "cost": "48"
        },
        {
            "ID": "57",
            "type": "その他",
            "const": "スタッフページの同時勤務条件を満たす",
            "use": "使用",
            "color": "",
            "code": "same",
            "cost": "35"
        }
      ]
      : [
        {"ID":"1","type":"その他","const":"勤務希望を満たす","use":"使用","color":"","code":"request","cost":"50"},
        {"ID":"2","type":"その他","const":"枠数を満たす","use":"使用","color":"","code":"limit","cost":"50"},
        {"ID":"3","type":"その他","const":"公休数の設定","use":"使用","color":"","code":"off","cost":"30"},
        {"ID":"4","type":"その他","const":"スタッフページの上下限を満たす","use":"使用","color":"","code":"uplow","cost":"49"},
        {"ID":"5","type":"その他","const":"スタッフページのシフト可否を満たす","use":"","color":"","code":"ok","cost":"48"},
        {"ID":"6","type":"その他","const":"スタッフページの同時勤務条件を満たす","use":"","color":"","code":"same","cost":"35"}
      ]
    
    //console.log(constdata)

    let shiftdata = [];
    let shiftcolumns = [
      {
        id: "ID",
        label: "No.",
        accessor: "ID",
        width: 5,
        dataType: "text",
        options: []
      }, 
      {
        id: "type",
        label: "グループ",
        accessor: "type",
        width: 7,
        dataType: "select",
        options: typeoptions
      },    
      {
        id: "use",
        label: "使用",
        accessor: "use",
        width: 5.5,
        dataType: "select",
        options: periodoptions
      },    
      {
        id: "cost",
        label: "重要度",
        accessor: "cost",
        width: 5,
        dataType: "number",
        options: []
      },   
      {
        id: "const",
        label: "条件",
        accessor: "const",
        width: 80,
        dataType: "text",
        options: []
      },    
       
    ]
    typeoptions.forEach((option)=>{
      constdata.forEach((data,i)=>{
        if (option.label==data.type){
          let row = {
            ID: String(shiftdata.length+1),
            type: data.type,
            const: data.const,
            use: data.use,
            color: data.color,
            code: data.code,
            cost: data.cost,
          }
          shiftdata.push(row);
      }})
    })


    let showshiftoptions = []
    
    shift.forEach((data,i) => {
      if (data.type!=="禁止系" && data.type!=="選択系" && data.type!=="連続系" && (data.count=="使用" || data.type.includes("休"))){
        showshiftoptions.push(data);
      }
    })

    let mmcdata = (localStorage.getItem('con1'+byoutou)==1 || localStorage.getItem('Limit-'+Byoutou)==null) ? {columns: scolumns, data: mdata, skipReset: false} : {columns: scolumns, data: mdata, skipReset: false}
    let shiftmcdata = (localStorage.getItem('con1'+byoutou)==1 || localStorage.getItem('Const-'+Byoutou)==null) ? {columns: shiftcolumns, data: shiftdata, skipReset: false} : {columns: shiftcolumns, data: shiftdata, skipReset: false}
    
    function reducer(state, action) {
      switch (action.type) {
        case "add_option_to_column":
          const optionIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, optionIndex),
              {
                ...state.columns[optionIndex],
                options: [
                  ...state.columns[optionIndex].options,
                  { label: action.option, backgroundColor: action.backgroundColor }
                ]
              },
              ...state.columns.slice(optionIndex + 1, state.columns.length)
            ]
          };
        case "add_row":
          return {
            ...state,
            skipReset: true,
            data: [...state.data, {}]
          };
        case "delete_row":
          return {
            ...state,
            skipReset: true,
            data: state.data.filter((_, index) => index !== action.rowIndex)
          };
        case "update_column_type":
          const typeIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          switch (action.dataType) {
            case "number":
              if (state.columns[typeIndex].dataType === "number") {
                return state;
              } else {
                return {
                  ...state,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  data: state.data.map((row) => ({
                    ...row,
                    [action.columnId]: isNaN(row[action.columnId])
                      ? ""
                      : Number.parseInt(row[action.columnId])
                  }))
                };
              }
            case "select":
              if (state.columns[typeIndex].dataType === "select") {
                return {
                  ...state,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  skipReset: true
                };
              } else {
                let options = [];
                state.data.forEach((row) => {
                  if (row[action.columnId]) {
                    options.push({
                      label: row[action.columnId],
                      backgroundColor: randomColor()
                    });
                  }
                });
                return {
                  ...state,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    {
                      ...state.columns[typeIndex],
                      dataType: action.dataType,
                      options: [...state.columns[typeIndex].options, ...options]
                    },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  skipReset: true
                };
              }
            case "text":
              if (state.columns[typeIndex].dataType === "text") {
                return state;
              } else if (state.columns[typeIndex].dataType === "select") {
                return {
                  ...state,
                  skipReset: true,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ]
                };
              } else {
                return {
                  ...state,
                  skipReset: true,
                  columns: [
                    ...state.columns.slice(0, typeIndex),
                    { ...state.columns[typeIndex], dataType: action.dataType },
                    ...state.columns.slice(typeIndex + 1, state.columns.length)
                  ],
                  data: state.data.map((row) => ({
                    ...row,
                    [action.columnId]: row[action.columnId] + ""
                  }))
                };
              }
            default:
              return state;
          }
        case "update_column_header":
          const index = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, index),
              { ...state.columns[index], label: action.label },
              ...state.columns.slice(index + 1, state.columns.length)
            ]
          };
        case "update_cell":
          return {
            ...state,
            skipReset: true,
            data: state.data.map((row, index) => {
              if (index === action.rowIndex) {
                return {
                  ...state.data[action.rowIndex],
                  [action.columnId]: action.value
                };
              }
              return row;
            })
          };
        case "add_column_to_left":
          const leftIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          let leftId = shortId();
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, leftIndex),
              {
                id: leftId,
                label: "Column",
                accessor: leftId,
                dataType: "text",
                created: action.focus && true,
                options: []
              },
              ...state.columns.slice(leftIndex, state.columns.length)
            ]
          };
        case "add_column_to_right":
          const rightIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          const rightId = shortId();
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, rightIndex + 1),
              {
                id: rightId,
                label: "Column",
                accessor: rightId,
                dataType: "text",
                created: action.focus && true,
                options: []
              },
              ...state.columns.slice(rightIndex + 1, state.columns.length)
            ]
          };
        case "delete_column":
          const deleteIndex = state.columns.findIndex(
            (column) => column.id === action.columnId
          );
          return {
            ...state,
            skipReset: true,
            columns: [
              ...state.columns.slice(0, deleteIndex),
              ...state.columns.slice(deleteIndex + 1, state.columns.length)
            ]
          };
        case "enable_reset":
          return {
            ...state,
            skipReset: false
          };
        default:
          return state;
      }
    }

    function TableShow() {
      const [tstate, tdispatch] = useReducer(reducer, mmcdata);
      useEffect(() => {tdispatch({ type: "enable_reset" });
      }, [tstate.data, tstate.columns]);
      const [hstate, hdispatch] = useReducer(reducer, shiftmcdata);
      useEffect(() => {hdispatch({ type: "enable_reset" });
      }, [hstate.data, hstate.columns]);
      
      if (localStorage.getItem('con1'+byoutou)==1){
        localStorage.setItem('Limit-'+Byoutou, JSON.stringify(mdata))
        localStorage.setItem('Const-'+Byoutou, JSON.stringify(shiftdata))
        localStorage.setItem('con1'+byoutou, 2)
      }

      const [open, setOpen] = useState(false)
      const [topen, settOpen] = useState(false)
      const [oopen, setoOpen] = useState(false)
      const [kopen, setkOpen] = useState(false)
      const [sopen, setsOpen] = useState(false)
      const [boopen, setbOpen] = useState(false)
      const [teamopen, setTeamopen] = useState(false)
      
      function handleSave(){
        localStorage.setItem('Limit-'+Byoutou, JSON.stringify(tstate.data))
        localStorage.setItem('Const-'+Byoutou, JSON.stringify(hstate.data))
        alert('勤務条件を保存しました')
      }

      function handleSaveAuto(){
        localStorage.setItem('Limit-'+Byoutou, JSON.stringify(tstate.data))
        localStorage.setItem('Const-'+Byoutou, JSON.stringify(hstate.data))
      }

      const [cost, setCost] = useState(25);
      const handleCostChange = (newValue) => {
        setCost(newValue);
      };


      const handleOpen = () => {
        setOpen(true)
        setSelectedValue1(null)
        setSelectedValue2(null)
        setSelectedValue3(null)
        setSelectedValue4(null)
        setSelectedValue5(null)
        setSelectedValue6(null)
        setPat2(0)
      }


      const handleClose = () => {
        let condata = JSON.parse(localStorage.getItem('Const-'+Byoutou))
        let con = {
          ID: String(condata.length+1),
          type: oopen ? "順序" : boopen ? "禁順序" : topen ? "回数" : kopen ? "スキル" : teamopen ? "チーム" : sopen ? "その他" : "",
          const: 
            (oopen && pat==0) ? selectedValue1 + "→" + selectedValue2 : (oopen && pat==1) ? selectedValue1 + "→" + selectedValue2 + "→" + selectedValue3 : (oopen && pat==2) ? selectedValue1 + "→" + selectedValue2 + "→" + selectedValue3 + "→" + selectedValue4 : (oopen && pat==3) ? selectedValue1 + "→" + selectedValue2 + "→" + selectedValue3 + "→" + selectedValue4 + "→" + selectedValue5
            : (boopen && pat==0) ? "禁" + selectedValue1 + "→" + selectedValue2 : (boopen && pat==1) ? "禁" + selectedValue1 + "→" + selectedValue2 + "→" + selectedValue3 : (boopen && pat==2) ? "禁" + selectedValue1 + "→" + selectedValue2 + "→" + selectedValue3 + "→" + selectedValue4 : (boopen && pat==3) ? "禁" + selectedValue1 + "→" + selectedValue2 + "→" + selectedValue3 + "→" + selectedValue4 + "→" + selectedValue5
            : topen ? (number1.label!=="なし" ? number1.label : "") + (period.label!=="常に" ? period.label + "のうち" : "") + (week.label==="全て" ? "" : week.label+"で") + (repeat.label==="繰り返し連続" || repeat.label==="順序通り連続" ? "連続" : "") + selectedValue1 + (selectedValue2!==null ?  "・" + selectedValue2 : "") + (selectedValue3!==null ? "・" + selectedValue3 : "") + (selectedValue4!==null ? "・" + selectedValue4 : "") + (selectedValue5!==null ? "・" + selectedValue5 : "") + (selectedValue7!==null ? "・" + selectedValue7 : "") + (selectedValue8!==null ? "・" + selectedValue8 : "") + (selectedValue9!==null ? "・" + selectedValue9 : "") + (selectedValue10!==null ? "・" + selectedValue10 : "") + (selectedValue11!==null ? "・" + selectedValue11 : "") + (repeat.label==="繰り返し連続" ? "の繰り返し" : "") + (repeat.label.includes("いずれか") ? "のいずれかが" + (repeat.label!="いずれか連続" ? repeat.label.slice(4,-2) : "") + "連続するの" : "") + "は" + (!numopen ? number2.label : "") + (!numopen ? "回" : "") + (numopen && selectedValue6!==null ? selectedValue6 : "") + ((!numopen && equal.value==="=") ? "" : (numopen && equal.value==="=" && selectedValue6==="均等") ? "" : (numopen && equal.value==="=") ? "と同じ回数" : (numopen && equal.value==="!=") ? "と同じ回数ではない" : equal.value==="<" ? "未満" : equal.value===">" ? "より多い" : equal.value==="<=" ? "以下" : equal.value===">=" ? "以上" : equal.value==="!=" ? "以外" : "")
            : kopen ?  (number1.label!=="なし" ? number1.label : "") + (period.label!=="常に" ? period.label + "のうち" : "") + (week.label==="全て" ? "" : week.label+"で") 
                + (pat==1 ? "もし" : "" )
                + selectedValue1 + (selectedValue2!==null ?  "・" + selectedValue2 : "") + (selectedValue3!==null ? "・" + selectedValue3 : "") + (selectedValue4!==null ? "・" + selectedValue4 : "") + (selectedValue5!==null ? "・" + selectedValue5 : "") + (selectedValue7!==null ? "・" + selectedValue7 : "") + (selectedValue8!==null ? "・" + selectedValue8 : "") + (selectedValue9!==null ? "・" + selectedValue9 : "") + (selectedValue10!==null ? "・" + selectedValue10 : "") + (selectedValue11!==null ? "・" + selectedValue11 : "") 
                + (pat==1 ? "で" : "は")
                + (team1[0]=="全て" ? "" : team1.length>=2 ? team1.map((item,i)=>{if (i==team1.length-1){return item}else {return item + "と"}}) : team1.map((item)=>{return item}))
                + (team1[0]=="各チーム" ? "で" : team1[0]=="全て" ? "" : "のチームで")
                + (skill.length>=2 ? skill.map((item,i)=>{if (i==skill.length-1){return item + "の合計"}else {return item + "と"}}) : skill.map((item)=>{return item}))
                + (skill[0]=="全て" ? "のスタッフ" : "")
                + "が"
                + (((pat==0 && pat4==1) || (pat==0 && pat4==3) || (pat==1 && pat4==1)  || (pat==1 && pat4==3)) ? "枠数" + (arith!==null ? arith.label : "") : "")
                + number22.label
                + (!((pat==0 && pat4==1) || (pat==0 && pat4==3) || (pat==1 && pat4==1)  || (pat==1 && pat4==3)) ? "人" : "")
                + ((equal.value==="==") ? "" : equal.value==="<" ? "未満" : equal.value===">" ? "より多い" : equal.value==="<=" ? "以下" : equal.value===">=" ? "以上" : equal.value==="!=" ? "以外" : "")
                + (pat==1 ? "なら" : "")

                + (selectedValue22!==null ? selectedValue22 : "") + (selectedValue32!==null ? "・" + selectedValue32 : "") + (selectedValue42!==null ? "・" + selectedValue42 : "") + (selectedValue52!==null ? "・" + selectedValue52 : "") + (selectedValue62!==null ? "・" + selectedValue62 : "") + (selectedValue72!==null ? "・" + selectedValue72 : "") + (selectedValue82!==null ? "・" + selectedValue82 : "") + (selectedValue92!==null ? "・" + selectedValue92 : "") + (selectedValue102!==null ? "・" + selectedValue102 : "") + (selectedValue112!==null ? "・" + selectedValue112 : "") 
                + (pat==0 ? "" : "では")
                + (pat==0 ||team2.length==0 ? "" : team2[0]=="全て" ? "" : team2.length>=2 ? team2.map((item,i)=>{if (i==team2.length-1){return item}else {return item + "と"}}) : team2.map((item)=>{return item}))
                + (pat==0 ||team2.length==0 ? "" : team2[0]=="各チーム" ? "で" : team2[0]=="全て" ? "" : "のチームで")
                + (pat==0 ||skill2.length==0 ? "" : skill2.length>=2 ? skill2.map((item,i)=>{if (i==skill2.length-1){return item + "の合計"}else {return item + "と"}}) : skill2.map((item)=>{return item}))
                + (skill2[0]=="全て" ? "のスタッフ" : "")
                + (pat==0 ? "" : "が")
                + (((pat==1 && pat4==3) || (pat==1 && pat4==2)) ? "枠数" + (arith2!==null ? arith2.label : "") : "")
                + (pat==0 || number3===null ? "" : number3.label)
                + ((pat==1 && !((pat==1 && pat4==3) || (pat==1 && pat4==2))) ? "人" : "")
                + (pat==0 || equal2===null ? "" :(equal2.value==="==") ? "" : equal2.value==="<" ? "未満" : equal2.value===">" ? "より多い" : equal2.value==="<=" ? "以下" : equal2.value===">=" ? "以上" : equal2.value==="!=" ? "以外" : "")
            : teamopen ? selectedValue1 + (selectedValue2!==null ?  "・" + selectedValue2 : "") + (selectedValue3!==null ? "・" + selectedValue3 : "") + (selectedValue4!==null ? "・" + selectedValue4 : "") + (selectedValue5!==null ? "・" + selectedValue5 : "") + (selectedValue7!==null ? "・" + selectedValue7 : "") + (selectedValue8!==null ? "・" + selectedValue8 : "") + (selectedValue9!==null ? "・" + selectedValue9 : "") + (selectedValue10!==null ? "・" + selectedValue10 : "") + (selectedValue11!==null ? "・" + selectedValue11 : "")  
                + "は"
                + (team1[0]=="全て" ? "" : team1.length>=2 ? team1.map((item,i)=>{if (i==team1.length-1){return item}else {return item + "と"}}) : team1.map((item)=>{return item}))
                + (team1[0]=="各チーム" ? "、" : team1[0]=="全て" ? "" : "のチーム、")
                + (skill.length>=2 ? skill.map((item,i)=>{if (i==skill.length-1){return item + "の合計"}else {return item + "と"}}) : skill.map((item)=>{return item}))
                + (skill[0]=="全て" ? "のスタッフ" : "")
                + "でチームまたぎは"
                + number22.label
                + "人"
                + ((equal.value==="==") ? "" : equal.value==="<" ? "未満" : equal.value===">" ? "より多い" : equal.value==="<=" ? "以下" : equal.value===">=" ? "以上" : equal.value==="!=" ? "以外" : "")
            : sopen ? other.label
            : "",
          use: "使用",
          color: "",
          code: (oopen && pat==0) ? selectedValue1 + "-" + selectedValue2 : (oopen && pat==1) ? selectedValue1 + "-" + selectedValue2 + "-" + selectedValue3 : (oopen && pat==2) ? selectedValue1 + "-" + selectedValue2 + "-" + selectedValue3 + "-" + selectedValue4 : (oopen && pat==3) ? selectedValue1 + "-" + selectedValue2 + "-" + selectedValue3 + "-" + selectedValue4 + "-" + selectedValue5
          : (boopen && pat==0) ? selectedValue1 + "-" + selectedValue2 : (boopen && pat==1) ? selectedValue1 + "-" + selectedValue2 + "-" + selectedValue3 : (boopen && pat==2) ? selectedValue1 + "-" + selectedValue2 + "-" + selectedValue3 + "-" + selectedValue4 : (boopen && pat==3) ? selectedValue1 + "-" + selectedValue2 + "-" + selectedValue3 + "-" + selectedValue4 + "-" + selectedValue5
          : topen ? number1.value + "-" + period.value + "-" + week.value + "-" + repeat.value + "-" + selectedValue1 + "-" + (selectedValue2!==null ? selectedValue2 : "") + "-" + (selectedValue3!==null ? selectedValue3 : "") + "-" + (selectedValue4!==null ? selectedValue4 : "") + "-" + (selectedValue5!==null ? selectedValue5 : "") + "-" + (selectedValue7!==null ? selectedValue7 : "") + "-" + (selectedValue8!==null ? selectedValue8 : "") + "-" + (selectedValue9!==null ? selectedValue9 : "") + "-" + (selectedValue10!==null ? selectedValue10 : "") + "-" + (selectedValue11!==null ? selectedValue11 : "") + "-" + equal.value + "-" + (!numopen ? number2.label : "") + (numopen && selectedValue6!==null ? selectedValue6 : "")
          : kopen ? number1.value + "-" + period.value + "-" + week.value + "-" 
              + selectedValue1 + "-" + (selectedValue2!==null ? selectedValue2 : "") + "-" + (selectedValue3!==null ? selectedValue3 : "") + "-" + (selectedValue4!==null ? selectedValue4 : "") + "-" + (selectedValue5!==null ? selectedValue5 : "") + "-" + (selectedValue7!==null ? selectedValue7 : "") + "-" + (selectedValue8!==null ? selectedValue8 : "") + "-" + (selectedValue9!==null ? selectedValue9 : "") + "-" + (selectedValue10!==null ? selectedValue10 : "") + "-" + (selectedValue11!==null ? selectedValue11 : "") + "-" 
              + team1 + "-" + skill + "-" + equal.value + "-" + (arith!==null ? arith.value : "") + "-" + number22.label + "-" 
              + (selectedValue22!==null ? selectedValue22 : "") + "-" + (selectedValue32!==null ? selectedValue32 : "") + "-" + (selectedValue42!==null ? selectedValue42 : "") + "-" + (selectedValue52!==null ? selectedValue52 : "") + "-" + (selectedValue62!==null ? selectedValue62 : "") + "-" + (selectedValue72!==null ? selectedValue72 : "") + "-" + (selectedValue82!==null ? selectedValue82 : "") + "-" + (selectedValue92!==null ? selectedValue92 : "") + "-" + (selectedValue102!==null ? selectedValue102 : "") + "-" + (selectedValue112!==null ? selectedValue112 : "") + "-" 
              + team2 + "-" + skill2 + "-" + (equal2===null ? "" : equal2.value) + "-" + (arith2!==null ? arith2.value : "") + "-" + (number3===null ? "" : number3.label)
          : teamopen ? selectedValue1 + "-" + (selectedValue2!==null ? selectedValue2 : "") + "-" + (selectedValue3!==null ? selectedValue3 : "") + "-" + (selectedValue4!==null ? selectedValue4 : "") + "-" + (selectedValue5!==null ? selectedValue5 : "") + "-" + (selectedValue7!==null ? selectedValue7 : "") + "-" + (selectedValue8!==null ? selectedValue8 : "") + "-" + (selectedValue9!==null ? selectedValue9 : "") + "-" + (selectedValue10!==null ? selectedValue10 : "") + "-" + (selectedValue11!==null ? selectedValue11 : "") + "-" 
              + team1 + "-" + skill + "-" + equal.value + "-" + number22.label
          : sopen ? other.value
          : "",
          cost: cost,
        }
        condata.push(con)
        const confirm = window.confirm("勤務条件を追加しますか？")
        if (confirm){
          localStorage.setItem('Const-'+Byoutou, JSON.stringify(condata))
          setOpen(false)
          setbOpen(false)
          settOpen(false)
          setoOpen(false)
          setkOpen(false)
          setsOpen(false)   
          window.location.reload()   
        }   
      }

      const [pat, setPat] = useState(0)

      const togglePat = () => {
        setPat((prevState) => (prevState === 3 ? 0 : prevState + 1));
        if (pat<=2){
          setSelectedValue5(null)
        } 
        if (pat<=1){
          setSelectedValue4(null)
        } 
        if (pat<=0){
          setSelectedValue3(null)
        }
      };

      const togglePat3 = () => {
        setTeam2([])
        setSkill2([])
        setEqual2(null)
        setNumber3(null)
        setSelectedValue22(null)
        setSelectedValue32(null)
        setSelectedValue42(null)
        setSelectedValue52(null)
        setSelectedValue62(null)
        setSelectedValue72(null)
        setSelectedValue82(null)
        setSelectedValue92(null)
        setSelectedValue102(null)
        setSelectedValue112(null)
        setPat((prevState) => (prevState === 1 ? 0 : prevState + 1));
      };

      function ShowOptions({name, onOptionSelected, selectedValue, color, setColor}){
        const [showSelect, setShowSelect] = useState(false);
        const [selectRef, setSelectRef] = useState(null);
        const [selectPop, setSelectPop] = useState(null);

        const {styles, attributes} = usePopper(selectRef, selectPop, {
          placement: "bottom-start",
          strategy: "fixed"
        });
      
        const handleOptionClick = (option) => {
          setColor(option.color)
          onOptionSelected(option.shift); 
          setShowSelect(false); 
        };
        
          return (
            <div>
                <div style={{minHeight: '30px' ,border: '1px solid gray', width: '80px', cursor: 'pointer', borderRadius: 5, backgroundColor: selectedValue===null ? "white" : color}} onClick={() => setShowSelect(true)}>
                  <div style={{color: 'black', fontSize: '18px', display: 'flex', justifyContent: 'center'}}>
                    {selectedValue==null ? name : selectedValue}
                  </div>
                </div>

                {showSelect && <div className='overlay' onClick={() => setShowSelect(false)} />}
                {showSelect && (
                  <div
                    className='shadow-5 bg-white border-radius-md'
                    ref={setSelectPop}
                    {...attributes.popper}
                    style={{
                      ...styles.popper,
                      zIndex: 10,
                      minWidth: 350,
                      maxWidth: 450,
                      padding: "0.75rem",
                      position: "absolute",
                      top: "45%",
                      left: "50%",
                      transform: "translate(-50%, -50%)"
                    }}>
                    <div className='d-flex flex-wrap-wrap' style={{marginTop: "-0.5rem"}}>
                      {showshiftoptions.map((option) => (
                        <div
                          className='cursor-pointer'
                          style={{marginRight: "0.5rem", marginTop: "0.5rem"}}
                          onClick={() => {
                            setShowSelect(false);
                            handleOptionClick(option)
                          }}>
                          <Relationship value={option.shift} backgroundColor={option.color} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
            </div>
          )
      }

      function ShowOptionsAll({name, onOptionSelected, selectedValue, color, setColor, all}){
        const [showSelect, setShowSelect] = useState(false);
        const [selectRef, setSelectRef] = useState(null);
        const [selectPop, setSelectPop] = useState(null);

        const {styles, attributes} = usePopper(selectRef, selectPop, {
          placement: "bottom-start",
          strategy: "fixed"
        });
      
        const handleOptionClick = (option) => {
          setColor(option.color)
          onOptionSelected(option.shift); 
          setShowSelect(false); 
        };
        
          return (
            <div>
                <div style={{ border: '1px solid gray', width: '100px', cursor: 'pointer', borderRadius: 5, backgroundColor: selectedValue===null ? "white" : color}} onClick={() => setShowSelect(true)}>
                  <div style={{color: 'black', fontSize: '20px', display: 'flex', justifyContent: 'center'}}>
                    {selectedValue==null ? name : selectedValue}
                  </div>
                </div>

                {showSelect && <div className='overlay' onClick={() => setShowSelect(false)} />}
                {showSelect && (
                  <div
                    className='shadow-5 bg-white border-radius-md'
                    ref={setSelectPop}
                    {...attributes.popper}
                    style={{
                      ...styles.popper,
                      zIndex: 10,
                      minWidth: 350,
                      maxWidth: 450,
                      padding: "0.75rem",
                      position: "absolute",
                      top: "45%",
                      left: "50%",
                      transform: "translate(-50%, -50%)"
                    }}>
                    <div className='d-flex flex-wrap-wrap' style={{marginTop: "-0.5rem"}}>
                      {all!=="n" &&
                      <div
                        className='cursor-pointer'
                        style={{marginRight: "0.5rem", marginTop: "0.5rem"}}
                        onClick={() => {
                          setShowSelect(false);
                          handleOptionClick({shift : "全て", color: "#D3D3D3"})
                        }}>
                        <Relationship value={"全て"} backgroundColor={"#D3D3D3"} />
                      </div>}
                      {all==="n" &&
                      <div
                        className='cursor-pointer'
                        style={{marginRight: "0.5rem", marginTop: "0.5rem"}}
                        onClick={() => {
                          setShowSelect(false);
                          handleOptionClick({shift : "均等", color: "#D3D3D3"})
                        }}>
                        <Relationship value={"均等"} backgroundColor={"#D3D3D3"} />
                      </div>}
                      {showshiftoptions.map((option) => (
                        <div
                          className='cursor-pointer'
                          style={{marginRight: "0.5rem", marginTop: "0.5rem"}}
                          onClick={() => {
                            setShowSelect(false);
                            handleOptionClick(option)
                          }}>
                          <Relationship value={option.shift} backgroundColor={option.color} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
            </div>
          )
      }
      
      const [selectedValue1, setSelectedValue1] = useState(null);
      const [selectedValue2, setSelectedValue2] = useState(null);
      const [selectedValue3, setSelectedValue3] = useState(null);
      const [selectedValue4, setSelectedValue4] = useState(null);
      const [selectedValue5, setSelectedValue5] = useState(null);
      const [selectedValue6, setSelectedValue6] = useState(null);
      const [selectedValue7, setSelectedValue7] = useState(null);
      const [selectedValue8, setSelectedValue8] = useState(null);
      const [selectedValue9, setSelectedValue9] = useState(null);
      const [selectedValue10, setSelectedValue10] = useState(null);
      const [selectedValue11, setSelectedValue11] = useState(null);
      const [selectedValue22, setSelectedValue22] = useState(null);
      const [selectedValue32, setSelectedValue32] = useState(null);
      const [selectedValue42, setSelectedValue42] = useState(null);
      const [selectedValue52, setSelectedValue52] = useState(null);
      const [selectedValue62, setSelectedValue62] = useState(null);
      const [selectedValue72, setSelectedValue72] = useState(null);
      const [selectedValue82, setSelectedValue82] = useState(null);
      const [selectedValue92, setSelectedValue92] = useState(null);
      const [selectedValue102, setSelectedValue102] = useState(null);
      const [selectedValue112, setSelectedValue112] = useState(null);
      const [color1, setColor1] = useState(null)
      const [color2, setColor2] = useState(null)
      const [color3, setColor3] = useState(null)
      const [color4, setColor4] = useState(null)
      const [color5, setColor5] = useState(null)
      const [color6, setColor6] = useState(null)
      const [color7, setColor7] = useState(null)
      const [color8, setColor8] = useState(null)
      const [color9, setColor9] = useState(null)
      const [color10, setColor10] = useState(null)
      const [color11, setColor11] = useState(null)      
      const [color22, setColor22] = useState(null)
      const [color32, setColor32] = useState(null)
      const [color42, setColor42] = useState(null)
      const [color52, setColor52] = useState(null)
      const [color62, setColor62] = useState(null)
      const [color72, setColor72] = useState(null)
      const [color82, setColor82] = useState(null)
      const [color92, setColor92] = useState(null)
      const [color102, setColor102] = useState(null)
      const [color112, setColor112] = useState(null)

      const handleOptionSelected1 = (selectedOption) => {
        setSelectedValue1(selectedOption); // selectedValue1を更新
      }
      const handleOptionSelected2 = (selectedOption) => {
        setSelectedValue2(selectedOption); // selectedValue2を更新
      }
      const handleOptionSelected3 = (selectedOption) => {
        setSelectedValue3(selectedOption); // selectedValue2を更新
      }
      const handleOptionSelected4 = (selectedOption) => {
        setSelectedValue4(selectedOption); // selectedValue2を更新
      }
      const handleOptionSelected5 = (selectedOption) => {
        setSelectedValue5(selectedOption); // selectedValue2を更新
      }
      const handleOptionSelected6 = (selectedOption) => {
        setSelectedValue6(selectedOption); 
      }
      const handleOptionSelected7 = (selectedOption) => {
        setSelectedValue7(selectedOption); 
      }
      const handleOptionSelected8 = (selectedOption) => {
        setSelectedValue8(selectedOption); 
      }
      const handleOptionSelected9 = (selectedOption) => {
        setSelectedValue9(selectedOption); 
      }
      const handleOptionSelected10 = (selectedOption) => {
        setSelectedValue10(selectedOption); 
      }
      const handleOptionSelected11 = (selectedOption) => {
        setSelectedValue11(selectedOption); 
      }
      const handleOptionSelected22 = (selectedOption) => {
        setSelectedValue22(selectedOption); 
      }
      const handleOptionSelected32 = (selectedOption) => {
        setSelectedValue32(selectedOption); 
      }
      const handleOptionSelected42 = (selectedOption) => {
        setSelectedValue42(selectedOption); 
      }
      const handleOptionSelected52 = (selectedOption) => {
        setSelectedValue52(selectedOption); 
      }
      const handleOptionSelected62 = (selectedOption) => {
        setSelectedValue62(selectedOption); 
      }
      const handleOptionSelected72 = (selectedOption) => {
        setSelectedValue72(selectedOption); 
      }
      const handleOptionSelected82 = (selectedOption) => {
        setSelectedValue82(selectedOption); 
      }
      const handleOptionSelected92 = (selectedOption) => {
        setSelectedValue92(selectedOption); 
      }
      const handleOptionSelected102 = (selectedOption) => {
        setSelectedValue102(selectedOption); 
      }
      const handleOptionSelected112 = (selectedOption) => {
        setSelectedValue112(selectedOption); 
      }

      let numberoptions = [
        {value: "none", label: "なし"},
      ]
      let numberoptions2 = []
      let numberoptions3 = []
      for (let i=0; i<=days; i++) {
        if (i>0){
          numberoptions.push(
            {label: String(i), value: String(i)}
          )        
        }
        numberoptions2.push(
          {label: String(i), value: String(i)}
        )
      }
      for (let i=0; i<=100; i++) {
        numberoptions3.push({label: String(i), value: String(i)})
      }

      const [number1, setNumber1] = useState(null)
      function DropdownNumber1() {
        const handleClick = (event) => {
          setNumber1(event);
        };
        return (
          <Select
            placeholder="期間数"
            value={number1}
            onChange={handleClick} 
            options={numberoptions}
            isSearchable={true}
          />
        );
      }
      const [number2, setNumber2] = useState(null)
      function DropdownNumber2() {
        const handleClick = (event) => {
          setNumber2(event);
        };
        return (
          <Select
            placeholder="数字"
            value={number2}
            onChange={handleClick} 
            options={numberoptions2}
            isSearchable={true}
          />
        );
      }
      const [number22, setNumber22] = useState(null)
      function DropdownNumber22() {
        const handleClick = (event) => {
          setNumber22(event);
        };
        return (
          <Select
            placeholder="数字"
            value={number22}
            onChange={handleClick} 
            options={numberoptions3}
            isSearchable={true}
          />
        );
      }
      const [number3, setNumber3] = useState(null)
      function DropdownNumber3() {
        const handleClick = (event) => {
          setNumber3(event);
        };
        return (
          <Select
            placeholder="数字"
            value={number3}
            onChange={handleClick} 
            options={numberoptions3}
            isSearchable={true}
          />
        );
      }

      let periodoptions = [
        {value: "always", label: "常に"},
        {value: "month", label: "ヶ月間"},
        {value: "week", label: "週間"},
        {value: "day", label: "日間"},
      ]
      const [period, setPeriod] = useState(null)
      function DropdownPeriod() {
        const handleClick = (event) => {
          setPeriod(event);
        };
        return (
          <Select
            placeholder="期間"
            value={period}
            onChange={handleClick} 
            options={periodoptions}
          />
        );
      }

      let daysoptions = [
        {label: "全て", value: "all"},
        {label: "平日", value: "weekday"},
        {label: "休日", value: "holiday"},
        {label: "月曜日", value: "0"},
        {label: "火曜日", value: "1"},
        {label: "水曜日", value: "2"},
        {label: "木曜日", value: "3"},
        {label: "金曜日", value: "4"},
        {label: "土曜日", value: "5"},
        {label: "日曜日", value: "6"},
      ]
      const [week, setWeek] = useState(null)
      function DropdownWeek() {
        const handleClick = (event) => {
          setWeek(event);
        };
        return (
          <Select
            placeholder="曜日"
            value={week}
            onChange={handleClick} 
            options={daysoptions}
          />
        );
      }

      let repoptions = [
        {label: "繰り返し連続", value: "repeat"},
        {label: "いずれか連続", value: "which"},
        {label: "なし", value: "none"},
      ]
      const [repeat, setRepeat] = useState(null)
      function DropdownRepeat() {
        const handleClick = (event) => {
          setRepeat(event);
        };
        return (
          <Select
            placeholder="連続タイプ"
            value={repeat}
            onChange={handleClick} 
            options={repoptions}
          />
        );
      }

      let repoptions2 = [
        {label: "なし", value: "none"},
        {label: "順序通り連続", value: "repeat"},
        {label: "いずれか2連続", value: "which"},
        {label: "いずれか3連続", value: "which3"},
        {label: "いずれか4連続", value: "which4"},
        {label: "いずれか5連続", value: "which5"},
        {label: "いずれか6連続", value: "which6"},
        {label: "いずれか7連続", value: "which7"},
        {label: "いずれか8連続", value: "which8"},
        {label: "いずれか9連続", value: "which9"},
        {label: "いずれか10連続", value: "which10"},
      ]
      function DropdownRepeat2() {
        const handleClick = (event) => {
          setRepeat(event);
        };
        return (
          <Select
            placeholder="連続タイプ"
            value={repeat}
            onChange={handleClick} 
            options={repoptions2}
          />
        );
      }

      const [team1, setTeam1] = useState([])
      const handleTeamChange = (selectedOptions) => {
        setTeam1(selectedOptions.map(option => option.label));
      };
      function DropdownTeam() {
        return (
          <Select
          isMulti 
          placeholder="チーム" 
          value={teamoptions.filter(option => team1.includes(option.label))} 
          onChange={handleTeamChange} 
          options={teamoptions} 
          isClearable={true} 
          isSearchable={true}/>
        )
      }
      const [team2, setTeam2] = useState([])
      const handleTeamChange2 = (selectedOptions) => {
        setTeam2(selectedOptions.map(option => option.label));
      };
      function DropdownTeam2() {
        return (
          <Select
          isMulti 
          placeholder="チーム" 
          value={teamoptions.filter(option => team2.includes(option.label))} 
          onChange={handleTeamChange2} 
          options={teamoptions} 
          isClearable={true} 
          isSearchable={true}/>
        )
      }

      const [skill, setSkill] = useState([])
      const handleSkillChange = (selectedOptions) => {
        setSkill(selectedOptions.map(option => option.label));
      };
      function DropdownSkill() {
        return (
          <Select
          isMulti 
          placeholder="スキル" 
          value={skilloptions.filter(option => skill.includes(option.label))} 
          onChange={handleSkillChange} 
          options={skilloptions} 
          isClearable={true} 
          isSearchable={true}/>
        )
      }
      const handleSkillChangea = (selectedOptions) => {
        setSkill(selectedOptions.map(option => option.label));
      };
      function DropdownSkilla() {
        return (
          <Select
          isMulti 
          placeholder="スキル" 
          value={skilloptions2.filter(option => skill.includes(option.label))} 
          onChange={handleSkillChangea} 
          options={skilloptions2} 
          isClearable={true} 
          isSearchable={true}/>
        )
      }
      const [skill2, setSkill2] = useState([])
      const handleSkillChange2 = (selectedOptions) => {
        setSkill2(selectedOptions.map(option => option.label));
      };
      function DropdownSkill2() {
        return (
          <Select
          isMulti 
          placeholder="スキル" 
          value={skilloptions.filter(option => skill2.includes(option.label))} 
          onChange={handleSkillChange2} 
          options={skilloptions} 
          isClearable={true} 
          isSearchable={true}/>
        )
      }

      let eqoptions = [
        {label: "=", value: "=="},
        {label: ">", value: ">"},
        {label: "<", value: "<"},
        {label: "≧", value: ">="},
        {label: "≦", value: "<="},
        {label: "≠", value: "!="},
      ]
      let eqoptions2 = [
        {label: "=", value: "=="},
        {label: "≦", value: "<="},
      ]
      const [equal, setEqual] = useState(null)
      function DropdownEqual() {
        const handleClick = (event) => {
          setEqual(event);
        };
        return (
          <Select
            placeholder="等号不等号"
            value={equal}
            onChange={handleClick} 
            options={eqoptions}
          />
        );
      }
      function DropdownEquala() {
        const handleClick = (event) => {
          setEqual(event);
        };
        return (
          <Select
            placeholder="等号不等号"
            value={equal}
            onChange={handleClick} 
            options={eqoptions2}
          />
        );
      }
      const [equal2, setEqual2] = useState(null)
      function DropdownEqual2() {
        const handleClick = (event) => {
          setEqual2(event);
        };
        return (
          <Select
            placeholder="等号不等号"
            value={equal2}
            onChange={handleClick} 
            options={eqoptions}
          />
        );
      }

      let arithoptions = [
        {label: "+", value: "+"},
        {label: "－", value: ""},
        {label: "×", value: "*"},
        {label: "÷", value: "//"},
      ]
      const [arith, setArith] = useState(null)
      function DropdownArith() {
        const handleClick = (event) => {
          setArith(event);
        };
        return (
          <Select
            placeholder="演算子"
            value={arith}
            onChange={handleClick} 
            options={arithoptions}
          />
        );
      }
      const [arith2, setArith2] = useState(null)
      function DropdownArith2() {
        const handleClick = (event) => {
          setArith2(event);
        };
        return (
          <Select
            placeholder="演算子"
            value={arith2}
            onChange={handleClick} 
            options={arithoptions}
          />
        );
      }

      const [pat2, setPat2] = useState(0)
      const togglePat2 = () => {
        setPat2((prevState) => (prevState===9 ? prevState : prevState + 1));
      };

      const [pat22, setPat22] = useState(0)
      const togglePat22 = () => {
        setPat22((prevState) => (prevState===9 ? prevState : prevState + 1));
      };

      const [pat4, setPat4] = useState(0)
      const togglePat4 = () => {
        setArith(null)
        setArith2(null)
        setPat4((prevState) => (prevState===3 ? 0 : prevState + 1));
      };

      const [numopen, setNumopen] = useState(false)

      if (open){
        if (number1!==null && period!==null && (number1.value!=="1" && number1.label!=="なし") && period.value=="month"){
          alert("最大期間は1ヶ月です")
          setNumber1(null)
        }
        if (number1!==null && period!==null && (number1.value>"4" && number1.label!=="なし") && period.value=="week"){
          alert("最大期間は4週間です")
          setNumber1(null)
        }
        if (repeat!==null && (repeat.value=="repeat" || repeat.value=="which") && numopen){
          alert("連続条件は数値のみ選択可能です")
          setNumopen(false)
        }
      }

      const [sample, setSample] = useState(false)

      let constoptions = [
        {value: "limit", label: "枠数を満たす"},
        {value: "request", label: "勤務希望を満たす"},
        {value: "off", label: "公休数の設定"},
        {value: "uplow", label: "スタッフページの上下限を満たす"},
        {value: "diff", label: "スタッフページの勤務差を満たす"},
        {value: "same", label: "スタッフページの同時勤務条件を満たす"},
        {value: "ok", label: "スタッフページのシフト可否を満たす"},
      ]
      const [other, setOther] = useState(null)
      function DropdownConst() {
        const handleClick = (event) => {
          setOther(event);
        };
        return (
          <Select
            placeholder="　　　　　　　条件　　　　　　　"
            value={other}
            onChange={handleClick} 
            options={constoptions}
          />
        );
      }

      const removeelements = () => {
        setSelectedValue6(null)
        setSelectedValue7(null)
        setSelectedValue8(null)
        setSelectedValue9(null)
        setSelectedValue10(null)
        setSelectedValue11(null)
        setSelectedValue22(null)
        setSelectedValue32(null)
        setSelectedValue42(null)
        setSelectedValue52(null)
        setSelectedValue62(null)
        setSelectedValue72(null)
        setSelectedValue82(null)
        setSelectedValue92(null)
        setSelectedValue102(null)
        setSelectedValue112(null)
        setPat22(0)
      }
      

      useKeybind({
        key: "s",
        ctrlKey: true,
        onKeyDown: () => handleSave(),
      });

      return (
        <div>
          <div>
            <Button size="small" variant="contained" color="success" onClick={handleSave}>
              保存
            </Button>
          </div>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} style={{paddingBottom: "1rem"}}>
          <Grid container spacing={3}>
            
              <Grid item xs={12} md={12} lg={12}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <strong>条件</strong>
              {<div style={{paddingBottom: '0.2rem'}}>
              <Button size="small" variant="contained" color="info" onClick={handleOpen}>
                条件を追加
              </Button>
              </div>}
              </div>
              <div style={{fontSize: '13px', paddingBottom: '0.5rem'}}>重要度を変更する場合は1～50の整数値で入力してください。</div>
              <div onClick={handleSaveAuto} style={{maxHeight: "450px", overflowY: "scroll", width: "100%",zIndex: 5, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}>
                <div  style={{maxWidth: '100%', minWidth: '1050px'}}>
                    <Table
                      columns={hstate.columns}
                      data={hstate.data}
                      skipReset={hstate.skipReset}
                      dispatch={hdispatch}
                      noadd={"nd"}
                      />
                  </div>
              </div>
              </Grid>

              <Grid item xs={12} md={12} lg={5} style={{paddingTop: '3rem'}}>
            <strong>枠数</strong><br/>
            <div style={{fontSize: '13px', paddingBottom: '0.5rem'}}>勤務情報画面のシフト記号の表で枠数使用にチェックを入れた記号が表示されます</div>
              <div onClick={handleSaveAuto} style={{maxHeight: "450px", overflowY: "scroll", width: "450px",zIndex: 5, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}>
                    <Table
                      columns={tstate.columns}
                      data={tstate.data}
                      skipReset={tstate.skipReset}
                      dispatch={tdispatch}
                      noadd={"n"}
                      />
              </div>
              </Grid>
          </Grid>
          </Container>

          {open && <div className='overlay' onClick={() => setOpen(false)} />}
          {open && (
            <div
              className='shadow-5 bg-white border-radius-md'
              style={{
                zIndex: 10,
                minWidth: (oopen || boopen) ? 410 : topen ? 500 : kopen ? 600 : sopen ? 410 : teamopen ? 550 : 410,
                maxWidth: 900,
                padding: "0.75rem",
                position: "absolute",
                top: "55%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                paddingBottom: '2rem',
              }}>
                <div style={{paddingBottom: '0.5rem'}}>
                条件を作成
                </div>
                {!oopen && !topen && !kopen && !sopen && !boopen && !teamopen &&(
                  <div style={{paddingBottom: '0.5rem'}}>
                    種類を選択してください
                  </div>)}
                <div>
                  <Button size="small" variant="contained" style={{backgroundColor: oopen ? "#A9A9A9" : "#DCDCDC", color: 'black'}} onClick={()=>{setoOpen(prevState=>!prevState); setbOpen(false); setkOpen(false); settOpen(false); setsOpen(false); setTeamopen(false); setPat(0);removeelements(); setPat2(0)}}>
                    順序
                  </Button>
                  <Button size="small" variant="contained" style={{backgroundColor: boopen ? "#A9A9A9" : "#DCDCDC", color: 'black'}} onClick={()=>{setbOpen(prevState=>!prevState); setoOpen(false); setkOpen(false); settOpen(false); setsOpen(false); setTeamopen(false); setPat(0);removeelements(); setPat2(0)}}>
                    禁順序
                  </Button>
                  <Button size="small" variant="contained" style={{backgroundColor: topen ? "#A9A9A9" : "#DCDCDC", color: 'black'}} onClick={()=>{settOpen(prevState=>!prevState); setbOpen(false); setoOpen(false); setkOpen(false); setsOpen(false); setTeamopen(false); setPat(0);removeelements(); setPat2(0)}}>
                    回数
                  </Button>
                  <Button size="small" variant="contained" style={{backgroundColor: kopen ? "#A9A9A9" : "#DCDCDC", color: 'black'}} onClick={()=>{setkOpen(prevState=>!prevState); setbOpen(false); setoOpen(false); settOpen(false); setsOpen(false); setTeamopen(false); setPat(0);removeelements(); setPat2(0)}}>
                    スキル
                  </Button>
                  <Button size="small" variant="contained" style={{backgroundColor: teamopen ? "#A9A9A9" : "#DCDCDC", color: 'black'}} onClick={()=>{setTeamopen(prevState=>!prevState); setbOpen(false); setoOpen(false); settOpen(false); setsOpen(false); setkOpen(false); setPat(0);removeelements(); setPat2(0)}}>
                    チーム
                  </Button>
                  <Button size="small" variant="contained" style={{backgroundColor: sopen ? "#A9A9A9" : "#DCDCDC", color: 'black'}} onClick={()=>{setsOpen(prevState=>!prevState); setbOpen(false); setoOpen(false); settOpen(false); setkOpen(false); setTeamopen(false); setPat(0);removeelements(); setPat2(0)}}>
                    その他
                  </Button>
                </div>
                {oopen && (
                  <div style={{minHeight: '130px' , border: '1px solid gray', marginTop: '0.5rem'}}>
                      <Button size="small" variant="contained" color="warning" onClick={()=>{setSample(prevState=>!prevState); setSelectedValue6(null)}}>
                        入力例・注意事項
                      </Button>
                      <Button size="small" variant="contained" color="inherit" onClick={togglePat}>
                        パターン変更
                      </Button>

                      {sample && <div className='overlay' onClick={() => setSample(false)} />}
                      {sample && (
                        <div
                          className='shadow-5 bg-white border-radius-md'
                          style={{
                            zIndex: 10,
                            minWidth: 460,
                            maxWidth: 650,
                            padding: "0.75rem",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            paddingBottom: '0.5rem'
                          }}>
                          <div style={{fontSize: '13px', display: 'flex', paddingTop: '0.25rem'}}>
                          （例1）準夜勤-深夜勤-公休 → 5/,*,公
                          <br/>
                          （例2）遅番-公休 → 遅,公
                          <br/>
                          <br/>
                          ※1　順序条件は入力されたシフトの順番を許可するという意味です
                          <br/>
                          ※2　重要度の大きい順序条件ほど優先されます
                          <br/>
                          ※3　パターン変更により最大5連続の順序まで設定できます
                          </div>
                        </div>
                      )}

                    {pat==0 &&
                    <div> 
                    <div style={{display: 'flex', justifyContent: 'center'}}> 
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', paddingTop: '2rem'}}>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトA"} 
                          onOptionSelected={handleOptionSelected1} 
                          selectedValue={selectedValue1}
                          color={color1}
                          setColor={setColor1}/>
                      </div>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトB"} 
                          onOptionSelected={handleOptionSelected2} 
                          selectedValue={selectedValue2}
                          color={color2}
                          setColor={setColor2}/>
                      </div>                     
                    </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', paddingTop: '2rem', paddingBottom: '1rem'}}>
                      {selectedValue1!==null && <div>{selectedValue1==null ? "シフトA" : selectedValue1}</div>}{selectedValue1==null && "シフトA"}
                      の次は
                      {selectedValue2!==null && <div>{selectedValue2==null ? "シフトB" : selectedValue2}</div>}{selectedValue2==null && "シフトB"}
                    </div>
                    </div>}

                    {pat==1 &&
                    <div> 
                    <div style={{display: 'flex', justifyContent: 'center'}}> 
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', paddingTop: '2rem'}}>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトA"} 
                          onOptionSelected={handleOptionSelected1} 
                          selectedValue={selectedValue1}
                          color={color1}
                          setColor={setColor1}/>
                      </div>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトB"} 
                          onOptionSelected={handleOptionSelected2} 
                          selectedValue={selectedValue2}
                          color={color2}
                          setColor={setColor2}/>
                      </div>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトC"} 
                          onOptionSelected={handleOptionSelected3} 
                          selectedValue={selectedValue3}
                          color={color3}
                          setColor={setColor3}/>
                      </div>                     
                    </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', paddingTop: '2rem', paddingBottom: '1rem'}}>
                      {selectedValue1!==null && <div>{selectedValue1==null ? "シフトA" : selectedValue1}</div>}{selectedValue1==null && "シフトA"}
                      の次は
                      {selectedValue2!==null && <div>{selectedValue2==null ? "シフトB" : selectedValue2}</div>}{selectedValue2==null && "シフトB"}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', paddingBottom: '1rem'}}>
                      {selectedValue2!==null && <div>{selectedValue2==null ? "シフトB" : selectedValue2}</div>}{selectedValue2==null && "シフトB"}
                      の次は
                      {selectedValue3!==null && <div>{selectedValue3==null ? "シフトC" : selectedValue3}</div>}{selectedValue3==null && "シフトC"}
                    </div>
                    </div>}

                    {pat==2 &&
                    <div> 
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', paddingTop: '2rem'}}>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトA"} 
                          onOptionSelected={handleOptionSelected1} 
                          selectedValue={selectedValue1}
                          color={color1}
                          setColor={setColor1}/>
                      </div>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトB"} 
                          onOptionSelected={handleOptionSelected2} 
                          selectedValue={selectedValue2}
                          color={color2}
                          setColor={setColor2}/>
                      </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', paddingTop: '0.25rem'}}>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトC"} 
                          onOptionSelected={handleOptionSelected3} 
                          selectedValue={selectedValue3}
                          color={color3}
                          setColor={setColor3}/>
                      </div>  
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトD"} 
                          onOptionSelected={handleOptionSelected4} 
                          selectedValue={selectedValue4}
                          color={color4}
                          setColor={setColor4}/>
                      </div>                     
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', paddingTop: '2rem', paddingBottom: '0.25rem'}}>
                      {selectedValue1!==null && <div>{selectedValue1==null ? "シフトA" : selectedValue1}</div>}{selectedValue1==null && "シフトA"}
                      の次は
                      {selectedValue2!==null && <div>{selectedValue2==null ? "シフトB" : selectedValue2}</div>}{selectedValue2==null && "シフトB"}
                      、
                      {selectedValue2!==null && <div>{selectedValue2==null ? "シフトB" : selectedValue2}</div>}{selectedValue2==null && "シフトB"}
                      の次は
                      {selectedValue3!==null && <div>{selectedValue3==null ? "シフトC" : selectedValue3}</div>}{selectedValue3==null && "シフトC"}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', paddingBottom: '1rem'}}>
                      {selectedValue3!==null && <div>{selectedValue3==null ? "シフトC" : selectedValue3}</div>}{selectedValue3==null && "シフトC"}
                      の次は
                      {selectedValue4!==null && <div>{selectedValue4==null ? "シフトD" : selectedValue4}</div>}{selectedValue4==null && "シフトD"}
                    </div>
                    </div>}

                    {pat==3 &&
                    <div> 
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', paddingTop: '2rem'}}>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトA"} 
                          onOptionSelected={handleOptionSelected1} 
                          selectedValue={selectedValue1}
                          color={color1}
                          setColor={setColor1}/>
                      </div>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトB"} 
                          onOptionSelected={handleOptionSelected2} 
                          selectedValue={selectedValue2}
                          color={color2}
                          setColor={setColor2}/>
                      </div>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトC"} 
                          onOptionSelected={handleOptionSelected3} 
                          selectedValue={selectedValue3}
                          color={color3}
                          setColor={setColor3}/>
                      </div>                    
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', paddingTop: '0.25rem'}}>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトD"} 
                          onOptionSelected={handleOptionSelected4} 
                          selectedValue={selectedValue4}
                          color={color4}
                          setColor={setColor4}/>
                      </div>  
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトE"} 
                          onOptionSelected={handleOptionSelected5} 
                          selectedValue={selectedValue5}
                          color={color5}
                          setColor={setColor5}/>
                      </div>                     
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', paddingTop: '2rem', paddingBottom: '0.25rem'}}>
                      {selectedValue1!==null && <div>{selectedValue1==null ? "シフトA" : selectedValue1}</div>}{selectedValue1==null && "シフトA"}
                      の次は
                      {selectedValue2!==null && <div>{selectedValue2==null ? "シフトB" : selectedValue2}</div>}{selectedValue2==null && "シフトB"}
                      、
                      {selectedValue2!==null && <div>{selectedValue2==null ? "シフトB" : selectedValue2}</div>}{selectedValue2==null && "シフトB"}
                      の次は
                      {selectedValue3!==null && <div>{selectedValue3==null ? "シフトC" : selectedValue3}</div>}{selectedValue3==null && "シフトC"}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', paddingBottom: '1rem'}}>
                      {selectedValue3!==null && <div>{selectedValue3==null ? "シフトC" : selectedValue3}</div>}{selectedValue3==null && "シフトC"}
                      の次は
                      {selectedValue4!==null && <div>{selectedValue4==null ? "シフトD" : selectedValue4}</div>}{selectedValue4==null && "シフトD"}
                      、
                      {selectedValue4!==null && <div>{selectedValue4==null ? "シフトD" : selectedValue4}</div>}{selectedValue4==null && "シフトD"}
                      の次は
                      {selectedValue5!==null && <div>{selectedValue5==null ? "シフトE" : selectedValue5}</div>}{selectedValue5==null && "シフトE"}
                    </div>
                    </div>}

                  </div>)}

                {boopen && (
                  <div style={{minHeight: '130px' , border: '1px solid gray', marginTop: '0.5rem'}}>
                      <Button size="small" variant="contained" color="warning" onClick={()=>{setSample(prevState=>!prevState); setSelectedValue6(null)}}>
                        入力例・注意事項
                      </Button>
                      <Button size="small" variant="contained" color="inherit" onClick={togglePat}>
                        パターン変更
                      </Button>

                      {sample && <div className='overlay' onClick={() => setSample(false)} />}
                      {sample && (
                        <div
                          className='shadow-5 bg-white border-radius-md'
                          style={{
                            zIndex: 10,
                            minWidth: 460,
                            maxWidth: 650,
                            padding: "0.75rem",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            paddingBottom: '0.5rem'
                          }}>
                          <div style={{fontSize: '13px', display: 'flex', paddingTop: '0.25rem'}}>
                          （例）公休-深夜勤は禁止 → 公,*
                          <br/>
                          <br/>
                          ※1　禁順序条件は入力されたシフトの順番を許可しないという意味です
                          <br/>
                          ※2　重要度の大きい禁順序条件ほど優先されます
                          <br/>
                          ※3　パターン変更により最大5連続の禁順序まで設定できます
                          </div>
                        </div>
                      )}

                    <div style={{display: 'flex', alignItems: 'center', fontSize: '23px', justifyContent: 'center', paddingTop: '2rem'}}>禁</div>
                    {pat==0 &&
                    <div> 
                    <div style={{display: 'flex', justifyContent: 'center'}}> 
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトA"} 
                          onOptionSelected={handleOptionSelected1} 
                          selectedValue={selectedValue1}
                          color={color1}
                          setColor={setColor1}/>
                      </div>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトB"} 
                          onOptionSelected={handleOptionSelected2} 
                          selectedValue={selectedValue2}
                          color={color2}
                          setColor={setColor2}/>
                      </div>                     
                    </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', paddingTop: '2rem', paddingBottom: '1rem'}}>
                      {selectedValue1!==null && <div>{selectedValue1==null ? "シフトA" : selectedValue1}</div>}{selectedValue1==null && "シフトA"}
                      の次は
                      {selectedValue2!==null && <div>{selectedValue2==null ? "シフトB" : selectedValue2}</div>}{selectedValue2==null && "シフトB"}
                      の順序を禁止
                    </div>
                    </div>}

                    {pat==1 &&
                    <div> 
                    <div style={{display: 'flex', justifyContent: 'center'}}> 
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトA"} 
                          onOptionSelected={handleOptionSelected1} 
                          selectedValue={selectedValue1}
                          color={color1}
                          setColor={setColor1}/>
                      </div>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトB"} 
                          onOptionSelected={handleOptionSelected2} 
                          selectedValue={selectedValue2}
                          color={color2}
                          setColor={setColor2}/>
                      </div>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトC"} 
                          onOptionSelected={handleOptionSelected3} 
                          selectedValue={selectedValue3}
                          color={color3}
                          setColor={setColor3}/>
                      </div>                     
                    </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', paddingTop: '2rem', paddingBottom: '1rem'}}>
                      {selectedValue1!==null && <div>{selectedValue1==null ? "シフトA" : selectedValue1}</div>}{selectedValue1==null && "シフトA"}
                      の次は
                      {selectedValue2!==null && <div>{selectedValue2==null ? "シフトB" : selectedValue2}</div>}{selectedValue2==null && "シフトB"}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', paddingBottom: '1rem'}}>
                      {selectedValue2!==null && <div>{selectedValue2==null ? "シフトB" : selectedValue2}</div>}{selectedValue2==null && "シフトB"}
                      の次は
                      {selectedValue3!==null && <div>{selectedValue3==null ? "シフトC" : selectedValue3}</div>}{selectedValue3==null && "シフトC"}
                      の順序を禁止
                    </div>
                    </div>}

                    {pat==2 &&
                    <div> 
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトA"} 
                          onOptionSelected={handleOptionSelected1} 
                          selectedValue={selectedValue1}
                          color={color1}
                          setColor={setColor1}/>
                      </div>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトB"} 
                          onOptionSelected={handleOptionSelected2} 
                          selectedValue={selectedValue2}
                          color={color2}
                          setColor={setColor2}/>
                      </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', paddingTop: '0.25rem'}}>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトC"} 
                          onOptionSelected={handleOptionSelected3} 
                          selectedValue={selectedValue3}
                          color={color3}
                          setColor={setColor3}/>
                      </div>  
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトD"} 
                          onOptionSelected={handleOptionSelected4} 
                          selectedValue={selectedValue4}
                          color={color4}
                          setColor={setColor4}/>
                      </div>                     
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', paddingTop: '2rem', paddingBottom: '0.25rem'}}>
                      {selectedValue1!==null && <div>{selectedValue1==null ? "シフトA" : selectedValue1}</div>}{selectedValue1==null && "シフトA"}
                      の次は
                      {selectedValue2!==null && <div>{selectedValue2==null ? "シフトB" : selectedValue2}</div>}{selectedValue2==null && "シフトB"}
                      、
                      {selectedValue2!==null && <div>{selectedValue2==null ? "シフトB" : selectedValue2}</div>}{selectedValue2==null && "シフトB"}
                      の次は
                      {selectedValue3!==null && <div>{selectedValue3==null ? "シフトC" : selectedValue3}</div>}{selectedValue3==null && "シフトC"}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', paddingBottom: '1rem'}}>
                      {selectedValue3!==null && <div>{selectedValue3==null ? "シフトC" : selectedValue3}</div>}{selectedValue3==null && "シフトC"}
                      の次は
                      {selectedValue4!==null && <div>{selectedValue4==null ? "シフトD" : selectedValue4}</div>}{selectedValue4==null && "シフトD"}
                      の順序を禁止
                    </div>
                    </div>}

                    {pat==3 &&
                    <div> 
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトA"} 
                          onOptionSelected={handleOptionSelected1} 
                          selectedValue={selectedValue1}
                          color={color1}
                          setColor={setColor1}/>
                      </div>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトB"} 
                          onOptionSelected={handleOptionSelected2} 
                          selectedValue={selectedValue2}
                          color={color2}
                          setColor={setColor2}/>
                      </div>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトC"} 
                          onOptionSelected={handleOptionSelected3} 
                          selectedValue={selectedValue3}
                          color={color3}
                          setColor={setColor3}/>
                      </div>                    
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', paddingTop: '0.25rem'}}>
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトD"} 
                          onOptionSelected={handleOptionSelected4} 
                          selectedValue={selectedValue4}
                          color={color4}
                          setColor={setColor4}/>
                      </div>  
                      <div>
                        <ArrowRightAltOutlinedIcon style={{fontSize: '50px', padding: '0 0.3rem 0 0.3rem'}}/>
                      </div>
                      <div style={{paddingTop: '0.5rem'}}>
                        <ShowOptions 
                          name={"シフトE"} 
                          onOptionSelected={handleOptionSelected5} 
                          selectedValue={selectedValue5}
                          color={color5}
                          setColor={setColor5}/>
                      </div>                     
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', paddingTop: '2rem', paddingBottom: '0.25rem'}}>
                      {selectedValue1!==null && <div>{selectedValue1==null ? "シフトA" : selectedValue1}</div>}{selectedValue1==null && "シフトA"}
                      の次は
                      {selectedValue2!==null && <div>{selectedValue2==null ? "シフトB" : selectedValue2}</div>}{selectedValue2==null && "シフトB"}
                      、
                      {selectedValue2!==null && <div>{selectedValue2==null ? "シフトB" : selectedValue2}</div>}{selectedValue2==null && "シフトB"}
                      の次は
                      {selectedValue3!==null && <div>{selectedValue3==null ? "シフトC" : selectedValue3}</div>}{selectedValue3==null && "シフトC"}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', paddingBottom: '1rem'}}>
                      {selectedValue3!==null && <div>{selectedValue3==null ? "シフトC" : selectedValue3}</div>}{selectedValue3==null && "シフトC"}
                      の次は
                      {selectedValue4!==null && <div>{selectedValue4==null ? "シフトD" : selectedValue4}</div>}{selectedValue4==null && "シフトD"}
                      、
                      {selectedValue4!==null && <div>{selectedValue4==null ? "シフトD" : selectedValue4}</div>}{selectedValue4==null && "シフトD"}
                      の次は
                      {selectedValue5!==null && <div>{selectedValue5==null ? "シフトE" : selectedValue5}</div>}{selectedValue5==null && "シフトE"}
                      の順序を禁止
                    </div>
                    </div>}

                  </div>)}

                {topen && (
                  <div style={{minHeight: '130px' , border: '1px solid gray', marginTop: '0.5rem'}}>
                    <div>
                      <Button size="small" variant="contained" color="warning" onClick={()=>{setSample(prevState=>!prevState); setSelectedValue6(null)}}>
                        入力例・注意事項
                      </Button>
                    </div>
                    {sample && <div className='overlay' onClick={() => setSample(false)} />}
                    {sample && (
                      <div
                        className='shadow-5 bg-white border-radius-md'
                        style={{
                          zIndex: 10,
                          minWidth: 460,
                          maxWidth: 650,
                          padding: "0.75rem",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          paddingBottom: '0.5rem'
                        }}>
                        <div style={{fontSize: '13px', display: 'flex', paddingTop: '0.25rem'}}>
                        （例1）連続準深公が3回以下 → なし,常に,全て,繰り返し連続,5/,*,公,≦,3
                        <br/>
                        （例2）休日日勤回数の均等 → 1,ヶ月間,休日,なし,〇,L,=,均等
                        <br/>
                        （例3）連続勤務5回以下 → なし,常に,全て,いずれか連続,全て,≦,5
                        <br/>
                        （例4）連続日勤は4回以下 → なし,常に,全て,いずれか連続,〇,L,≦,4
                        <br/>
                        （例5）連休を1回は作る → 1,ヶ月間,全て,繰り返し連続 or いずれか2連続,公,公,≧,1
                        <br/>
                        （例6）長と準夜勤の数を揃える → 1,ヶ月間,全て,なし,長1,=,1/
                        <br/>
                        <br/>
                        ※1　期間は最大1ヶ月（4週間）です
                        <br/>
                        ※2　連続条件の場合、等式の右辺は数値のみ入力可能です
                        <br/>
                        ※3　夜勤連続条件などを追加する場合は、期間を「なし、常に」と選択してください
                        <br/>
                        ※4　1ヶ月のそれぞれのシフト数は枠数の表に入力することが望ましいです
                        <br/>
                        ※5　正しく条件を追加しなかった場合勤務表自動作成に影響を与えてしまうので注意してください
                        </div>
                      </div>
                    )}
                    <div style={{paddingLeft: '0.5rem', paddingTop: '1rem'}}>
                    <div style={{display: 'flex', justifyContent: 'center', paddingBottom: '0.5rem'}}>
                      <div>
                        <DropdownNumber1/>
                      </div>
                      <div>
                        <DropdownPeriod/>
                      </div>
                      <div>
                        <DropdownWeek/>
                      </div>
                      <div>
                        {(number1==null || period==null) ?
                          <DropdownRepeat/> 
                          : (number1.value!=="none" && period.value!=="always") ?
                          <DropdownRepeat2/> :
                          <DropdownRepeat/>
                        }
                      </div>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', paddingBottom: '0.5rem', alignItems: 'center'}}>
                      {pat2==0 &&
                        <ShowOptionsAll 
                          name={"シフト"} 
                          onOptionSelected={handleOptionSelected1} 
                          selectedValue={selectedValue1}
                          color={color1}
                          setColor={setColor1}/>}
                      <div style={{flexWrap: 'wrap'}}>
                      {pat2==1 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                        </div>}
                      {pat2==2 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                        </div>}
                      {pat2==3 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                        </div>}
                      {pat2==4 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected5} 
                            selectedValue={selectedValue5}
                            color={color5}
                            setColor={setColor5}/>
                        </div>}
                      {pat2==5 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected5} 
                            selectedValue={selectedValue5}
                            color={color5}
                            setColor={setColor5}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected7} 
                            selectedValue={selectedValue7}
                            color={color7}
                            setColor={setColor7}/>
                        </div>}
                      {pat2==6 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected5} 
                            selectedValue={selectedValue5}
                            color={color5}
                            setColor={setColor5}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected7} 
                            selectedValue={selectedValue7}
                            color={color7}
                            setColor={setColor7}/>
                          <ShowOptions 
                            name={"シフトG"} 
                            onOptionSelected={handleOptionSelected8} 
                            selectedValue={selectedValue8}
                            color={color8}
                            setColor={setColor8}/>
                        </div>}
                      {pat2==7 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected5} 
                            selectedValue={selectedValue5}
                            color={color5}
                            setColor={setColor5}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected7} 
                            selectedValue={selectedValue7}
                            color={color7}
                            setColor={setColor7}/>
                          <ShowOptions 
                            name={"シフトG"} 
                            onOptionSelected={handleOptionSelected8} 
                            selectedValue={selectedValue8}
                            color={color8}
                            setColor={setColor8}/>
                          <ShowOptions 
                            name={"シフトH"} 
                            onOptionSelected={handleOptionSelected9} 
                            selectedValue={selectedValue9}
                            color={color9}
                            setColor={setColor9}/>
                        </div>}
                      {pat2==8 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected5} 
                            selectedValue={selectedValue5}
                            color={color5}
                            setColor={setColor5}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected7} 
                            selectedValue={selectedValue7}
                            color={color7}
                            setColor={setColor7}/>
                          <ShowOptions 
                            name={"シフトG"} 
                            onOptionSelected={handleOptionSelected8} 
                            selectedValue={selectedValue8}
                            color={color8}
                            setColor={setColor8}/>
                          <ShowOptions 
                            name={"シフトH"} 
                            onOptionSelected={handleOptionSelected9} 
                            selectedValue={selectedValue9}
                            color={color9}
                            setColor={setColor9}/>
                          <ShowOptions 
                            name={"シフトI"} 
                            onOptionSelected={handleOptionSelected10} 
                            selectedValue={selectedValue10}
                            color={color10}
                            setColor={setColor10}/>
                        </div>}
                      {pat2==9 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected5} 
                            selectedValue={selectedValue5}
                            color={color5}
                            setColor={setColor5}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected7} 
                            selectedValue={selectedValue7}
                            color={color7}
                            setColor={setColor7}/>
                          <ShowOptions 
                            name={"シフトG"} 
                            onOptionSelected={handleOptionSelected8} 
                            selectedValue={selectedValue8}
                            color={color8}
                            setColor={setColor8}/>
                          <ShowOptions 
                            name={"シフトH"} 
                            onOptionSelected={handleOptionSelected9} 
                            selectedValue={selectedValue9}
                            color={color9}
                            setColor={setColor9}/>
                          <ShowOptions 
                            name={"シフトI"} 
                            onOptionSelected={handleOptionSelected10} 
                            selectedValue={selectedValue10}
                            color={color10}
                            setColor={setColor10}/>
                          <ShowOptions 
                            name={"シフトJ"} 
                            onOptionSelected={handleOptionSelected11} 
                            selectedValue={selectedValue11}
                            color={color11}
                            setColor={setColor11}/>
                        </div>}
                      </div>
                      <Button size="small" variant="contained" color="inherit" onClick={togglePat2}>
                        シフト追加
                      </Button>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <div>
                        <DropdownEqual/>
                      </div>
                      {!numopen && 
                      <div>
                        <DropdownNumber2/>
                      </div>}
                      {numopen && 
                      <ShowOptionsAll
                        name={"シフト"} 
                        onOptionSelected={handleOptionSelected6} 
                        selectedValue={selectedValue6}
                        color={color6}
                        setColor={setColor6}
                        all={"n"}/>}
                      <Button size="small" variant="contained" color="inherit" onClick={()=>{setNumopen(prevState=>!prevState); setSelectedValue6(null)}}>
                        {numopen ? "数字" : "シフト"}
                      </Button>
                    </div>
                    </div>

                    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', paddingTop: '2rem', paddingBottom: '1rem', paddingLeft: '0.25rem'}}>
                      {number1===null ? "(数字)" : number1.label!=="なし" ? number1.label : ""}
                      {period===null ? "(期間)" : period.label!=="常に" ? period.label + "のうち" : ""}
                      {week===null || week.label==="全て" ? "" : week.label+"で"}
                      {repeat!==null && repeat.label==="繰り返し連続" ? "連続" : ""}
                      {selectedValue1===null ? "(シフト)" : selectedValue1}
                      {selectedValue2===null ? "" : "・" + selectedValue2}
                      {selectedValue3===null ? "" : "・" + selectedValue3}
                      {selectedValue4===null ? "" : "・" + selectedValue4}
                      {selectedValue5===null ? "" : "・" + selectedValue5}
                      {selectedValue7===null ? "" : "・" + selectedValue7}
                      {selectedValue8===null ? "" : "・" + selectedValue8}
                      {selectedValue9===null ? "" : "・" + selectedValue9}
                      {selectedValue10===null ? "" : "・" + selectedValue10}
                      {selectedValue11===null ? "" : "・" + selectedValue11}
                      {repeat!==null && repeat.label.includes("いずれか") ? "のいずれかが" + (repeat.label!="いずれか連続" ? repeat.label.slice(4,-2) : "") + "連続するの" : ""}
                      {repeat!==null && (repeat.label==="繰り返し連続" || repeat.label==="順序通り連続") ? "の繰り返し" : ""}
                      は
                      {!numopen && number2===null ? "(数字)" : (!numopen && number2!==null) ? number2.label : ""}
                      {!numopen && "回"}
                      {numopen && selectedValue6===null ? "(シフト)" : (numopen && selectedValue6!==null) ? selectedValue6 : ""}
                      {(equal===null) ? "" : (selectedValue6==="均等") ? "" : (!numopen && equal.value==="==") ? "" : (numopen && equal.value==="==") ? "と同じ回数" : (numopen && equal.value==="!=") ? "と同じ回数ではない" : equal.value==="<" ? "未満" : equal.value===">" ? "より多い" : equal.value==="<=" ? "以下" : equal.value===">=" ? "以上" : equal.value==="!=" ? "以外" : ""}
                    </div>

                  </div>)}

                {kopen && (
                  <div style={{minHeight: '130px' , border: '1px solid gray', marginTop: '0.5rem'}}>
                    <div>
                      <Button size="small" variant="contained" color="warning" onClick={()=>{setSample(prevState=>!prevState); setSelectedValue6(null)}}>
                        入力例・注意事項
                      </Button>
                      <Button size="small" variant="contained" color="inherit" onClick={togglePat3}>
                        パターン変更IF
                      </Button>
                      <Button size="small" variant="contained" color="inherit" onClick={togglePat4}>
                        数字入力変更
                      </Button>
                    </div>
                    {sample && <div className='overlay' onClick={() => setSample(false)} />}
                    {sample && (
                      <div
                        className='shadow-5 bg-white border-radius-md'
                        style={{
                          zIndex: 10,
                          minWidth: 460,
                          maxWidth: 650,
                          padding: "0.75rem",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          paddingBottom: '0.5rem'
                        }}>
                        <div style={{fontSize: '13px', display: 'flex', paddingTop: '0.25rem'}}>
                        （例1）準夜勤にはL1以上が1人は必ず入る → なし,常に,全て,5/,全て,TL,L2,L1,≧,1
                        <br/>
                        （例2）準夜勤にM1が入るときは枠数＋1 → なし,常に,全て,5/,IF,全て,M1,,≧,1,5/,全て,全て,=,枠数,+,1
                        <br/>
                        （例3）準夜勤には男性は2人以下 → なし,常に,全て,5/,全て,男性,≦,1
                        <br/>
                        （例4）準夜勤にL1以上が1人しかいなければLが1人以上入る → なし,常に,全て,5/,IF,全て,L2,L1,=,1,5/,全て,L,≧,1
                        <br/>
                        （例5）準夜勤にM1が入るなら遅番はM以上 → なし,常に,全て,5/,IF,全て,M1 ≧ 1,遅J,全て,TL,L2,L1,L,M,≧,1
                        <br/>
                        <br/>
                        ※1　スキルや性別によって人数制限をかけるための条件です
                        <br/>
                        ※2　パターン変更IFボタンは「AならばB」という条件を作成するためのものです
                        <br/>
                        ※3　数字入力変更ボタンはシフトの枠数に四則演算で制限をかけるためのものです
                        </div>
                      </div>
                    )}
                    <div style={{paddingLeft: '0.5rem', paddingTop: '1rem'}}>
                    <div style={{display: 'flex', justifyContent: 'center', paddingBottom: '0.5rem'}}>
                      <div>
                        <DropdownNumber1/>
                      </div>
                      <div>
                        <DropdownPeriod/>
                      </div>
                      <div>
                        <DropdownWeek/>
                      </div>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', paddingBottom: '0.25rem', alignItems: 'center'}}>
                      {pat==1 && 
                        <div style={{paddingRight: '0.5rem'}}>
                        <div style={{fontSize: '20px', backgroundColor: 'lightblue', border: '1px solid gray', borderRadius: 10, height: '30px', width: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          IF
                        </div>
                        </div>
                        }
                        {pat2==0 &&
                        <div style={{alignItems: 'center', display: 'flex'}}>
                          <ShowOptionsAll 
                            name={"シフト"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                        </div>}
                        {pat2==0 && 
                        <div style={{display:'flex', alignItems: 'center'}}>
                        <Button size="small" variant="contained" color="inherit" onClick={togglePat2} style={{height: '40px'}}>
                          シフト追加
                        </Button>
                        </div>}  
                      <div style={{flexWrap: 'wrap'}}>
                      {pat2==1 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                        </div>}
                      {pat2==2 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                        </div>}
                      {pat2==3 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                        </div>}
                      {pat2==4 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected5} 
                            selectedValue={selectedValue5}
                            color={color5}
                            setColor={setColor5}/>
                        </div>}
                      {pat2==5 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected5} 
                            selectedValue={selectedValue5}
                            color={color5}
                            setColor={setColor5}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected7} 
                            selectedValue={selectedValue7}
                            color={color7}
                            setColor={setColor7}/>
                        </div>}
                      {pat2==6 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected5} 
                            selectedValue={selectedValue5}
                            color={color5}
                            setColor={setColor5}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected7} 
                            selectedValue={selectedValue7}
                            color={color7}
                            setColor={setColor7}/>
                          <ShowOptions 
                            name={"シフトG"} 
                            onOptionSelected={handleOptionSelected8} 
                            selectedValue={selectedValue8}
                            color={color8}
                            setColor={setColor8}/>
                        </div>}
                      {pat2==7 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected5} 
                            selectedValue={selectedValue5}
                            color={color5}
                            setColor={setColor5}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected7} 
                            selectedValue={selectedValue7}
                            color={color7}
                            setColor={setColor7}/>
                          <ShowOptions 
                            name={"シフトG"} 
                            onOptionSelected={handleOptionSelected8} 
                            selectedValue={selectedValue8}
                            color={color8}
                            setColor={setColor8}/>
                          <ShowOptions 
                            name={"シフトH"} 
                            onOptionSelected={handleOptionSelected9} 
                            selectedValue={selectedValue9}
                            color={color9}
                            setColor={setColor9}/>
                        </div>}
                      {pat2==8 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected5} 
                            selectedValue={selectedValue5}
                            color={color5}
                            setColor={setColor5}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected7} 
                            selectedValue={selectedValue7}
                            color={color7}
                            setColor={setColor7}/>
                          <ShowOptions 
                            name={"シフトG"} 
                            onOptionSelected={handleOptionSelected8} 
                            selectedValue={selectedValue8}
                            color={color8}
                            setColor={setColor8}/>
                          <ShowOptions 
                            name={"シフトH"} 
                            onOptionSelected={handleOptionSelected9} 
                            selectedValue={selectedValue9}
                            color={color9}
                            setColor={setColor9}/>
                          <ShowOptions 
                            name={"シフトI"} 
                            onOptionSelected={handleOptionSelected10} 
                            selectedValue={selectedValue10}
                            color={color10}
                            setColor={setColor10}/>
                        </div>}
                      {pat2==9 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected5} 
                            selectedValue={selectedValue5}
                            color={color5}
                            setColor={setColor5}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected7} 
                            selectedValue={selectedValue7}
                            color={color7}
                            setColor={setColor7}/>
                          <ShowOptions 
                            name={"シフトG"} 
                            onOptionSelected={handleOptionSelected8} 
                            selectedValue={selectedValue8}
                            color={color8}
                            setColor={setColor8}/>
                          <ShowOptions 
                            name={"シフトH"} 
                            onOptionSelected={handleOptionSelected9} 
                            selectedValue={selectedValue9}
                            color={color9}
                            setColor={setColor9}/>
                          <ShowOptions 
                            name={"シフトI"} 
                            onOptionSelected={handleOptionSelected10} 
                            selectedValue={selectedValue10}
                            color={color10}
                            setColor={setColor10}/>
                          <ShowOptions 
                            name={"シフトJ"} 
                            onOptionSelected={handleOptionSelected11} 
                            selectedValue={selectedValue11}
                            color={color11}
                            setColor={setColor11}/>
                        </div>}
                      </div>
                      {pat2>=1 && <Button size="small" variant="contained" color="inherit" onClick={togglePat2}>
                        シフト追加
                      </Button>}
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', paddingTop: '0.25rem', paddingBottom: '0.25rem', alignItems: 'center', backgroundColor: pat==1 && 'lightblue', marginLeft: '1rem', marginRight: '1rem'}}>
                      <div style={{minWidth: '100px'}}>
                        <DropdownTeam/>
                      </div>
                      <div>
                        <DropdownSkill/>
                      </div>
                      <div style={{minWidth: '70px'}}>
                        <DropdownEqual/>
                      </div>
                      {((pat==0 && pat4==1) || (pat==0 && pat4==3) || (pat==1 && pat4==1)  || (pat==1 && pat4==3)) &&
                      <div style={{fontSize: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '0.2rem', paddingRight: '0.2rem'}}>
                        枠数
                      </div>
                      }
                      {((pat==0 && pat4==1) || (pat==0 && pat4==3) || (pat==1 && pat4==1)  || (pat==1 && pat4==3)) &&
                      <div style={{minWidth: '70px'}}>
                        <DropdownArith/>
                      </div>
                      }
                      <div style={{minWidth: '70px'}}>
                        <DropdownNumber22/>
                      </div>

                    </div>
                    
                    {pat==1 && 
                    <div>
                    <div style={{display: 'flex', justifyContent: 'center', paddingTop: '0.25rem', paddingBottom: '0.25rem', alignItems: 'center'}}>
                      {pat==1 && 
                        <div style={{paddingRight: '0.5rem'}}>
                        <div style={{fontSize: '20px', backgroundColor: 'lightgreen', border: '1px solid gray', borderRadius: 10, height: '30px', width: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          ⇒
                        </div>
                        </div>
                        }
                        {pat22==0 &&
                        <div style={{alignItems: 'center', display: 'flex'}}>
                          <ShowOptionsAll 
                            name={"シフト"} 
                            onOptionSelected={handleOptionSelected22} 
                            selectedValue={selectedValue22}
                            color={color22}
                            setColor={setColor22}/>
                        </div>}
                        {pat22==0 && 
                        <div style={{display:'flex', alignItems: 'center'}}>
                        <Button size="small" variant="contained" color="inherit" onClick={togglePat22} style={{height: '40px'}}>
                          シフト追加
                        </Button>
                        </div>}  
                      <div style={{flexWrap: 'wrap'}}>
                      {pat22==1 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected22} 
                            selectedValue={selectedValue22}
                            color={color22}
                            setColor={setColor22}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected32} 
                            selectedValue={selectedValue32}
                            color={color32}
                            setColor={setColor32}/>
                        </div>}
                      {pat22==2 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected22} 
                            selectedValue={selectedValue22}
                            color={color22}
                            setColor={setColor22}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected32} 
                            selectedValue={selectedValue32}
                            color={color32}
                            setColor={setColor32}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected42} 
                            selectedValue={selectedValue42}
                            color={color42}
                            setColor={setColor42}/>
                        </div>}
                      {pat22==3 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected22} 
                            selectedValue={selectedValue22}
                            color={color22}
                            setColor={setColor22}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected32} 
                            selectedValue={selectedValue32}
                            color={color32}
                            setColor={setColor32}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected42} 
                            selectedValue={selectedValue42}
                            color={color42}
                            setColor={setColor42}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected52} 
                            selectedValue={selectedValue52}
                            color={color52}
                            setColor={setColor52}/>
                        </div>}
                      {pat22==4 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected22} 
                            selectedValue={selectedValue22}
                            color={color22}
                            setColor={setColor22}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected32} 
                            selectedValue={selectedValue32}
                            color={color32}
                            setColor={setColor32}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected42} 
                            selectedValue={selectedValue42}
                            color={color42}
                            setColor={setColor42}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected52} 
                            selectedValue={selectedValue52}
                            color={color52}
                            setColor={setColor52}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected62} 
                            selectedValue={selectedValue62}
                            color={color62}
                            setColor={setColor62}/>
                        </div>}
                      {pat22==5 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected22} 
                            selectedValue={selectedValue22}
                            color={color22}
                            setColor={setColor22}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected32} 
                            selectedValue={selectedValue32}
                            color={color32}
                            setColor={setColor32}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected42} 
                            selectedValue={selectedValue42}
                            color={color42}
                            setColor={setColor42}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected52} 
                            selectedValue={selectedValue52}
                            color={color52}
                            setColor={setColor52}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected62} 
                            selectedValue={selectedValue62}
                            color={color62}
                            setColor={setColor62}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected72} 
                            selectedValue={selectedValue72}
                            color={color72}
                            setColor={setColor72}/>
                        </div>}
                      {pat22==6 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected22} 
                            selectedValue={selectedValue22}
                            color={color22}
                            setColor={setColor22}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected32} 
                            selectedValue={selectedValue32}
                            color={color32}
                            setColor={setColor32}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected42} 
                            selectedValue={selectedValue42}
                            color={color42}
                            setColor={setColor42}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected52} 
                            selectedValue={selectedValue52}
                            color={color52}
                            setColor={setColor52}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected62} 
                            selectedValue={selectedValue62}
                            color={color62}
                            setColor={setColor62}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected72} 
                            selectedValue={selectedValue72}
                            color={color72}
                            setColor={setColor72}/>
                          <ShowOptions 
                            name={"シフトG"} 
                            onOptionSelected={handleOptionSelected82} 
                            selectedValue={selectedValue82}
                            color={color82}
                            setColor={setColor82}/>
                        </div>}
                      {pat22==7 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected22} 
                            selectedValue={selectedValue22}
                            color={color22}
                            setColor={setColor22}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected32} 
                            selectedValue={selectedValue32}
                            color={color32}
                            setColor={setColor32}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected42} 
                            selectedValue={selectedValue42}
                            color={color42}
                            setColor={setColor42}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected52} 
                            selectedValue={selectedValue52}
                            color={color52}
                            setColor={setColor52}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected62} 
                            selectedValue={selectedValue62}
                            color={color62}
                            setColor={setColor62}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected72} 
                            selectedValue={selectedValue72}
                            color={color72}
                            setColor={setColor72}/>
                          <ShowOptions 
                            name={"シフトG"} 
                            onOptionSelected={handleOptionSelected82} 
                            selectedValue={selectedValue82}
                            color={color82}
                            setColor={setColor82}/>
                          <ShowOptions 
                            name={"シフトH"} 
                            onOptionSelected={handleOptionSelected92} 
                            selectedValue={selectedValue92}
                            color={color92}
                            setColor={setColor92}/>
                        </div>}
                      {pat22==8 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected22} 
                            selectedValue={selectedValue22}
                            color={color22}
                            setColor={setColor22}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected32} 
                            selectedValue={selectedValue32}
                            color={color32}
                            setColor={setColor32}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected42} 
                            selectedValue={selectedValue42}
                            color={color42}
                            setColor={setColor42}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected52} 
                            selectedValue={selectedValue52}
                            color={color52}
                            setColor={setColor52}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected62} 
                            selectedValue={selectedValue62}
                            color={color62}
                            setColor={setColor62}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected72} 
                            selectedValue={selectedValue72}
                            color={color72}
                            setColor={setColor72}/>
                          <ShowOptions 
                            name={"シフトG"} 
                            onOptionSelected={handleOptionSelected82} 
                            selectedValue={selectedValue82}
                            color={color82}
                            setColor={setColor82}/>
                          <ShowOptions 
                            name={"シフトH"} 
                            onOptionSelected={handleOptionSelected92} 
                            selectedValue={selectedValue92}
                            color={color92}
                            setColor={setColor92}/>
                          <ShowOptions 
                            name={"シフトI"} 
                            onOptionSelected={handleOptionSelected102} 
                            selectedValue={selectedValue102}
                            color={color102}
                            setColor={setColor102}/>
                        </div>}
                      {pat22==9 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected22} 
                            selectedValue={selectedValue22}
                            color={color22}
                            setColor={setColor22}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected32} 
                            selectedValue={selectedValue32}
                            color={color32}
                            setColor={setColor32}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected42} 
                            selectedValue={selectedValue42}
                            color={color42}
                            setColor={setColor42}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected52} 
                            selectedValue={selectedValue52}
                            color={color52}
                            setColor={setColor52}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected62} 
                            selectedValue={selectedValue62}
                            color={color62}
                            setColor={setColor62}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected72} 
                            selectedValue={selectedValue72}
                            color={color72}
                            setColor={setColor72}/>
                          <ShowOptions 
                            name={"シフトG"} 
                            onOptionSelected={handleOptionSelected82} 
                            selectedValue={selectedValue82}
                            color={color82}
                            setColor={setColor82}/>
                          <ShowOptions 
                            name={"シフトH"} 
                            onOptionSelected={handleOptionSelected92} 
                            selectedValue={selectedValue92}
                            color={color92}
                            setColor={setColor92}/>
                          <ShowOptions 
                            name={"シフトI"} 
                            onOptionSelected={handleOptionSelected102} 
                            selectedValue={selectedValue102}
                            color={color102}
                            setColor={setColor102}/>
                          <ShowOptions 
                            name={"シフトJ"} 
                            onOptionSelected={handleOptionSelected112} 
                            selectedValue={selectedValue112}
                            color={color112}
                            setColor={setColor112}/>
                        </div>}
                      </div>
                      {pat22>=1 && <Button size="small" variant="contained" color="inherit" onClick={togglePat22}>
                        シフト追加
                      </Button>}
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '0.25rem', paddingBottom: '0.25rem', backgroundColor: 'lightgreen', marginLeft: '1rem', marginRight: '1rem'}}>
                      <div>
                        <DropdownTeam2/>
                      </div>
                      <div>
                        <DropdownSkill2/>
                      </div>
                      <div style={{minWidth: '70px'}}>
                        <DropdownEqual2/>
                      </div>
                      {((pat==1 && pat4==3) || (pat==1 && pat4==2)) &&
                      <div style={{fontSize: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '0.2rem', paddingRight: '0.2rem'}}>
                        枠数
                      </div>
                      }
                      {((pat==1 && pat4==3) || (pat==1 && pat4==2)) &&
                      <div style={{minWidth: '70px'}}>
                        <DropdownArith2/>
                      </div>
                      }
                      <div style={{minWidth: '70px'}}>
                        <DropdownNumber3/>
                      </div>
                    </div>
                    </div>}
                    </div>

                    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', paddingTop: '2rem', paddingBottom: '1rem', paddingLeft: '0.25rem'}}>
                      {number1===null ? "(数字)" : number1.label!=="なし" ? number1.label : ""}
                      {period===null ? "(期間)" : period.label!=="常に" ? period.label + "のうち" : ""}
                      {week===null || week.label==="全て" ? "" : week.label+"で"}
                      {pat==1 ? "もし" : ""}
                      {selectedValue1===null ? "(シフト)" : selectedValue1}
                      {selectedValue2===null ? "" : "・" + selectedValue2}
                      {selectedValue3===null ? "" : "・" + selectedValue3}
                      {selectedValue4===null ? "" : "・" + selectedValue4}
                      {selectedValue5===null ? "" : "・" + selectedValue5}
                      {selectedValue7===null ? "" : "・" + selectedValue7}
                      {selectedValue8===null ? "" : "・" + selectedValue8}
                      {selectedValue9===null ? "" : "・" + selectedValue9}
                      {selectedValue10===null ? "" : "・" + selectedValue10}
                      {selectedValue11===null ? "" : "・" + selectedValue11}
                      {pat==1 ? "で" : "は"}
                      {team1.length==0 ? "(チーム)" : team1[0]=="全て" ? "" : team1.length>=2 ? team1.map((item,i)=>{if (i==team1.length-1){return item}else {return item + "と"}}) : team1.map((item)=>{return item})}
                      {team1[0]=="各チーム" ? "で" : team1[0]=="全て" ? "" : "のチームで"}
                      {skill.length==0 ? "(スキル)" : skill.length>=2 ? skill.map((item,i)=>{if (i==skill.length-1){return item + "の合計"}else {return item + "と"}}) : skill.map((item)=>{return item})}
                      {skill[0]=="全て" && "のスタッフ"}
                      が
                      {((pat==0 && pat4==1) || (pat==0 && pat4==3) || (pat==1 && pat4==1)  || (pat==1 && pat4==3)) &&
                      "枠数" +
                      (arith!==null ? arith.label : "(四則演算子)")}
                      {number22===null ? "(数字)" : number22.label}
                      {!((pat==0 && pat4==1) || (pat==0 && pat4==3) || (pat==1 && pat4==1)  || (pat==1 && pat4==3)) &&
                      "人"}
                      {((equal===null) ? "" : (equal.value==="==") ? "" : equal.value==="<" ? "未満" : equal.value===">" ? "より多い" : equal.value==="<=" ? "以下" : equal.value===">=" ? "以上" : equal.value==="!=" ? "以外" : "")}
                      {pat==1 ? "なら" : ""}
                      
                      {(pat!==1) ? "" : (pat==1 && selectedValue22===null) ? "(シフト)" : selectedValue22}
                      {(pat!==1 || selectedValue32===null) ? "" : "・" + selectedValue32}
                      {(pat!==1 || selectedValue42===null) ? "" : "・" + selectedValue42}
                      {(pat!==1 || selectedValue52===null )? "" : "・" + selectedValue52}
                      {(pat!==1 || selectedValue62===null )? "" : "・" + selectedValue62}
                      {(pat!==1 || selectedValue72===null )? "" : "・" + selectedValue72}
                      {(pat!==1 || selectedValue82===null )? "" : "・" + selectedValue82}
                      {(pat!==1 || selectedValue92===null )? "" : "・" + selectedValue92}
                      {(pat!==1 || selectedValue102===null )? "" : "・" + selectedValue102}
                      {(pat!==1 || selectedValue112===null )? "" : "・" + selectedValue112}
                      {pat==0 ? "" : "では"}
                      {pat==0 ? "" : team2.length==0 ? "(チーム)" : team2[0]=="全て" ? "" : team2.length>=2 ? team2.map((item,i)=>{if (i==team2.length-1){return item}else {return item + "と"}}) : team2.map((item)=>{return item})}
                      {pat==0 ? "" : team2[0]=="各チーム" ? "で" : team2[0]=="全て" ? "" : "のチームで"}
                      {pat==0 ? "" : skill2.length==0 ? "(スキル)" : skill2.length>=2 ? skill2.map((item,i)=>{if (i==skill2.length-1){return item + "の合計"}else {return item + "と"}}) : skill2.map((item)=>{return item})}
                      {skill2[0]=="全て" && "のスタッフ"}
                      {pat==0 ? "" : "が"}
                      {((pat==1 && pat4==3) || (pat==1 && pat4==2)) &&
                      "枠数" +
                      (arith2!==null ? arith2.label : "(四則演算子)")}
                      {pat==0 ? "" : number3===null ? "(数字)" : number3.label}
                      {(pat==1 && !((pat==1 && pat4==3) || (pat==1 && pat4==2))) &&
                      "人"}
                      {pat==0 ? "" : (equal2===null) ? "" : (equal2.value==="==") ? "" : equal2.value==="<" ? "未満" : equal2.value===">" ? "より多い" : equal2.value==="<=" ? "以下" : equal2.value===">=" ? "以上" : equal2.value==="!=" ? "以外" : ""}                      
                    </div>

                  </div>)}

                {sopen && (
                  <div style={{minHeight: '130px' , border: '1px solid gray', marginTop: '0.5rem'}}>
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', paddingTop: '2rem'}}>
                      <DropdownConst/>
                    </div>
                  </div>)}

                {teamopen && (
                  <div style={{minHeight: '130px' , border: '1px solid gray', marginTop: '0.5rem'}}>
                    <div>
                      <Button size="small" variant="contained" color="warning" onClick={()=>{setSample(prevState=>!prevState); setSelectedValue6(null)}}>
                        入力例・注意事項
                      </Button>
                    </div>
                    {sample && <div className='overlay' onClick={() => setSample(false)} />}
                    {sample && (
                      <div
                        className='shadow-5 bg-white border-radius-md'
                        style={{
                          zIndex: 10,
                          minWidth: 460,
                          maxWidth: 650,
                          padding: "0.75rem",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          paddingBottom: '0.5rem'
                        }}>
                        <div style={{fontSize: '13px', display: 'flex', paddingTop: '0.25rem'}}>
                        <br/>
                        （例）日勤のチームまたぎは2人まで可 → 〇,全て,全て,≦,2
                        <br/>
                        <br/>
                        ※1　チームまたぎをシフトやスキルごとに設定する条件です
                        <br/>
                        ※2　チームまたぎが不可の場合は条件を追加する必要はありません。チームまたぎを認める場合のみ条件を追加してください
                        </div>
                      </div>
                    )}
                    <div style={{paddingLeft: '0.5rem', paddingTop: '1rem'}}>
                    <div style={{display: 'flex', justifyContent: 'center', paddingBottom: '0.25rem', alignItems: 'center'}}>
                      {pat==1 && 
                        <div style={{paddingRight: '0.5rem'}}>
                        <div style={{fontSize: '20px', backgroundColor: 'lightblue', border: '1px solid gray', borderRadius: 10, height: '30px', width: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          IF
                        </div>
                        </div>
                        }
                        {pat2==0 &&
                        <div style={{alignItems: 'center', display: 'flex'}}>
                          <ShowOptionsAll 
                            name={"シフト"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                        </div>}
                        {pat2==0 && 
                        <div style={{display:'flex', alignItems: 'center'}}>
                        <Button size="small" variant="contained" color="inherit" onClick={togglePat2} style={{height: '40px'}}>
                          シフト追加
                        </Button>
                        </div>}  
                      <div style={{flexWrap: 'wrap'}}>
                      {pat2==1 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                        </div>}
                      {pat2==2 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                        </div>}
                      {pat2==3 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                        </div>}
                      {pat2==4 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected5} 
                            selectedValue={selectedValue5}
                            color={color5}
                            setColor={setColor5}/>
                        </div>}
                      {pat2==5 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected5} 
                            selectedValue={selectedValue5}
                            color={color5}
                            setColor={setColor5}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected7} 
                            selectedValue={selectedValue7}
                            color={color7}
                            setColor={setColor7}/>
                        </div>}
                      {pat2==6 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected5} 
                            selectedValue={selectedValue5}
                            color={color5}
                            setColor={setColor5}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected7} 
                            selectedValue={selectedValue7}
                            color={color7}
                            setColor={setColor7}/>
                          <ShowOptions 
                            name={"シフトG"} 
                            onOptionSelected={handleOptionSelected8} 
                            selectedValue={selectedValue8}
                            color={color8}
                            setColor={setColor8}/>
                        </div>}
                      {pat2==7 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected5} 
                            selectedValue={selectedValue5}
                            color={color5}
                            setColor={setColor5}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected7} 
                            selectedValue={selectedValue7}
                            color={color7}
                            setColor={setColor7}/>
                          <ShowOptions 
                            name={"シフトG"} 
                            onOptionSelected={handleOptionSelected8} 
                            selectedValue={selectedValue8}
                            color={color8}
                            setColor={setColor8}/>
                          <ShowOptions 
                            name={"シフトH"} 
                            onOptionSelected={handleOptionSelected9} 
                            selectedValue={selectedValue9}
                            color={color9}
                            setColor={setColor9}/>
                        </div>}
                      {pat2==8 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected5} 
                            selectedValue={selectedValue5}
                            color={color5}
                            setColor={setColor5}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected7} 
                            selectedValue={selectedValue7}
                            color={color7}
                            setColor={setColor7}/>
                          <ShowOptions 
                            name={"シフトG"} 
                            onOptionSelected={handleOptionSelected8} 
                            selectedValue={selectedValue8}
                            color={color8}
                            setColor={setColor8}/>
                          <ShowOptions 
                            name={"シフトH"} 
                            onOptionSelected={handleOptionSelected9} 
                            selectedValue={selectedValue9}
                            color={color9}
                            setColor={setColor9}/>
                          <ShowOptions 
                            name={"シフトI"} 
                            onOptionSelected={handleOptionSelected10} 
                            selectedValue={selectedValue10}
                            color={color10}
                            setColor={setColor10}/>
                        </div>}
                      {pat2==9 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <ShowOptions 
                            name={"シフトA"} 
                            onOptionSelected={handleOptionSelected1} 
                            selectedValue={selectedValue1}
                            color={color1}
                            setColor={setColor1}/>
                          <ShowOptions 
                            name={"シフトB"} 
                            onOptionSelected={handleOptionSelected2} 
                            selectedValue={selectedValue2}
                            color={color2}
                            setColor={setColor2}/>
                          <ShowOptions 
                            name={"シフトC"} 
                            onOptionSelected={handleOptionSelected3} 
                            selectedValue={selectedValue3}
                            color={color3}
                            setColor={setColor3}/>
                          <ShowOptions 
                            name={"シフトD"} 
                            onOptionSelected={handleOptionSelected4} 
                            selectedValue={selectedValue4}
                            color={color4}
                            setColor={setColor4}/>
                          <ShowOptions 
                            name={"シフトE"} 
                            onOptionSelected={handleOptionSelected5} 
                            selectedValue={selectedValue5}
                            color={color5}
                            setColor={setColor5}/>
                          <ShowOptions 
                            name={"シフトF"} 
                            onOptionSelected={handleOptionSelected7} 
                            selectedValue={selectedValue7}
                            color={color7}
                            setColor={setColor7}/>
                          <ShowOptions 
                            name={"シフトG"} 
                            onOptionSelected={handleOptionSelected8} 
                            selectedValue={selectedValue8}
                            color={color8}
                            setColor={setColor8}/>
                          <ShowOptions 
                            name={"シフトH"} 
                            onOptionSelected={handleOptionSelected9} 
                            selectedValue={selectedValue9}
                            color={color9}
                            setColor={setColor9}/>
                          <ShowOptions 
                            name={"シフトI"} 
                            onOptionSelected={handleOptionSelected10} 
                            selectedValue={selectedValue10}
                            color={color10}
                            setColor={setColor10}/>
                          <ShowOptions 
                            name={"シフトJ"} 
                            onOptionSelected={handleOptionSelected11} 
                            selectedValue={selectedValue11}
                            color={color11}
                            setColor={setColor11}/>
                        </div>}
                      </div>
                      {pat2>=1 && <Button size="small" variant="contained" color="inherit" onClick={togglePat2}>
                        シフト追加
                      </Button>}
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', paddingTop: '0.25rem', paddingBottom: '0.25rem', alignItems: 'center', backgroundColor: pat==1 && 'lightblue', marginLeft: '1rem', marginRight: '1rem'}}>
                      <div style={{minWidth: '100px'}}>
                        <DropdownTeam/>
                      </div>
                      <div>
                        <DropdownSkilla/>
                      </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', paddingTop: '0.25rem', paddingBottom: '0.25rem', alignItems: 'center', backgroundColor: pat==1 && 'lightblue', marginLeft: '1rem', marginRight: '1rem'}}>
                      <div style={{paddingRight: '0.5rem'}}>
                        チームまたぎ
                      </div>
                      <div style={{minWidth: '70px'}}>
                        <DropdownEquala/>
                      </div>
                      <div style={{minWidth: '70px'}}>
                        <DropdownNumber22/>
                      </div>

                    </div>
                    
                    </div>

                    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', paddingTop: '2rem', paddingBottom: '1rem', paddingLeft: '0.25rem'}}>
                      {selectedValue1===null ? "(シフト)" : selectedValue1}
                      {selectedValue2===null ? "" : "・" + selectedValue2}
                      {selectedValue3===null ? "" : "・" + selectedValue3}
                      {selectedValue4===null ? "" : "・" + selectedValue4}
                      {selectedValue5===null ? "" : "・" + selectedValue5}
                      {selectedValue7===null ? "" : "・" + selectedValue7}
                      {selectedValue8===null ? "" : "・" + selectedValue8}
                      {selectedValue9===null ? "" : "・" + selectedValue9}
                      {selectedValue10===null ? "" : "・" + selectedValue10}
                      {selectedValue11===null ? "" : "・" + selectedValue11}
                      は
                      {team1.length==0 ? "(チーム)" : team1[0]=="全て" ? "" : team1.length>=2 ? team1.map((item,i)=>{if (i==team1.length-1){return item}else {return item + "と"}}) : team1.map((item)=>{return item})}
                      {team1[0]=="各チーム" ? "、" : team1[0]=="全て" ? "" : "のチーム、"}
                      {skill.length==0 ? "(スキル)" : skill.length>=2 ? skill.map((item,i)=>{if (i==skill.length-1){return item + "の合計"}else {return item + "と"}}) : skill.map((item)=>{return item})}
                      {skill[0]=="全て" && "のスタッフ"}
                      でチームまたぎは
                      {number22===null ? "(数字)" : number22.label}
                      人
                      {((equal===null) ? "" : (equal.value==="==") ? "" : equal.value==="<" ? "未満" : equal.value===">" ? "より多い" : equal.value==="<=" ? "以下" : equal.value===">=" ? "以上" : equal.value==="!=" ? "以外" : "")}                      
                    </div>

                  </div>)}


                {(oopen || topen || kopen || sopen || boopen || teamopen) &&
                <div>
                <div style={{display: 'flex', justifyContent: 'center', paddingTop: '1rem'}}>
                  <Slider value={cost} onValueChange={handleCostChange}/>
                </div>
                <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: '0.25rem'}}>
                  <Button size="small" variant="contained" color="info" onClick={handleClose}>
                    追加
                  </Button>
                </div>
                </div>}
            </div>
          )}
        </div>
      );
    }
    
    return (
      <div className="excel-table-container">
        <div className="foo">
        </div>
        <div>
          <TableShow/>
        </div>
      </div>
    );
  }
}

export default ExcelRequestsImport;